import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { GetPlayerWeeklyTasksEventsRequest } from '../interfaces/get-player-weekly-tasks-events.request.interface';
import { GetPlayerWeeklyTasksInteractionsRequest } from '../interfaces/get-player-weekly-tasks-interactions.request.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiBusinessWeekly {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
  ) {
  }

  playerWeeklyTasksEvents({week, year, playerId}: GetPlayerWeeklyTasksEventsRequest) {
    const options: ApiOptions = {
      params: {
        week,
        year,
      }
    };

    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`business/player/${playerId}/weekly/tasks/events`, options);
  }

  playerWeeklyTasksInteractions({week, year, playerId}: GetPlayerWeeklyTasksInteractionsRequest) {
    const options: ApiOptions = {
      params: {
        week,
        year,
      }
    };

    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`business/player/${playerId}/weekly/tasks/interactions`, options);
  }
}
