import { Injectable } from "@angular/core";
import * as R from "ramda";

import { MENU_BUTTONS_CONFIG } from "../../const/core/menu-buttons.const";
import { CUSTOM_MENU_BUTTONS_CONFIG } from "../../const/custom/menu-buttons-custom.const";
import { ButtonMenuConfigFilter, MappedMenuButtonConfig, MenuButtonConfig } from "../../interfaces/core/menu-buttonts-config.interface";

@Injectable({
  providedIn: "root",
})
export class MenuButtonsConfigService {
  buttons: MenuButtonConfig[] = [];

  constructor() {
    const config = R.mergeDeepRight(MENU_BUTTONS_CONFIG, CUSTOM_MENU_BUTTONS_CONFIG);
    this.buttons = Object.values(config);
  }

  getButtonsMenuConfig(config: ButtonMenuConfigFilter): MappedMenuButtonConfig[] {
    let buttons = R.clone(this.buttons)
      .filter(button => button.place === config.place)
      .filter(button => button.gui_unlocks_button === null || config.gui_unlocks[button.gui_unlocks_button])
      .map(button => (button.group_name ? button : { ...button, isVisible: true }));
    buttons = R.sortBy(R.prop("order"))(buttons);
    buttons = buttons.reduce((result, button) => {
      if (button.type === "group") {
        if (config.isDialog) {
          return result;
        }

        let groupButtons = buttons.filter(btn => btn.group_name === button.name);
        if (config.sceneConfig.gui_unlocks_button) {
          // filter the buttons depending on the current map -> do not show button for current map
          groupButtons = groupButtons.filter(b => b.gui_unlocks_button !== config.sceneConfig.gui_unlocks_button);
        }

        switch (button.group_variant) {
          case 1:
            if (groupButtons.length === 1) {
              // if there is only one map button available - show it directly
              result.push({ ...groupButtons[0], isVisible: true });
            } else if (groupButtons.length > 1) {
              // if there is more than one map button - show pin button with all map buttons in group
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
          default:
            // always show pin button, even if there's only one map button available
            if (groupButtons.length > 0) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
        }
      } else if (!button.group_name || config.isDialog) {
        result.push(button);
      }
      return result;
    }, []);
    return buttons;
  }
}
