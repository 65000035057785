import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { MgmInviteeComponent } from "../../base/custom/dialogs/mgm-invitee/mgm-invitee.component";
import { MgmInviterComponent } from "../../base/custom/dialogs/mgm-inviter/mgm-inviter.component";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

@NgModule({
  declarations: [MgmInviteeComponent, MgmInviterComponent],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, FormsModule, ReactiveFormsModule, OwPerfectScrollbarModule],
})
export class MgmModule {}
