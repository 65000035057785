import * as moment from "moment";
import { Moment } from "moment";
import { timer } from "rxjs";

import { PHASER_CAMERA_ZOOM, TILE_TOOLTIP_DEPTH, TileTooltipType } from "../../../../constants";
import { isAutoproduction } from "../../../../game-gui/helpers/buildings.helper";
import { GameService } from "../../../../services/game.service";
import { TileTooltipConfig } from "../../../interfaces/tile-tooltip-config.interface";
import { TILE_MENU_ATLAS } from "../../../scenes-main/main.constants";
import { CustomWorldMainScene } from "../../../scenes-world/custom/CustomWorld.main.scene";
import { determineTileTooltipPositionOffset } from "../../../utils/board.helper";
import { isAssetLoadedToPhaserCache } from "../../../utils/game.helper";
import { addTextShadow, isoToScreen, keepScale1 } from "../../../utils/utils";
import { MyScene } from "../../core/MyScene";
import { BoardTile } from "../../custom/BoardTile.class";

export class TileTooltipCore extends Phaser.GameObjects.Container {
  config: TileTooltipConfig;
  boardTile: BoardTile;
  tooltipImage: Phaser.GameObjects.Sprite;
  animationTween;
  tooltipType: TileTooltipType;
  destroying: boolean;

  counter: TooltipCounter;

  gameService: GameService;
  noTween: boolean;

  collectValueText: Phaser.GameObjects.Text;
  backgroundImage: Phaser.GameObjects.Image;
  productionFinishValue: number;

  constructor(scene: MyScene, tile: BoardTile, type: TileTooltipType) {
    super(scene, 0, 0);

    this.provideConfig(scene.sceneConfig.tileTooltipConfig);
    this.beforeCreate();

    this.gameService = scene.gameService;
    this.boardTile = tile;
    this.scene.add.existing(this);
    this.setDepth(TILE_TOOLTIP_DEPTH);
    this.setType(type);

    this.scalingListener();
  }

  /**
   * Override this method to provide custom logic for config providing.
   * Default config is passed when this method is executed on a class constructor.
   */
  provideConfig(config: TileTooltipConfig) {
    this.config = config;
  }

  scalingListener() {
    keepScale1(this, this.scene.cameras.main?.zoom, this.config.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.config.maxZoomScaleValue);
    });
  }

  beforeCreate() {}

  setType(type: TileTooltipType) {
    this.tooltipType = type;
    if (
      (!this.boardTile.isBuildInProgress() && (this.isProductionInProgress() || this.isProductionFinished() || this.isAutoProduction())) ||
      this.boardTile.hasMission()
    ) {
      this.backgroundImage = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
      this.backgroundImage.setOrigin(0.5);
      this.backgroundImage.setScale(this.config.scaleFactor);
      this.add(this.backgroundImage);
      this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spinner.png");
      this.tooltipImage.setScale(this.config.scaleFactor);
      this.tooltipImage.setOrigin(0.5, 0.5);

      let icon = null;
      if (this.boardTile.hasMission()) {
        icon = this.gameService.assetsService.getAsset(`missions/board-main/${this.boardTile.playerBuildingData.player_mission.icon}.png`);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
        if (icon) {
          this.setTooltipTexture(icon.path);
          this.tooltipImage.setOrigin(0.5, 0.58);
        }

        if (this.boardTile.tileData.player_building.player_mission.expires_at) {
          const missionTime = this.scene.add.text(0, -160, "00:00:00", this.config.textStyles);
          addTextShadow(missionTime);
          missionTime.setOrigin(0.5, -1);

          this.counter = {
            percent: 0,
            intervalRef: null,
            stringTime: "",
            startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
            finishTime: moment(this.boardTile.tileData.player_building.player_mission.expires_at),
          };
          this.setTimer(missionTime);
          this.add(missionTime);
        }
      } else if (this.isProductionFinished()) {
        const currencyPrize = this.boardTile.tileData.production.currency_prizes[0];
        const productPrize = this.boardTile.tileData.production.product_prizes[0] as any;

        this.productionFinishValue = currencyPrize ? currencyPrize.amount : productPrize.amount;

        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
      } else if (this.isAutoProduction()) {
        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
      }
    } else {
      /**
       * Refactored to allow collect auto production while building build is in progress.
       * This change is made just after another change, that checked if tile should be update silent (without re-rendering),
       * so it's a little mess and redundant now. @todo: need to refactor/rewrite all Tooltip logic. Maybe allow multiple tooltips?
       */
      let icon = null;
      if (this.isAutoProduction() && this.boardTile.playerBuildingData.auto_production_amount > 0) {
        const bg = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
        bg.setOrigin(0.5);
        bg.setScale(this.config.scaleFactor);
        this.add(bg);
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spiner.png");
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);

        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }
        this.type = TileTooltipType.COLLECT;
        this.setTooltipTexture(icon);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else {
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, `tooltip_${type}.png`);
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.setLevitationAnim(this.tooltipImage);
      }
    }

    this.add(this.tooltipImage);
    const screenPos = isoToScreen(this.boardTile.isoX, this.boardTile.isoY);
    const tooltipOffset = determineTileTooltipPositionOffset(this.boardTile);
    this.x = this.boardTile.x + tooltipOffset.x;
    this.y = this.boardTile.y + tooltipOffset.y;

    this.handleTooltipExtensions();
    this.afterCreate();
  }

  setTooltipTexture(tileImage: string, iconFrame?: string) {
    const atlas = this.scene.cache.json.get("icons-atlas");
    if (atlas && iconFrame && atlas.frames.hasOwnProperty(`${iconFrame}.png`)) {
      this.tooltipImage.setTexture("icons-atlas", `${iconFrame}.png`);
    } else if (isAssetLoadedToPhaserCache(tileImage, this.scene.game.textures.getTextureKeys())) {
      if (!this.destroying) {
        this.tooltipImage.setTexture(tileImage);
      }
    } else {
      this.gameService.loadGameImages(this.scene as MyScene, [tileImage]).subscribe(() => {
        // Timeout fix to wait till Phaser prepare texture from image.
        setTimeout(() => {
          if (!this.destroying) {
            this.tooltipImage.setTexture(tileImage);
          }
        }, 500);
      });
    }
  }

  handleTooltipExtensions() {
    switch (this.tooltipType) {
      case TileTooltipType.UPGRADE:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
          finishTime: moment(this.boardTile.tileData.player_building.built_at),
        };

        const buildTimeTextObject = this.scene.add.text(0, 10, "", this.config.textStyles);
        addTextShadow(buildTimeTextObject);
        buildTimeTextObject.setOrigin(0.5, -1);
        this.setTimer(buildTimeTextObject);
        this.add(buildTimeTextObject);
        break;

      case TileTooltipType.PRODUCTION:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: new Date(this.boardTile.tileData.production.created_at).getTime(),
          finishTime: moment(this.boardTile.tileData.production.finishes_at),
        };
        this.backgroundImage.destroy();
        const container = this.scene.add.container(-65, 0);
        const productionTimeTextObject = this.scene.add.text(35, 0, "", this.config.textStyles);
        addTextShadow(productionTimeTextObject);
        productionTimeTextObject.setOrigin(0, 0.5);
        this.tooltipImage.setPosition(10, 0);
        this.add(container);
        this.setTimer(productionTimeTextObject);

        const icon = this.getProductionIcon();
        const spinnerIcon = this.scene.add.image(-40, 0, TILE_MENU_ATLAS, "production-spinner.png");
        this.setTooltipTexture(icon);
        this.setRotationAnim(spinnerIcon, -1);
        container.add([productionTimeTextObject, this.tooltipImage, spinnerIcon]);

        break;

      case TileTooltipType.PRODUCTION_FINISHED:
      case TileTooltipType.COLLECT:
        const collectValue = this.scene.add.text(
          0,
          -160,
          `${
            this.boardTile.playerBuildingData.warehouse_income ||
            this.boardTile.playerBuildingData.auto_production_amount ||
            this.productionFinishValue
          }`,
          this.config.textStyles
        );
        addTextShadow(collectValue);
        collectValue.setOrigin(0.5, -1);
        this.add(collectValue);
        this.collectValueText = collectValue;
        break;
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  isProductionInProgress() {
    return this.tooltipType === TileTooltipType.PRODUCTION;
  }

  isProductionFinished() {
    return this.tooltipType === TileTooltipType.PRODUCTION_FINISHED;
  }

  setLevitationAnim(target: Phaser.GameObjects.GameObject) {
    this.scene.add.tween({
      targets: [target],
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: -20,
      ease: Phaser.Math.Easing.Quadratic.InOut,
    });
  }

  setJumpAnim(target: Phaser.GameObjects.GameObject[]) {
    this.scene.add.tween({
      targets: target,
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: -20,
      ease: Phaser.Math.Easing.Quadratic.Out,
    });
  }

  setRotationAnim(target: Phaser.GameObjects.GameObject, direction = 1) {
    this.scene.add.tween({
      targets: [target],
      repeat: -1,
      duration: 1000,
      angle: 360 * direction,
      ease: Phaser.Math.Easing.Quadratic.InOut,
    });
  }

  stopAnimation() {
    if (this.animationTween) {
      this.animationTween.stop(true);
    }
  }

  niceDestroy() {
    this.destroying = true;
    this.stopAnimation();
    this.destroy();
  }

  destroy(fromScene?: boolean) {
    if (this.counter) {
      clearInterval(this.counter.intervalRef);
    }
    super.destroy();
  }

  isType(tooltipType: TileTooltipType) {
    return tooltipType === this.tooltipType;
  }

  protected isAutoProduction() {
    return isAutoproduction(this.boardTile.playerBuildingData.group_type);
  }

  refreshAutoproductionValue(value: number) {
    if (this?.scene?.scene.isActive() && this.active && this?.collectValueText.active) {
      this.collectValueText.setText(`${value}`);
    }
  }

  afterCreate() {
    if (this.gameService.game.currentScene instanceof CustomWorldMainScene) {
      this.y = this.boardTile.y - 88;
      this.tooltipImage.setOrigin(0.5, 0.5);
    }
  }

  setTimer(counter: Phaser.GameObjects.Text) {
    const timer$ = timer(0, 450).subscribe(res => {
      if (!this.destroying && this.scene) {
        const duration = moment
          .duration(this.counter.finishTime.diff(moment(this.gameService.synchronizeTimeService.getActualLocalTimeWithOffset())))
          .asSeconds();
        const time = this.gameService.hoursPipe.transform(duration, "seconds");
        counter.setText(`${time}`);
        if (duration <= 0) {
          timer$.unsubscribe();
        }
      } else {
        timer$.unsubscribe();
      }
    });
  }

  getProductionIcon() {
    const currencyPrize = this.boardTile.tileData.production.currency_prizes[0];
    const productPrize = this.boardTile.tileData.production.product_prizes[0];
    let icon;
    if (currencyPrize) {
      const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
      icon = currency.iconUrl;
    } else if (productPrize) {
      const product = this.gameService.productionService.productsService.getProduct(productPrize);
      icon = product.iconUrl;
    }
    return icon;
  }
}

export interface TooltipCounter {
  secondsLeft?: number;
  intervalRef: any;
  stringTime: string;
  percent?: number;
  startTime?: number;
  finishTime?: Moment;
  secondsTotal?: number;
}
