import { PrimaryGuard } from "../../core/guards/primary.guard";
import { SocketGuard } from "../../core/guards/socket.guard";
import { SyncGuard } from "../../core/guards/sync.guard";
import { ShopRestrictedGuard } from "../../guards/shop-restricted.guard";
import { APP_ROUTING_PATH } from "../routing-app.const";
import { GalleryGuard } from "../../modules/shared/guards/custom/gallery.guard";

export const CUSTOM_ROUTING_APP_CHILDREN = [
  {
    path: APP_ROUTING_PATH.GAME,
    canActivate: [SyncGuard],
    data: {
      syncGuards: [PrimaryGuard, SocketGuard, ShopRestrictedGuard],
    },
    loadChildren: () => import("../../modules/new-game/module/core/game.module").then(m => m.GameModule),
  },
  {
    path: "gallery",
    canActivate: [PrimaryGuard, SocketGuard, GalleryGuard],
    loadChildren: () => import("../../modules/photo-gallery/module/core/photo-gallery.module").then(m => m.PhotoGalleryModule),
  },
];
