export enum CardStatus {
  Blocked = -1,
  Unassigned = 0,
  Active = 1,
}

export interface GetEdenredCardListResponse {
  card_id: number;
  number: string;
  activated_at: string;
  blocked_at?: string;
  valid_till: string;
  status: CardStatus;
}
