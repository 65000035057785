import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-action-header",
  templateUrl: "./action-header.component.html",
  styleUrls: ["action-header.component.scss"],
})
export class ActionHeaderComponent {
  @Input() showBackButton: boolean = false;
  @Input() showDetailsButton: boolean = false;
  @Input() title: string = "";
  @Output() back = new EventEmitter();
  @Output() details = new EventEmitter();

  whileBack() {
    this.back.emit(true);
  }

  whileDetails() {
    this.details.emit(true);
  }
}
