import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { HudModule } from "../../game-ui/hud/module/core/hud.module";
import { MobileGameEnginePceComponent } from "./components/game-engine-pce/game-engine-pce.component";

import { DebugModule } from "../../../debug/module/debug.module";
import { environment } from "../../../../../environments/environment";

@NgModule({
  declarations: [MobileGameEnginePceComponent],
  imports: [CommonModule, HudModule, FormsModule, RouterModule, environment.devel ? DebugModule : []],
  exports: [MobileGameEnginePceComponent],
})
export class GameEngineMobileModule {}
