import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { LoadingService } from "../../../../../../../../core/providers/loading.service";
import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";
import { Qa } from "../../../../interfaces/core/qa.interface";
import { QaCustom } from "../../../../interfaces/custom/qa.interface";

@Component({
  selector: "app-custom-m-qa-list",
  templateUrl: "./qa-list.component.html",
})
export class MQaListComponent extends AbstractQaListComponent implements OnInit {
  @OwInject(LoadingService) loadingService: LoadingService;

  @OwInject(MAT_DIALOG_DATA) data: {
    location?: number;
    qa?: Qa;
    type?: string;
    eachQaId?: number;
    isShowBackButton?: boolean;
    isOpenFromMockUpTask?: boolean;
  };
  activeQa: QaCustom;

  ngOnInit() {
    this.baseInit();
  }

  openQaDetails() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_DETAILS,
      config: {
        disableClose: true,
        data: {
          qa: this.activeQa,
          prevState: {
            location: this.data.location,
            type: this.data.type,
            eachQaId: this.data.eachQaId,
          },
          isOpenFromMockUpTask: this.data.isOpenFromMockUpTask,
        },
      },
    });
  }

  refreshList() {
    this.loadingService.show();

    setTimeout(() => {
      this.ngOnInit();
    }, 2000);
  }
}
