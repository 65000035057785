import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { A2hsService } from "../../../../services/custom/a2hs.service";
import { CookiesComponent } from "../cookies/cookies.component";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Router) router: Router;
  @OwInject(A2hsService) a2hsService: A2hsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CookieService) cookieService: CookieService;

  windowScrolled: boolean;
  timer;
  intervalTimer;
  isLoading = true;

  @HostListener("window:scroll") onWindowScroll() {
    const scroll = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scroll > 100) {
      this.windowScrolled = true;
    } else if (scroll < 10) {
      this.windowScrolled = false;
    }
  }

  ngOnInit() {
    const analytics = this.cookieService.get("analytics");

    if (!analytics) {
      this.openConsentDialog();
    } else {
      this.checkGA();
    }

    this.sendEventGtm("landingPages");
    this.setTimer();
  }

  checkGA() {
    const cookie = this.cookieService.get("analytics");
    if (cookie == "true") {
      window["gtag"]("consent", "update", {
        analytics_storage: "granted",
      });

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window["gtag"]("event", "page_view");
        }
      });
    }
  }

  openConsentDialog() {
    this.dialogService.open(CookiesComponent, {}, isClosedByButtonX => {
      if (isClosedByButtonX) {
        this.checkGA();
      }
    });
  }

  login() {
    if (this.timer <= 0) {
      this.router.navigate(["/"]);
    }
  }

  sendEventGtm(eventName: string) {
    if (window["gtag"]) {
      window["gtag"]("event", eventName);
    }
  }

  getApp() {
    this.a2hsService.addApplicationToHomeScreen();
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = new Date().getTime();
      const expiredAt = new Date("July 03 2023 12:00:00 GMT+0200").getTime();
      this.timer = moment(expiredAt).diff(moment(actualDate), "seconds");
      this.isLoading = false;
      if (this.timer <= 0) {
        clearInterval(this.intervalTimer);
      }
    }, 1000);
  }

  convertTime(seconds) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const day = d < 1 ? "" : d + "d ";
    const hour = h < 10 ? "0" + h + ":" : h + ":";
    const min = m < 10 ? "0" + m + ":" : m + ":";
    const sec = s < 10 ? "0" + s : s;

    return day + hour + min + sec;
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
  }
}
