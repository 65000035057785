import { NgIf } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../core/decorators/ow-inject.decorator";
import { mobileDetect } from "../../core/providers/device.service";
import { updateMessageCount } from "../../store/chat/actions";
import { AppState } from "../../store/state";
import { BusinessModule } from "../game/game-ui/business/module/business.module";
import { PlayerService } from "../player/providers/player.service";
import { DialogService } from "../shared/providers/dialog.service";
import { SharedModule } from "../shared/shared.module";

@Component({
  standalone: true,
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  imports: [BusinessModule, NgIf, SharedModule],
})
export class ChatComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  subs = {
    player: null,
  };
  switchOrientation: boolean = false;

  ngOnInit(): void {
    this.switchOrientationCheck();

    window.addEventListener("message", e => {
      if (e.data.eventName === "startup") {
        const playerId = this.playerService.getMePlayerId();
        if (playerId != null) {
          this.playerService.getPlayer(playerId).subscribe(res => {
            const iframe = document.querySelector("iframe");
            if (iframe) {
              iframe.contentWindow.postMessage(
                {
                  externalCommand: "login-with-token",
                  token: res.chat_token,
                },
                "*"
              );
            }
          });
        }
      }
      if (e.data.eventName === "unread-changed") {
        return this.store.dispatch(updateMessageCount({ count: e.data.data }));
      }
    });
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event) {
    this.switchOrientationCheck();
  }

  switchOrientationCheck() {
    const MobileDetect = require("mobile-detect");
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    const isMobile = mobileDetect.mobile() || this.checkSafariOnIpad();
    if (isMobile) {
      this.switchOrientation = window.orientation === -90 || window.orientation === 90;
    }
  }

  checkSafariOnIpad() {
    return mobileDetect["ua"].toLowerCase().includes("safari") && window.orientation !== undefined;
  }

  protected readonly window = window;
}
