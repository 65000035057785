import { ComponentPortal, Portal } from "@angular/cdk/portal";
import { AfterViewInit, Component, InjectionToken, OnDestroy, OnInit, Type } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { EVENT_DIALOGS_NEW_BUSINESS_CUSTOM } from "../../../consts/custom/event-dialogs.const";
import { BusinessViewSelectorService } from "../../../services/business-view-selector.service";
import { TasksListComponent } from "../tasks-list/tasks-list.component";
export const CONTEXT_TOKEN = new InjectionToken("lol");
@Component({
  selector: "app-new-business-container",
  templateUrl: "./new-business-container.component.html",
})
export class NewBusinessContainerComponent extends AbstractInjectBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @OwInject(BusinessViewSelectorService) viewSelector: BusinessViewSelectorService;
  componentPortal: ComponentPortal<AbstractInjectBaseComponent> = null;
  selectedPortal: ComponentPortal<AbstractInjectBaseComponent> = null;
  ngOnInit() {
    this.viewSelector.changeComponent$.subscribe(data => {
      const component = EVENT_DIALOGS_NEW_BUSINESS_CUSTOM[data];
      this.componentPortal = new ComponentPortal(component as Type<AbstractInjectBaseComponent>);
      this.selectedPortal = this.componentPortal;
    });
  }

  ngAfterViewInit() {
    this.componentPortal = new ComponentPortal(TasksListComponent);
    this.selectedPortal = this.componentPortal;
  }

  ngOnDestroy() {
    this.viewSelector.clearFields();
  }
}
