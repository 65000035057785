import { lastElement } from "../../../../../../helpers/common.helpers";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { Scope } from "../../interfaces/custom/scope.interface";
import { Task } from "../../interfaces/custom/task.interface";

export function setBaseScopeHelper(task: Task): Scope {
  const isDefaultScope = task.scopes.find(scope => scope.default);
  const isTarget100 = task.scopes.find(scope => scope.target === 1);
  const fallback = task.scopes.length ? task.scopes[0] : null;

  switch (task.card_template) {
    case CARD_TEMPLATE.S4:
      return lastElement(task.scopes.filter(scope => scope.target <= task.performance));

    default:
      return isDefaultScope || isTarget100 || fallback;
  }
}
