<div class="background-wrapper">
  <div class="title">
    Gotowi na rywalizację, <br />
    strategię i współpracę? <br />
    Znakomicie! <br />
    Właśnie na Ciebie czekamy! <br /><br />
    Przenieś się w okolice Komornik i...
  </div>
  <button class="cta-button" (click)="login()"></button>
</div>
<div class="description-wrapper">
  <div class="title">Już od 9 stycznia 2024 dołącz do Misji Eurocash. Graj, wygrywaj i zgarniaj cenne nagrody!</div>
  <div class="divider"></div>
  <div class="content">
    <span class="highlighed">Jak zalogować się do gry?</span> <br /><br />

    1. Wejdź na <a href="https://www.euromisja.pl">www.euromisja.pl</a> i kliknij przycisk Zaloguj. <br/>
    2. Następnie wprowadź dane do logowania – instrukcja poniżej.<br /><br />

    Jeżeli posiadasz imienny, służbowy adres e-mail w domenie &#64;eurocash.pl to do gry logujesz się danymi, jak do poczty służbowej. <br />
    Jeśli nie posiadasz imiennego, służbowego maila, to zgłoś swój prywatny adres mailowy pod poniższym adresem w Intranet lub poproś kierownika o pomoc:
    <a href="https://intranet.grupaeurocash.pl/EurocashForms.OrchardCore/Forms/FillForm?Id=271" target="_blank">https://intranet.grupaeurocash.pl/EurocashForms.OrchardCore/Forms/FillForm?Id=271</a><br /><br />

    Uwaga! Posiadanie konta w Microsoft Azure jest niezbędne do wejścia do gry.
  </div>
</div>
