import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";

import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { CurrencyService } from "../../../../../../../core/providers/currency.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { AbstractBusinessCards } from "../../../abstract/custom/abstract-business-cards.component";
import { EVENT_DIALOGS_NAMES_NEW_BUSINESS } from "../../../consts/custom/event-names.const";
import { PlanListComponent } from "../plan-list/plan-list.component";
import { Moment } from "moment";
@Component({
  selector: "app-tasks-list",
  templateUrl: "./tasks-list.component.html",
})
export class TasksListComponent extends AbstractBusinessCards implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  owDate: OwDate = null;
  protected readonly moment = moment;
  currencyDefinitions = null;
  monthNames = [
    "Styczeń", "Luty", "Marzec",
    "Kwiecień", "Maj", "Czerwiec",
    "Lipiec", "Sierpień", "Wrzesień",
    "Październik", "Listopad", "Grudzień"
  ];
  ngOnInit() {
    this.owDate = new OwDate();
    this.currencyDefinitions = this.getCurrencyDefinition();
    this.tasksFromService();
    this.subscribeGlobalEvents();
    this.sendGAEvent();
  }
  getCurrencyDefinition() {
    const currencyDefinitions = {};
    for (const currency of this.currencyService.currencyDefinitions) {
      currencyDefinitions[currency.currency_id] = currency;
    }
    return currencyDefinitions;
  }
  tasksFromService() {
    const tasks = this.viewSelector.cardTasks;
    if (!tasks) {
      this.getTasks();
    } else {
      this.getWeekMoment();
      this.customLoading = true;
      this.setPagination();
      this.cardTasks = tasks;
      this.customLoading = false;
    }
  }
  openPlans() {
    this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "PLANS" } });
  }
  openChallenges() {
    this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "CHALLENGES" } });
  }
  backToListView() {
    this.viewSelector.changeView(EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_LIST);
  }
  openTasks(index?: number) {
    this.viewSelector.selectComponent(index);
  }
  closeDialogWithBusiness() {
    this.dialogService.closeActive();
  }
  sendGAEvent() {
    if (window["gtag"]) {
      window["gtag"]("event", "Lista Zadań Operacyjnych", {
        event_category: "Lista Zadań Operacyjnych",
        event_label: "Lista Zadań Operacyjnych",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
      });
    }
  }
  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
