import { Progress } from "../../interfaces/custom/progress.interface";
import { Task } from "../../interfaces/custom/task.interface";

export function setProgressbarHelper(task: Task): Progress {
  function setValuePercentage(value: number): string {
    return (value * 100).toFixed(2) + "%";
  }

  const progress: Progress = {
    from: null,
    to: null,
    valuePercentage: null,
    label: null,
    title: null,
    classes: [],
  };

  switch (task.card_template) {
    default:
      progress.valuePercentage = setValuePercentage(task.realization);
  }

  return progress;
}
