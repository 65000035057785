<div class="wrapper">
  <!-- LOGO -->
  <div class="logo"></div>

  <loading></loading>

  <div class="content-wrapper form">
    <form [formGroup]="form" *ngIf="form">
      <!-- USERNAME -->
      <ng-container *ngIf="form.get('username') as username">
        <div class="control" [class.error]="getErrors(username)">
          <div class="label-flex">
            <label>Twój Nick (widoczny będzie w rankingach)</label>
          </div>
          <input class="control-input" formControlName="username" type="text" (paste)="onPaste($event)" />
          <div class="alert alert-danger" *ngIf="getErrors(username)">
            <span *ngIf="username.errors.required">Pole wymagane</span>
          </div>
        </div>
      </ng-container>

      <div class="confirmations">
        <div class="control control-checkbox">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirm" id="confirm" required />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label for="confirm"> Akceptuję <a [href]="LINKS.TERMS" target="_blank">regulamin</a> </label>
        </div>

        <div class="control control-checkbox">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirmRodo" id="confirmRodo" required />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label for="confirmRodo">
            Akceptuję
            <a
              [href]="
                'https://euromisja.pl/api/files/download/b2018129-9438-40c0-b664-de6f91cc7e10/Informacje%20o%20przetwarzaniu%20danych%20osobowych.docx.pdf'
              "
              target="_blank"
              >klauzulę RODO</a
            >
          </label>
        </div>
      </div>
    </form>

    <div class="buttons" *ngIf="form">
      <button class="auth-button primary" (click)="registrationConfirm()" [disabled]="form.invalid">Zarejestruj</button>
    </div>
  </div>
</div>
