<div class="chat-container">
  <ng-container *ngIf="switchOrientation; else chat">
    <app-generic-device-rotate [orientation]="'portrait'"></app-generic-device-rotate
  ></ng-container>
  <ng-template #chat>
    <iframe *ngIf="window.location.host !== 'euromisja.pl'" src="https://czat.eurocash.o-w.dev"></iframe>
    <iframe *ngIf="window.location.host === 'euromisja.pl'" src="https://czat.euromisja.pl"></iframe>
    <app-action-navigation actualComponent="CHAT"></app-action-navigation>
  </ng-template>
</div>
