import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { MessageState } from "../../../../../../../../store/chat/reducer";
import { AppState } from "../../../../../../../../store/state";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ChallengesDetailsComponent } from "../../../../../business/base/dialogs/challenges-details/challenges-details.component";
import { NewBusinessContainerComponent } from "../../../../../business/base/dialogs/new-business-container/new-business-container.component";
import { PlanListComponent } from "../../../../../business/base/dialogs/plan-list/plan-list.component";
import { PlayerService } from "../../../../../../../player/providers/player.service";

type Destination = "PLANS" | "CHALLENGES" | "TASKS" | "ACTIONS" | "GAME" | "CHAT";

type State = AppState & {
  message: MessageState;
};

@Component({
  selector: "app-hud-business",
  templateUrl: "./hud-business.component.html",
})
export class HudBusinessComponent implements OnInit {
  messageCount$: Observable<number>;
  constructor(
    public playerService: PlayerService,
    private dialogService: DialogService,
    private router: Router,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.messageCount$ = this.store.pipe(select(state => state.message.messageCount));
  }

  open(destination: Destination) {
    switch (destination) {
      case "PLANS": {
        this.dialogService.open(PlanListComponent, {
          data: {
            mode: "PLANS",
          },
        });
        break;
      }
      case "CHALLENGES": {
        this.dialogService.open(PlanListComponent, {
          data: {
            mode: "CHALLENGES",
          },
        });
        break;
      }
      case "TASKS": {
        this.dialogService.open(NewBusinessContainerComponent, {});
        break;
      }
      case "ACTIONS": {
        this.router.navigate(["/auth/actions"]);
        break;
      }
      case "GAME": {
        break;
      }
      case "CHAT": {
        this.router.navigate(["/auth/chat"]);
        break;
      }
    }
  }
}
