import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { PlayerService } from "../../../../../player/providers/player.service";
import { CardTask } from "../../../business/interfaces/custom/card-task.interface";
import { ApiCoreGameRanksService } from "../../api/core/services/api-game-ranks.service";
import { RankGame } from "../../interfaces/custom/rank-game.interface";

@Injectable({
  providedIn: "root",
})
export class BusinessRanksService {
  bussinesRankList: RankGame[] = [];
  constructor(
    private apiCoreGameRanksService: ApiCoreGameRanksService,
    private playerService: PlayerService
  ) {}

  //FILTERING RANK WORLD BRANCHES
  ranksWorldAllBranches: { branchName: string; branchId: number }[] = [];
  ranksWorldSelectedBranches: { branchName: string; branchId: number }[] = [];
  LOCAL_STORAGE = "selectedBranchesEC";
  SPECIAL_BRANCHES = {
    WSZYSTKIE: {
      name: "Wszyscy",
      branchesToDisplay: () => {
        return [...this.ranksWorldAllBranches.filter(branch => branch.branchName !== this.SPECIAL_BRANCHES.WSZYSTKIE.name)];
      },
    },
  };

  initAllBranch(branches: { branchName: string; branchId: number }[]) {
    this.ranksWorldAllBranches = [...branches, ...this.getListOfSpecialBranches()];
  }

  getListOfSpecialBranches() {
    const specialBranches: { branchName: string; branchId: number }[] = [];
    Object.keys(this.SPECIAL_BRANCHES).forEach(key => {
      specialBranches.push({ branchName: this.SPECIAL_BRANCHES[key].name, branchId: 0 });
    });

    return specialBranches;
  }

  initSelectedBranch(rank: RankGame, branches: { branchName: string; branchId: number }[]) {
    const fromLocalStorage = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE + rank.rank_id + this.playerService.player.id));
    if (fromLocalStorage) {
      this.ranksWorldSelectedBranches = [...fromLocalStorage];
    } else {
      this.ranksWorldSelectedBranches = [...branches];
    }
  }

  saveSelectedBranchesInLocalStorage(rank) {
    localStorage.setItem(
      this.LOCAL_STORAGE + rank.rank_id + this.playerService.player.id,
      JSON.stringify([...this.ranksWorldSelectedBranches])
    );
  }

  fetchBussinesRanks() {
    this.apiCoreGameRanksService
      .getGameRanks({ location: 2 })
      .pipe(map(this.filterRanksByTitleId.bind(this)), map(this.filterRanksByBranchId.bind(this)))
      .subscribe(
        (resp: RankGame[]) => {
          this.bussinesRankList = resp;
          // this.setVisibleRanks();
        },
        () => {
          this.bussinesRankList = [];
        }
      );
    // this.useMockedData();
  }

  filterRanksByTitleId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_title_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.title_id.id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }
  filterRanksByBranchId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_branch_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.branch_id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }

  isRankInBusinessRank(card: CardTask): CardTask {
    const cardEdited = card;
    this.bussinesRankList.forEach(rank => {
      const parameters = rank?.parameters?.business_task_id;
      if (!parameters) return;
      parameters.forEach(param => {
        if (param === card.selected.task_id) {
          cardEdited.selected.rank_edition_id = rank.rank_edition_id;
          cardEdited.selected.rank_is_visible = true;
        }
      });
    });
    return cardEdited;
  }
  selectedIncludeSpecial() {
    let specialName = null;
    Object.keys(this.SPECIAL_BRANCHES).forEach(key => {
      if (this.ranksWorldSelectedBranches.filter(branch => branch.branchName === this.SPECIAL_BRANCHES[key].name).length > 0) {
        specialName = key;
      }
    });
    return specialName;
  }
}
