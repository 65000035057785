import { Routes } from "@angular/router";
import { OauthConfirmComponent } from "../../base/custom/components/oauth-confirm/oauth-confirm.component";
import { AUTH_ROUTING_PATH } from "../core/routing-auth.const";
import { LoginComponent } from "../../base/custom/components/login/login.component";

export const CUSTOM_ROUTING_AUTH_CHILDREN: Routes = [
  // {
  //   path: AUTH_ROUTING_PATH.LOGIN,
  //   pathMatch: "full",
  //   component: LoginComponent,
  // },
  {
    path: "oauth2/confirm/:token",
    component: OauthConfirmComponent,
  },
  // {
  //   path: 'auth/match3/:gameId',
  //   component: Match3MinigameComponent,
  //   canActivate: [AuthGuard]
  // }
];
