<div class="navigation-boxes-container">
  <div class="logo"></div>
  <div class="wrapper">
    <a [routerLink]="'/auth/actions'">
      <div class="box">
        <i class="fa-regular fa-bullseye-pointer"></i>
      </div>
      <p>Akcje</p>
    </a>
    <a [routerLink]="'/auth/player-choose'">
      <div class="box">
        <i class="fa-regular fa-gamepad-modern"></i>
      </div>
      <p>Grywalizacja</p>
    </a>
    <a [routerLink]="'/auth/chat'">
      <div class="box">
        <i class="fa-regular fa-comment"></i>
        <span *ngIf="messageCount$ | async" class="message-count">{{ messageCount$ | async }}</span>
      </div>
      <p>Chat Box</p>
    </a>
  </div>
  <p class="disclaimer">Kontakt: <a href="mailto:info@euromisja.pl">info&#64;euromisja.pl</a></p>
  <iframe *ngIf="window.location.host !== 'euromisja.pl'" src="https://czat.eurocash.o-w.dev"></iframe>
  <iframe *ngIf="window.location.host === 'euromisja.pl'" src="https://czat.euromisja.pl"></iframe>
</div>
