import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { Observable } from "rxjs";

import { GetMediaCategoryResponse } from "../../interfaces/core/get-media-category.interface";

@Injectable({
  providedIn: "root",
})
export class BankAccountNumberService {
  constructor(private apiService: ApiService) {}
  getBankAccountNumber(): Observable<{
    account_number: string;
    updated_at: string;
  }> {
    return this.apiService.request("GET", `bank-account`);
  }
  postBankAccountNumber(bankAccountNumber: string): Observable<any> {
    return this.apiService.request("POST", `bank-account`, {
      body: {
        account_number: bankAccountNumber,
      },
    });
  }
  patchBankAccountNumber(bankAccountNumber: string): Observable<any> {
    return this.apiService.request("PATCH", `bank-account`, {
      body: {
        account_number: bankAccountNumber,
      },
    });
  }
}
