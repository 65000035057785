import { APP_CONFIG } from "../config";

export const environment = {
  production: true,
  apiUrl: APP_CONFIG.apiUrl,
  wsUrl: APP_CONFIG.wsUrl,
  base: APP_CONFIG.baseHref,
  cookieConfig: {
    path: '/main',
    secure: window.location.protocol === 'https:',
  },
  devel: APP_CONFIG.devel === "true"
};
