import { Injectable } from "@angular/core";

import { BusinessDialogsService } from "../../../modules/game/game-ui/business/services/event-dialogs.service";
import { RankingsEventDialogsService } from "../../../modules/game/game-ui/rankings/services/custom/event-dialogs.service";
import { MgmEventDialogsService } from "../../../modules/game/game-ui/mgm/services/custom/event-dialogs.service";
import { EdenredEventDialogsService } from "../../../modules/game/game-ui/edenred/services/event-dialogs.service";
import { A2hsService } from "../../../modules/auth/services/custom/a2hs.service";

@Injectable({
  providedIn: "root",
})
export class RegisterCustomEventDialogsService {
  constructor(
    private businessDialogsService: BusinessDialogsService,
    private rankingsEventDialogsService: RankingsEventDialogsService,
    private mgmEventDialogsService: MgmEventDialogsService,
    private edenredDialogsService: EdenredEventDialogsService,
    private a2hs: A2hsService,
  ) {}
}
