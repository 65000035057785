import { Component, OnInit } from "@angular/core";

import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { AbstractMessageDetailsNormalComponent } from "../../../../abstract/core/abstract-message-details-normal.component";
import { MessageDetails } from "../../../../interfaces/core/message-details.interface";

@Component({
  selector: "app-message-details-normal",
  templateUrl: "./message-details-normal.component.html",
})
export class CustomMessageDetailsNormalComponent extends AbstractMessageDetailsNormalComponent implements OnInit {
  message: MessageDetails;
  STOCK_VIEW = STOCK_VIEW;

  ngOnInit() {
    this.afterInit();
  }
}

export interface MessageParameters {
  expiring_balances: Expiring[];
}

export interface Expiring {
  currency_id: number;
  amount: number;
  reason: string;
  expiry_date: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}
