import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { GetEdenredCardHistoryResponse } from "../../api/interfaces/get-edenred-card-history-response.interface";
import { CardStatus, GetEdenredCardListResponse } from "../../api/interfaces/get-edenred-card-list-response.interface";
import { ApiEdenredService } from "../../api/services/api-edenred.service";

@Component({
  selector: "app-prepaid-card-transactions-list",
  standalone: true,
  imports: [CommonModule, SharedModule, SharedUiMobileModule, OwPerfectScrollbarModule],
  templateUrl: "./prepaid-card-transactions-list.component.html",
  styleUrls: ["./prepaid-card-transactions-list.component.scss"],
})
export class PrepaidCardTransactionsListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEdenredService) apiEdenredService: ApiEdenredService;
  @OwInject(MAT_DIALOG_DATA) data: { card: GetEdenredCardListResponse };
  cardHistory: GetEdenredCardHistoryResponse[] = [];
  card: GetEdenredCardListResponse;

  ngOnInit() {
    this.card = this.data.card;
    this.apiEdenredService.getEdenredCardHistory(this.data.card.card_id).subscribe(response => {
      this.cardHistory = response;
    });
  }

  protected readonly CardStatus = CardStatus;
}
