<div class="global-dialog ow-dialog window-a primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>
  <loading></loading>
  <!-- DIALOG TITLE -->
  <div class="content" *ngIf="!isLoading">
    <div class="top-bar">
      <div class="title">Numer konta bankowego</div>
    </div>
    <div class="description">Wpisz poniżej nr swojego konta bankowego, na który będą wpływać nagrody w ramach Akcji.</div>
    <div class="bank-account-label">Numer konta bankowego:</div>
    <div class="bank-account-form">
      <form [formGroup]="ibanNumberForm">
         <div class="input-wrapper">
             <span class="iban-label">{{ ibanCode }}</span>
             <input #ibanNumberInput [(ngModel)]="userIbanNumber" formControlName="ibanNumber" type="text" minlength="26" maxlength="26"/>
         </div>
        <button *ngIf="ibanNumberIsSet" (click)="updateNumber()" type="submit" class="base primary" [disabled]="ibanNumberForm.invalid || !ibanNumberForm.dirty">
          {{ "Aktualizuj" }}
        </button>
        <button *ngIf="!ibanNumberIsSet" (click)="saveNumber()" type="submit" class="base primary" [disabled]="ibanNumberForm.invalid">
          {{ "Zapisz" }}
        </button>
      </form>
    </div>
  </div>
</div>
