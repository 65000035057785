import { Injectable } from "@angular/core";
import { AbstractEventDialogsService } from "../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_EDENRED } from "../const/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class EdenredEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      customEventDialogs: EVENT_DIALOGS_EDENRED,
    };
  }
}
