import * as R from "ramda";

import { CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY } from "../consts/custom/game-map-custom.const";
import { SceneConfigMap } from "../interfaces/scene-config.interface";
import { BASIC_SCENE_BASE_CONFIG } from "./basic-scene.base-config";

export const BASIC_SCENE_CUSTOM_CONFIG: SceneConfigMap = {
  default: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "wmap-0-0.jpg",
        x: 0,
        y: 0,
        z: -1,
        key: "wmap-0-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "wmap-1-0.jpg",
        x: 4096,
        y: 0,
        z: -1,
        key: "wmap-1-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "wmap-2-0.jpg",
        x: 8192,
        y: 0,
        z: -1,
        key: "wmap-2-0",
        displayWidth: 1808,
        displayHeight: 4096,
      },
      {
        filename: "wmap-0-1.jpg",
        x: 0,
        y: 4096,
        z: -1,
        key: "wmap-0-1",
        displayWidth: 4096,
        displayHeight: 2904,
      },
      {
        filename: "wmap-1-1.jpg",
        x: 4096,
        y: 4096,
        z: -1,
        key: "wmap-1-1",
        displayWidth: 4096,
        displayHeight: 2904,
      },
      {
        filename: "wmap-2-1.jpg",
        x: 8192,
        y: 4096,
        z: -1,
        key: "wmap-2-1",
        displayWidth: 1808,
        displayHeight: 2904,
      },
    ],
    mapDirectoryName: "world_map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "D",
    boardStartOffset: {
      x: 5000,
      y: 3648,
    },
    tileMenuAtlasDirectory: "tile-menu",
    mapAtlasDirectory: "world_map",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 10000,
      height: 7000,
    },
    other: {
      sceneZoom: {
        max: 0.45,
        min: 0.7,
      },
      sceneSize: {
        width: 6650,
        height: 4500,
      },
    },
  },
  ["world"]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "wmap-0-0.jpg",
        x: 0,
        y: 0,
        z: -1,
        key: "wmap-0-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "wmap-1-0.jpg",
        x: 4096,
        y: 0,
        z: -1,
        key: "wmap-1-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "wmap-2-0.jpg",
        x: 8192,
        y: 0,
        z: -1,
        key: "wmap-2-0",
        displayWidth: 1808,
        displayHeight: 4096,
      },
      {
        filename: "wmap-0-1.jpg",
        x: 0,
        y: 4096,
        z: -1,
        key: "wmap-0-1",
        displayWidth: 4096,
        displayHeight: 2904,
      },
      {
        filename: "wmap-1-1.jpg",
        x: 4096,
        y: 4096,
        z: -1,
        key: "wmap-1-1",
        displayWidth: 4096,
        displayHeight: 2904,
      },
      {
        filename: "wmap-2-1.jpg",
        x: 8192,
        y: 4096,
        z: -1,
        key: "wmap-2-1",
        displayWidth: 1808,
        displayHeight: 2904,
      },
    ],
    mapDirectoryName: "world_map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "D",
    boardStartOffset: {
      x: 5000,
      y: 3648,
    },
    tileMenuAtlasDirectory: "tile-menu",
    mapAtlasDirectory: "world_map",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 10000,
      height: 7000,
    },
    other: {
      sceneZoom: {
        max: 0.45,
        min: 0.7,
      },
      sceneSize: {
        width: 6650,
        height: 4500,
      },
    },
  },
  ["big"]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    mapDirectoryName: "third_map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "C",
    backgroundImages: [
      {
        filename: "third_map-0-0.jpg",
        key: "third_map-0-0",
        displayWidth: 4096,
        displayHeight: 3700,
        x: 0,
        z: 0,
      },
      {
        filename: "third_map-1-0.jpg",
        key: "third_map-1-0",
        displayWidth: 904,
        displayHeight: 3700,
        x: 4096,
        z: 0,
      },
    ],
    other: {
      isCountryMap: true,
    },
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 5000,
      height: 3700,
    },
    gui_unlocks_button: CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
    configKeyName: CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1,
    },
  },
  ["medium"]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    mapDirectoryName: "second_map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "B",
    backgroundImages: [
      {
        filename: "second_map-0-0.jpg",
        key: "second_map-0-0",
        displayWidth: 3500,
        displayHeight: 2800,
        x: 0,
        z: 0,
      },
    ],
    other: {
      isBranchMap: true,
    },
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 3500,
      height: 2800,
    },
    gui_unlocks_button: CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
    configKeyName: CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1,
    },
  },
  ["small"]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "map-0-0.jpg",
        x: 0,
        y: 0,
        key: "map-0-0",
        displayWidth: 4000,
        displayHeight: 2100,
      },
    ],
    yAxisSign: 1,
    mapDirectoryName: "map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "A",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 4000,
      height: 2100,
    },
    cameraStartPosition: {
      x: 0,
      y: 0,
    },
    other: {
      sceneZoom: {
        max: 0.45,
        min: 0.7,
      },
      sceneSize: {
        width: 4000,
        height: 2100,
      },
      startZoom: 0.5,
    },
  },
};
