<div class="global-dialog ow-dialog window-a primary no-transparent">
  <ng-container *ngIf="switchOrientation; else itemTpl">
    <app-generic-device-rotate [orientation]="'portrait'"></app-generic-device-rotate
  ></ng-container>
  <ng-template #itemTpl>
    <loading></loading>
    <div class="wrapper">
      <div class="sticky">
        <app-action-header
          [title]="action.name"
          [showBackButton]="true"
          [showDetailsButton]="true"
          (back)="onBack()"
          (details)="onDetails()"></app-action-header>
        <app-action-blocks-header
          [blocks]="action.blocks"
          (openBlock)="onOpenBlock($event)"
          [hidden]="!action.accepted_consents"></app-action-blocks-header>
      </div>
      <ng-container [ngSwitch]="actualView === 'details' ? 1 : action.accepted_consents ? 2 : 3">
        <ng-container *ngSwitchCase="1"><app-action-details [actionId]="action.id"></app-action-details></ng-container>
        <ng-container *ngSwitchCase="2"
          ><app-action-blocks-content
            [action]="action"
            (activateDefaultBlock)="activateFirstBlock()"
            (blockChanged)="activateFirstBlock()"></app-action-blocks-content>
        </ng-container>
        <ng-container *ngSwitchCase="3"
          ><app-action-consents-form [actionId]="action.id" (consentsAccepted)="onConsentsAccepted()"></app-action-consents-form
        ></ng-container>
      </ng-container>
      <app-action-navigation actualComponent="ACTIONS"></app-action-navigation>
    </div>
  </ng-template>
</div>
