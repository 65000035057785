import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { VirtualKeyboardMobileModule } from "../../../virtual-keyboard/mobile/virtual-keyboard-mobile.module";
import { WarehouseAbstractModule } from "../../abstract/core/warehouse-abstract.module";
import { CoreWarehouseComponent } from "../../base/core/dialogs/warehouse/warehouse.component";
import { CoreWarehouseDetailsComponent } from "../../base/core/dialogs/warehouse-details/warehouse-details.component";
import { CUSTOM_DECLARATIONS } from "../../consts/custom/components.const";
import { CUSTOM_EXPORTS } from "../../consts/custom/exports.const";
import { CUSTOM_IMPORTS } from "../../consts/custom/imports.const";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";
import { CoreWarehouseSaleComponent } from "../../base/core/dialogs/warehouse-sale/warehouse-sale.component";

@NgModule({
  declarations: [CoreWarehouseComponent, CoreWarehouseDetailsComponent, CoreWarehouseSaleComponent, ...CUSTOM_DECLARATIONS],
  imports: [
    CommonModule,
    WarehouseAbstractModule,
    SharedModule,
    ReactiveFormsModule,
    VirtualKeyboardMobileModule,
    SharedUiMobileModule,
    OwPerfectScrollbarModule,
    ...CUSTOM_IMPORTS,
  ],
  exports: [...CUSTOM_EXPORTS],
})
export class WarehouseModule {}
