import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { MessageState } from "../../../../../../../store/chat/reducer";
import { AppState } from "../../../../../../../store/state";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { NewBusinessContainerComponent } from "../../dialogs/new-business-container/new-business-container.component";
import { PlanListComponent } from "../../dialogs/plan-list/plan-list.component";
import { PlayerService } from "../../../../../../player/providers/player.service";

export type BusinessPanelSections = "PLANS" | "CHALLENGES" | "TASKS";
type GameSections = "ACTIONS" | "GAME" | "CHAT";
type BusinessMenu = BusinessPanelSections | GameSections;

type State = AppState & {
  message: MessageState;
};

@Component({
  selector: "app-action-navigation",
  templateUrl: "./action-navigation.component.html",
  styleUrls: ["./action-navigation.component.scss"],
})
export class ActionNavigationComponent implements OnInit {
  @Input() actualComponent: GameSections = null;
  messageCount$: Observable<number>;
  player: PlayerService;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private store: Store<State>,
    private playerService: PlayerService
  ) {}

  ngOnInit() {
    this.player = this.playerService;
    this.messageCount$ = this.store.pipe(select(state => state.message.messageCount));
  }

  open(name: BusinessMenu) {
    switch (name) {
      case "PLANS": {
        this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "PLANS" } });
        break;
      }
      case "CHALLENGES": {
        this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "CHALLENGES" } });
        break;
      }
      case "TASKS": {
        this.dialogService.open(NewBusinessContainerComponent, { data: { closeActive: true } });
        break;
      }
      case "ACTIONS": {
        this.router.navigate(["/auth/actions"]);
        break;
      }
      case "GAME": {
        this.router.navigate(["/auth/game"]);
        this.dialogService.closeAll();
        break;
      }
      case "CHAT": {
        this.router.navigate(["/auth/chat"]);
        this.dialogService.closeAll();
        break;
      }
    }
  }
}
