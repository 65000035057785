<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="wrapper">
    <nav>
      <div class="left">
        <div class="top">
          <button class="round-button round-reverse fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>

        <div class="bottom">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
        <div class="bottom">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-light-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-lazure">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
      </div>
    </nav>
    <div class="content">

        <i class="far fa-chevron-square-left left arrow-pagination " ></i>
        <i class="far fa-chevron-square-right right arrow-pagination"></i>

      <swiper-container #swiperRef class="slider-container">
        <swiper-slide class="page">
            <div class="plan">
              <img [src]="'actimel.png' | asset: 'vendors'" />
              <div *ngIf="true" class="badge green">
                Rozliczone
              </div>
            </div>
            <div class="task">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            </div>
            <div class="progress">
              <p class="percentage" [ngStyle]="{'left': (a.progress - 2) + '%'}">{{a.progress}}%</p>
              <div class="bar">
                <div class="fill" [ngStyle]="{'width': a.progress + '%' }"></div>
              </div>
              <div class="info">
                <div>
                  <p>Daty: {{a.dates}}</p>
                  <p>Dane aktualne na: {{a.actualDate}}</p>
                </div>
                <div>
                  <p>Realizacja: {{a.currPoints | number: '3.'}}</p>
                  <p>Plan: {{a.maxPoints | number: '3.'}}</p>
                </div>
              </div>
            </div>
            <div class="search">
              <div class="search-bar">
                <input [(ngModel)]="searchString" (ngModelChange)="onSearchStringChange()" type="text" name="shop" placeholder="Szukaj">
                <i class="far fa-search"></i>
              </div>
            </div>
            <div class="table">
              <div class="table-headers">
                <button class="header-name" (click)="sortBy('name')">Nazwa<i class="fas fa-sort"></i></button>
                <button class="header-id" (click)="sortBy('numberID')">Numer ID<i class="fas fa-sort"></i></button>
                <button class="header-realization" (click)="sortBy('realization')">Realizacja<i class="fas fa-sort"></i></button>
              </div>
              <ow-perfect-scrollbar scrollGradient>
                <table mat-table [dataSource]="dataAdjusted" class="mat-elevation-z8">

                  <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="numberID">
                    <td mat-cell *matCellDef="let element"> {{element.numberID}} </td>
                  </ng-container>

                  <ng-container matColumnDef="realization">
                    <td mat-cell *matCellDef="let element"> {{element.realization}} </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-style"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-match" colspan="4">Brak pasujących danych: "{{searchString}}"</td>
                  </tr>
                </table>
              </ow-perfect-scrollbar>
            </div>
        </swiper-slide>
      </swiper-container>

    </div>
  </div>
</div>
