import { Injectable } from "@angular/core";

import {
  EVENT_DIALOGS_NEW_BUSINESS_CUSTOM,
} from "../consts/custom/event-dialogs.const";
import { AbstractEventDialogsService } from "../../../../../core/abstracts/abstract-event-dialogs.service";

@Injectable({
  providedIn: "root",
})
export class BusinessDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      coreEventDialogs: {},
      customEventDialogs: EVENT_DIALOGS_NEW_BUSINESS_CUSTOM,
    };
  }
}
