<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="wrapper">
    <nav>
      <div class="left">
        <div class="top">
          <button class="round-button round-reverse fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>

        <div class="bottom">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
        <div class="bottom">
          <button class="round-button fixed c-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-light-green">
            <i class="fal fa-shield-check"></i>
          </button>
          <button class="round-button fixed c-lazure">
            <i class="fal fa-shield-check"></i>
          </button>
        </div>
      </div>
    </nav>
    <div class="content">
      <div class="swiper-navigation" >
        <i class="far fa-chevron-square-left left arrow-pagination " ></i>
        <i class="far fa-chevron-square-right right arrow-pagination"></i>
      </div>
      <swiper-container #swiperRef class="slider-container">
        <swiper-slide class="page">

            <div class="challenge">
              <p>Zadanie 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            </div>
            <div class="progress">
              <p class="percentage" [ngStyle]="{'left': (a.progress - 2) + '%'}">{{a.progress}}%</p>
              <div class="bar">
                <div class="fill" [ngStyle]="{'width': a.progress + '%' }"></div>
              </div>
              <div class="info">
                <div>
                  <p>Daty: {{a.dates}}</p>
                  <p>Dane aktualne na: {{a.actualDate}}</p>
                </div>
                <div>
                  <p>Realizacja: {{a.currPoints | number: '3.'}}</p>
                  <p>Plan: {{a.maxPoints | number: '3.'}}</p>
                </div>
              </div>
            </div>
            <div class="difficulty">
              <div class="boxes-container">
                <div class="box green">
                  <div class="info">
                    <div class="header">
                      <p class="name">Łatwe</p>
                      <div class="votes">
                        Głosów:
                        <div class="round-button c-green round-reverse">
                          <p>2</p>
                        </div>
                      </div>
                    </div>
                    <div class="award">
                      <div class="objective">
                        <p class="name">Cel:</p>
                        <p class="value">90%</p>
                      </div>
                      <div class="bonus">
                        <p class="name">Bonus:</p>
                        <div class="list">
                          <div><p>1500</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                          <div><p>1000</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                          <div><p>500</p> <img [src]="'diamonds.png' | asset: 'currency/small'" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box yellow active">
                  <div class="info">
                    <div class="header">
                      <p class="name">Średnie</p>
                      <div class="votes">
                        Głosów:
                        <div class="round-button c-green round-reverse">
                          <p>5</p>
                        </div>
                      </div>
                    </div>
                    <div class="award">
                      <div class="objective">
                        <p class="name">Cel:</p>
                        <p class="value">100%</p>
                      </div>
                      <div class="bonus">
                        <p class="name">Bonus:</p>
                        <div class="list">
                          <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                          <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                          <div><p>700</p> <img [src]="'diamonds.png' | asset: 'currency/small'" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box red">
                  <div class="info">
                    <div class="header">
                      <p class="name">Trudne</p>
                      <!--                  <div class="votes">-->
                      <!--                    Głosów:-->
                      <!--                    <div class="round-button c-green round-reverse">-->
                      <!--                      <p>2</p>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                    </div>
                    <div class="award">
                      <div class="objective">
                        <p class="name">Cel:</p>
                        <p class="value">120%</p>
                      </div>
                      <div class="bonus">
                        <p class="name">Bonus:</p>
                        <div class="list">
                          <div><p>3500</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                          <div><p>2000</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                          <div><p>1000</p> <img [src]="'diamonds.png' | asset: 'currency/small'" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="strategy">
              <div class="decorator"><p>Strategia nagradzania:</p></div>
              <div class="thresholds">
                <div class="step">
                  <p class="name">70%</p>
                  <div class="list">
                    <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                    <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                  </div>
                </div>
                <div class="step">
                  <p class="name">90%</p>
                  <div class="list">
                    <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                    <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                  </div>
                </div>
                <div class="step">
                  <p class="name">100%</p>
                  <div class="list">
                    <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                    <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                  </div>
                </div>
                <div class="step">
                  <p class="name">110%</p>
                  <div class="list">
                    <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                    <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                  </div>
                </div>
                <div class="step">
                  <p class="name">120%</p>
                  <div class="list">
                    <div><p>2000</p> <img [src]="'money.png' | asset: 'currency/small'" /></div>
                    <div><p>1200</p> <img [src]="'cash.png' | asset: 'currency/small'" /></div>
                    <div><p>700</p> <img [src]="'diamonds.png' | asset: 'currency/small'" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="description">
              <div class="decorator"><p>Opis zadania:</p></div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lorem magna. Integer posuere enim nec elit
                rutrum, eu accumsan nisl pellentesque. Sed ac bibendum metus. Phasellus nibh dolor, egestas sed enim sed,
                dictum ultrices nisi. Aliquam sed egestas mauris. Duis nec lectus leo. Ut eget faucibus eros. Aliquam
                efficitur condimentum lectus quis interdum. Nulla turpis nisl, hendrerit nec sollicitudin a, tristique vitae
                ipsum. Donec pellentesque imperdiet augue sit amet euismod. Pellentesque a vestibulum quam, sed posuere
                magna. Praesent cursus scelerisque libero, eget auctor ante luctus quis.
              </p>
            </div>

        </swiper-slide>
      </swiper-container>

    </div>
  </div>
</div>
