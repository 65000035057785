import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AbstractQaDetailsComponent } from "../../../../abstract/core/abstract-qa-details.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";
import { Qa } from "../../../../interfaces/core/qa.interface";

@Component({
  selector: "app-custom-m-qa-details",
  templateUrl: "./qa-details.component.html",
})
export class MQaDetailsComponent extends AbstractQaDetailsComponent implements OnInit, OnDestroy {
  @OwInject(MAT_DIALOG_DATA) data: {
    qa: Qa;
    prevState: {
      location;
      type;
      eachQaId?: number;
    };
    isOpenFromMockUpTask?: boolean;
  };

  ngOnInit() {
    if (this.data.qa) {
      this.setCurrentQa();
    }
  }

  openFinishDialog(backToList: boolean) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_FINISH,
      config: {
        disableClose: true,
        data: {
          qa: this.qa,
          prevState: this.data.prevState,
          backToList,
          isOpenFromMockUpTask: this.data.isOpenFromMockUpTask,
        },
      },
    });
  }

  ngOnDestroy() {
    this.clearInterval();
  }
}
