<div class="production-container-allow" *ngIf="production && production.length">
  <p class="allow-production-title">
    {{ "building-allow-production.title" | myTranslate }}
  </p>

  <!-- TOOLTIP PRODUCTION -->
  <div class="tooltip-production" *ngIf="activeProduct">
    <m-ui-close-button></m-ui-close-button>
    <m-building-row-production #buildingRowProductionComponent [production]="activeProduct"></m-building-row-production>
  </div>

  <!-- PRODUCTION -->
  <div class="production">
    <div class="product-container" *ngFor="let item of production" (click)="tooltipRowProduction(item, $event)">
      <!-- BULLET -->
      <div class="bullet" *ngIf="activeProduct === item"></div>

      <!-- PRODUCT -->
      <ng-container *ngIf="item.product_prizes.length === 1 && !item.currency_prizes.length">
        <span matTooltip="{{item.product_prizes[0].name | htmlEntity}}"
              [matTooltipPosition]="'below'"
              [matTooltipShowDelay]="300">
                  <m-ui-product [stockView]="STOCK_VIEW.E"
                                [item]="item.product_prizes[0]"
                                [other]="{ value: 'x' }" [lack]="false"
                  ></m-ui-product>
        </span>

      </ng-container>

      <!-- CURRENCY -->
      <ng-container *ngIf="item.currency_prizes.length === 1 && !item.product_prizes.length">
        <m-ui-currency [stockView]="STOCK_VIEW.E" [item]="item.currency_prizes[0]" [other]="{ value: 'x' }" [lack]="false"></m-ui-currency>
      </ng-container>

      <!-- MULTIPLE PRODUCTION -->
      <ng-container
        *ngIf="
          item.product_prizes.length > 1 ||
          item.currency_prizes.length > 1 ||
          (item.product_prizes.length === 1 && item.currency_prizes.length === 1)
        ">
        <m-ui-multi-stock [stockView]="STOCK_VIEW.E" [items]="item" [preventDefault]="true"></m-ui-multi-stock>
      </ng-container>
    </div>
  </div>

  <!-- BACKDROP -->
  <div class="background-tooltip-hide" [class.active]="activeProduct" (click)="tooltipRowProduction(null)"></div>
</div>
