import { EVENTS } from "../../../core/consts/core/events";
import { GlobalEvent } from "../../../core/interfaces/shared.interfaces";
import { BoardTileUpdate, NewImportantMessageReceived } from "../../../store/game/actions";
import { BoardTileState } from "../../../store/game/interfaces/board-tile.state";
import { TryUpdatePlayer, UpdateUnreadMessages } from "../../../store/player/actions";
import { UtilityActions } from "../../../store/utility";
import { ADDITIONAL_BOARD_DATA } from "../game-engine/config/additional-board-data.config";
import { isTileActive, isTileBuyable } from "../game-engine/utils/game.helper";
import { EVENT_DIALOGS_NAMES_BUILDINGS } from "../game-ui/buildings/consts/core/event-dialogs/event-names.const";
import { BuildingData } from "../game-ui/buildings/interfaces/core/dialogs/building-data.interface";
import { BuildingMoveData } from "../game-ui/buildings/interfaces/core/dialogs/building-move-data.interface";
import { TileBuyData } from "../game-ui/buildings/interfaces/core/dialogs/tile-buy-data.interface";
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../game-ui/currency-exchange/consts/core/event-dialogs/event-names.const";
import { MISSION_TYPE } from "../game-ui/mission/consts/core";
import { EVENT_DIALOGS_NAMES_MISSION } from "../game-ui/mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_QA } from "../game-ui/qa/consts/core/event-dialogs/event-names.const";
import { GAME_EVENTS } from "./game.constants";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../game-ui/warehouse/consts/core/event-dialogs/event-names.const";

export const HANDLING_METHOD = {
  DIALOG_EMITTER: "DIALOG_EMITTER",
  PHASER_OPEN_TILE_MENU: "PHASER_OPEN_TILE_MENU",
  PHASER_TILE_CLICK: "PHASER_TILE_CLICK",
  STORE_DISPATCH: "STORE_DISPATCH",
  STORE_DISPATCH_MULTIPLE: "STORE_DISPATCH_MULTIPLE",
};

export interface GlobalHandler {
  [key: string]: {
    handling_method: string;
    returnData: (event: GlobalEvent, data?: any) => any;
  };
}

export const GLOBAL_HANDLERS: GlobalHandler = {
  [GAME_EVENTS.BUILDING_INFO]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingInfoData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO,
        config: {
          data: buildingInfoData,
        },
      };
    },
  },
  [GAME_EVENTS.OPEN_TILE_MENU]: {
    handling_method: HANDLING_METHOD.PHASER_OPEN_TILE_MENU,
    returnData: (event: GlobalEvent) => {
      return event.value;
    },
  },
  [GAME_EVENTS.BUILDING_UPGRADE]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingUpgradeData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE,
        config: {
          data: buildingUpgradeData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_SPECIAL_UPGRADE]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingSpecialData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE,
        config: {
          data: buildingSpecialData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_UPGRADE_WITH_PROBABILITY]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingProbabilityData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PROBABILITY_UPGRADE,
        config: {
          data: buildingProbabilityData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_UPGRADE_SPECIAL_INCREASE]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingSpecialIncreaseData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_INCREASE,
        config: {
          data: buildingSpecialIncreaseData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_PRODUCTION]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingProductionData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION,
        config: {
          data: buildingProductionData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_DAILY_PRODUCTION]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingDailyProductionData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DAILY_PRODUCTION,
        config: {
          data: buildingDailyProductionData,
        },
      };
    },
  },
  [GAME_EVENTS.OPEN_PLAYER_MISSION]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const missionDetailsData = {
        mission: {
          player_mission_id: event.value.player_mission_id,
        },
      };
      return {
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
        config: {
          data: missionDetailsData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_AUTO_PRODUCTION]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingAutoProductionData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_AUTO_PRODUCTION,
        config: {
          data: buildingAutoProductionData,
        },
      };
    },
  },
  [GAME_EVENTS.TILE_CLICK]: {
    handling_method: HANDLING_METHOD.PHASER_TILE_CLICK,
    returnData: (event: GlobalEvent, tile: BoardTileState) => {
      if (isTileBuyable(tile.state)) {
        const tileBuyData: TileBuyData = {
          tile,
        };
        return {
          name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_TILE_BUY,
          config: {
            data: tileBuyData,
          },
        };
      } else if (isTileActive(tile.state)) {
        const buildingData: BuildingData = {
          playerTileId: tile.player_tile_id,
        };
        return {
          name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST,
          config: {
            data: buildingData,
          },
        };
      }
    },
  },
  [GAME_EVENTS.BUILDING_DELETE]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingDemolishData: BuildingData = {
        playerTileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DEMOLISH,
        config: {
          data: buildingDemolishData,
        },
      };
    },
  },
  [GAME_EVENTS.BUILDING_MOVE]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      const buildingMoveData: BuildingMoveData = {
        tileId: event.value,
      };
      return {
        name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE,
        config: {
          data: buildingMoveData,
        },
      };
    },
  },
  [EVENTS.GAME.BUILDING_MENU_CLICK_ACTION.OPEN_FUNCTIONAL]: {
    handling_method: HANDLING_METHOD.DIALOG_EMITTER,
    returnData: (event: GlobalEvent) => {
      if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_EXCHANGE)) {
        return {
          name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
        };
      }
      if (event.data.player_building.group === "bank") {
        return {
          name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
        };
      }
      if (event.data.player_building.group === "warehouse_wholesaler") {
        return {
          name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_SALE,
        };
      }
      if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_QA_LOCATION_ID)) {
        const qaLocationId = event.data.player_building.additional_board_data[ADDITIONAL_BOARD_DATA.OPEN_QA_LOCATION_ID];
        return {
          name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
          config: {
            data: {
              location: qaLocationId,
            },
          },
        };
      }
    },
  },
  [EVENTS.GLOBAL.UPDATE_PLAYER]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new TryUpdatePlayer(event.value);
    },
  },
  [EVENTS.GLOBAL.MISSION_COMPLETED]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH_MULTIPLE,
    returnData: (event: GlobalEvent) => {
      const elements = [];
      if (event.value.mission_type === MISSION_TYPE.ONE && event.value.mission_slot_id !== 1) {
        elements.push(new UtilityActions.SetHasMissionsToCollect(true));
      }
      if (event.value.mission_type === MISSION_TYPE.ONE) {
        elements.push(
          new UtilityActions.AddMissionToOpen({
            player_mission_id: event.value.player_mission_id,
            slot: {
              mission_slot_id: event.value.mission_slot_id,
              type: event.value.mission_type,
            },
          })
        );
      }
      return elements;
    },
  },
  [EVENTS.GLOBAL.PLAYER_MISSION_SHOW_DETAILS]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new UtilityActions.AddMissionToOpen({
        player_mission_id: event.value.player_mission_id,
        slot: {
          mission_slot_id: event.value.mission_slot_id,
          type: event.value.mission_type,
        },
      });
    },
  },
  [EVENTS.GLOBAL.NEW_PRODUCTS_AT_LEVEL]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new UtilityActions.AddNewProductsInStorage(event.value.products);
    },
  },
  [EVENTS.GLOBAL.NEW_IMPORTANT_MESSAGE]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new NewImportantMessageReceived(event.value);
    },
  },
  [EVENTS.GLOBAL.TILE_CHANGED]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new BoardTileUpdate(event.value);
    },
  },
  [EVENTS.GLOBAL.UNREAD_COUNT]: {
    handling_method: HANDLING_METHOD.STORE_DISPATCH,
    returnData: (event: GlobalEvent) => {
      return new UpdateUnreadMessages(event.value);
    },
  },
};
