import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { GetGameRanksRequest } from '../interfaces/request/get-game-ranks.request.interface';
import { Observable } from 'rxjs';
import { GetGameRanksResponse } from '../interfaces/response/get-game-ranks.response.interface';
import { GetGameRankResultsRequest } from '../interfaces/request/get-game-rank-results.request.interface';
import { GetGameRankResultsResponse } from '../interfaces/response/get-game-rank-results.response.interface';
import { GetGameRankRequest } from '../interfaces/request/get-game-rank.request.interface';
import { GetGameRankResponse } from '../interfaces/response/get-game-rank.response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiCoreGameRanksService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  getGameRanks({location}: GetGameRanksRequest = {}): Observable<GetGameRanksResponse> {
    const options = {
      params: {
        location,
      }
    };
    return this.apiService.get('game/ranks', options);
  }

  getGameRank({rank_edition_id}: GetGameRankRequest): Observable<GetGameRankResponse> {
    return this.apiService.get(`game/ranks/${rank_edition_id}`);
  }

  getGameRanksResults({rank_edition_id, ...data}: GetGameRankResultsRequest): Observable<GetGameRankResultsResponse> {
    const options = {
      params: {
        ...data
      }
    };

    return this.apiService.get(`game/ranks/${rank_edition_id}/results`, options);
  }

  gameRankBranchesResult({rank_edition_id, ...data}: GetGameRankResultsRequest): Observable<GetGameRankResultsResponse> {
    const options = {
      params: {
        ...data
      }
    };
    return this.apiService.get(`game/ranks/${rank_edition_id}/branch-results`, options);
  }

  getGameRanksDetails(rank_edition_id): Observable<any> {
    return this.apiService.get(`game/ranks/${rank_edition_id}/details`);
  }
}
