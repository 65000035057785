<loading></loading>
<div class="filter-button-wrapper" [ngClass]="isFiltersOpen ? 'open' : ''">
  <button *ngIf="!isFiltersOpen" [disabled]="loading" class="auth-button primary business-button-round filters" (click)="toggleFilters()">
    Filtry {{ filtersCount > 0 ? "(" + filtersCount + ")" : "" }}
  </button>
  <span *ngIf="isFiltersOpen">Filtry</span>
</div>
<div class="action-blocks-content-wrapper">
  <div *ngIf="isFiltersOpen" class="filters">
    <div class="filters-wrapper">
      <div class="item" *ngFor="let i of filters[0]">
        <div class="label-wrapper">{{ i.label }}</div>
        <div class="search-wrapper">
          <div class="search">
            <div class="search-bar">
              <input
                type="text"
                name="filter-{{ i.id }}"
                placeholder="Szukaj"
                [ngModel]="itemFiltered[i.id]"
                (ngModelChange)="onFilterChange($event, i.id)" />
              <i class="far fa-search"></i>
            </div>
          </div>
          <button (click)="changeSort(i.id, i.label_type)" class="sort-button">
            <i
              class="fa-sharp fa-solid"
              [ngClass]="
                itemSorted[i.id]?.direction
                  ? itemSorted[i.id]?.direction === 'asc'
                    ? 'fa-arrow-up'
                    : 'fa-arrow-down'
                  : 'fa-arrow-up-arrow-down'
              "></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isFiltersOpen" class="data-wrapper">
    <div [ngClass]="{ active: block?.data?.length === 0 && !loading }" class="empty"><span>Brak danych</span></div>
    <div class="item-wrapper" *ngFor="let item of block?.data">
      <div *ngFor="let i of item" class="item">
        <div class="label">{{ i.label }}</div>
        <div class="value">{{ formatBlockValue(i.value, i.label_type) }}</div>
      </div>
    </div>
  </div>
  <button
    *ngIf="isMoreBlocks && !isFiltersOpen"
    [disabled]="loading"
    class="auth-button primary business-button-round"
    (click)="loadMoreBlockData()">
    Załaduj więcej danych
  </button>
</div>
<button *ngIf="isFiltersOpen" [disabled]="loading" class="auth-button primary business-button-round" (click)="setFilters()">
  Zastosuj
</button>
