import { Component, OnInit } from "@angular/core";

import { AbstractMissionsComponent } from "../../../../abstract/core/abstract-missions.component";
import { Mission } from "../../../../interfaces/core";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../consts/core/event-dialogs/event-names";

@Component({
  selector: "app-custom-missions",
  templateUrl: "../../../core/dialogs/missions/missions.component.html",
})
export class MissionsComponent extends AbstractMissionsComponent implements OnInit {
  ngOnInit() {
    this.baseInit();
  }

  afterMissionsRequest() {
    this.filterWithoutSlotType(1);
    this.setCurrencyAndProducts();
    this.checkMissionComplete();
    this.setDiffTimes();
    this.sortMissions();
    this.normalMissions.pages = [];
    this.normalMissions.items = this.missions;
    this.generatePages();
    this.setSwiper()
  }

  filterWithoutSlotType(missionSlotType: number) {
    this.missions = this.missions.filter(mission => {
      return mission.slot.mission_slot_id !== missionSlotType || mission.conditions_completed === mission.conditions_count;
    });
  }

  sortMissions() {
    this.missions = this.missions.filter(mission => mission.slot.mission_slot_id > 1);
  }
}
