import { Injectable } from "@angular/core";
import { Moment } from "moment";
import { Subject } from "rxjs";

import { EVENT_DIALOGS_NAMES_NEW_BUSINESS } from "../consts/custom/event-names.const";
import { TASK_TYPE } from "../consts/custom/task-type.const";
import { CardTask } from "../interfaces/custom/card-task.interface";

@Injectable({
  providedIn: "root",
})
export class BusinessViewSelectorService {
  private componentSubject = new Subject<string>();
  cardTasks: CardTask[];
  index = 0;
  week: Moment;
  changeComponent$ = this.componentSubject.asObservable();
  constructor() {}
  changeView(component: string) {
    this.componentSubject.next(component);
  }
  selectComponent(taskIndex?: number) {
    let component: string;
    let task: CardTask;
    if (taskIndex !== null && taskIndex !== undefined) {
      this.index = taskIndex;
      task = this.cardTasks[taskIndex];
    } else {
      task = this.cardTasks[this.index];
    }
    switch (task.selected.task_type) {
      case TASK_TYPE.DAILY:
        component = EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_DAILY;
        break;
      case TASK_TYPE.BI_WEEKLY:
        component = EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_WEEKLY;
        break;
      case TASK_TYPE.WEEKLY:
        component = EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_WEEKLY;
        break;
      case TASK_TYPE.OPEARATION_WEEKLY:
        component = EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_WEEKLY;
        break;
      default:
        component = EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_WEEKLY;
    }
    this.changeView(component);
  }

  nextTask() {
    this.index++;
    if (this.index >= this.cardTasks.length) {
      this.index = 0;
    }
    this.selectComponent(this.index);
  }
  prevTask() {
    this.index--;
    if (this.index < 0) {
      this.index = this.cardTasks.length - 1;
    }
    this.selectComponent(this.index);
  }

  clearFields() {
    this.week = null;
    this.index = null;
    this.cardTasks = null;
  }
}
