<nav class="action-navigation">
  <div class="left">
    <button
      *ngIf="actualComponent !== 'CHAT'"
      class="business-button-round fixed c-green"
      [matTooltip]="'Plany'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('PLANS')">
      <i class="fa-regular fa-square-list"></i>
    </button>
    <button
      *ngIf="actualComponent !== 'CHAT'"
      class="business-button-round fixed c-green"
      [matTooltip]="'Wyzwania'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('CHALLENGES')">
      <i class="fal fa-shield-check"></i>
    </button>
    <button
      *ngIf="actualComponent !== 'CHAT' && [2, 3, 4, 5].includes(player.player.title_id.id)"
      class="business-button-round fixed c-green"
      [matTooltip]="'Zadania'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('TASKS')">
      <i class="fal fa-list-check"></i>
    </button>
    <div *ngIf="actualComponent === 'CHAT'"></div>
  </div>
  <div class="right">
    <button
      class="business-button-round fixed c-green"
      [disabled]="actualComponent === 'ACTIONS'"
      [matTooltip]="'Akcje'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('ACTIONS')">
      <i class="fa-regular fa-bullseye-pointer"></i>
    </button>
    <button
      class="business-button-round fixed c-green"
      [matTooltip]="'Grywalizacja'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('GAME')">
      <i class="fa-regular fa-gamepad-modern"></i>
    </button>
    <button
      class="business-button-round fixed c-green"
      [disabled]="actualComponent === 'CHAT'"
      [matTooltip]="'Chat'"
      matTooltipPosition="left"
      matTooltipShowDelay="300"
      (click)="open('CHAT')">
      <i class="fa-regular fa-comment"></i>
      <span *ngIf="messageCount$ | async" class="message-count">{{ messageCount$ | async }}</span>
    </button>
  </div>
</nav>
