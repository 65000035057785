export enum BadgeStatus {
  TO_PARAMETRIZE, // waiting to be parametrized by import
  CLEARED, // is finished and awards has been settled
  VOTING, // difficulty vote is active
  ACTIVE // difficulty vote has ended, but task is still active
}

export enum BadgeColors {
  green = "green",
  yellow = "yellow",
  red = "red",
}

export interface Badge {
  color: BadgeColors;
  title: string;
  description: string;
  status?: BadgeStatus
}

export interface AwardPrize {
  amount: number;
  currency_id: number;
  currency_type: string;
}

export interface CombinedAward {
  amount?: number;
  currency_id: number;
  currency_type: string;
  bonus_amount?: number;
  type: "combined" | "normal" | "bonus";
}
