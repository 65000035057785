import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { Router } from "@angular/router";

@Component({
  selector: 'app-plan-shop-list',
  templateUrl: './plan-shop-list.component.html'
})
export class PlanShopListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(Router) router: Router;
  searchString="";
  sortReverted={
    name: false,
    numerID: false,
    realization: false
  };
  a = {
    progress: 50,
    currPoints: 60000,
    maxPoints: 120000,
    dates: "01.09-30.09",
    actualDate: "23.09",
  };
  displayedColumns: string[] = ['name', "numberID", "realization"];
  dataSource = [
    {name: " snazw1323a sklepu 1", numberID: "859 123625 452", realization: "120 000", },
    {name: " nazwa sklepu 2", numberID: "859 625 42352", realization: "120 02", },
    {name: " sdnasazwa 123sklepu 3", numberID: "859 6125 452", realization: "120 000", },
    {name: " asdnazw231a sklepu 42", numberID: "859 123625 452", realization: "120 000", },
    {name: " nazwa sklepu 4", numberID: "859 625 452", realization: "120 000", },
    {name: " asdnazw112323klepu 123", numberID: "859 625 452", realization: "12120 000", },
    {name: " nazwa sklepu 61", numberID: "859 621235 452", realization: "120 000", },
    {name: " nazw123a sklepu 7", numberID: "859 625 452", realization: "120 000", },
    {name: " naz123wa sklepu 8", numberID: "812359 625 452", realization: "1220 000", },
    {name: " nazw3a skl123epu 9", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 10", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 11", numberID: "859 625 452", realization: "120 000", },
    {name: " naz12wa sklepu 12", numberID: "859 625 452", realization: "12012 000", },
    {name: " nazwa12312 sklepu 15", numberID: "859 625 452", realization: "12012 000", },
    {name: " nazwa sklepu 16", numberID: "859 62125 452", realization: "120 000", },
    {name: " nazwa sklepu 17", numberID: "859 625 452", realization: "120 2000", },
    {name: " nazwa sklepu 18", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 19", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 20", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 21", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 22", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 23", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 24", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 25", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 26", numberID: "859 625 452", realization: "120 000", },
    {name: " nazwa sklepu 27", numberID: "859 625 452", realization: "120 000", },

  ];
  dataAdjusted=[];
  ngOnInit() {
    this.initData();
  };

  onSearchStringChange(){
    const search = this.searchString.toLowerCase();
    this.dataAdjusted = this.dataSource.filter(element=>{
      const combinedStrings = element.name.toLowerCase() + element.numberID.toLowerCase() + element.realization.toLowerCase();
      return combinedStrings.includes(search);
    })
  }

  initData(){
    this.dataAdjusted = [...this.dataSource];
  }

  sortBy(key){
    if(this.sortReverted[key]){
      this.dataAdjusted = [
        ...this.dataAdjusted.sort((ele1, ele2) => {
        return ele1[key].localeCompare(ele2[key]);
      })];
      this.sortReverted[key] = !this.sortReverted[key];
    } else {
      this.dataAdjusted = [
        ...this.dataAdjusted.sort((ele1, ele2) => {
        console.log(ele1[key].localeCompare(ele2[key]));
        return -ele1[key].localeCompare(ele2[key]);
      })];
      this.sortReverted[key] = !this.sortReverted[key];
    }
  }

}
