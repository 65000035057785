import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { ClientList } from "../../../interfaces/custom/client-list.interface";
import { Badge } from "../../../interfaces/custom/common-business.interface";
import { ClientRealization } from "../../../interfaces/custom/plans.interface";
import { BusinessPanelSections } from "../../components/business-navigation/business-navigation.component";

@Component({
  selector: "app-client-list",
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
})
export class ClientListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ChangeDetectorRef) changeDetection: ChangeDetectorRef;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  imagePath = window.location.origin.includes("localhost") ? "https://eurocash4.o-w.dev/" : window.location.origin + "/";

  @OwInject(MAT_DIALOG_DATA) data?: {
    clientList: ClientList;
    listType: BusinessPanelSections;
    badge: Badge;
  };
  date: OwDate;
  clientList: ClientList;
  searchString = "";
  sortReverted = {
    external_id: false,
    name: false,
    performance: false,
  };
  displayedColumns: string[] = ["external_id", "name", "performance"];
  dataAdjusted: ClientList["client_realizations"] = [];
  currentSortColumn: string = "";
  sortDirection: string = "asc"; // 'asc' lub 'desc'
  listType: BusinessPanelSections = null;

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.clientList = this.data.clientList;
    this.dataAdjusted = this.clientList.client_realizations.map(this.updateClientAddress);
    this.listType = this.data.listType;
  }

  backToPreviousDialog() {
    this.dialogService.closeActive();
  }

  updateClientAddress = (realization: ClientRealization): ClientRealization => {
    return {
      ...realization,
      performance: realization.performance || 0,
    };
  };

  onSearchStringChange() {
    const search = this.searchString.toLowerCase();
    this.dataAdjusted = this.clientList.client_realizations.filter(element => {
      const combinedStrings = element.full_name.toLowerCase() + element.external_id + element.performance.toString();
      return combinedStrings.includes(search);
    });
  }

  sortBy(key) {
    if (key === this.currentSortColumn) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortDirection = "asc";
    }
    this.currentSortColumn = key;

    if (this.sortReverted[key]) {
      this.dataAdjusted = [
        ...this.dataAdjusted.sort((ele1, ele2) => {
          if (typeof ele1[key] === "number") {
            return ele1[key] - ele2[key];
          }

          return ele1[key].localeCompare(ele2[key]);
        }),
      ];
      this.sortReverted[key] = !this.sortReverted[key];
    } else {
      this.dataAdjusted = [
        ...this.dataAdjusted.sort((ele1, ele2) => {
          if (typeof ele1[key] === "number") {
            return ele2[key] - ele1[key];
          }

          return -ele1[key].localeCompare(ele2[key]);
        }),
      ];
      this.sortReverted[key] = !this.sortReverted[key];
    }
  }

  markAsInvalid(el) {
    el.logo = "";
  }
}
