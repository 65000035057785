import { BoardTileCore } from "../core/BoardTileCore.class";
import { EcGameService } from "../../../services/custom/ec-game.service";
import { PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS, ProductionStatus, TileTooltipType } from "../../../constants";
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from "../../../constants/iso.constants";

export class BoardTile extends BoardTileCore {
  beforeClickHandle(pointer: Phaser.Input.Pointer): any {
    // eslint-disable-next-line no-prototype-builtins
    if (this.playerBuildingData?.hasOwnProperty("warehouse_income") && this.playerBuildingData["warehouse_income"] > 0) {
      this.warehouseSaleCollect();
      return true;
    }
  }

  warehouseSaleCollect?() {
    this.disableInteractive();
    (this.gameService as EcGameService).apiWarehouseService.warehouseSaleReceive().subscribe(
      () => {
        this.tooltip.niceDestroy();
        this.tooltip = null;
        try {
          this.setInteractive();
        } catch (error) {
          console.log(error);
          console.log(this);
        }
      },
      () => {
        this.setInteractive();
      }
    );
  }

  handleBuildingStatus() {
    if (!this.gameService.playerService.isActiveMe) {
      return;
    }

    if (this.hasMission()) {
      this.createTooltip(TileTooltipType.MISSION);
    } else if (this.hasBuilding && this.playerBuildingData.auto_production_amount) {
      this.createTooltip(TileTooltipType.COLLECT);
    } else if (this.hasBuilding && this.isBuildInProgress()) {
      switch (this.playerBuildingData.status) {
        case PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS:
          this.createTooltip(TileTooltipType.UPGRADE);
          break;
      }
    } else if (this.tileData.production) {
      switch (this.tileData.production.status) {
        case ProductionStatus.InProgress:
          this.createTooltip(TileTooltipType.PRODUCTION);
          break;
        case ProductionStatus.Finished:
          this.createTooltip(TileTooltipType.PRODUCTION_FINISHED);
          break;
      }
    } else if (this.playerBuildingData["warehouse_income"]) {
      this.createTooltip(TileTooltipType.COLLECT);
    }
  }
  calculateDepth() {
    if (this.baseSprite) {
      let correction = 0;
      switch (this.tileData.tile_id) {
        case 124:
          correction = 100;
          break;
      }
      if (this.tileData.x_pos === 3200 && this.tileData.y_pos === -2688) {
        correction = -100;
      }

      this.setDepth(this.depthIndex + (this.y + correction) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
    }
  }
}
