import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

import { ScreenToIsoComponent } from "../components/game-debug/components/screen-to-iso/screen-to-iso.component";
import { WorldInfoComponent } from "../components/game-debug/components/world-info/world-info.component";
import { GameDebugComponent } from "../components/game-debug/game-debug.component";
import { OnOffPipe } from "../components/game-debug/pipes/on-off.pipe";

@NgModule({
  declarations: [WorldInfoComponent, ScreenToIsoComponent, GameDebugComponent, OnOffPipe],
  imports: [CommonModule, FormsModule, RouterLink],
  exports: [GameDebugComponent],
})
export class DebugModule {}
