import { Directive, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { ApiOptions } from "../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../core/providers/api.service";
import { AppState } from "../../../../../store/state";
import { UtilityActions } from "../../../../../store/utility";
import { GuiService } from "../../../../game/services/gui.service";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { checkPasswordTheSame } from "../../../helpers/validators-form-password-the-same.helper";
import { TokenObject } from "../../../interfaces/token-object";
import { AuthService } from "../../../providers/auth.service";

@Directive()
@Injectable()
export abstract class AbstractRegistrationConfirmAComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Router) router: Router;
  @OwInject(GuiService) guiService: GuiService;
  form: FormGroup;
  token: string;
  devLogin = false;

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty("token")) {
        this.token = params["token"];
        this.clearForm();
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty("test")) {
        this.devLogin = true;
      }
    });
  }

  clearForm() {
    this.form = this.fb.group(
      {
        token: this.fb.control(this.token, [Validators.required]),
        password: this.fb.control(null, [Validators.required]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  registrationConfirm() {
    const data: RegistrationConfirmRequestData = {
      token: this.form.value.token,
      password: this.form.value.password,
    };

    this.registrationConfirmRequest(data).subscribe((resp: TokenObject) => {
      this.dispatchToken(resp);
      this.routeToPlayerChoose();
    });
  }

  registrationConfirmRequest(data: RegistrationConfirmRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/confirm", options);
  }

  dispatchToken(tokenObject: TokenObject) {
    this.store.dispatch(new UtilityActions.SetToken(tokenObject));
  }

  routeToPlayerChoose() {
    this.guiService.isSplashShow.next(true);

    setTimeout(() => {
      // CORE OVERRIDE
      this.router.navigate(["/auth/dashboard"]);
      // CORE OVERRIDE END
    });
  }
}

interface RegistrationConfirmRequestData {
  token: string;
  password: string;
}
