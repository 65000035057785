export const pwaImage= "<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n" +
    "\t width=\"100%\" viewBox=\"0 0 512 512\" enable-background=\"new 0 0 512 512\" xml:space=\"preserve\">\n" +
    "<path fill=\"#FEFEFE\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M306.000000,513.000000 \n" +
    "\tC204.024765,513.000000 102.549522,513.000000 1.037143,513.000000 \n" +
    "\tC1.037143,342.399750 1.037143,171.799469 1.037143,1.099597 \n" +
    "\tC171.558578,1.099597 342.117249,1.099597 512.837952,1.099597 \n" +
    "\tC512.837952,171.666550 512.837952,342.333252 512.837952,513.000000 \n" +
    "\tC444.132782,513.000000 375.316376,513.000000 306.000000,513.000000 \n" +
    "M212.499969,160.691666 \n" +
    "\tC272.825867,160.825928 333.151733,160.966599 393.477631,161.089981 \n" +
    "\tC408.672394,161.121063 418.508118,152.289642 420.202576,137.156067 \n" +
    "\tC420.721375,132.522324 421.242889,127.888603 421.716461,123.250114 \n" +
    "\tC422.930267,111.361473 424.117828,99.470161 425.343262,87.308159 \n" +
    "\tC423.752960,87.172714 422.631287,86.993668 421.509583,86.993530 \n" +
    "\tC329.187225,86.982552 236.864868,86.984077 144.542511,86.989441 \n" +
    "\tC134.539032,86.990021 127.072517,91.573822 123.011559,100.517105 \n" +
    "\tC120.720085,105.563515 119.591049,111.260376 118.678696,116.786461 \n" +
    "\tC116.327103,131.029922 114.387573,145.341400 112.126640,160.690079 \n" +
    "\tC145.614349,160.690079 178.557144,160.690079 212.499969,160.691666 \n" +
    "M88.379578,305.857544 \n" +
    "\tC68.359581,321.836426 61.406296,342.928497 63.717903,367.869629 \n" +
    "\tC67.167862,405.092926 96.802246,429.899139 133.984131,426.491669 \n" +
    "\tC143.550919,425.614929 152.796494,422.764221 162.823837,424.876831 \n" +
    "\tC170.322403,426.456665 178.421387,425.238037 186.252289,425.174957 \n" +
    "\tC194.474213,425.108673 199.335312,421.677002 202.184875,414.067780 \n" +
    "\tC202.884689,412.199066 203.444748,410.273682 204.229248,408.442993 \n" +
    "\tC204.589386,407.602661 205.309509,406.305481 205.912994,406.279053 \n" +
    "\tC210.062881,406.097107 214.391907,405.535889 218.303085,406.523438 \n" +
    "\tC219.918289,406.931274 220.902802,410.769287 221.726852,413.198914 \n" +
    "\tC223.955093,419.768616 228.084381,424.895294 235.123596,424.873230 \n" +
    "\tC247.157257,424.835541 259.392822,427.358490 271.254456,423.049805 \n" +
    "\tC272.330475,422.658966 273.832367,423.195190 275.071716,423.536224 \n" +
    "\tC294.085785,428.768219 312.539581,428.572876 328.492096,418.814331 \n" +
    "\tC333.985229,421.217285 338.392212,424.414185 343.090881,424.924133 \n" +
    "\tC351.654572,425.853607 360.397949,425.403961 369.053802,425.130890 \n" +
    "\tC377.877106,424.852539 384.136597,418.005035 384.103149,409.056519 \n" +
    "\tC384.092590,406.234680 383.660553,403.414154 383.416290,400.593475 \n" +
    "\tC382.991577,395.689148 382.562836,390.785217 382.142059,385.953217 \n" +
    "\tC385.936737,384.706512 387.180084,385.973694 387.469666,389.239624 \n" +
    "\tC388.102020,396.371429 388.978760,403.482239 389.799622,410.596527 \n" +
    "\tC390.823456,419.470551 396.867493,425.028076 405.826813,425.174866 \n" +
    "\tC412.989899,425.292236 420.156281,425.222260 427.321167,425.207794 \n" +
    "\tC439.658478,425.182922 446.503235,417.565704 445.027191,405.376526 \n" +
    "\tC441.384979,375.299408 437.723511,345.224487 433.996582,315.157776 \n" +
    "\tC432.041626,299.386230 430.003296,296.962250 416.023010,293.302002 \n" +
    "\tC418.666229,291.546295 421.213409,290.075226 423.517029,288.289948 \n" +
    "\tC445.104462,271.559570 452.680145,249.111496 450.272095,222.577133 \n" +
    "\tC446.719574,183.432358 413.088043,159.320297 374.736237,168.288910 \n" +
    "\tC362.045044,171.256760 351.422272,177.616837 342.105682,187.116425 \n" +
    "\tC335.027771,176.494858 325.017609,170.373352 313.009521,169.432114 \n" +
    "\tC296.948486,168.173187 280.760162,168.498398 264.624603,168.279510 \n" +
    "\tC262.536865,168.251160 260.425995,169.436340 258.346497,169.373779 \n" +
    "\tC248.572571,169.079727 238.805450,168.194458 229.036682,168.205276 \n" +
    "\tC220.623154,168.214584 215.097824,173.385101 213.601089,181.729691 \n" +
    "\tC212.809235,186.144363 212.374420,190.626312 211.864868,195.087769 \n" +
    "\tC210.391037,207.992569 208.983139,220.904938 207.494492,233.808014 \n" +
    "\tC207.209335,236.279419 206.903122,238.973160 202.957611,237.873123 \n" +
    "\tC202.957611,237.158356 202.888336,236.523895 202.967743,235.908646 \n" +
    "\tC204.927933,220.720932 206.757980,205.514481 208.918716,190.355194 \n" +
    "\tC211.010605,175.679001 204.691086,168.126923 189.871964,168.130402 \n" +
    "\tC179.438934,168.132858 169.005890,168.163483 158.572861,168.178329 \n" +
    "\tC138.911179,168.206314 119.247803,168.089218 99.588631,168.324860 \n" +
    "\tC91.030640,168.427429 85.460800,173.378845 84.014046,181.742096 \n" +
    "\tC78.398796,214.202225 72.838631,246.672333 67.413765,279.164734 \n" +
    "\tC65.501328,290.619354 72.519836,298.506287 84.277084,298.600433 \n" +
    "\tC88.802986,298.636658 93.329430,298.606323 97.855621,298.606323 \n" +
    "\tC97.894875,298.923004 97.934128,299.239716 97.973381,299.556396 \n" +
    "\tC94.971504,301.521484 91.969627,303.486542 88.379578,305.857544 \n" +
    "z\"/>\n" +
    "<path fill=\"#EF3446\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M211.999954,160.690872 \n" +
    "\tC178.557144,160.690079 145.614349,160.690079 112.126640,160.690079 \n" +
    "\tC114.387573,145.341400 116.327103,131.029922 118.678696,116.786461 \n" +
    "\tC119.591049,111.260376 120.720085,105.563515 123.011559,100.517105 \n" +
    "\tC127.072517,91.573822 134.539032,86.990021 144.542511,86.989441 \n" +
    "\tC236.864868,86.984077 329.187225,86.982552 421.509583,86.993530 \n" +
    "\tC422.631287,86.993668 423.752960,87.172714 425.343262,87.308159 \n" +
    "\tC424.117828,99.470161 422.930267,111.361473 421.716461,123.250114 \n" +
    "\tC421.242889,127.888603 420.721375,132.522324 420.202576,137.156067 \n" +
    "\tC418.508118,152.289642 408.672394,161.121063 393.477631,161.089981 \n" +
    "\tC333.151733,160.966599 272.825867,160.825928 211.999954,160.690872 \n" +
    "M211.031204,119.575233 \n" +
    "\tC211.618454,112.527405 212.205688,105.479576 212.830399,97.982033 \n" +
    "\tC209.530319,97.982033 206.861282,98.257439 204.271484,97.921692 \n" +
    "\tC199.632507,97.320290 196.510513,98.853493 193.969803,102.909767 \n" +
    "\tC190.999084,107.652557 187.328751,111.957123 183.545593,117.007584 \n" +
    "\tC180.460419,111.281158 177.838272,106.125145 174.901947,101.154747 \n" +
    "\tC174.106613,99.808502 172.436356,98.262993 171.037521,98.124603 \n" +
    "\tC166.953140,97.720528 162.803009,97.981018 158.391586,97.981018 \n" +
    "\tC155.967285,115.133286 153.607086,131.832001 151.205704,148.822174 \n" +
    "\tC156.297668,148.822174 160.861877,148.822174 165.687515,148.822174 \n" +
    "\tC166.922424,139.299179 168.123734,130.035370 169.504791,119.385452 \n" +
    "\tC173.622375,126.740959 177.052551,132.868500 180.816269,139.591873 \n" +
    "\tC185.939590,132.953735 190.606110,126.907478 195.272614,120.861206 \n" +
    "\tC195.600784,121.050873 195.928940,121.240532 196.257095,121.430191 \n" +
    "\tC195.410919,130.456970 194.564743,139.483749 193.687180,148.845444 \n" +
    "\tC198.771378,148.845444 203.347015,148.845444 208.397751,148.845444 \n" +
    "\tC209.263977,139.321960 210.120163,129.908844 211.031204,119.575233 \n" +
    "M389.079468,145.816864 \n" +
    "\tC382.385590,132.594040 375.234772,119.575493 369.192627,106.061256 \n" +
    "\tC366.219666,99.411652 362.575439,96.761551 355.374207,97.588318 \n" +
    "\tC352.039490,97.971191 350.459930,98.826210 349.270905,101.918449 \n" +
    "\tC343.594849,116.680115 337.678375,131.349289 331.873566,146.061676 \n" +
    "\tC331.535126,146.919373 331.427002,147.867950 331.102356,149.234543 \n" +
    "\tC335.631012,149.234543 339.786957,149.406601 343.911255,149.121323 \n" +
    "\tC345.032440,149.043777 346.844604,147.713989 347.001312,146.742935 \n" +
    "\tC348.094940,139.965485 352.538208,139.110641 358.184296,139.773376 \n" +
    "\tC360.321014,140.024216 362.552124,140.088425 364.668457,139.766235 \n" +
    "\tC369.002686,139.106415 371.423584,140.780457 372.911407,144.878357 \n" +
    "\tC373.533051,146.590576 375.327209,148.788345 376.888428,149.063751 \n" +
    "\tC380.443420,149.690872 384.192017,149.387115 387.841156,149.146149 \n" +
    "\tC388.433624,149.107040 388.919373,147.451492 389.079468,145.816864 \n" +
    "M274.952484,111.303619 \n" +
    "\tC276.225922,111.904243 277.499359,112.504860 278.794342,113.115639 \n" +
    "\tC281.218964,109.795639 283.352509,106.874199 285.457214,103.992233 \n" +
    "\tC276.879120,96.535400 260.980377,94.848709 251.806625,100.244591 \n" +
    "\tC246.130890,103.582985 243.435043,108.603584 243.835114,115.232674 \n" +
    "\tC244.215149,121.529953 248.455139,124.539574 253.679718,126.508110 \n" +
    "\tC256.624054,127.617485 259.754364,128.229156 262.791504,129.097946 \n" +
    "\tC265.025787,129.737076 267.372284,130.175018 269.419647,131.200180 \n" +
    "\tC270.529297,131.755798 271.848297,133.428253 271.741730,134.459320 \n" +
    "\tC271.628326,135.556473 270.055206,137.169785 268.919769,137.358887 \n" +
    "\tC266.355530,137.785965 263.512543,138.028778 261.065063,137.341721 \n" +
    "\tC256.667908,136.107346 252.478149,134.134262 247.803864,132.314987 \n" +
    "\tC245.338455,135.040527 242.588791,138.080353 239.649963,141.329285 \n" +
    "\tC249.253845,149.358032 259.920502,151.432434 271.387665,149.462830 \n" +
    "\tC280.859558,147.835938 286.398468,141.482010 286.685150,132.995148 \n" +
    "\tC286.929565,125.760048 282.614044,120.990303 273.059113,118.145592 \n" +
    "\tC269.714325,117.149780 266.164642,116.733826 262.952881,115.444435 \n" +
    "\tC261.302216,114.781761 260.188934,112.780426 258.832825,111.384026 \n" +
    "\tC260.598022,110.466202 262.409515,108.712395 264.118591,108.807106 \n" +
    "\tC267.524750,108.995857 270.874573,110.200119 274.952484,111.303619 \n" +
    "M300.608276,149.217667 \n" +
    "\tC303.070923,149.517914 305.533203,150.071884 307.996277,150.075531 \n" +
    "\tC320.205933,150.093613 327.247375,143.188156 327.401184,131.029770 \n" +
    "\tC327.466309,125.880989 327.161194,120.728607 327.111267,115.577019 \n" +
    "\tC327.055511,109.825470 327.098450,104.072960 327.098450,98.404778 \n" +
    "\tC321.687317,98.404778 317.304016,98.404778 312.931915,98.404778 \n" +
    "\tC312.931915,108.768593 313.153168,118.736351 312.784302,128.682220 \n" +
    "\tC312.685822,131.338165 311.298828,135.629425 309.587341,136.148270 \n" +
    "\tC307.015808,136.927872 303.618683,135.105225 300.613556,134.245773 \n" +
    "\tC299.891937,134.039383 299.331543,133.269302 298.417603,132.533524 \n" +
    "\tC295.341705,135.418655 292.359436,138.215958 288.379303,141.949265 \n" +
    "\tC292.688446,144.605194 296.255127,146.803497 300.608276,149.217667 \n" +
    "M236.143433,104.525795 \n" +
    "\tC236.143433,102.455536 236.143433,100.385269 236.143433,98.361557 \n" +
    "\tC231.207977,98.361557 226.814926,98.361557 222.193390,98.361557 \n" +
    "\tC220.891525,115.378212 219.616852,132.039642 218.324341,148.934204 \n" +
    "\tC223.575928,148.934204 228.173050,148.934204 233.323120,148.934204 \n" +
    "\tC234.251923,134.327744 235.168304,119.916550 236.143433,104.525795 \n" +
    "z\"/>\n" +
    "<path fill=\"#060A07\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M88.673668,305.654572 \n" +
    "\tC91.969627,303.486542 94.971504,301.521484 97.973381,299.556396 \n" +
    "\tC97.934128,299.239716 97.894875,298.923004 97.855621,298.606323 \n" +
    "\tC93.329430,298.606323 88.802986,298.636658 84.277084,298.600433 \n" +
    "\tC72.519836,298.506287 65.501328,290.619354 67.413765,279.164734 \n" +
    "\tC72.838631,246.672333 78.398796,214.202225 84.014046,181.742096 \n" +
    "\tC85.460800,173.378845 91.030640,168.427429 99.588631,168.324860 \n" +
    "\tC119.247803,168.089218 138.911179,168.206314 158.572861,168.178329 \n" +
    "\tC169.005890,168.163483 179.438934,168.132858 189.871964,168.130402 \n" +
    "\tC204.691086,168.126923 211.010605,175.679001 208.918716,190.355194 \n" +
    "\tC206.757980,205.514481 204.927933,220.720932 202.967743,235.908646 \n" +
    "\tC202.888336,236.523895 202.957611,237.158356 202.957611,237.873123 \n" +
    "\tC206.903122,238.973160 207.209335,236.279419 207.494492,233.808014 \n" +
    "\tC208.983139,220.904938 210.391037,207.992569 211.864868,195.087769 \n" +
    "\tC212.374420,190.626312 212.809235,186.144363 213.601089,181.729691 \n" +
    "\tC215.097824,173.385101 220.623154,168.214584 229.036682,168.205276 \n" +
    "\tC238.805450,168.194458 248.572571,169.079727 258.346497,169.373779 \n" +
    "\tC260.425995,169.436340 262.536865,168.251160 264.624603,168.279510 \n" +
    "\tC280.760162,168.498398 296.948486,168.173187 313.009521,169.432114 \n" +
    "\tC325.017609,170.373352 335.027771,176.494858 342.105682,187.116425 \n" +
    "\tC351.422272,177.616837 362.045044,171.256760 374.736237,168.288910 \n" +
    "\tC413.088043,159.320297 446.719574,183.432358 450.272095,222.577133 \n" +
    "\tC452.680145,249.111496 445.104462,271.559570 423.517029,288.289948 \n" +
    "\tC421.213409,290.075226 418.666229,291.546295 416.023010,293.302002 \n" +
    "\tC430.003296,296.962250 432.041626,299.386230 433.996582,315.157776 \n" +
    "\tC437.723511,345.224487 441.384979,375.299408 445.027191,405.376526 \n" +
    "\tC446.503235,417.565704 439.658478,425.182922 427.321167,425.207794 \n" +
    "\tC420.156281,425.222260 412.989899,425.292236 405.826813,425.174866 \n" +
    "\tC396.867493,425.028076 390.823456,419.470551 389.799622,410.596527 \n" +
    "\tC388.978760,403.482239 388.102020,396.371429 387.469666,389.239624 \n" +
    "\tC387.180084,385.973694 385.936737,384.706512 382.142059,385.953217 \n" +
    "\tC382.562836,390.785217 382.991577,395.689148 383.416290,400.593475 \n" +
    "\tC383.660553,403.414154 384.092590,406.234680 384.103149,409.056519 \n" +
    "\tC384.136597,418.005035 377.877106,424.852539 369.053802,425.130890 \n" +
    "\tC360.397949,425.403961 351.654572,425.853607 343.090881,424.924133 \n" +
    "\tC338.392212,424.414185 333.985229,421.217285 328.492096,418.814331 \n" +
    "\tC312.539581,428.572876 294.085785,428.768219 275.071716,423.536224 \n" +
    "\tC273.832367,423.195190 272.330475,422.658966 271.254456,423.049805 \n" +
    "\tC259.392822,427.358490 247.157257,424.835541 235.123596,424.873230 \n" +
    "\tC228.084381,424.895294 223.955093,419.768616 221.726852,413.198914 \n" +
    "\tC220.902802,410.769287 219.918289,406.931274 218.303085,406.523438 \n" +
    "\tC214.391907,405.535889 210.062881,406.097107 205.912994,406.279053 \n" +
    "\tC205.309509,406.305481 204.589386,407.602661 204.229248,408.442993 \n" +
    "\tC203.444748,410.273682 202.884689,412.199066 202.184875,414.067780 \n" +
    "\tC199.335312,421.677002 194.474213,425.108673 186.252289,425.174957 \n" +
    "\tC178.421387,425.238037 170.322403,426.456665 162.823837,424.876831 \n" +
    "\tC152.796494,422.764221 143.550919,425.614929 133.984131,426.491669 \n" +
    "\tC96.802246,429.899139 67.167862,405.092926 63.717903,367.869629 \n" +
    "\tC61.406296,342.928497 68.359581,321.836426 88.673668,305.654572 \n" +
    "M331.855804,184.620285 \n" +
    "\tC331.536865,184.668533 331.217926,184.716797 330.709564,185.242966 \n" +
    "\tC330.746399,185.565811 330.783264,185.888672 330.458862,185.800858 \n" +
    "\tC326.119751,178.215622 318.717529,175.309372 310.835144,174.864044 \n" +
    "\tC295.911652,174.020935 280.930023,174.206406 265.289062,173.994598 \n" +
    "\tC264.231628,174.418671 263.174164,174.842743 261.490143,175.378571 \n" +
    "\tC259.632141,175.308533 257.774170,175.238480 255.209854,175.014557 \n" +
    "\tC254.486267,174.997162 253.762665,174.979767 252.525620,174.263596 \n" +
    "\tC244.395782,174.220200 236.265930,174.176804 227.406433,174.099426 \n" +
    "\tC221.784958,175.872650 219.488205,179.878098 218.944992,185.594604 \n" +
    "\tC217.963669,195.921646 216.501144,206.201935 215.383240,216.517319 \n" +
    "\tC214.531799,224.374054 213.902664,232.254883 212.763107,240.711349 \n" +
    "\tC207.635422,244.962112 201.895035,244.847153 196.013245,242.207748 \n" +
    "\tC197.163071,233.520798 198.392715,224.843491 199.442322,216.144455 \n" +
    "\tC200.729904,205.473114 201.994781,194.795715 203.007141,184.096115 \n" +
    "\tC203.421051,179.721344 201.523575,176.366226 196.416580,174.721466 \n" +
    "\tC195.602661,174.491501 194.788742,174.261536 193.052353,173.868607 \n" +
    "\tC189.560486,173.854446 186.062225,173.701141 182.578308,173.860641 \n" +
    "\tC178.006104,174.069962 173.445160,174.525848 168.271729,174.864944 \n" +
    "\tC167.761581,175.166168 167.251434,175.467392 165.996124,175.836288 \n" +
    "\tC164.753693,175.813202 163.511246,175.790100 161.862152,175.256226 \n" +
    "\tC160.892944,174.857269 159.923737,174.458298 158.035431,173.892181 \n" +
    "\tC139.630051,173.887848 121.224632,173.867630 102.819359,173.909836 \n" +
    "\tC101.372032,173.913147 99.925705,174.351059 97.899597,174.714386 \n" +
    "\tC97.201347,175.074097 96.503105,175.433792 95.076790,175.870102 \n" +
    "\tC93.361961,178.257141 90.628632,180.439606 90.101265,183.065155 \n" +
    "\tC87.167297,197.672333 84.728828,212.380157 82.196648,227.066620 \n" +
    "\tC79.129860,244.853806 76.092163,262.646271 73.138100,280.452454 \n" +
    "\tC71.994621,287.345032 76.818619,292.903717 83.934860,292.924011 \n" +
    "\tC104.086784,292.981476 124.239098,292.982330 144.390991,292.920135 \n" +
    "\tC150.391647,292.901611 153.963654,289.768341 155.065186,283.936615 \n" +
    "\tC155.718033,280.480347 156.337646,277.017761 157.219070,272.212097 \n" +
    "\tC168.382660,290.541199 184.548721,294.792999 203.325775,294.173859 \n" +
    "\tC222.393112,293.545197 236.142593,283.936615 248.090698,268.274170 \n" +
    "\tC247.617645,273.984283 247.088226,277.927765 247.007736,281.880371 \n" +
    "\tC246.862122,289.031830 250.766983,292.872620 258.043579,292.934387 \n" +
    "\tC265.371063,292.996552 272.699921,292.994354 280.027435,292.933197 \n" +
    "\tC286.156982,292.882080 290.341858,289.078308 290.951599,282.915833 \n" +
    "\tC291.441223,277.967377 291.664429,272.993011 292.078217,268.036194 \n" +
    "\tC292.170654,266.928619 292.565552,265.846283 293.092224,263.592529 \n" +
    "\tC295.638885,271.368683 297.843567,277.795654 299.840240,284.286591 \n" +
    "\tC301.693481,290.311249 304.765076,292.879303 310.927704,292.933624 \n" +
    "\tC317.922089,292.995270 324.917480,292.949219 331.912415,292.948761 \n" +
    "\tC342.485992,292.948120 343.766083,292.013550 347.207062,281.855438 \n" +
    "\tC347.287964,281.616608 347.690033,281.486603 348.289429,281.057037 \n" +
    "\tC360.048798,291.536530 374.143127,294.976440 389.703247,294.108429 \n" +
    "\tC406.462921,293.173523 420.313721,286.316650 430.632904,273.362122 \n" +
    "\tC445.790558,254.333450 448.017609,232.551285 441.558899,209.754410 \n" +
    "\tC439.813202,203.592606 437.992188,196.819885 431.635345,192.511200 \n" +
    "\tC431.345398,192.105072 431.055450,191.698944 430.753448,190.613525 \n" +
    "\tC429.766602,189.789337 428.779785,188.965164 427.429047,187.592133 \n" +
    "\tC413.891968,174.070282 397.496368,170.287674 379.059998,173.319763 \n" +
    "\tC367.309906,175.252243 357.832031,181.343842 348.639221,189.330475 \n" +
    "\tC347.095245,191.222656 345.551270,193.114838 343.463623,195.320862 \n" +
    "\tC342.971008,196.207092 342.478363,197.093323 341.283813,197.876617 \n" +
    "\tC340.505981,196.690063 339.761566,195.479904 338.943665,194.321655 \n" +
    "\tC336.978210,191.538437 334.978333,188.779541 332.773468,185.575775 \n" +
    "\tC332.576050,185.304764 332.319855,185.111740 331.855804,184.620285 \n" +
    "M390.300873,301.063873 \n" +
    "\tC386.332733,302.860718 384.638458,306.068268 384.951019,310.265717 \n" +
    "\tC385.539368,318.167419 386.287476,326.057251 386.050262,334.110260 \n" +
    "\tC381.032196,334.075684 376.014130,334.041077 370.975983,333.140228 \n" +
    "\tC370.289062,325.396545 369.788269,317.630188 368.850250,309.917053 \n" +
    "\tC368.218506,304.722656 365.483215,300.981323 358.956268,299.943420 \n" +
    "\tC350.972839,299.964661 342.989410,299.985901 334.357819,299.978027 \n" +
    "\tC333.578064,300.329559 332.798279,300.681091 331.324341,301.064697 \n" +
    "\tC329.551819,303.079834 327.779297,305.094971 325.307434,307.039825 \n" +
    "\tC324.614838,306.948456 323.922272,306.857086 322.779114,306.220795 \n" +
    "\tC310.401184,299.531677 297.054077,297.639862 283.313812,299.153931 \n" +
    "\tC274.235809,300.154236 266.204956,303.996765 259.624451,311.362762 \n" +
    "\tC259.040283,312.190094 258.456116,313.017456 257.171814,313.948822 \n" +
    "\tC256.144867,315.189789 254.961655,316.333221 254.116333,317.687592 \n" +
    "\tC248.636337,326.467560 248.683960,336.186920 250.967041,345.600739 \n" +
    "\tC252.291962,351.063782 256.108032,355.922668 258.896393,361.852020 \n" +
    "\tC258.892670,363.188385 258.888977,364.524750 258.159088,365.932861 \n" +
    "\tC256.938263,364.583221 255.203659,363.437195 254.575714,361.852600 \n" +
    "\tC247.939377,345.106201 241.639160,328.224854 234.848618,311.542603 \n" +
    "\tC233.316605,307.778870 230.245865,304.641479 227.180817,301.073303 \n" +
    "\tC226.465561,301.034637 225.750305,300.995941 224.465378,300.318665 \n" +
    "\tC219.995544,300.172363 215.522110,299.842377 211.056854,299.929962 \n" +
    "\tC206.303421,300.023163 201.557739,300.511658 196.079285,300.843414 \n" +
    "\tC193.759201,302.952515 190.344772,304.624054 189.297958,307.242249 \n" +
    "\tC182.859116,323.346436 176.975922,339.672272 170.852890,355.903656 \n" +
    "\tC169.810440,358.667084 168.502808,361.330475 167.423264,363.798676 \n" +
    "\tC165.216095,361.593414 163.558853,359.937592 161.245224,358.286285 \n" +
    "\tC160.410172,358.811035 159.525467,359.271362 158.747757,359.870544 \n" +
    "\tC150.809311,365.987122 141.993576,370.154419 131.899490,370.552643 \n" +
    "\tC125.174660,370.817871 118.142151,370.916595 113.850914,363.326294 \n" +
    "\tC113.896179,360.664276 113.859932,357.997833 114.002975,355.341095 \n" +
    "\tC114.342873,349.028259 119.520004,342.865204 125.657738,342.133545 \n" +
    "\tC133.911621,341.149597 141.934814,342.101532 149.140533,346.764587 \n" +
    "\tC151.372711,348.209106 153.491638,349.839966 155.782318,351.180634 \n" +
    "\tC162.720901,355.241638 169.924026,351.220123 170.039047,343.132477 \n" +
    "\tC170.164490,334.312256 170.056686,325.488403 170.014908,316.666290 \n" +
    "\tC169.994949,312.452606 168.323013,309.293976 163.687363,307.415527 \n" +
    "\tC162.918869,307.162201 162.150375,306.908875 160.918365,306.124237 \n" +
    "\tC142.854675,295.782928 109.260895,293.851166 87.693954,314.354706 \n" +
    "\tC86.522606,315.961395 85.351257,317.568054 84.062416,319.764709 \n" +
    "\tC83.912025,320.136688 83.761635,320.508667 82.907616,320.841095 \n" +
    "\tC81.021461,323.020660 78.717789,324.962189 77.317490,327.418701 \n" +
    "\tC68.765007,342.421936 67.412865,358.336334 70.938782,375.122803 \n" +
    "\tC77.613754,406.901550 105.445709,424.443207 135.991913,420.421753 \n" +
    "\tC143.926575,419.377106 151.355927,415.988068 160.335968,418.530792 \n" +
    "\tC168.821655,420.933502 178.521835,419.092163 187.687210,419.029388 \n" +
    "\tC191.989899,418.999939 194.893524,416.893250 196.253769,412.733673 \n" +
    "\tC196.770004,411.155060 197.379532,409.604401 198.003082,408.063873 \n" +
    "\tC201.256760,400.025513 201.263611,399.972931 209.854767,400.051300 \n" +
    "\tC214.156219,400.090576 219.364761,398.957550 222.487564,400.963287 \n" +
    "\tC225.335022,402.792206 226.008560,408.109833 227.479843,411.951263 \n" +
    "\tC229.368301,416.881958 232.580719,419.406555 238.115311,419.118164 \n" +
    "\tC243.766098,418.823669 249.449875,419.211151 255.108688,419.007507 \n" +
    "\tC260.304138,418.820526 265.838043,420.446472 270.476288,416.440125 \n" +
    "\tC270.981201,416.004028 272.350494,416.432617 273.277252,416.643250 \n" +
    "\tC278.925995,417.926941 284.499420,419.808044 290.216217,420.471924 \n" +
    "\tC303.054108,421.962738 315.474304,420.495483 326.401917,412.749847 \n" +
    "\tC328.753723,411.082886 330.790924,408.972107 333.174866,406.889435 \n" +
    "\tC335.505005,416.318726 337.599701,418.917603 343.258423,419.014679 \n" +
    "\tC351.755157,419.160431 360.257721,419.149628 368.754883,419.018494 \n" +
    "\tC374.136230,418.935455 377.873047,415.236359 377.988739,409.888977 \n" +
    "\tC378.056580,406.753632 377.599457,403.605865 377.359070,400.465179 \n" +
    "\tC376.847656,393.782593 376.323517,387.100983 375.766815,379.932617 \n" +
    "\tC380.772614,379.932617 384.752197,380.028473 388.724609,379.901123 \n" +
    "\tC391.701294,379.805664 392.787140,381.069824 392.993347,384.012970 \n" +
    "\tC393.526306,391.620880 394.160370,399.232666 395.120514,406.796387 \n" +
    "\tC395.993561,413.673737 397.779510,419.972595 408.402344,419.154175 \n" +
    "\tC415.028992,418.643585 421.727173,419.074829 428.393188,419.057556 \n" +
    "\tC435.583099,419.038940 439.423615,415.557434 438.940796,408.389771 \n" +
    "\tC438.261810,398.309845 437.011963,388.262573 435.808655,378.225006 \n" +
    "\tC433.264984,357.006897 430.763702,335.780670 427.849487,314.612213 \n" +
    "\tC427.154449,309.563568 428.007935,303.131409 420.407959,300.735840 \n" +
    "\tC419.594360,300.505249 418.780762,300.274658 417.048248,299.864319 \n" +
    "\tC409.372772,299.918915 401.697296,299.973541 393.371185,299.998260 \n" +
    "\tC392.585327,300.339661 391.799438,300.681061 390.300873,301.063873 \n" +
    "M232.785736,291.461487 \n" +
    "\tC231.282928,292.373962 229.780121,293.286469 227.689819,294.555695 \n" +
    "\tC240.666367,299.083771 239.451614,312.396149 245.805344,321.610107 \n" +
    "\tC249.135208,311.530487 255.813171,304.865967 263.784210,298.860260 \n" +
    "\tC252.956268,299.633301 243.960297,297.801819 240.931366,285.731384 \n" +
    "\tC238.019348,287.824829 235.732086,289.469147 232.785736,291.461487 \n" +
    "M149.480911,358.846100 \n" +
    "\tC150.496399,358.119171 151.511887,357.392242 153.000702,356.326477 \n" +
    "\tC150.292343,354.581360 148.141495,353.046204 145.854874,351.750702 \n" +
    "\tC139.595490,348.204529 132.981522,346.211304 125.772873,348.202942 \n" +
    "\tC121.105278,349.492584 118.738991,354.335358 119.638695,360.431702 \n" +
    "\tC120.278946,364.770081 124.003250,364.455292 126.956276,364.760223 \n" +
    "\tC134.871353,365.577606 142.067535,363.181244 149.480911,358.846100 \n" +
    "M158.235306,298.113800 \n" +
    "\tC160.041489,298.935394 161.817383,299.833282 163.659424,300.564636 \n" +
    "\tC169.717392,302.969818 174.332138,306.782837 175.727280,313.441101 \n" +
    "\tC176.248962,315.930756 176.108002,318.559296 176.269180,321.124512 \n" +
    "\tC176.521439,321.190582 176.773697,321.256653 177.025940,321.322723 \n" +
    "\tC179.924774,314.074615 182.823593,306.826508 185.412643,300.352966 \n" +
    "\tC177.135086,296.290558 168.888214,292.243225 160.599258,288.175262 \n" +
    "\tC158.994278,290.500153 156.885223,293.555237 154.681061,296.748077 \n" +
    "\tC155.668259,297.140625 156.586349,297.505676 158.235306,298.113800 \n" +
    "M379.120636,328.729706 \n" +
    "\tC379.454620,327.335022 380.114716,325.929535 380.068817,324.547516 \n" +
    "\tC379.803162,316.544678 376.629608,308.445923 381.562714,300.486664 \n" +
    "\tC377.813721,299.965210 374.594788,299.517487 372.575226,299.236572 \n" +
    "\tC373.741760,307.740356 374.822449,316.201965 376.134674,324.627472 \n" +
    "\tC376.361633,326.084930 377.569580,327.389618 379.120636,328.729706 \n" +
    "z\"/>\n" +
    "<path fill=\"#FEF9FA\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M211.003784,120.035477 \n" +
    "\tC210.120163,129.908844 209.263977,139.321960 208.397751,148.845444 \n" +
    "\tC203.347015,148.845444 198.771378,148.845444 193.687180,148.845444 \n" +
    "\tC194.564743,139.483749 195.410919,130.456970 196.257095,121.430191 \n" +
    "\tC195.928940,121.240532 195.600784,121.050873 195.272614,120.861206 \n" +
    "\tC190.606110,126.907478 185.939590,132.953735 180.816269,139.591873 \n" +
    "\tC177.052551,132.868500 173.622375,126.740959 169.504791,119.385452 \n" +
    "\tC168.123734,130.035370 166.922424,139.299179 165.687515,148.822174 \n" +
    "\tC160.861877,148.822174 156.297668,148.822174 151.205704,148.822174 \n" +
    "\tC153.607086,131.832001 155.967285,115.133286 158.391586,97.981018 \n" +
    "\tC162.803009,97.981018 166.953140,97.720528 171.037521,98.124603 \n" +
    "\tC172.436356,98.262993 174.106613,99.808502 174.901947,101.154747 \n" +
    "\tC177.838272,106.125145 180.460419,111.281158 183.545593,117.007584 \n" +
    "\tC187.328751,111.957123 190.999084,107.652557 193.969803,102.909767 \n" +
    "\tC196.510513,98.853493 199.632507,97.320290 204.271484,97.921692 \n" +
    "\tC206.861282,98.257439 209.530319,97.982033 212.830399,97.982033 \n" +
    "\tC212.205688,105.479576 211.618454,112.527405 211.003784,120.035477 \n" +
    "z\"/>\n" +
    "<path fill=\"#FEFAFA\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M389.266968,146.179993 \n" +
    "\tC388.919373,147.451492 388.433624,149.107040 387.841156,149.146149 \n" +
    "\tC384.192017,149.387115 380.443420,149.690872 376.888428,149.063751 \n" +
    "\tC375.327209,148.788345 373.533051,146.590576 372.911407,144.878357 \n" +
    "\tC371.423584,140.780457 369.002686,139.106415 364.668457,139.766235 \n" +
    "\tC362.552124,140.088425 360.321014,140.024216 358.184296,139.773376 \n" +
    "\tC352.538208,139.110641 348.094940,139.965485 347.001312,146.742935 \n" +
    "\tC346.844604,147.713989 345.032440,149.043777 343.911255,149.121323 \n" +
    "\tC339.786957,149.406601 335.631012,149.234543 331.102356,149.234543 \n" +
    "\tC331.427002,147.867950 331.535126,146.919373 331.873566,146.061676 \n" +
    "\tC337.678375,131.349289 343.594849,116.680115 349.270905,101.918449 \n" +
    "\tC350.459930,98.826210 352.039490,97.971191 355.374207,97.588318 \n" +
    "\tC362.575439,96.761551 366.219666,99.411652 369.192627,106.061256 \n" +
    "\tC375.234772,119.575493 382.385590,132.594040 389.266968,146.179993 \n" +
    "M357.545380,117.199921 \n" +
    "\tC356.293732,120.827400 355.042084,124.454880 353.763000,128.161942 \n" +
    "\tC357.959656,128.161942 361.436829,128.161942 365.288666,128.161942 \n" +
    "\tC363.421783,124.031136 361.855927,120.409668 360.095215,116.885559 \n" +
    "\tC359.894196,116.483253 358.601807,116.626221 357.545380,117.199921 \n" +
    "z\"/>\n" +
    "<path fill=\"#FEF8F9\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M274.600098,111.144257 \n" +
    "\tC270.874573,110.200119 267.524750,108.995857 264.118591,108.807106 \n" +
    "\tC262.409515,108.712395 260.598022,110.466202 258.832825,111.384026 \n" +
    "\tC260.188934,112.780426 261.302216,114.781761 262.952881,115.444435 \n" +
    "\tC266.164642,116.733826 269.714325,117.149780 273.059113,118.145592 \n" +
    "\tC282.614044,120.990303 286.929565,125.760048 286.685150,132.995148 \n" +
    "\tC286.398468,141.482010 280.859558,147.835938 271.387665,149.462830 \n" +
    "\tC259.920502,151.432434 249.253845,149.358032 239.649963,141.329285 \n" +
    "\tC242.588791,138.080353 245.338455,135.040527 247.803864,132.314987 \n" +
    "\tC252.478149,134.134262 256.667908,136.107346 261.065063,137.341721 \n" +
    "\tC263.512543,138.028778 266.355530,137.785965 268.919769,137.358887 \n" +
    "\tC270.055206,137.169785 271.628326,135.556473 271.741730,134.459320 \n" +
    "\tC271.848297,133.428253 270.529297,131.755798 269.419647,131.200180 \n" +
    "\tC267.372284,130.175018 265.025787,129.737076 262.791504,129.097946 \n" +
    "\tC259.754364,128.229156 256.624054,127.617485 253.679718,126.508110 \n" +
    "\tC248.455139,124.539574 244.215149,121.529953 243.835114,115.232674 \n" +
    "\tC243.435043,108.603584 246.130890,103.582985 251.806625,100.244591 \n" +
    "\tC260.980377,94.848709 276.879120,96.535400 285.457214,103.992233 \n" +
    "\tC283.352509,106.874199 281.218964,109.795639 278.794342,113.115639 \n" +
    "\tC277.499359,112.504860 276.225922,111.904243 274.600098,111.144257 \n" +
    "z\"/>\n" +
    "<path fill=\"#FEF9FA\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M300.215057,149.109741 \n" +
    "\tC296.255127,146.803497 292.688446,144.605194 288.379303,141.949265 \n" +
    "\tC292.359436,138.215958 295.341705,135.418655 298.417603,132.533524 \n" +
    "\tC299.331543,133.269302 299.891937,134.039383 300.613556,134.245773 \n" +
    "\tC303.618683,135.105225 307.015808,136.927872 309.587341,136.148270 \n" +
    "\tC311.298828,135.629425 312.685822,131.338165 312.784302,128.682220 \n" +
    "\tC313.153168,118.736351 312.931915,108.768593 312.931915,98.404778 \n" +
    "\tC317.304016,98.404778 321.687317,98.404778 327.098450,98.404778 \n" +
    "\tC327.098450,104.072960 327.055511,109.825470 327.111267,115.577019 \n" +
    "\tC327.161194,120.728607 327.466309,125.880989 327.401184,131.029770 \n" +
    "\tC327.247375,143.188156 320.205933,150.093613 307.996277,150.075531 \n" +
    "\tC305.533203,150.071884 303.070923,149.517914 300.215057,149.109741 \n" +
    "z\"/>\n" +
    "<path fill=\"#FEF8F9\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M236.114059,105.015572 \n" +
    "\tC235.168304,119.916550 234.251923,134.327744 233.323120,148.934204 \n" +
    "\tC228.173050,148.934204 223.575928,148.934204 218.324341,148.934204 \n" +
    "\tC219.616852,132.039642 220.891525,115.378212 222.193390,98.361557 \n" +
    "\tC226.814926,98.361557 231.207977,98.361557 236.143433,98.361557 \n" +
    "\tC236.143433,100.385269 236.143433,102.455536 236.114059,105.015572 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M332.992310,186.010849 \n" +
    "\tC334.978333,188.779541 336.978210,191.538437 338.943665,194.321655 \n" +
    "\tC339.761566,195.479904 340.505981,196.690063 341.644928,198.315063 \n" +
    "\tC338.349365,208.748962 334.992493,218.704956 335.901398,229.711136 \n" +
    "\tC336.861816,241.341431 341.229034,251.394547 349.238586,259.529755 \n" +
    "\tC361.415070,271.897308 377.143616,274.962158 393.470551,272.719330 \n" +
    "\tC425.603607,268.305145 444.712494,240.058044 437.549530,207.594482 \n" +
    "\tC436.718353,203.827469 433.988525,200.479385 432.051819,196.627655 \n" +
    "\tC431.883667,195.217575 431.802612,194.116943 431.721558,193.016296 \n" +
    "\tC437.992188,196.819885 439.813202,203.592606 441.558899,209.754410 \n" +
    "\tC448.017609,232.551285 445.790558,254.333450 430.632904,273.362122 \n" +
    "\tC420.313721,286.316650 406.462921,293.173523 389.703247,294.108429 \n" +
    "\tC374.143127,294.976440 360.048798,291.536530 348.289429,281.057037 \n" +
    "\tC347.690033,281.486603 347.287964,281.616608 347.207062,281.855438 \n" +
    "\tC343.766083,292.013550 342.485992,292.948120 331.912415,292.948761 \n" +
    "\tC324.917480,292.949219 317.922089,292.995270 310.927704,292.933624 \n" +
    "\tC304.765076,292.879303 301.693481,290.311249 299.840240,284.286591 \n" +
    "\tC297.843567,277.795654 295.638885,271.368683 293.092224,263.592529 \n" +
    "\tC292.565552,265.846283 292.170654,266.928619 292.078217,268.036194 \n" +
    "\tC291.664429,272.993011 291.441223,277.967377 290.951599,282.915833 \n" +
    "\tC290.341858,289.078308 286.156982,292.882080 280.027435,292.933197 \n" +
    "\tC272.699921,292.994354 265.371063,292.996552 258.043579,292.934387 \n" +
    "\tC250.766983,292.872620 246.862122,289.031830 247.007736,281.880371 \n" +
    "\tC247.088226,277.927765 247.617645,273.984283 248.090698,268.274170 \n" +
    "\tC236.142593,283.936615 222.393112,293.545197 203.325775,294.173859 \n" +
    "\tC184.548721,294.792999 168.382660,290.541199 157.219070,272.212097 \n" +
    "\tC156.337646,277.017761 155.718033,280.480347 155.065186,283.936615 \n" +
    "\tC153.963654,289.768341 150.391647,292.901611 144.390991,292.920135 \n" +
    "\tC124.239098,292.982330 104.086784,292.981476 83.934860,292.924011 \n" +
    "\tC76.818619,292.903717 71.994621,287.345032 73.138100,280.452454 \n" +
    "\tC76.092163,262.646271 79.129860,244.853806 82.196648,227.066620 \n" +
    "\tC84.728828,212.380157 87.167297,197.672333 90.101265,183.065155 \n" +
    "\tC90.628632,180.439606 93.361961,178.257141 95.465439,176.251587 \n" +
    "\tC95.671638,177.269577 95.433220,177.895828 95.314804,178.544006 \n" +
    "\tC89.946152,207.931900 84.568153,237.318130 79.251549,266.715454 \n" +
    "\tC78.513023,270.799042 80.984535,271.987793 84.534142,271.985962 \n" +
    "\tC104.156807,271.975800 123.779572,271.949493 143.402023,272.014404 \n" +
    "\tC146.501907,272.024658 148.645447,271.011719 149.169342,267.823822 \n" +
    "\tC150.185104,261.642822 151.272598,255.459030 151.847153,249.231827 \n" +
    "\tC151.968842,247.912903 150.018707,246.402817 148.637970,244.818100 \n" +
    "\tC137.494171,244.471741 126.735573,244.288055 115.978500,243.660065 \n" +
    "\tC116.402420,239.974884 116.824806,236.733994 117.277939,233.257233 \n" +
    "\tC119.321640,233.161621 120.948257,233.024292 122.575241,233.019745 \n" +
    "\tC130.729507,232.996964 138.884995,232.929062 147.037704,233.043777 \n" +
    "\tC150.878769,233.097824 152.779968,231.766739 153.091644,227.637009 \n" +
    "\tC153.475769,222.547272 154.272873,217.443176 155.440811,212.474396 \n" +
    "\tC156.196045,209.261261 155.226608,208.099960 151.929550,207.599365 \n" +
    "\tC141.751450,207.168732 131.909134,207.015869 122.049973,206.464432 \n" +
    "\tC121.943939,201.566574 123.745506,199.635269 128.622147,199.870773 \n" +
    "\tC136.929092,200.271942 145.269699,199.947464 153.595779,199.994308 \n" +
    "\tC157.116211,200.014114 159.416748,198.405884 159.918259,194.970657 \n" +
    "\tC160.849594,188.591034 161.500916,182.170502 162.268814,175.766998 \n" +
    "\tC163.511246,175.790100 164.753693,175.813202 166.413055,176.222977 \n" +
    "\tC166.553146,178.070724 166.229507,179.524689 166.006683,180.993927 \n" +
    "\tC163.553726,197.168793 161.320435,213.380142 158.620712,229.513535 \n" +
    "\tC155.582047,247.672394 163.961334,266.809906 184.046112,271.330902 \n" +
    "\tC190.747803,272.839417 197.890625,273.143921 204.789017,272.880188 \n" +
    "\tC224.951492,272.109344 243.520752,258.871185 247.922562,238.577377 \n" +
    "\tC250.298126,227.625214 251.528793,216.407059 252.921616,205.264618 \n" +
    "\tC254.171707,195.264038 254.937012,185.202881 255.916168,175.168442 \n" +
    "\tC257.774170,175.238480 259.632141,175.308533 261.773376,175.755432 \n" +
    "\tC260.991638,188.333298 260.061005,200.547974 258.821198,212.731186 \n" +
    "\tC257.733856,223.416031 256.228973,234.057709 255.043152,244.733414 \n" +
    "\tC254.239685,251.966766 253.624664,259.222260 253.014267,266.475311 \n" +
    "\tC252.729843,269.854980 254.166229,271.959991 257.814087,271.969727 \n" +
    "\tC265.309753,271.989746 272.819183,272.213531 280.290466,271.785492 \n" +
    "\tC281.922974,271.691956 284.345215,269.464539 284.787903,267.792145 \n" +
    "\tC285.747772,264.165802 285.608368,260.257141 286.017548,256.474609 \n" +
    "\tC286.777100,249.453354 287.607574,242.439804 288.429169,235.241852 \n" +
    "\tC294.058380,235.345139 295.508972,239.405258 296.908020,243.270004 \n" +
    "\tC299.734100,251.076889 302.417938,258.936737 305.050232,266.811615 \n" +
    "\tC306.255981,270.418854 308.298462,272.202026 312.373688,272.057800 \n" +
    "\tC319.860046,271.792877 327.362793,272.021515 334.858185,271.969147 \n" +
    "\tC340.492218,271.929810 341.765625,270.114471 339.839813,264.935577 \n" +
    "\tC335.847565,254.199570 331.936646,243.430161 327.705841,232.788376 \n" +
    "\tC326.716339,230.299530 324.584351,228.264908 322.957733,225.767151 \n" +
    "\tC322.922821,225.343826 322.908234,225.174637 323.207306,224.877563 \n" +
    "\tC333.861328,216.793350 337.857178,206.496246 334.941803,193.676804 \n" +
    "\tC334.357300,191.106628 333.645447,188.565414 332.992310,186.010849 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M326.006775,307.110138 \n" +
    "\tC327.779297,305.094971 329.551819,303.079834 331.660583,301.509857 \n" +
    "\tC332.649872,310.369385 333.219086,318.791229 333.972351,327.196625 \n" +
    "\tC335.936188,349.111023 338.029480,371.013947 339.943451,392.932617 \n" +
    "\tC340.251282,396.457977 341.765137,398.041443 345.253754,398.019592 \n" +
    "\tC352.748962,397.972687 360.245758,397.930328 367.739746,398.036926 \n" +
    "\tC371.061066,398.084198 372.285461,396.513214 371.991394,393.435059 \n" +
    "\tC371.535339,388.661224 371.013489,383.893677 370.546906,379.120789 \n" +
    "\tC369.976501,373.285187 369.434357,367.446838 368.825989,361.041138 \n" +
    "\tC378.049957,361.041138 386.349579,360.865479 394.624573,361.237762 \n" +
    "\tC395.813324,361.291260 397.627014,363.867645 397.885590,365.479553 \n" +
    "\tC399.402832,374.937469 400.626221,384.445862 401.766266,393.959106 \n" +
    "\tC402.149536,397.157166 403.718689,398.110352 406.738159,398.049866 \n" +
    "\tC413.564667,397.913177 420.395935,398.028839 427.225067,398.002869 \n" +
    "\tC432.746307,397.981873 433.767853,396.589417 432.842529,390.966705 \n" +
    "\tC432.118683,386.568176 431.594849,382.136200 431.007751,377.715698 \n" +
    "\tC429.158264,363.789825 427.305023,349.864410 425.483643,335.934845 \n" +
    "\tC423.962341,324.300598 422.483917,312.660736 420.986481,301.023376 \n" +
    "\tC428.007935,303.131409 427.154449,309.563568 427.849487,314.612213 \n" +
    "\tC430.763702,335.780670 433.264984,357.006897 435.808655,378.225006 \n" +
    "\tC437.011963,388.262573 438.261810,398.309845 438.940796,408.389771 \n" +
    "\tC439.423615,415.557434 435.583099,419.038940 428.393188,419.057556 \n" +
    "\tC421.727173,419.074829 415.028992,418.643585 408.402344,419.154175 \n" +
    "\tC397.779510,419.972595 395.993561,413.673737 395.120514,406.796387 \n" +
    "\tC394.160370,399.232666 393.526306,391.620880 392.993347,384.012970 \n" +
    "\tC392.787140,381.069824 391.701294,379.805664 388.724609,379.901123 \n" +
    "\tC384.752197,380.028473 380.772614,379.932617 375.766815,379.932617 \n" +
    "\tC376.323517,387.100983 376.847656,393.782593 377.359070,400.465179 \n" +
    "\tC377.599457,403.605865 378.056580,406.753632 377.988739,409.888977 \n" +
    "\tC377.873047,415.236359 374.136230,418.935455 368.754883,419.018494 \n" +
    "\tC360.257721,419.149628 351.755157,419.160431 343.258423,419.014679 \n" +
    "\tC337.599701,418.917603 335.505005,416.318726 333.174866,406.889435 \n" +
    "\tC330.790924,408.972107 328.753723,411.082886 326.401917,412.749847 \n" +
    "\tC315.474304,420.495483 303.054108,421.962738 290.216217,420.471924 \n" +
    "\tC284.499420,419.808044 278.925995,417.926941 273.277252,416.643250 \n" +
    "\tC272.350494,416.432617 270.981201,416.004028 270.476288,416.440125 \n" +
    "\tC265.838043,420.446472 260.304138,418.820526 255.108688,419.007507 \n" +
    "\tC249.449875,419.211151 243.766098,418.823669 238.115311,419.118164 \n" +
    "\tC232.580719,419.406555 229.368301,416.881958 227.479843,411.951263 \n" +
    "\tC226.008560,408.109833 225.335022,402.792206 222.487564,400.963287 \n" +
    "\tC219.364761,398.957550 214.156219,400.090576 209.854767,400.051300 \n" +
    "\tC201.263611,399.972931 201.256760,400.025513 198.003082,408.063873 \n" +
    "\tC197.379532,409.604401 196.770004,411.155060 196.253769,412.733673 \n" +
    "\tC194.893524,416.893250 191.989899,418.999939 187.687210,419.029388 \n" +
    "\tC178.521835,419.092163 168.821655,420.933502 160.335968,418.530792 \n" +
    "\tC151.355927,415.988068 143.926575,419.377106 135.991913,420.421753 \n" +
    "\tC105.445709,424.443207 77.613754,406.901550 70.938782,375.122803 \n" +
    "\tC67.412865,358.336334 68.765007,342.421936 77.317490,327.418701 \n" +
    "\tC78.717789,324.962189 81.021461,323.020660 83.375504,321.166779 \n" +
    "\tC83.295265,322.377899 82.747124,323.263306 81.853790,324.244934 \n" +
    "\tC79.567642,330.060638 76.897896,335.646637 75.821259,341.524384 \n" +
    "\tC72.480888,359.760864 79.505478,379.265900 95.325180,390.046967 \n" +
    "\tC113.194725,402.225067 132.542343,402.337616 152.397598,395.326172 \n" +
    "\tC153.765930,394.842987 156.361938,394.449127 156.712799,395.028137 \n" +
    "\tC158.806778,398.483978 161.975296,398.023224 165.120178,398.015015 \n" +
    "\tC171.115463,397.999329 177.127502,397.734863 183.100998,398.096039 \n" +
    "\tC188.050842,398.395264 191.366119,397.530731 192.305298,391.835297 \n" +
    "\tC192.804779,388.806305 194.362396,385.839294 195.957611,383.149933 \n" +
    "\tC196.780441,381.762695 198.709579,380.214325 200.174454,380.178101 \n" +
    "\tC204.620895,380.068085 209.080673,380.747101 213.542892,380.953491 \n" +
    "\tC218.020248,381.160583 223.520584,379.621887 226.700378,381.705505 \n" +
    "\tC229.797226,383.734863 230.598801,389.351471 232.265854,393.450897 \n" +
    "\tC233.530609,396.561035 235.412384,398.201935 239.041138,398.075867 \n" +
    "\tC245.694580,397.844727 252.369965,397.811432 259.020355,398.083221 \n" +
    "\tC263.165161,398.252625 266.587402,397.765442 267.504486,394.058716 \n" +
    "\tC276.027100,396.061035 283.962006,398.982544 292.070953,399.593292 \n" +
    "\tC305.693085,400.619263 318.918030,399.034088 328.954468,387.673645 \n" +
    "\tC336.876282,378.706787 336.529541,359.504639 327.812622,350.737854 \n" +
    "\tC325.164062,347.921173 322.667419,345.399048 319.957733,342.623718 \n" +
    "\tC318.223938,341.535797 316.703186,340.701080 314.943298,339.610962 \n" +
    "\tC310.971924,337.675507 307.249603,335.972961 303.505127,334.320587 \n" +
    "\tC297.988617,331.886292 292.456390,329.487762 287.009094,326.691254 \n" +
    "\tC288.957916,325.543182 290.820282,324.142395 292.700378,324.118195 \n" +
    "\tC302.968994,323.985962 311.966339,328.460968 321.027313,332.546204 \n" +
    "\tC325.893860,334.740387 327.087433,334.193146 326.970825,328.942596 \n" +
    "\tC326.809143,321.661560 326.342712,314.387299 326.006775,307.110138 \n" +
    "z\"/>\n" +
    "<path fill=\"#007637\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M327.964539,351.032379 \n" +
    "\tC336.529541,359.504639 336.876282,378.706787 328.954468,387.673645 \n" +
    "\tC318.918030,399.034088 305.693085,400.619263 292.070953,399.593292 \n" +
    "\tC283.962006,398.982544 276.027100,396.061035 267.504486,394.058716 \n" +
    "\tC266.587402,397.765442 263.165161,398.252625 259.020355,398.083221 \n" +
    "\tC252.369965,397.811432 245.694580,397.844727 239.041138,398.075867 \n" +
    "\tC235.412384,398.201935 233.530609,396.561035 232.265854,393.450897 \n" +
    "\tC230.598801,389.351471 229.797226,383.734863 226.700378,381.705505 \n" +
    "\tC223.520584,379.621887 218.020248,381.160583 213.542892,380.953491 \n" +
    "\tC209.080673,380.747101 204.620895,380.068085 200.174454,380.178101 \n" +
    "\tC198.709579,380.214325 196.780441,381.762695 195.957611,383.149933 \n" +
    "\tC194.362396,385.839294 192.804779,388.806305 192.305298,391.835297 \n" +
    "\tC191.366119,397.530731 188.050842,398.395264 183.100998,398.096039 \n" +
    "\tC177.127502,397.734863 171.115463,397.999329 165.120178,398.015015 \n" +
    "\tC161.975296,398.023224 158.806778,398.483978 156.712799,395.028137 \n" +
    "\tC156.361938,394.449127 153.765930,394.842987 152.397598,395.326172 \n" +
    "\tC132.542343,402.337616 113.194725,402.225067 95.325180,390.046967 \n" +
    "\tC79.505478,379.265900 72.480888,359.760864 75.821259,341.524384 \n" +
    "\tC76.897896,335.646637 79.567642,330.060638 81.817535,324.635834 \n" +
    "\tC81.556198,327.007843 80.761475,329.053406 80.461639,331.169098 \n" +
    "\tC79.805077,335.801941 79.399490,340.470367 78.911148,345.575134 \n" +
    "\tC78.518929,361.517456 84.012558,374.478180 96.799568,383.346405 \n" +
    "\tC117.296761,397.561890 138.544235,395.332611 159.674744,384.527557 \n" +
    "\tC160.881729,383.910370 161.886108,381.697052 161.925095,380.199005 \n" +
    "\tC162.115112,372.898438 161.944565,365.588440 161.901611,358.281799 \n" +
    "\tC163.558853,359.937592 165.216095,361.593414 167.423264,363.798676 \n" +
    "\tC168.502808,361.330475 169.810440,358.667084 170.852890,355.903656 \n" +
    "\tC176.975922,339.672272 182.859116,323.346436 189.297958,307.242249 \n" +
    "\tC190.344772,304.624054 193.759201,302.952515 196.469666,301.218445 \n" +
    "\tC194.341415,307.843323 191.759857,314.068817 189.320343,320.349426 \n" +
    "\tC186.411118,327.839294 183.623611,335.376465 180.567505,343.095032 \n" +
    "\tC180.195938,343.863922 180.040237,344.430969 179.631302,345.235352 \n" +
    "\tC173.263290,360.424347 167.148544,375.376068 160.747375,391.028137 \n" +
    "\tC168.777985,391.028137 175.762100,390.823547 182.726059,391.109863 \n" +
    "\tC186.510864,391.265472 188.591003,390.171326 189.475082,386.334656 \n" +
    "\tC190.140289,383.447693 191.583191,380.747925 192.546616,377.919464 \n" +
    "\tC193.530518,375.030914 195.227158,373.806030 198.466644,373.905426 \n" +
    "\tC206.290863,374.145538 214.147217,374.323700 221.952423,373.869812 \n" +
    "\tC227.644485,373.538818 231.599670,374.404419 232.610168,380.947266 \n" +
    "\tC233.003174,383.491791 234.463974,385.920990 235.702377,388.263123 \n" +
    "\tC236.266144,389.329346 237.452438,390.797638 238.388153,390.821198 \n" +
    "\tC246.431442,391.023743 254.482010,390.937531 263.045502,390.937531 \n" +
    "\tC256.683624,376.222382 250.702652,362.375580 244.704361,348.536255 \n" +
    "\tC244.175293,347.315582 243.532974,346.143982 242.828720,344.658020 \n" +
    "\tC242.432083,343.584442 242.149994,342.802490 241.863007,341.658478 \n" +
    "\tC241.205688,339.775421 240.538727,338.260437 239.903152,336.732422 \n" +
    "\tC234.943726,324.808777 229.990311,312.882629 225.035049,300.957275 \n" +
    "\tC225.750305,300.995941 226.465561,301.034637 227.563202,301.519897 \n" +
    "\tC228.329849,303.388702 228.557373,304.876709 229.122360,306.223114 \n" +
    "\tC235.456619,321.317963 241.813812,336.403351 248.226624,351.464996 \n" +
    "\tC251.757187,359.757172 255.404785,367.999512 258.999603,376.264313 \n" +
    "\tC259.389099,376.147766 259.778595,376.031219 260.168121,375.914673 \n" +
    "\tC259.740509,372.563477 259.312866,369.212311 258.885254,365.861115 \n" +
    "\tC258.888977,364.524750 258.892670,363.188385 259.200073,361.322540 \n" +
    "\tC260.673767,360.532990 261.843781,360.272919 263.002441,360.478699 \n" +
    "\tC263.315613,366.902771 263.978302,372.868622 263.843719,378.816406 \n" +
    "\tC263.756500,382.671387 265.419128,384.295898 268.557190,385.917664 \n" +
    "\tC281.272308,392.488861 294.589203,394.496368 308.608765,391.891083 \n" +
    "\tC322.368225,389.334137 332.824310,380.317139 330.924866,364.080566 \n" +
    "\tC330.409607,359.675995 328.976410,355.378845 327.964539,351.032379 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M431.678467,192.763748 \n" +
    "\tC431.802612,194.116943 431.883667,195.217575 432.047791,197.012085 \n" +
    "\tC433.373566,204.771622 434.616211,211.837311 435.404755,218.936127 \n" +
    "\tC426.933929,219.014023 418.917236,219.058762 410.620972,218.802063 \n" +
    "\tC409.378845,213.702942 409.368744,208.437012 407.281769,204.192154 \n" +
    "\tC399.929321,189.237381 376.224060,190.684357 368.995911,205.160721 \n" +
    "\tC366.816925,209.524719 365.670990,214.404541 363.580139,219.034592 \n" +
    "\tC355.119019,218.981842 347.129639,218.942566 339.108765,218.474762 \n" +
    "\tC339.461823,215.294449 339.527985,212.449524 340.299805,209.811005 \n" +
    "\tC341.732117,204.914352 343.576447,200.138184 345.550873,195.161102 \n" +
    "\tC347.014465,193.106506 348.176392,191.202667 349.338318,189.298828 \n" +
    "\tC357.832031,181.343842 367.309906,175.252243 379.059998,173.319763 \n" +
    "\tC397.496368,170.287674 413.891968,174.070282 427.633240,188.221695 \n" +
    "\tC428.813416,189.665115 429.789429,190.478973 430.765503,191.292816 \n" +
    "\tC431.055450,191.698944 431.345398,192.105072 431.678467,192.763748 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M195.922058,243.074875 \n" +
    "\tC201.895035,244.847153 207.635422,244.962112 213.300140,240.354004 \n" +
    "\tC214.516266,239.534256 215.268784,239.150681 215.863083,238.597244 \n" +
    "\tC221.885788,232.988754 221.735306,225.214386 223.520599,217.971771 \n" +
    "\tC231.684464,218.034882 239.385254,218.083725 247.069946,218.571838 \n" +
    "\tC246.706955,222.299957 246.536346,225.618744 245.983505,228.872604 \n" +
    "\tC243.144531,245.581665 234.193741,257.396759 218.096771,263.473633 \n" +
    "\tC208.774307,266.993011 198.970642,266.914032 189.519638,265.683624 \n" +
    "\tC173.761154,263.631989 160.798431,252.367126 161.987900,233.685486 \n" +
    "\tC162.324661,228.396576 162.803787,223.116699 163.661270,217.880203 \n" +
    "\tC172.031448,217.917709 179.957977,217.907700 187.905975,218.325104 \n" +
    "\tC187.768173,219.552521 187.500519,220.347977 187.466705,221.153244 \n" +
    "\tC187.192001,227.695312 184.852341,234.643143 191.239380,240.215698 \n" +
    "\tC192.926208,241.344177 194.424133,242.209534 195.922058,243.074875 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65E\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M265.972565,173.964539 \n" +
    "\tC280.930023,174.206406 295.911652,174.020935 310.835144,174.864044 \n" +
    "\tC318.717529,175.309372 326.119751,178.215622 330.707031,186.486649 \n" +
    "\tC331.557220,189.720322 332.214874,192.256577 332.750244,194.818405 \n" +
    "\tC334.044617,201.012070 329.257446,215.050446 322.703217,217.034760 \n" +
    "\tC301.877899,216.685349 281.524902,216.355301 261.117554,215.589264 \n" +
    "\tC261.081696,213.829086 260.971924,212.488525 261.138611,211.183243 \n" +
    "\tC262.723145,198.773575 264.354584,186.369919 265.972565,173.964539 \n" +
    "M291.949127,208.896713 \n" +
    "\tC295.721924,208.011520 299.494690,207.126343 303.963989,206.040543 \n" +
    "\tC308.086121,201.341461 308.063141,195.134537 303.321259,193.543442 \n" +
    "\tC299.231293,192.171082 294.547028,192.393448 290.123810,192.377762 \n" +
    "\tC289.446228,192.375351 288.318237,194.842926 288.161652,196.252884 \n" +
    "\tC287.724915,200.186005 287.689026,204.163651 287.470795,208.614365 \n" +
    "\tC288.602661,208.671692 289.848145,208.734756 291.949127,208.896713 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A24D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M161.573425,358.284058 \n" +
    "\tC161.944565,365.588440 162.115112,372.898438 161.925095,380.199005 \n" +
    "\tC161.886108,381.697052 160.881729,383.910370 159.674744,384.527557 \n" +
    "\tC138.544235,395.332611 117.296761,397.561890 96.799568,383.346405 \n" +
    "\tC84.012558,374.478180 78.518929,361.517456 79.390106,345.543121 \n" +
    "\tC87.908737,345.048981 95.968147,345.037781 104.028526,345.446320 \n" +
    "\tC104.538887,351.790070 105.244743,357.640717 110.211533,362.225555 \n" +
    "\tC111.465576,363.081085 112.545860,363.677155 113.626144,364.273193 \n" +
    "\tC118.142151,370.916595 125.174660,370.817871 131.899490,370.552643 \n" +
    "\tC141.993576,370.154419 150.809311,365.987122 158.747757,359.870544 \n" +
    "\tC159.525467,359.271362 160.410172,358.811035 161.573425,358.284058 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M104.027557,345.026550 \n" +
    "\tC95.968147,345.037781 87.908737,345.048981 79.370369,345.092224 \n" +
    "\tC79.399490,340.470367 79.805077,335.801941 80.461639,331.169098 \n" +
    "\tC80.761475,329.053406 81.556198,327.007843 82.162735,324.539612 \n" +
    "\tC82.747124,323.263306 83.295265,322.377899 83.727318,321.186554 \n" +
    "\tC83.761635,320.508667 83.912025,320.136688 84.432236,319.347046 \n" +
    "\tC86.002151,317.406860 87.202248,315.884308 88.402344,314.361755 \n" +
    "\tC109.260895,293.851166 142.854675,295.782928 161.007416,306.874023 \n" +
    "\tC161.096451,314.665558 161.096451,321.707306 161.096451,328.632812 \n" +
    "\tC159.763306,328.761932 159.374557,328.924164 159.129105,328.800934 \n" +
    "\tC158.244019,328.356628 157.405685,327.818665 156.552383,327.311584 \n" +
    "\tC147.929810,322.187347 138.738159,319.577484 128.574997,319.892517 \n" +
    "\tC114.181511,320.338684 103.969688,330.537292 104.027557,345.026550 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M335.005981,300.007172 \n" +
    "\tC342.989410,299.985901 350.972839,299.964661 359.444946,300.486572 \n" +
    "\tC360.964325,311.219849 361.939667,321.416473 363.122375,331.588959 \n" +
    "\tC363.225983,332.479767 364.584839,333.704254 365.546204,333.910950 \n" +
    "\tC367.289062,334.285736 369.171753,334.010437 370.996063,334.006500 \n" +
    "\tC376.014130,334.041077 381.032196,334.075684 386.947327,334.084717 \n" +
    "\tC389.895844,334.038910 391.947296,334.018616 394.407410,334.021271 \n" +
    "\tC398.686584,334.232208 397.909027,331.723816 397.611969,329.361053 \n" +
    "\tC396.383270,319.587433 395.213348,309.806427 394.021820,300.028137 \n" +
    "\tC401.697296,299.973541 409.372772,299.918915 417.521454,300.372253 \n" +
    "\tC419.609863,312.932434 421.225067,324.984741 422.394775,337.024902 \n" +
    "\tC409.208771,337.672211 396.474091,338.496552 383.726227,338.948151 \n" +
    "\tC368.784485,339.477478 353.831299,339.682495 338.621582,339.717865 \n" +
    "\tC337.242096,326.274109 336.124023,313.140625 335.005981,300.007172 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M286.930847,327.073975 \n" +
    "\tC292.456390,329.487762 297.988617,331.886292 303.505127,334.320587 \n" +
    "\tC307.249603,335.972961 310.971924,337.675507 314.547546,339.799744 \n" +
    "\tC313.105988,340.494354 311.826202,340.931305 310.535339,340.966827 \n" +
    "\tC295.947754,341.368530 281.358459,341.708649 266.445679,341.989166 \n" +
    "\tC264.001984,337.936279 260.698639,334.143494 260.044861,329.938751 \n" +
    "\tC259.106323,323.902283 260.099030,317.565521 260.267303,311.356964 \n" +
    "\tC266.204956,303.996765 274.235809,300.154236 283.313812,299.153931 \n" +
    "\tC297.054077,297.639862 310.401184,299.531677 322.895630,306.969971 \n" +
    "\tC323.012146,313.889771 323.012146,320.060425 323.012146,326.348145 \n" +
    "\tC320.305817,325.204712 317.345123,323.727142 314.238281,322.685333 \n" +
    "\tC306.176453,319.981995 298.295074,315.822296 289.391876,318.119019 \n" +
    "\tC287.191315,318.686737 285.306091,320.476898 283.275879,321.705109 \n" +
    "\tC284.494202,323.494720 285.712524,325.284363 286.930847,327.073975 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65E\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M224.750214,300.637970 \n" +
    "\tC229.990311,312.882629 234.943726,324.808777 239.903152,336.732422 \n" +
    "\tC240.538727,338.260437 241.205688,339.775421 241.409149,341.651672 \n" +
    "\tC233.248154,342.346527 225.536102,342.686157 217.574478,342.797394 \n" +
    "\tC215.406204,338.010529 213.487503,333.452087 211.381638,328.448914 \n" +
    "\tC209.162109,333.694855 207.188126,338.360504 204.732147,343.017029 \n" +
    "\tC196.427902,342.969635 188.605652,342.931396 180.783386,342.893158 \n" +
    "\tC183.623611,335.376465 186.411118,327.839294 189.320343,320.349426 \n" +
    "\tC191.759857,314.068817 194.341415,307.843323 196.834366,301.210510 \n" +
    "\tC201.557739,300.511658 206.303421,300.023163 211.056854,299.929962 \n" +
    "\tC215.522110,299.842377 219.995544,300.172363 224.750214,300.637970 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65E\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M122.066811,206.863007 \n" +
    "\tC131.909134,207.015869 141.751450,207.168732 151.852325,207.978073 \n" +
    "\tC151.751968,210.085861 151.393036,211.537155 150.563995,212.991638 \n" +
    "\tC130.993820,212.294205 111.893768,211.593613 92.819107,210.453064 \n" +
    "\tC93.099083,207.560043 93.224785,205.085419 93.631081,202.657745 \n" +
    "\tC95.198402,193.292709 96.855362,183.942642 98.478912,174.587006 \n" +
    "\tC99.925705,174.351059 101.372032,173.913147 102.819359,173.909836 \n" +
    "\tC121.224632,173.867630 139.630051,173.887848 158.504242,174.390839 \n" +
    "\tC158.296051,179.786819 157.485245,184.670776 156.992828,189.586594 \n" +
    "\tC156.703049,192.479446 155.137146,193.074020 152.644913,193.047974 \n" +
    "\tC146.009872,192.978607 139.373627,193.026581 132.737854,193.026840 \n" +
    "\tC120.908913,193.027298 120.906151,193.026352 118.447807,204.228073 \n" +
    "\tC118.310394,204.854248 118.278763,205.503616 118.138069,206.614548 \n" +
    "\tC119.571297,206.705185 120.819054,206.784088 122.066811,206.863007 \n" +
    "z\"/>\n" +
    "<path fill=\"#007938\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M422.840302,337.037018 \n" +
    "\tC421.225067,324.984741 419.609863,312.932434 417.980896,300.462097 \n" +
    "\tC418.780762,300.274658 419.594360,300.505249 420.697205,300.879608 \n" +
    "\tC422.483917,312.660736 423.962341,324.300598 425.483643,335.934845 \n" +
    "\tC427.305023,349.864410 429.158264,363.789825 431.007751,377.715698 \n" +
    "\tC431.594849,382.136200 432.118683,386.568176 432.842529,390.966705 \n" +
    "\tC433.767853,396.589417 432.746307,397.981873 427.225067,398.002869 \n" +
    "\tC420.395935,398.028839 413.564667,397.913177 406.738159,398.049866 \n" +
    "\tC403.718689,398.110352 402.149536,397.157166 401.766266,393.959106 \n" +
    "\tC400.626221,384.445862 399.402832,374.937469 397.885590,365.479553 \n" +
    "\tC397.627014,363.867645 395.813324,361.291260 394.624573,361.237762 \n" +
    "\tC386.349579,360.865479 378.049957,361.041138 368.825989,361.041138 \n" +
    "\tC369.434357,367.446838 369.976501,373.285187 370.546906,379.120789 \n" +
    "\tC371.013489,383.893677 371.535339,388.661224 371.991394,393.435059 \n" +
    "\tC372.285461,396.513214 371.061066,398.084198 367.739746,398.036926 \n" +
    "\tC360.245758,397.930328 352.748962,397.972687 345.253754,398.019592 \n" +
    "\tC341.765137,398.041443 340.251282,396.457977 339.943451,392.932617 \n" +
    "\tC338.029480,371.013947 335.936188,349.111023 333.972351,327.196625 \n" +
    "\tC333.219086,318.791229 332.649872,310.369385 332.007660,301.493835 \n" +
    "\tC332.798279,300.681091 333.578064,300.329559 334.681885,299.992615 \n" +
    "\tC336.124023,313.140625 337.242096,326.274109 338.633118,340.111511 \n" +
    "\tC338.947266,341.866302 338.988464,342.917145 339.020660,344.404297 \n" +
    "\tC339.336456,347.632141 339.726196,350.417816 339.974731,353.216064 \n" +
    "\tC341.014404,364.922943 341.965363,376.638123 343.112152,388.334259 \n" +
    "\tC343.204620,389.277618 344.517731,390.843750 345.294403,390.862701 \n" +
    "\tC353.046356,391.052399 360.804779,390.977814 368.748169,390.977814 \n" +
    "\tC367.479340,378.648682 366.289703,367.089172 365.082397,355.358093 \n" +
    "\tC377.361603,355.358093 388.918793,355.358093 400.617432,355.358093 \n" +
    "\tC401.107758,359.298523 401.544678,362.896881 402.005280,366.492188 \n" +
    "\tC402.908386,373.541168 403.740997,380.601288 404.816223,387.624115 \n" +
    "\tC404.999939,388.824158 406.213196,390.802856 407.002533,390.828461 \n" +
    "\tC414.541779,391.073120 422.092133,390.974640 429.730652,390.974640 \n" +
    "\tC428.725525,382.663544 427.933716,375.281952 426.917145,367.931458 \n" +
    "\tC425.767792,359.620514 424.416595,351.337463 423.146759,342.612152 \n" +
    "\tC423.040344,340.467010 422.940338,338.752014 422.840302,337.037018 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M113.738525,363.799744 \n" +
    "\tC112.545860,363.677155 111.465576,363.081085 110.198540,361.827698 \n" +
    "\tC109.567429,359.577972 108.991768,358.009369 108.698715,356.389618 \n" +
    "\tC105.185219,336.970276 112.350746,325.353546 133.896332,326.197876 \n" +
    "\tC142.380096,326.530304 150.248505,329.993073 157.396957,334.821777 \n" +
    "\tC158.712769,335.710632 161.113403,336.580109 162.090454,335.973145 \n" +
    "\tC163.252090,335.251404 163.889069,332.938354 163.933746,331.292603 \n" +
    "\tC164.145645,323.488495 164.078156,315.676758 164.110168,307.867767 \n" +
    "\tC168.323013,309.293976 169.994949,312.452606 170.014908,316.666290 \n" +
    "\tC170.056686,325.488403 170.164490,334.312256 170.039047,343.132477 \n" +
    "\tC169.924026,351.220123 162.720901,355.241638 155.782318,351.180634 \n" +
    "\tC153.491638,349.839966 151.372711,348.209106 149.140533,346.764587 \n" +
    "\tC141.934814,342.101532 133.911621,341.149597 125.657738,342.133545 \n" +
    "\tC119.520004,342.865204 114.342873,349.028259 114.002975,355.341095 \n" +
    "\tC113.859932,357.997833 113.896179,360.664276 113.738525,363.799744 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M265.630798,173.979553 \n" +
    "\tC264.354584,186.369919 262.723145,198.773575 261.138611,211.183243 \n" +
    "\tC260.971924,212.488525 261.081696,213.829086 261.071045,215.944366 \n" +
    "\tC261.107666,217.447372 261.136444,218.159271 261.111511,219.308624 \n" +
    "\tC259.416840,234.615311 257.775879,249.484573 256.068420,264.956268 \n" +
    "\tC264.096832,264.956268 271.547180,265.081543 278.983154,264.811523 \n" +
    "\tC280.014099,264.774078 281.662415,262.887817 281.843689,261.680115 \n" +
    "\tC282.505005,257.274048 282.584320,252.784592 283.034149,248.341843 \n" +
    "\tC283.711517,241.651840 284.516113,234.974701 285.251740,228.424530 \n" +
    "\tC293.055298,227.653015 297.172455,230.455200 299.891174,238.097412 \n" +
    "\tC302.616119,245.757111 305.146576,253.487442 307.979675,261.105988 \n" +
    "\tC308.528778,262.582611 310.039764,264.750671 311.174438,264.792969 \n" +
    "\tC319.579651,265.106384 328.001892,264.962769 337.121124,264.962769 \n" +
    "\tC335.873627,261.394958 334.935974,258.606445 333.926117,255.844345 \n" +
    "\tC330.289917,245.898514 326.629364,235.961578 322.978027,226.021286 \n" +
    "\tC324.584351,228.264908 326.716339,230.299530 327.705841,232.788376 \n" +
    "\tC331.936646,243.430161 335.847565,254.199570 339.839813,264.935577 \n" +
    "\tC341.765625,270.114471 340.492218,271.929810 334.858185,271.969147 \n" +
    "\tC327.362793,272.021515 319.860046,271.792877 312.373688,272.057800 \n" +
    "\tC308.298462,272.202026 306.255981,270.418854 305.050232,266.811615 \n" +
    "\tC302.417938,258.936737 299.734100,251.076889 296.908020,243.270004 \n" +
    "\tC295.508972,239.405258 294.058380,235.345139 288.429169,235.241852 \n" +
    "\tC287.607574,242.439804 286.777100,249.453354 286.017548,256.474609 \n" +
    "\tC285.608368,260.257141 285.747772,264.165802 284.787903,267.792145 \n" +
    "\tC284.345215,269.464539 281.922974,271.691956 280.290466,271.785492 \n" +
    "\tC272.819183,272.213531 265.309753,271.989746 257.814087,271.969727 \n" +
    "\tC254.166229,271.959991 252.729843,269.854980 253.014267,266.475311 \n" +
    "\tC253.624664,259.222260 254.239685,251.966766 255.043152,244.733414 \n" +
    "\tC256.228973,234.057709 257.733856,223.416031 258.821198,212.731186 \n" +
    "\tC260.061005,200.547974 260.991638,188.333298 262.086670,175.699554 \n" +
    "\tC263.174164,174.842743 264.231628,174.418671 265.630798,173.979553 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M163.217606,217.832687 \n" +
    "\tC162.803787,223.116699 162.324661,228.396576 161.987900,233.685486 \n" +
    "\tC160.798431,252.367126 173.761154,263.631989 189.519638,265.683624 \n" +
    "\tC198.970642,266.914032 208.774307,266.993011 218.096771,263.473633 \n" +
    "\tC234.193741,257.396759 243.144531,245.581665 245.983505,228.872604 \n" +
    "\tC246.536346,225.618744 246.706955,222.299957 247.292511,218.322449 \n" +
    "\tC247.645767,216.766144 247.760376,215.898514 247.937775,214.595520 \n" +
    "\tC248.737640,207.434311 249.379547,200.696274 250.239304,193.986130 \n" +
    "\tC251.053757,187.629654 252.096634,181.302444 253.039078,174.962372 \n" +
    "\tC253.762665,174.979767 254.486267,174.997162 255.563019,175.091492 \n" +
    "\tC254.937012,185.202881 254.171707,195.264038 252.921616,205.264618 \n" +
    "\tC251.528793,216.407059 250.298126,227.625214 247.922562,238.577377 \n" +
    "\tC243.520752,258.871185 224.951492,272.109344 204.789017,272.880188 \n" +
    "\tC197.890625,273.143921 190.747803,272.839417 184.046112,271.330902 \n" +
    "\tC163.961334,266.809906 155.582047,247.672394 158.620712,229.513535 \n" +
    "\tC161.320435,213.380142 163.553726,197.168793 166.006683,180.993927 \n" +
    "\tC166.229507,179.524689 166.553146,178.070724 166.785629,176.189133 \n" +
    "\tC167.251434,175.467392 167.761581,175.166168 168.644104,175.325897 \n" +
    "\tC168.824051,178.254684 168.758942,180.740326 168.416885,183.187256 \n" +
    "\tC167.025696,193.138763 165.549606,203.078400 164.017883,213.369034 \n" +
    "\tC163.693161,215.087997 163.455383,216.460342 163.217606,217.832687 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M252.782349,174.612976 \n" +
    "\tC252.096634,181.302444 251.053757,187.629654 250.239304,193.986130 \n" +
    "\tC249.379547,200.696274 248.737640,207.434311 247.482376,214.586670 \n" +
    "\tC239.047333,215.020813 231.130493,215.028473 223.188019,214.631226 \n" +
    "\tC224.820282,200.862015 226.478180,187.497711 228.136093,174.133408 \n" +
    "\tC236.265930,174.176804 244.395782,174.220200 252.782349,174.612976 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M435.858856,218.902985 \n" +
    "\tC434.616211,211.837311 433.373566,204.771622 432.134949,197.321503 \n" +
    "\tC433.988525,200.479385 436.718353,203.827469 437.549530,207.594482 \n" +
    "\tC444.712494,240.058044 425.603607,268.305145 393.470551,272.719330 \n" +
    "\tC377.143616,274.962158 361.415070,271.897308 349.238586,259.529755 \n" +
    "\tC341.229034,251.394547 336.861816,241.341431 335.901398,229.711136 \n" +
    "\tC334.992493,218.704956 338.349365,208.748962 341.995880,198.366516 \n" +
    "\tC342.478363,197.093323 342.971008,196.207092 344.051575,195.217056 \n" +
    "\tC344.842773,195.179459 345.046021,195.245651 345.249237,195.311859 \n" +
    "\tC343.576447,200.138184 341.732117,204.914352 340.299805,209.811005 \n" +
    "\tC339.527985,212.449524 339.461823,215.294449 339.064331,218.936218 \n" +
    "\tC338.677917,230.261078 340.889526,240.225662 347.213898,248.476425 \n" +
    "\tC361.971832,267.729675 384.560394,270.236511 404.781555,262.846771 \n" +
    "\tC424.665161,255.580399 434.353271,239.819382 435.858856,218.902985 \n" +
    "z\"/>\n" +
    "<path fill=\"#02C65E\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M164.104813,213.022415 \n" +
    "\tC165.549606,203.078400 167.025696,193.138763 168.416885,183.187256 \n" +
    "\tC168.758942,180.740326 168.824051,178.254684 168.947937,175.331268 \n" +
    "\tC173.445160,174.525848 178.006104,174.069962 182.578308,173.860641 \n" +
    "\tC186.062225,173.701141 189.560486,173.854446 193.519867,174.377090 \n" +
    "\tC193.658829,177.510254 193.384613,180.143066 192.992157,182.758163 \n" +
    "\tC191.425629,193.196701 189.822311,203.629700 187.742493,214.041885 \n" +
    "\tC179.536514,213.686813 171.820663,213.354614 164.104813,213.022415 \n" +
    "z\"/>\n" +
    "<path fill=\"#007737\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M98.189255,174.650696 \n" +
    "\tC96.855362,183.942642 95.198402,193.292709 93.631081,202.657745 \n" +
    "\tC93.224785,205.085419 93.099083,207.560043 92.593796,210.718903 \n" +
    "\tC91.951889,213.236359 91.560684,215.048035 91.116043,217.282562 \n" +
    "\tC88.519554,231.530136 85.960655,245.351959 83.447166,259.182068 \n" +
    "\tC82.958588,261.870361 81.582062,265.026337 86.553635,265.010162 \n" +
    "\tC105.205757,264.949463 123.858955,265.051636 142.508881,264.840424 \n" +
    "\tC143.776199,264.826080 145.824448,262.914917 146.133591,261.569733 \n" +
    "\tC147.388901,256.107239 148.106964,250.521240 149.023148,244.980789 \n" +
    "\tC150.018707,246.402817 151.968842,247.912903 151.847153,249.231827 \n" +
    "\tC151.272598,255.459030 150.185104,261.642822 149.169342,267.823822 \n" +
    "\tC148.645447,271.011719 146.501907,272.024658 143.402023,272.014404 \n" +
    "\tC123.779572,271.949493 104.156807,271.975800 84.534142,271.985962 \n" +
    "\tC80.984535,271.987793 78.513023,270.799042 79.251549,266.715454 \n" +
    "\tC84.568153,237.318130 89.946152,207.931900 95.314804,178.544006 \n" +
    "\tC95.433220,177.895828 95.671638,177.269577 95.829468,176.213287 \n" +
    "\tC96.503105,175.433792 97.201347,175.074097 98.189255,174.650696 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M263.013824,360.012817 \n" +
    "\tC261.843781,360.272919 260.673767,360.532990 259.153412,360.923248 \n" +
    "\tC256.108032,355.922668 252.291962,351.063782 250.967041,345.600739 \n" +
    "\tC248.683960,336.186920 248.636337,326.467560 254.116333,317.687592 \n" +
    "\tC254.961655,316.333221 256.144867,315.189789 257.535706,314.303955 \n" +
    "\tC252.532455,332.164185 256.935974,345.448700 272.882507,354.369659 \n" +
    "\tC280.352814,358.548767 288.591522,361.334412 296.340729,365.046539 \n" +
    "\tC298.822540,366.235352 300.850861,368.370911 302.825500,370.361084 \n" +
    "\tC297.729523,370.932983 292.436371,372.613220 288.156281,371.207947 \n" +
    "\tC279.485016,368.360840 271.362549,363.842285 263.013824,360.012817 \n" +
    "z\"/>\n" +
    "<path fill=\"#007938\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M163.898773,307.641663 \n" +
    "\tC164.078156,315.676758 164.145645,323.488495 163.933746,331.292603 \n" +
    "\tC163.889069,332.938354 163.252090,335.251404 162.090454,335.973145 \n" +
    "\tC161.113403,336.580109 158.712769,335.710632 157.396957,334.821777 \n" +
    "\tC150.248505,329.993073 142.380096,326.530304 133.896332,326.197876 \n" +
    "\tC112.350746,325.353546 105.185219,336.970276 108.698715,356.389618 \n" +
    "\tC108.991768,358.009369 109.567429,359.577972 110.024780,361.568237 \n" +
    "\tC105.244743,357.640717 104.538887,351.790070 104.028526,345.446320 \n" +
    "\tC103.969688,330.537292 114.181511,320.338684 128.574997,319.892517 \n" +
    "\tC138.738159,319.577484 147.929810,322.187347 156.552383,327.311584 \n" +
    "\tC157.405685,327.818665 158.244019,328.356628 159.129105,328.800934 \n" +
    "\tC159.374557,328.924164 159.763306,328.761932 161.096451,328.632812 \n" +
    "\tC161.096451,321.707306 161.096451,314.665558 161.239166,307.139679 \n" +
    "\tC162.150375,306.908875 162.918869,307.162201 163.898773,307.641663 \n" +
    "z\"/>\n" +
    "<path fill=\"#005D2B\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M227.771271,174.116425 \n" +
    "\tC226.478180,187.497711 224.820282,200.862015 223.108185,214.990829 \n" +
    "\tC223.055161,216.489395 223.056335,217.223450 223.057510,217.957504 \n" +
    "\tC221.735306,225.214386 221.885788,232.988754 215.863083,238.597244 \n" +
    "\tC215.268784,239.150681 214.516266,239.534256 213.505920,240.060822 \n" +
    "\tC213.902664,232.254883 214.531799,224.374054 215.383240,216.517319 \n" +
    "\tC216.501144,206.201935 217.963669,195.921646 218.944992,185.594604 \n" +
    "\tC219.488205,179.878098 221.784958,175.872650 227.771271,174.116425 \n" +
    "z\"/>\n" +
    "<path fill=\"#005126\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M195.967651,242.641312 \n" +
    "\tC194.424133,242.209534 192.926208,241.344177 191.227020,239.794861 \n" +
    "\tC188.494125,230.310654 191.584442,221.804398 192.480118,213.203033 \n" +
    "\tC193.807846,200.452316 195.473419,187.736786 196.995178,175.006271 \n" +
    "\tC201.523575,176.366226 203.421051,179.721344 203.007141,184.096115 \n" +
    "\tC201.994781,194.795715 200.729904,205.473114 199.442322,216.144455 \n" +
    "\tC198.392715,224.843491 197.163071,233.520798 195.967651,242.641312 \n" +
    "z\"/>\n" +
    "<path fill=\"#F3F3F3\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M233.115265,291.287476 \n" +
    "\tC235.732086,289.469147 238.019348,287.824829 240.931366,285.731384 \n" +
    "\tC243.960297,297.801819 252.956268,299.633301 263.784210,298.860260 \n" +
    "\tC255.813171,304.865967 249.135208,311.530487 245.805344,321.610107 \n" +
    "\tC239.451614,312.396149 240.666367,299.083771 227.689819,294.555695 \n" +
    "\tC229.780121,293.286469 231.282928,292.373962 233.115265,291.287476 \n" +
    "z\"/>\n" +
    "<path fill=\"#F5F5F5\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M149.151154,359.032532 \n" +
    "\tC142.067535,363.181244 134.871353,365.577606 126.956276,364.760223 \n" +
    "\tC124.003250,364.455292 120.278946,364.770081 119.638695,360.431702 \n" +
    "\tC118.738991,354.335358 121.105278,349.492584 125.772873,348.202942 \n" +
    "\tC132.981522,346.211304 139.595490,348.204529 145.854874,351.750702 \n" +
    "\tC148.141495,353.046204 150.292343,354.581360 153.000702,356.326477 \n" +
    "\tC151.511887,357.392242 150.496399,358.119171 149.151154,359.032532 \n" +
    "z\"/>\n" +
    "<path fill=\"#007938\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M303.086670,370.073303 \n" +
    "\tC300.850861,368.370911 298.822540,366.235352 296.340729,365.046539 \n" +
    "\tC288.591522,361.334412 280.352814,358.548767 272.882507,354.369659 \n" +
    "\tC256.935974,345.448700 252.532455,332.164185 257.885773,314.251953 \n" +
    "\tC258.456116,313.017456 259.040283,312.190094 259.945862,311.359863 \n" +
    "\tC260.099030,317.565521 259.106323,323.902283 260.044861,329.938751 \n" +
    "\tC260.698639,334.143494 264.001984,337.936279 266.631378,342.303253 \n" +
    "\tC268.734528,343.501129 270.328033,344.308838 271.975281,345.461914 \n" +
    "\tC275.145233,347.861816 278.069946,350.328094 281.417542,351.885162 \n" +
    "\tC288.325745,355.098236 295.571320,357.588898 302.462463,360.834656 \n" +
    "\tC307.347168,363.135315 307.436066,367.392609 303.086670,370.073303 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M122.049973,206.464432 \n" +
    "\tC120.819054,206.784088 119.571297,206.705185 118.138069,206.614548 \n" +
    "\tC118.278763,205.503616 118.310394,204.854248 118.447807,204.228073 \n" +
    "\tC120.906151,193.026352 120.908913,193.027298 132.737854,193.026840 \n" +
    "\tC139.373627,193.026581 146.009872,192.978607 152.644913,193.047974 \n" +
    "\tC155.137146,193.074020 156.703049,192.479446 156.992828,189.586594 \n" +
    "\tC157.485245,184.670776 158.296051,179.786819 158.963806,174.474411 \n" +
    "\tC159.923737,174.458298 160.892944,174.857269 162.065491,175.511627 \n" +
    "\tC161.500916,182.170502 160.849594,188.591034 159.918259,194.970657 \n" +
    "\tC159.416748,198.405884 157.116211,200.014114 153.595779,199.994308 \n" +
    "\tC145.269699,199.947464 136.929092,200.271942 128.622147,199.870773 \n" +
    "\tC123.745506,199.635269 121.943939,201.566574 122.049973,206.464432 \n" +
    "z\"/>\n" +
    "<path fill=\"#F0F0F0\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M157.869873,297.992279 \n" +
    "\tC156.586349,297.505676 155.668259,297.140625 154.681061,296.748077 \n" +
    "\tC156.885223,293.555237 158.994278,290.500153 160.599258,288.175262 \n" +
    "\tC168.888214,292.243225 177.135086,296.290558 185.412643,300.352966 \n" +
    "\tC182.823593,306.826508 179.924774,314.074615 177.025940,321.322723 \n" +
    "\tC176.773697,321.256653 176.521439,321.190582 176.269180,321.124512 \n" +
    "\tC176.108002,318.559296 176.248962,315.930756 175.727280,313.441101 \n" +
    "\tC174.332138,306.782837 169.717392,302.969818 163.659424,300.564636 \n" +
    "\tC161.817383,299.833282 160.041489,298.935394 157.869873,297.992279 \n" +
    "z\"/>\n" +
    "<path fill=\"#007939\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M287.009094,326.691254 \n" +
    "\tC285.712524,325.284363 284.494202,323.494720 283.275879,321.705109 \n" +
    "\tC285.306091,320.476898 287.191315,318.686737 289.391876,318.119019 \n" +
    "\tC298.295074,315.822296 306.176453,319.981995 314.238281,322.685333 \n" +
    "\tC317.345123,323.727142 320.305817,325.204712 323.012146,326.348145 \n" +
    "\tC323.012146,320.060425 323.012146,313.889771 323.120911,307.242432 \n" +
    "\tC323.922272,306.857086 324.614838,306.948456 325.657104,307.074982 \n" +
    "\tC326.342712,314.387299 326.809143,321.661560 326.970825,328.942596 \n" +
    "\tC327.087433,334.193146 325.893860,334.740387 321.027313,332.546204 \n" +
    "\tC311.966339,328.460968 302.968994,323.985962 292.700378,324.118195 \n" +
    "\tC290.820282,324.142395 288.957916,325.543182 287.009094,326.691254 \n" +
    "z\"/>\n" +
    "<path fill=\"#005E2C\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M370.986023,333.573364 \n" +
    "\tC369.171753,334.010437 367.289062,334.285736 365.546204,333.910950 \n" +
    "\tC364.584839,333.704254 363.225983,332.479767 363.122375,331.588959 \n" +
    "\tC361.939667,321.416473 360.964325,311.219849 359.895844,300.586182 \n" +
    "\tC365.483215,300.981323 368.218506,304.722656 368.850250,309.917053 \n" +
    "\tC369.788269,317.630188 370.289062,325.396545 370.986023,333.573364 \n" +
    "z\"/>\n" +
    "<path fill=\"#007938\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M196.705872,174.863861 \n" +
    "\tC195.473419,187.736786 193.807846,200.452316 192.480118,213.203033 \n" +
    "\tC191.584442,221.804398 188.494125,230.310654 191.038116,239.531708 \n" +
    "\tC184.852341,234.643143 187.192001,227.695312 187.466705,221.153244 \n" +
    "\tC187.500519,220.347977 187.768173,219.552521 187.958008,217.944824 \n" +
    "\tC188.069901,216.112991 188.151260,215.088882 188.232620,214.064758 \n" +
    "\tC189.822311,203.629700 191.425629,193.196701 192.992157,182.758163 \n" +
    "\tC193.384613,180.143066 193.658829,177.510254 193.981110,174.458572 \n" +
    "\tC194.788742,174.261536 195.602661,174.491501 196.705872,174.863861 \n" +
    "z\"/>\n" +
    "<path fill=\"#015026\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M258.522156,365.896973 \n" +
    "\tC259.312866,369.212311 259.740509,372.563477 260.168121,375.914673 \n" +
    "\tC259.778595,376.031219 259.389099,376.147766 258.999603,376.264313 \n" +
    "\tC255.404785,367.999512 251.757187,359.757172 248.226624,351.464996 \n" +
    "\tC241.813812,336.403351 235.456619,321.317963 229.122360,306.223114 \n" +
    "\tC228.557373,304.876709 228.329849,303.388702 227.914520,301.591125 \n" +
    "\tC230.245865,304.641479 233.316605,307.778870 234.848618,311.542603 \n" +
    "\tC241.639160,328.224854 247.939377,345.106201 254.575714,361.852600 \n" +
    "\tC255.203659,363.437195 256.938263,364.583221 258.522156,365.896973 \n" +
    "z\"/>\n" +
    "<path fill=\"#005126\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M393.998779,333.998352 \n" +
    "\tC391.947296,334.018616 389.895844,334.038910 387.406769,334.005615 \n" +
    "\tC386.287476,326.057251 385.539368,318.167419 384.951019,310.265717 \n" +
    "\tC384.638458,306.068268 386.332733,302.860718 390.648499,301.498169 \n" +
    "\tC390.999084,303.762207 390.840790,305.609070 391.032227,307.418884 \n" +
    "\tC391.970001,316.284119 393.000824,325.139496 393.998779,333.998352 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M323.175537,217.054138 \n" +
    "\tC329.257446,215.050446 334.044617,201.012070 332.750244,194.818405 \n" +
    "\tC332.214874,192.256577 331.557220,189.720322 330.887634,186.691986 \n" +
    "\tC330.783264,185.888672 330.746399,185.565811 331.074158,185.095551 \n" +
    "\tC331.627441,184.964325 331.816162,184.980530 332.004883,184.996719 \n" +
    "\tC332.319855,185.111740 332.576050,185.304764 332.882874,185.793304 \n" +
    "\tC333.645447,188.565414 334.357300,191.106628 334.941803,193.676804 \n" +
    "\tC337.857178,206.496246 333.861328,216.793350 322.859253,224.844299 \n" +
    "\tC321.157928,222.995224 320.118286,221.051559 319.421814,219.076279 \n" +
    "\tC320.901825,218.381149 322.038696,217.717651 323.175537,217.054138 \n" +
    "z\"/>\n" +
    "<path fill=\"#E0E0E0\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M378.722534,328.747284 \n" +
    "\tC377.569580,327.389618 376.361633,326.084930 376.134674,324.627472 \n" +
    "\tC374.822449,316.201965 373.741760,307.740356 372.575226,299.236572 \n" +
    "\tC374.594788,299.517487 377.813721,299.965210 381.562714,300.486664 \n" +
    "\tC376.629608,308.445923 379.803162,316.544678 380.068817,324.547516 \n" +
    "\tC380.114716,325.929535 379.454620,327.335022 378.722534,328.747284 \n" +
    "z\"/>\n" +
    "<path fill=\"#007939\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M394.407410,334.021271 \n" +
    "\tC393.000824,325.139496 391.970001,316.284119 391.032227,307.418884 \n" +
    "\tC390.840790,305.609070 390.999084,303.762207 391.004883,301.477417 \n" +
    "\tC391.799438,300.681061 392.585327,300.339661 393.696503,300.013184 \n" +
    "\tC395.213348,309.806427 396.383270,319.587433 397.611969,329.361053 \n" +
    "\tC397.909027,331.723816 398.686584,334.232208 394.407410,334.021271 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M345.550873,195.161102 \n" +
    "\tC345.046021,195.245651 344.842773,195.179459 344.323425,195.060135 \n" +
    "\tC345.551270,193.114838 347.095245,191.222656 348.988770,189.314651 \n" +
    "\tC348.176392,191.202667 347.014465,193.106506 345.550873,195.161102 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M88.048157,314.358215 \n" +
    "\tC87.202248,315.884308 86.002151,317.406860 84.490982,319.052063 \n" +
    "\tC85.351257,317.568054 86.522606,315.961395 88.048157,314.358215 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M430.759460,190.953186 \n" +
    "\tC429.789429,190.478973 428.813416,189.665115 427.815186,188.496124 \n" +
    "\tC428.779785,188.965164 429.766602,189.789337 430.759460,190.953186 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M331.930359,184.808502 \n" +
    "\tC331.816162,184.980530 331.627441,184.964325 331.168884,184.856598 \n" +
    "\tC331.217926,184.716797 331.536865,184.668533 331.930359,184.808502 \n" +
    "z\"/>\n" +
    "<path fill=\"#F04455\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M357.680420,116.858139 \n" +
    "\tC358.601807,116.626221 359.894196,116.483253 360.095215,116.885559 \n" +
    "\tC361.855927,120.409668 363.421783,124.031136 365.288666,128.161942 \n" +
    "\tC361.436829,128.161942 357.959656,128.161942 353.763000,128.161942 \n" +
    "\tC355.042084,124.454880 356.293732,120.827400 357.680420,116.858139 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M319.078644,219.107895 \n" +
    "\tC320.118286,221.051559 321.157928,222.995224 322.545593,224.972168 \n" +
    "\tC322.908234,225.174637 322.922821,225.343826 322.957733,225.767151 \n" +
    "\tC326.629364,235.961578 330.289917,245.898514 333.926117,255.844345 \n" +
    "\tC334.935974,258.606445 335.873627,261.394958 337.121124,264.962769 \n" +
    "\tC328.001892,264.962769 319.579651,265.106384 311.174438,264.792969 \n" +
    "\tC310.039764,264.750671 308.528778,262.582611 307.979675,261.105988 \n" +
    "\tC305.146576,253.487442 302.616119,245.757111 299.891174,238.097412 \n" +
    "\tC297.172455,230.455200 293.055298,227.653015 285.251740,228.424530 \n" +
    "\tC284.516113,234.974701 283.711517,241.651840 283.034149,248.341843 \n" +
    "\tC282.584320,252.784592 282.505005,257.274048 281.843689,261.680115 \n" +
    "\tC281.662415,262.887817 280.014099,264.774078 278.983154,264.811523 \n" +
    "\tC271.547180,265.081543 264.096832,264.956268 256.068420,264.956268 \n" +
    "\tC257.775879,249.484573 259.416840,234.615311 261.561615,219.340378 \n" +
    "\tC281.069824,218.992432 300.074249,219.050156 319.078644,219.107895 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M148.637970,244.818100 \n" +
    "\tC148.106964,250.521240 147.388901,256.107239 146.133591,261.569733 \n" +
    "\tC145.824448,262.914917 143.776199,264.826080 142.508881,264.840424 \n" +
    "\tC123.858955,265.051636 105.205757,264.949463 86.553635,265.010162 \n" +
    "\tC81.582062,265.026337 82.958588,261.870361 83.447166,259.182068 \n" +
    "\tC85.960655,245.351959 88.519554,231.530136 91.565506,217.323547 \n" +
    "\tC111.407631,217.008377 130.746857,217.075104 150.070007,217.576721 \n" +
    "\tC149.921402,226.022186 149.921402,226.022217 141.693375,226.022629 \n" +
    "\tC134.031494,226.023010 126.366539,225.910568 118.710320,226.118927 \n" +
    "\tC117.182693,226.160492 114.627373,227.152634 114.362183,228.176743 \n" +
    "\tC113.206841,232.638596 112.549561,237.255508 112.054039,241.853012 \n" +
    "\tC111.721138,244.941803 113.257515,245.806961 115.976974,244.104385 \n" +
    "\tC126.735573,244.288055 137.494171,244.471741 148.637970,244.818100 \n" +
    "z\"/>\n" +
    "<path fill=\"#007B39\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M115.978500,243.660065 \n" +
    "\tC113.257515,245.806961 111.721138,244.941803 112.054039,241.853012 \n" +
    "\tC112.549561,237.255508 113.206841,232.638596 114.362183,228.176743 \n" +
    "\tC114.627373,227.152634 117.182693,226.160492 118.710320,226.118927 \n" +
    "\tC126.366539,225.910568 134.031494,226.023010 141.693375,226.022629 \n" +
    "\tC149.921402,226.022217 149.921402,226.022186 150.318680,217.305862 \n" +
    "\tC150.733673,215.396210 150.883896,214.192322 151.034119,212.988449 \n" +
    "\tC151.393036,211.537155 151.751968,210.085861 152.188110,208.255859 \n" +
    "\tC155.226608,208.099960 156.196045,209.261261 155.440811,212.474396 \n" +
    "\tC154.272873,217.443176 153.475769,222.547272 153.091644,227.637009 \n" +
    "\tC152.779968,231.766739 150.878769,233.097824 147.037704,233.043777 \n" +
    "\tC138.884995,232.929062 130.729507,232.996964 122.575241,233.019745 \n" +
    "\tC120.948257,233.024292 119.321640,233.161621 117.277939,233.257233 \n" +
    "\tC116.824806,236.733994 116.402420,239.974884 115.978500,243.660065 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M302.825500,370.361084 \n" +
    "\tC307.436066,367.392609 307.347168,363.135315 302.462463,360.834656 \n" +
    "\tC295.571320,357.588898 288.325745,355.098236 281.417542,351.885162 \n" +
    "\tC278.069946,350.328094 275.145233,347.861816 272.450043,345.408813 \n" +
    "\tC287.271179,344.684845 301.672089,344.388092 316.070160,343.987396 \n" +
    "\tC317.446259,343.949066 318.804352,343.262482 320.170807,342.876892 \n" +
    "\tC322.667419,345.399048 325.164062,347.921173 327.812622,350.737854 \n" +
    "\tC328.976410,355.378845 330.409607,359.675995 330.924866,364.080566 \n" +
    "\tC332.824310,380.317139 322.368225,389.334137 308.608765,391.891083 \n" +
    "\tC294.589203,394.496368 281.272308,392.488861 268.557190,385.917664 \n" +
    "\tC265.419128,384.295898 263.756500,382.671387 263.843719,378.816406 \n" +
    "\tC263.978302,372.868622 263.315613,366.902771 263.002441,360.478699 \n" +
    "\tC271.362549,363.842285 279.485016,368.360840 288.156281,371.207947 \n" +
    "\tC292.436371,372.613220 297.729523,370.932983 302.825500,370.361084 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D565\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M319.957733,342.623718 \n" +
    "\tC318.804352,343.262482 317.446259,343.949066 316.070160,343.987396 \n" +
    "\tC301.672089,344.388092 287.271179,344.684845 272.396301,345.063477 \n" +
    "\tC270.328033,344.308838 268.734528,343.501129 266.955322,342.379333 \n" +
    "\tC281.358459,341.708649 295.947754,341.368530 310.535339,340.966827 \n" +
    "\tC311.826202,340.931305 313.105988,340.494354 314.786682,340.055084 \n" +
    "\tC316.703186,340.701080 318.223938,341.535797 319.957733,342.623718 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M242.943253,344.949585 \n" +
    "\tC243.532974,346.143982 244.175293,347.315582 244.704361,348.536255 \n" +
    "\tC250.702652,362.375580 256.683624,376.222382 263.045502,390.937531 \n" +
    "\tC254.482010,390.937531 246.431442,391.023743 238.388153,390.821198 \n" +
    "\tC237.452438,390.797638 236.266144,389.329346 235.702377,388.263123 \n" +
    "\tC234.463974,385.920990 233.003174,383.491791 232.610168,380.947266 \n" +
    "\tC231.599670,374.404419 227.644485,373.538818 221.952423,373.869812 \n" +
    "\tC214.147217,374.323700 206.290863,374.145538 198.466644,373.905426 \n" +
    "\tC195.227158,373.806030 193.530518,375.030914 192.546616,377.919464 \n" +
    "\tC191.583191,380.747925 190.140289,383.447693 189.475082,386.334656 \n" +
    "\tC188.591003,390.171326 186.510864,391.265472 182.726059,391.109863 \n" +
    "\tC175.762100,390.823547 168.777985,391.028137 160.747375,391.028137 \n" +
    "\tC167.148544,375.376068 173.263290,360.424347 180.111969,345.240479 \n" +
    "\tC188.597260,345.055603 196.348602,345.102905 204.065063,345.529419 \n" +
    "\tC203.588852,347.453491 203.292969,349.059174 202.677231,350.531036 \n" +
    "\tC201.190674,354.084686 202.430634,355.382874 206.506378,354.960144 \n" +
    "\tC210.294357,354.977966 213.630478,354.970032 217.366455,354.978180 \n" +
    "\tC219.015854,354.888855 220.265411,354.783447 221.815826,354.652710 \n" +
    "\tC220.781082,351.219635 219.856995,348.153656 219.404907,345.058563 \n" +
    "\tC227.565704,345.002838 235.254471,344.976227 242.943253,344.949585 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D163\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M242.828720,344.658020 \n" +
    "\tC235.254471,344.976227 227.565704,345.002838 219.165527,344.871277 \n" +
    "\tC218.244110,344.150665 218.034088,343.588226 217.824066,343.025757 \n" +
    "\tC225.536102,342.686157 233.248154,342.346527 241.414032,342.013702 \n" +
    "\tC242.149994,342.802490 242.432083,343.584442 242.828720,344.658020 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D565\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M204.099976,345.150177 \n" +
    "\tC196.348602,345.102905 188.597260,345.055603 180.365234,345.003174 \n" +
    "\tC180.040237,344.430969 180.195938,343.863922 180.567505,343.095032 \n" +
    "\tC188.605652,342.931396 196.427902,342.969635 204.670227,343.321991 \n" +
    "\tC204.760162,344.140808 204.430069,344.645508 204.099976,345.150177 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A24D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M435.404755,218.936127 \n" +
    "\tC434.353271,239.819382 424.665161,255.580399 404.781555,262.846771 \n" +
    "\tC384.560394,270.236511 361.971832,267.729675 347.213898,248.476425 \n" +
    "\tC340.889526,240.225662 338.677917,230.261078 339.095825,219.364746 \n" +
    "\tC347.129639,218.942566 355.119019,218.981842 363.621155,219.429901 \n" +
    "\tC364.420471,221.608932 364.402252,223.501877 365.055389,225.124588 \n" +
    "\tC366.538239,228.808563 368.351318,232.359573 370.171936,236.255585 \n" +
    "\tC371.502533,237.400146 372.691315,238.253769 374.066467,239.400330 \n" +
    "\tC382.368073,246.571808 395.946930,245.300735 401.548553,237.137924 \n" +
    "\tC402.553833,236.710083 403.244232,236.312012 404.253296,235.787018 \n" +
    "\tC408.944244,231.006104 410.055725,225.105774 410.900513,219.103516 \n" +
    "\tC418.917236,219.058762 426.933929,219.014023 435.404755,218.936127 \n" +
    "z\"/>\n" +
    "<path fill=\"#007838\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M410.620972,218.802048 \n" +
    "\tC410.055725,225.105774 408.944244,231.006104 404.275726,235.408615 \n" +
    "\tC404.990295,229.188873 406.602966,223.245377 406.850159,217.245636 \n" +
    "\tC407.121216,210.666611 403.674744,205.480270 397.745453,202.381500 \n" +
    "\tC386.132721,196.312378 372.751923,202.565384 369.014221,215.424713 \n" +
    "\tC366.963379,222.480392 368.514679,229.179276 370.030151,235.964645 \n" +
    "\tC368.351318,232.359573 366.538239,228.808563 365.055389,225.124588 \n" +
    "\tC364.402252,223.501877 364.420471,221.608932 364.092865,219.443390 \n" +
    "\tC365.670990,214.404541 366.816925,209.524719 368.995911,205.160721 \n" +
    "\tC376.224060,190.684357 399.929321,189.237381 407.281769,204.192154 \n" +
    "\tC409.368744,208.437012 409.378845,213.702942 410.620972,218.802048 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D565\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M187.742493,214.041885 \n" +
    "\tC188.151260,215.088882 188.069901,216.112991 187.936523,217.517395 \n" +
    "\tC179.957977,217.907700 172.031448,217.917709 163.661270,217.880203 \n" +
    "\tC163.455383,216.460342 163.693161,215.087997 164.017883,213.369034 \n" +
    "\tC171.820663,213.354614 179.536514,213.686813 187.742493,214.041885 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D766\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M223.520599,217.971771 \n" +
    "\tC223.056335,217.223450 223.055161,216.489395 223.133820,215.395721 \n" +
    "\tC231.130493,215.028473 239.047333,215.020813 247.419586,215.022003 \n" +
    "\tC247.760376,215.898514 247.645767,216.766144 247.308594,217.883179 \n" +
    "\tC239.385254,218.083725 231.684464,218.034882 223.520599,217.971771 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D163\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M319.421814,219.076279 \n" +
    "\tC300.074249,219.050156 281.069824,218.992432 261.615326,218.902954 \n" +
    "\tC261.136444,218.159271 261.107666,217.447372 261.125397,216.380356 \n" +
    "\tC281.524902,216.355301 301.877899,216.685349 322.703217,217.034760 \n" +
    "\tC322.038696,217.717651 320.901825,218.381149 319.421814,219.076279 \n" +
    "z\"/>\n" +
    "<path fill=\"#007B39\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M291.093628,208.797836 \n" +
    "\tC289.848145,208.734756 288.602661,208.671692 287.470795,208.614365 \n" +
    "\tC287.689026,204.163651 287.724915,200.186005 288.161652,196.252884 \n" +
    "\tC288.318237,194.842926 289.446228,192.375351 290.123810,192.377762 \n" +
    "\tC294.547028,192.393448 299.231293,192.171082 303.321259,193.543442 \n" +
    "\tC308.063141,195.134537 308.086121,201.341461 303.564514,205.752548 \n" +
    "\tC304.173889,202.698303 304.214600,200.231323 300.835968,199.142960 \n" +
    "\tC293.998413,196.940292 290.936981,199.204498 290.997223,206.340988 \n" +
    "\tC291.004150,207.160156 291.060303,207.978897 291.093628,208.797836 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M291.521362,208.847275 \n" +
    "\tC291.060303,207.978897 291.004150,207.160156 290.997223,206.340988 \n" +
    "\tC290.936981,199.204498 293.998413,196.940292 300.835968,199.142960 \n" +
    "\tC304.214600,200.231323 304.173889,202.698303 303.216278,205.852859 \n" +
    "\tC299.494690,207.126343 295.721924,208.011520 291.521362,208.847275 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D766\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M422.394806,337.024902 \n" +
    "\tC422.940338,338.752014 423.040344,340.467010 422.662720,342.604797 \n" +
    "\tC394.466583,343.341064 366.748138,343.654510 339.029663,343.967957 \n" +
    "\tC338.988464,342.917145 338.947266,341.866302 338.894562,340.421814 \n" +
    "\tC353.831299,339.682495 368.784485,339.477478 383.726227,338.948151 \n" +
    "\tC396.474091,338.496552 409.208771,337.672211 422.394806,337.024902 \n" +
    "z\"/>\n" +
    "<path fill=\"#007939\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M204.065079,345.529419 \n" +
    "\tC204.430069,344.645508 204.760162,344.140808 205.152191,343.331116 \n" +
    "\tC207.188126,338.360504 209.162109,333.694855 211.381638,328.448914 \n" +
    "\tC213.487503,333.452087 215.406204,338.010529 217.574478,342.797363 \n" +
    "\tC218.034088,343.588226 218.244110,344.150665 218.693512,344.900391 \n" +
    "\tC219.856995,348.153656 220.781082,351.219635 221.815826,354.652710 \n" +
    "\tC220.265411,354.783447 219.015854,354.888855 217.336884,354.610291 \n" +
    "\tC215.226929,349.970306 213.546387,345.714264 211.865845,341.458221 \n" +
    "\tC211.480469,341.531494 211.095078,341.604797 210.709702,341.678101 \n" +
    "\tC209.157990,346.096863 207.606262,350.515656 206.054550,354.934418 \n" +
    "\tC202.430634,355.382874 201.190674,354.084686 202.677231,350.531036 \n" +
    "\tC203.292969,349.059174 203.588852,347.453491 204.065079,345.529419 \n" +
    "z\"/>\n" +
    "<path fill=\"#03D867\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M150.563995,212.991638 \n" +
    "\tC150.883896,214.192322 150.733673,215.396210 150.334747,216.870941 \n" +
    "\tC130.746857,217.075104 111.407631,217.008377 91.618942,216.900696 \n" +
    "\tC91.560684,215.048035 91.951889,213.236359 92.568405,211.158844 \n" +
    "\tC111.893768,211.593613 130.993820,212.294205 150.563995,212.991638 \n" +
    "z\"/>\n" +
    "<path fill=\"#02A34D\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M339.020660,344.404297 \n" +
    "\tC366.748138,343.654510 394.466583,343.341064 422.669128,343.034973 \n" +
    "\tC424.416595,351.337463 425.767792,359.620514 426.917145,367.931458 \n" +
    "\tC427.933716,375.281952 428.725525,382.663544 429.730652,390.974640 \n" +
    "\tC422.092133,390.974640 414.541779,391.073120 407.002533,390.828461 \n" +
    "\tC406.213196,390.802856 404.999939,388.824158 404.816223,387.624115 \n" +
    "\tC403.740997,380.601288 402.908386,373.541168 402.005280,366.492188 \n" +
    "\tC401.544678,362.896881 401.107758,359.298523 400.617432,355.358093 \n" +
    "\tC388.918793,355.358093 377.361603,355.358093 365.082397,355.358093 \n" +
    "\tC366.289703,367.089172 367.479340,378.648682 368.748169,390.977814 \n" +
    "\tC360.804779,390.977814 353.046356,391.052399 345.294403,390.862701 \n" +
    "\tC344.517731,390.843750 343.204620,389.277618 343.112152,388.334259 \n" +
    "\tC341.965363,376.638123 341.014404,364.922943 339.974731,353.216064 \n" +
    "\tC339.726196,350.417816 339.336456,347.632141 339.020660,344.404297 \n" +
    "z\"/>\n" +
    "<path fill=\"#005427\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M206.506378,354.960144 \n" +
    "\tC207.606262,350.515656 209.157990,346.096863 210.709702,341.678101 \n" +
    "\tC211.095078,341.604797 211.480469,341.531494 211.865845,341.458221 \n" +
    "\tC213.546387,345.714264 215.226929,349.970306 216.937042,354.594238 \n" +
    "\tC213.630478,354.970032 210.294357,354.977966 206.506378,354.960144 \n" +
    "z\"/>\n" +
    "<path fill=\"#005226\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M370.171936,236.255585 \n" +
    "\tC368.514679,229.179276 366.963379,222.480392 369.014221,215.424713 \n" +
    "\tC372.751923,202.565384 386.132721,196.312378 397.745453,202.381500 \n" +
    "\tC403.674744,205.480270 407.121216,210.666611 406.850159,217.245636 \n" +
    "\tC406.602966,223.245377 404.990295,229.188873 403.957031,235.535553 \n" +
    "\tC403.244232,236.312012 402.553833,236.710083 401.467834,236.665375 \n" +
    "\tC401.067078,233.735489 401.094788,231.247772 401.050507,228.761337 \n" +
    "\tC400.928101,221.890137 397.823547,217.586472 391.717712,215.764633 \n" +
    "\tC386.387665,214.174286 381.222961,216.570389 377.378815,221.922562 \n" +
    "\tC373.590790,227.196625 374.130768,233.197067 373.880096,239.107391 \n" +
    "\tC372.691315,238.253769 371.502533,237.400146 370.171936,236.255585 \n" +
    "z\"/>\n" +
    "<path fill=\"#060A07\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M374.066467,239.400330 \n" +
    "\tC374.130768,233.197067 373.590790,227.196625 377.378815,221.922562 \n" +
    "\tC381.222961,216.570389 386.387665,214.174286 391.717712,215.764633 \n" +
    "\tC397.823547,217.586472 400.928101,221.890137 401.050507,228.761337 \n" +
    "\tC401.094788,231.247772 401.067078,233.735489 401.152954,236.695160 \n" +
    "\tC395.946930,245.300735 382.368073,246.571808 374.066467,239.400330 \n" +
    "M395.071625,227.481720 \n" +
    "\tC394.364899,223.908188 392.722870,221.186417 388.617188,221.283478 \n" +
    "\tC383.114807,221.413574 377.430054,231.561584 380.181244,236.283661 \n" +
    "\tC380.550751,236.917908 381.379272,237.392914 382.098511,237.698639 \n" +
    "\tC389.841248,240.989944 395.941620,236.569946 395.071625,227.481720 \n" +
    "z\"/>\n" +
    "<path fill=\"#F3F3F3\" opacity=\"1.000000\" stroke=\"none\" \n" +
    "\td=\"\n" +
    "M395.132965,227.863495 \n" +
    "\tC395.941620,236.569946 389.841248,240.989944 382.098511,237.698639 \n" +
    "\tC381.379272,237.392914 380.550751,236.917908 380.181244,236.283661 \n" +
    "\tC377.430054,231.561584 383.114807,221.413574 388.617188,221.283478 \n" +
    "\tC392.722870,221.186417 394.364899,223.908188 395.132965,227.863495 \n" +
    "z\"/>\n" +
    "</svg>";
