import { EventDialog } from "../../../../../../core/interfaces/event-dialog.interface";
import { PrepaidCardDashboardComponent } from "../../base/prepaid-card-dashboard/prepaid-card-dashboard.component";
import { PrepaidCardTransactionsListComponent } from "../../base/prepaid-card-transactions-list/prepaid-card-transactions-list.component";
import { PrepaidCardsListComponent } from "../../base/prepaid-cards-list/prepaid-cards-list.component";
import { EVENT_DIALOGS_NAMES_EDENRED } from "./event-names.const";

export const EVENT_DIALOGS_EDENRED: EventDialog = {
  [EVENT_DIALOGS_NAMES_EDENRED.PREPAID_DASHBOARD]: PrepaidCardDashboardComponent,
  [EVENT_DIALOGS_NAMES_EDENRED.PREPAID_TRANSACTIONS]: PrepaidCardTransactionsListComponent,
  [EVENT_DIALOGS_NAMES_EDENRED.PREPAID_CARDS_LIST]: PrepaidCardsListComponent,
};
