import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AbstractQaFinishComponent } from "../../../../abstract/core/abstract-qa-finish.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";
import { Qa } from "../../../../interfaces/core/qa.interface";

@Component({
  selector: "app-custom-m-qa-finish",
  templateUrl: "./qa-finish.component.html",
})
export class MQaFinishComponent extends AbstractQaFinishComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: {
    qa: Qa;
    prevState: {
      location;
      type;
      eachQaId;
    };
    backToList: boolean;
    isOpenFromMockUpTask?: boolean;
  };

  ngOnInit() {
    this.setQa();
  }

  openQaList() {
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
        config: {
          data: {
            qa: this.qa,
            prevState: this.data.prevState,
            eachQaId: this.data.prevState.eachQaId,
          },
        },
      });
    });
  }
}
