import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { MessageState } from "../../../../../../../store/chat/reducer";
import { AppState } from "../../../../../../../store/state";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ActionListComponent } from "../../dialogs/action-list/action-list.component";
import { NewBusinessContainerComponent } from "../../dialogs/new-business-container/new-business-container.component";
import { PlanListComponent } from "../../dialogs/plan-list/plan-list.component";
import { PlayerService } from "../../../../../../player/providers/player.service";

export type BusinessPanelSections = "PLANS" | "CHALLENGES" | "TASKS";
type GameSections = "ACTIONS" | "GAME" | "CHAT";
type BusinessMenu = BusinessPanelSections | GameSections;

type State = AppState & {
  message: MessageState;
};

@Component({
  selector: "app-business-navigation",
  templateUrl: "./business-navigation.component.html",
})
export class BusinessNavigationComponent implements OnInit {
  @Input() reverseLeftTop = false;
  @Input() blockButton: BusinessPanelSections = null;
  messageCount$: Observable<number>;

  constructor(
    public playerService: PlayerService,
    private dialogService: DialogService,
    private router: Router,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.messageCount$ = this.store.pipe(select(state => state.message.messageCount));
  }

  open(name: BusinessMenu) {
    if (this.blockButton === name) {
      return;
    }

    switch (name) {
      case "PLANS": {
        this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "PLANS" } });
        break;
      }
      case "CHALLENGES": {
        this.dialogService.open(PlanListComponent, { data: { closeActive: true, mode: "CHALLENGES" } });
        break;
      }
      case "TASKS": {
        this.dialogService.open(NewBusinessContainerComponent, { data: { closeActive: true } });
        break;
      }
      case "ACTIONS": {
        if (this.router.url.includes("/game")) {
          this.router.navigate(["/auth/actions"]);
          this.dialogService.closeAll();
        } else {
          this.dialogService.closeActive();
          this.dialogService.open(ActionListComponent);
        }
        break;
      }
      case "GAME": {
        this.dialogService.closeAll();
        if (!this.router.url.includes("/game")) {
          this.router.navigate(["/auth/game"]);
        }
        break;
      }
      case "CHAT": {
        this.router.navigate(["/auth/chat"]);
        this.dialogService.closeAll();
        break;
      }
    }
  }
}
