import { select } from "@ngrx/store";
import * as R from "ramda";
import { filter } from "rxjs/operators";

import { EVENTS } from "../../../../../core/consts/core/events";
import { isEqual } from "../../../../../helpers/common.helpers";
import { BoardTileState } from "../../../../../store/game/interfaces/board-tile.state";
import { selectLastUpdateTile } from "../../../../../store/game/selectors";
import { BOARD_TILE_ALPHA, BOARD_TILE_WITH_BUILDING_ALPHA, BoardViewMode, CAMERA_START_TILE, TileTooltipType } from "../../../constants";
import { GameService } from "../../../services/game.service";
import { PhaserGameService } from "../../../services/phaser-game.service";
import { BoardConfig, PositionOffset } from "../../interfaces/shared";
import { MoveBuildingPreview } from "../core/MoveBuildingPreview";
import { MyScene } from "../core/MyScene";
import { BoardTile } from "../custom/BoardTile.class";
import { TileHover } from "../tile-hover/custom/TileHover";
import { TileMenu } from "../tile-menu/custom/TileMenu";

export class BoardCore {
  myScene: MyScene;
  gameService: GameService;
  phaserGameService: PhaserGameService;
  boardTiles: BoardTile[] = [];
  movingTile: BoardTile;
  moveTileInvalidTypeAlpha = 0.05;
  moveBuildingPreview: MoveBuildingPreview;
  tileHover: TileHover;
  tileMenuRef: TileMenu;
  viewMode = BoardViewMode.Isometric;
  offset: PositionOffset = {
    x: 0,
    y: 0,
  };
  isVisibleBuildingsLevelLayer: boolean;
  yAxisSign = -1;

  debugControllers: any[] = [];

  constructor(scene: MyScene, config?: BoardConfig) {
    this.myScene = scene;
    this.gameService = scene.gameService;
    this.phaserGameService = scene.gameService.phaserGameService;

    if (config && config.yAxisSign) {
      this.yAxisSign = config.yAxisSign;
    }

    if (config) {
      this.processConfig(config);
    }

    this.afterCreate();
  }

  afterCreate() {
    this.gameService.globalService.globalEvents.subscribe(event => {
      if (event.name === EVENTS.GAME.TOGGLE_BUILDINGS_LEVEL_LAYER) {
        this.isVisibleBuildingsLevelLayer = !this.isVisibleBuildingsLevelLayer;
      }
    });
  }

  createBoard(boardData: BoardTileState[], viewMode = BoardViewMode.Isometric) {
    this.viewMode = viewMode;
    this.tileHover = new TileHover(this.myScene);
    boardData.forEach(tileData => this.createTile(tileData));

    if (this.phaserGameService.lastUpdatedTile$) {
      this.phaserGameService.lastUpdatedTile$.unsubscribe();
    }

    this.phaserGameService.lastUpdatedTile$ = this.gameService.store
      .pipe(
        select(selectLastUpdateTile),
        filter(state => !!state)
      )
      .subscribe((updatedTile: BoardTileState) => {
        console.log("update");
        this.updateTile(updatedTile);
      });
  }

  /**
   * Create single board tile instance, set board position and sort buildings layer for isometric rendering.
   * Based on tile data can be instance of BoardTile class or child class depend on building group.
   * Isometric sorting is using myDept value to allow control tile rendering order.
   * @param tileData
   * @param isUpdate - used if tile is creating after socket received to skip tile that was not created on state start.
   */
  createTile(tileData: BoardTileState, isUpdate?: boolean) {
    const tileToDestroy = this.getTileByTileId(tileData.tile_id);
    if (!tileToDestroy && isUpdate) {
      console.log("No tile to update. From other board?", tileData);
      return true;
    }

    if (
      tileData.player_building &&
      (tileData.player_building.group.includes("hidden") || tileData.player_building.group.includes("hiden"))
    ) {
      console.log("Hidden building", tileData);
      return true;
    }

    this.destroyTile(tileData.tile_id);

    const boardTile = new BoardTile({
      scene: this.myScene,
      x: tileData.x_pos,
      y: tileData.y_pos * this.yAxisSign,
      tileData: tileData,
      viewMode: this.viewMode,
      offset: this.offset,
    });

    if (this.myScene.buildingsLayer) {
      boardTile.depthIndex = this.myScene.buildingsLayer.depthIndex;
      boardTile.calculateDepth();
      this.myScene.buildingsLayer.add(boardTile);
    }

    if (tileData.x_pos === CAMERA_START_TILE.x && tileData.y_pos === CAMERA_START_TILE.y) {
      this.myScene.cameraStartPos = {
        x: boardTile.x,
        y: boardTile.y - 100,
      };
    }
    this.boardTiles.push(boardTile);
  }

  /**
   * Find tile object reference by player tile id.
   * @param tileId
   */
  getTileByTileId(tileId: number) {
    return this.boardTiles.find(tile => tile.tileData.tile_id === tileId);
  }

  /**
   * Update tile.
   * Destroy tile and then recreate with updated data.
   * @todo: allow some properties to update without destroying tile.
   * @param tileData
   */
  updateTile(tileData: BoardTileState) {
    const currentTile = this.getTileByTileId(tileData.tile_id);
    if (currentTile && this.shouldUpdateSilent(currentTile.tileData, tileData, currentTile)) {
      this.silentUpdate(currentTile, tileData);
    } else {
      this.createTile(tileData, true);
      this.resetBoardTilesAlpha();
    }
  }

  shouldUpdateSilent(currentData: BoardTileState, newData: BoardTileState, currentTile: BoardTile): boolean {
    if (currentData.player_building && newData.player_building) {
      currentData = R.clone(currentData);
      newData = R.clone(newData);

      const autoProductionValue = newData.player_building ? newData.player_building.auto_production_amount : null;

      if (autoProductionValue === 0 || (currentTile.tooltip && currentTile.tooltip.tooltipType !== TileTooltipType.COLLECT)) {
        return false;
      }

      delete currentData.player_building.auto_production_amount;
      delete newData.player_building.auto_production_amount;

      delete currentData.resource_left;
      delete newData.resource_left;

      return isEqual(currentData, newData);
    } else {
      return false;
    }
  }

  silentUpdate(targetTile: BoardTile, data) {
    targetTile.updateState(data);
  }

  /**
   * Destroy tile and remove reference from tiles array.
   * @param tileId
   */
  destroyTile(tileId: number) {
    const targetTile = this.getTileByTileId(tileId);
    if (targetTile) {
      targetTile.destroyTile();
      this.boardTiles = this.boardTiles.filter(tile => tile.tileData.tile_id !== tileId);
    }
  }

  /**
   * Destroy board and unsubscribe from store board state.
   */
  destroy() {
    this.boardTiles.forEach(tile => tile.destroyTile());
    // this.board$ && this.board$.unsubscribe();
    if (this.phaserGameService.lastUpdatedTile$) {
      this.phaserGameService.lastUpdatedTile$.unsubscribe();
    }
  }

  debugTilesInput() {
    this.boardTiles
      .filter(tile =>
        (tile.playerBuildingData && tile.playerBuildingData.pixel_perfect) ||
        (!tile.playerBuildingData && tile.tileData["empty_icon_pixel_perfect"])
          ? tile.baseSprite?.input
          : tile.input
      )
      .forEach(tile => {
        tile.debugTileInput();
      });
  }

  debugTiles() {
    this.boardTiles
      .filter(tile =>
        (tile.playerBuildingData && tile.playerBuildingData.pixel_perfect) ||
        (!tile.playerBuildingData && tile.tileData["empty_icon_pixel_perfect"])
          ? tile.baseSprite?.input
          : tile.input
      )
      .forEach(tile => {
        tile.debugTile();
      });
  }

  /**
   * Start building move mode.
   * Set low alpha on unavailable tiles.
   * @param movingTileId
   * @param tileFilterFn
   */
  startBuildingMoveMode(movingTileId: number, tileFilterFn = (boardTile: BoardTile) => true) {
    this.movingTile = this.boardTiles.find(tile => tile.tileData.player_tile_id === movingTileId);

    for (const tile of this.boardTiles.filter(tileFilterFn)) {
      const hasBuilding = tile.hasBuilding;
      if (hasBuilding && tile.tileData.player_tile_id === movingTileId) {
      } else {
        const isDifferentTileType = tile.tileData.tile_type.type_id !== this.movingTile.tileData.tile_type.type_id;
        if (hasBuilding || isDifferentTileType) {
          this.myScene.tweens.add({
            targets: [tile],
            alpha: this.moveTileInvalidTypeAlpha,
            duration: 250,
            delay: Phaser.Math.Distance.Between(this.movingTile.x, this.movingTile.y, tile.x, tile.y) / 3,
          });
          if (tile.tooltip) {
            tile.tooltip.visible = false;
          }
        }
      }
    }
    this.moveBuildingPreview = new MoveBuildingPreview(this.movingTile, this);
  }

  moveBuildingPreviewIfPossible(target: BoardTile) {
    const isDifferentTileType = target.tileData.tile_type.type_id !== this.movingTile.tileData.tile_type.type_id;
    if (isDifferentTileType) {
      return;
    }
    this.myScene.tweens.add({
      targets: [this.moveBuildingPreview],
      x: target.x,
      y: target.y,
      duration: 500,
      ease: "Power2",
    });
    this.movingTile.movedToTile = target;
    this.moveBuildingPreview.setDepth(target.depth + 1);
  }

  /**
   * Confirm building move.
   * Send api request and stop moving mode.
   * @param playerTileId
   * @param playerBuildingId
   */
  confirmBuildingMove(playerTileId: number, playerBuildingId: number) {
    this.gameService.buildingsService.movePlayerBuilding(playerTileId, playerBuildingId).subscribe(
      () => {
        this.stopBuildingMoveMode();
      },
      () => {
        this.stopBuildingMoveMode();
      }
    );
  }

  /**
   * Stop moving mode.
   * Re-set normal alpha on all tiles.
   * Destroy moving preview sprite.
   */
  stopBuildingMoveMode() {
    this.moveBuildingPreview.destroy();
    this.movingTile = null;
    this.resetBoardTilesAlpha();
  }

  /**
   * Destroy tooltip on all building in group.
   * Using when collection gastro income.
   * @param group
   */
  destroyTooltipsByBuildingGroup(group: string) {
    this.boardTiles.forEach((boardTile: BoardTile) => {
      boardTile.isBuildingGroup(group) && boardTile.destroyTooltip();
    });
  }

  /**
   * Check if building can be moved to tile.
   * First check if tile_type exists, to detect if tile is not fakeBoardTile.
   * @param tile
   * @param movingTile
   */
  private canBeMovedTo(tile: BoardTile, movingTile: BoardTile) {
    // return tile.tile_type &&
    //   (
    //     (tile.hasBuilding() && tile.player_tile_id != movingTile.player_tile_id) ||
    //     tile.tile_type.type_id != movingTile.tile_type.type_id ||
    //     (!tile.hasBuilding() && !tile.isActive())
    //   );
  }

  closeTileMenus() {
    if (this.tileMenuRef) {
      this.tileMenuRef.targetTile.closeTileMenu();
      this.tileMenuRef = null;
    }
  }

  resetBoardTilesAlpha() {
    const setTileAlpha = boardTile => {
      boardTile.hasBuilding ? boardTile.setAlpha(BOARD_TILE_WITH_BUILDING_ALPHA) : boardTile.setAlpha(BOARD_TILE_ALPHA);

      if (this.myScene.tweens.isTweening(boardTile)) {
        const tileTweens = this.myScene.tweens.getTweensOf(boardTile);
        tileTweens.forEach(tween => tween.remove());
      }

      boardTile.hasBuilding ? boardTile.setAlpha(BOARD_TILE_WITH_BUILDING_ALPHA) : boardTile.setAlpha(BOARD_TILE_ALPHA);
      if (boardTile.tooltip) {
        boardTile.tooltip.visible = true;
      }
    };
    this.boardTiles.forEach(setTileAlpha);
  }

  private processConfig(config: BoardConfig) {
    if (config.offset) {
      this.offset = config.offset;
    }
  }
}
