import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import * as R from "ramda";
import { of } from "rxjs";
import {catchError, startWith, take, tap} from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DeviceService } from "../../../../../../core/providers/device.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../player/providers/player.service";
import { AssetPipe } from "../../../../../shared/pipes/asset.pipe";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { ApiQaService } from "../../../qa/api/core/services/api-qa.service";
import { GetPlayerTasksRequest } from "../../api/custom/interfaces/get-player-tasks-request.interface";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { ApiBusinessWeekly } from "../../api/custom/services/api-business-weekly";
import { TASK_TYPE } from "../../consts/custom/task-type.const";
import { prepareCardTask } from "../../helpers/custom";
import { CardTask } from "../../interfaces/custom/card-task.interface";
import { BusinessViewSelectorService } from "../../services/business-view-selector.service";
import { tasks } from "../../mocks/tasks.mock";

@Injectable()
export abstract class AbstractBusinessCards extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(ApiBusinessWeekly) apiBusinessWeekly: ApiBusinessWeekly;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Router) router: Router;
  @OwInject(AssetPipe) assetPipe: AssetPipe;
  @OwInject(ApiQaService) apiQaService: ApiQaService;
  @OwInject(DeviceService) deviceService: DeviceService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ActivatedRoute) route: ActivatedRoute;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(BusinessViewSelectorService) viewSelector: BusinessViewSelectorService;
  TASK_TYPE = TASK_TYPE;
  subs = {
    global: null,
  };
  cardTasks: CardTask[];
  allCardTasks: Array<CardTask>;
  paginationDate: {
    current: Moment;
    start?: Moment;
    end?: Moment;
    allowNext?: boolean;
    allowPrev?: boolean;
  };
  customLoading: boolean;
  startDate: {
    year: number;
    week: number;
  };
  endDate: {
    year: number;
    week: number;
  };
  _momentDate: Moment = this.createMomentIsoDate();
  get momentDate(): Moment {
    return this._momentDate;
  }
  set momentDate(momentDate: Moment) {
    this._momentDate = momentDate;
    this.setPagination();
  }
  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe(event => {
      switch (event.name) {
        case EVENTS.GUI.RELOAD_CARDS:
          this.getTasks();
          break;
      }
    });
  }
  getTasks() {
    this.getWeekMoment();
    this.customLoading = true;
    this.setPagination();
    this.getTasksBasic().subscribe(() => {
      this.sortTasksByPosition();
      this.customLoading = false;
      this.viewSelector.cardTasks = this.cardTasks;
      this.viewSelector.index = 0;
      this.viewSelector.week = this.momentDate;
    });
  }
  sortTasksByPosition() {
    this.cardTasks.sort((task1, task2) => {
      if (task1.card_position > task2.card_position) {
        return 1;
      } else if (task1.card_position === task2.card_position) {
        return 0;
      } else if (task1.card_position < task2.card_position) {
        return -1;
      }
    });
  }
  getTasksBasic() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      week: this.momentDate.isoWeek(),
      year: this.momentDate.year(),
    };
    return this.apiBusinessService.playerTasks(getPlayerTasksRequest).pipe(
      // startWith(tasks),
      // take(1),
      tap((resp: CardTask[]) => {
        this.clearCardTasks();
        this.cardTasks = prepareCardTask(resp);
      }),
      catchError(() => {
        this.clearCardTasks();
        return of();
      })
    );
  }
  clearCardTasks() {
    this.cardTasks = [];
  }
  setPagination() {
    this.getInfoAboutPagination();
    this.paginationDate = {
      current: this.momentDate,
      start: this.createMomentIsoDate({ year: this.startDate.year, week: this.startDate.week }),
      end: this.createMomentIsoDate({ year: this.endDate.year, week: this.endDate.week }),
    };
    this.paginationDate.allowNext = this.paginationDate.current.isBefore(this.paginationDate.end, "isoWeek");
    this.paginationDate.allowPrev = this.paginationDate.current.isAfter(this.paginationDate.start, "isoWeek");
  }
  getInfoAboutPagination() {
    const tasksSetup: { week: number; year: number } = this.playerService.player["tasks_setup"];
    this.startDate = {
      week: tasksSetup.week,
      year: tasksSetup.year,
    };
    const playerTasksPagination: { week: number; year: number } = this.playerService.player["player_tasks_pagination"];
    this.endDate = {
      week: playerTasksPagination.week,
      year: playerTasksPagination.year,
    };
  }
  next() {
    if (this.paginationDate.allowNext) {
      this.momentDate.add({ week: 1 });
      this.getTasks();
    }
  }
  prev() {
    if (this.paginationDate.allowPrev) {
      this.momentDate.subtract({ week: 1 });
      this.getTasks();
    }
  }
  createMomentIsoDate({ year, week }: { year?: number; week?: number } = {}) {
    const date = new OwDate();

    year = year || date.year;
    week = week || date.momentDate.isoWeekYear(year).isoWeek();

    return moment().isoWeekYear(year).isoWeek(week);
  }
  getWeekMoment() {
    const moment = this.viewSelector.week;
    if (moment) {
      this.momentDate = moment;
    }
  }
}
