import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { BankAccountNumberService } from "../../../../services/custom/bank-account-number.service";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-hud-bank-account-number",
  templateUrl: "./hud-bank-account-number.component.html",
})
export class HudBankAccountNumberComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<HudBankAccountNumberComponent>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(BankAccountNumberService) bankAccountNumberService: BankAccountNumberService;
  @ViewChild("ibanNumberInput") ibanNumberInputRef: ElementRef;
  ibanCode = "PL";
  ibanNumberIsSet = false;
  isLoading = true;
  ibanNumberForm;
  userIbanNumber = "";
  ngOnInit() {
    this.setForm();
    this.fetchAndCompute();
  }
  fetchAndCompute() {
    this.bankAccountNumberService
      .getBankAccountNumber()
      .pipe(tap(this.setUserIbanNumber.bind(this)))
      .subscribe(
        () => {
          if (this.userIbanNumber) this.ibanNumberIsSet = true;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  setUserIbanNumber({ account_number }) {
    this.userIbanNumber = account_number.replace(this.ibanCode, "");
  }
  setForm() {
    this.ibanNumberForm = new FormGroup({
      ibanNumber: new FormControl(this.userIbanNumber ?? "", [Validators.required]),
    });
  }

  close() {
    this.matDialogRef.close();
  }
  saveNumber() {
    this.cutWhiteSpaces();
    if (this.ibanNumberForm.valid) {
      this.bankAccountNumberService
        .postBankAccountNumber(this.ibanCode + this.ibanNumberForm.value.ibanNumber)
        .pipe(tap(this.fetchAndCompute.bind(this)))
        .subscribe();
    }
  }

  updateNumber() {
    this.cutWhiteSpaces();
    if (this.ibanNumberForm.valid) {
      this.bankAccountNumberService
        .patchBankAccountNumber(this.ibanCode + this.ibanNumberForm.value.ibanNumber)
        .pipe(
          tap(this.fetchAndCompute.bind(this)),
          tap(() => {
            this.dialogService.openAlert({ description: "Nr konta został zmieniony." });
          })
        )
        .subscribe();
    }
  }

  cutWhiteSpaces() {
    this.userIbanNumber = this.userIbanNumber.replace(/\s/g, "");
    this.changeDetectorRef.detectChanges();
  }

  protected readonly allowOnlyDigits = allowOnlyDigits;
}
function allowOnlyDigits(event) {
  // Get the ASCII code of the key that was pressed
  var charCode = event.which ? event.which : event.keyCode;
  console.log(charCode);

  // Allow only digits (ASCII 48-57 for 0-9) and other non-printable keys (backspace, tab)
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    event.preventDefault(); // Stop the character from being typed
    return false;
  }
  return true;
}
