import { Injectable } from "@angular/core";

import { AndroidService } from "../../providers/android.service";

@Injectable({ providedIn: "root" })
export class RegisterCustomFirebaseTokenService {
  isRegisterFirebaseFired: boolean;
  didItGoToFirstIf: boolean;
  downloadedToken: boolean;
  catchErrorMsg;

  token: string;
  vapidKey = "BCQswd3czhMLef8KYSVCLIN2WjeDgwtapPs4og9qNG4reybONeUWWpP2gtYt4BMIJhjRzsTLR4yAnHABaVsnWA0";

  constructor(private androidService: AndroidService) {}

  registerFirebase() {
    this.isRegisterFirebaseFired = false;
    this.downloadedToken = false;
    this.didItGoToFirstIf = false;
    this.token = null;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "notifications" }).then(function (notificationPerm) {
        notificationPerm.onchange = function () {
          console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
          if (notificationPerm.state === "granted") {
            that.registerFirebase();
          }
        };
      });
    }
    try {
      const messaging = window["firebase"].messaging();

      if (this.isRegisterFirebaseFired || !navigator.serviceWorker || !("Notification" in window) || Notification.permission === "denied") {
        this.didItGoToFirstIf = true;
        return;
      }
      messaging.getToken({ vapidKey: this.vapidKey }).then(token => {
        this.token = token;
        this.pushToken(this.token);
        this.downloadedToken = true;
      });
      this.isRegisterFirebaseFired = true;
    } catch (e) {
      this.catchErrorMsg = e;
      console.error(e);
    }
  }

  unregisterFirebase() {
    try {
      const messaging = window["firebase"].messaging();
      messaging.deleteToken().then(res => console.log(res));
      this.isRegisterFirebaseFired = false;
    } catch (e) {
      console.error(e);
    }
  }

  pushToken(token: string) {
    this.androidService.pushTokenRequest(token, document["GAME_VERSION_TIMESTAMP"]).subscribe();
  }
}
