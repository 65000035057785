<!--<div class="global-dialog ow-dialog window-a primary no-transparent">-->
<div class="wrapper">
  <app-business-navigation [reverseLeftTop]="true" blockButton="TASKS">
    <ng-container left-top>
      <button class="round-button fixed c-green" (click)="backToListView()">
        <i class="far fa-arrow-to-left"></i>
      </button>
    </ng-container>
  </app-business-navigation>
  <div class="content">
    <i class="far fa-chevron-square-left left arrow-pagination" (click)="prevTaskView()"></i>
    <i class="far fa-chevron-square-right right arrow-pagination" (click)="nextTaskView()"></i>

    <div class="title">
      <img
        *ngIf="task.selected.task_definition.partner_logo | asset: 'vendors'; else text"
        [src]="task.selected.task_definition.partner_logo | asset: 'vendors'" />
      <ng-template #text>
        <p>{{ task.selected.task_definition.name }}</p>
      </ng-template>
    </div>
    <ow-perfect-scrollbar scrollGradient>
      <div class="page">
        <!--          <div class="task-name" *ngIf="(task.selected.task_definition.partner_logo && (task.selected.task_definition.partner_logo | asset: 'vendors'))">-->
        <!--            <p>{{ task.selected.task_definition.name }}</p>-->
        <!--          </div>-->
        <div class="description">
          <div class="subtitle">
            <p
              owEllipsis
              [innerText]="task.selected.description"
              [isDisabledClick]="true"
              (click)="openDescriptionDialog(task.selected.description)"></p>
          </div>
          <div class="time-period">
            <p class="date">
              Zadanie obowiązuje od
              {{ moment(task.selected.task_start_date).format("DD") + "." + moment(task.selected.task_start_date).format("MM") }} do
              {{ moment(task.selected.task_end_date).format("DD") + "." + moment(task.selected.task_end_date).format("MM") }}
            </p>
            <p class="result" *ngIf="task.selected?.last_import_date">
              Wynik z dnia:
              {{ moment(task.selected.last_import_date).format("DD") + "." + moment(task.selected.last_import_date).format("MM") }}
            </p>
          </div>
        </div>
        <div class="progress">
          <p>
            Twój aktualny poziom realizacji: <b>{{ task.selected.performance + " " + task.selected.task_definition.string_format }}</b>
          </p>
          <div class="bar">
            <div class="fill" [ngStyle]="{ width: task.selected.progress.valuePercentage }"></div>
          </div>
          <div class="table" [class.narrow-columns]="task.selected.scopes.length < 6">
            <div class="plan" *ngFor="let scope of task.selected.scopes">
              <p class="execution">
                {{ task.selected.task_definition.reversed_scope ? "<=" : ">=" }}{{ (scope.target * 100).toFixed(0) }}%
              </p>
              <div class="sale" *ngFor="let currency_prizes of scope.currency_prizes; let i = index">
                <div class="prize" [class.rounded-bottom]="i === scope.currency_prizes.length - 1">
                  <p class="value">{{ currency_prizes.amount }}</p>
                  <m-ui-currency [item]="currency_prizes" [stockView]="STOCK_VIEW.H" [hideValue]="true" [lack]="false"></m-ui-currency>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="total-prizes">
          <p class="target" *ngIf="task.selected.task_type === 'o'; else notOperational">
            Cel: <b>{{ task.selected.target === 1 ? 100 : task.selected.target }} {{ task.selected.task_definition.string_format }}</b>
          </p>
          <ng-template #notOperational>
            <p class="target">
              Cel: <b>{{ task?.selected?.target }} {{ task.selected.task_definition.string_format }}</b>
            </p>
          </ng-template>
          <p class="amount">
            Procent Realizacji <b>{{ (task.selected.realization * 100).toFixed(2) }}%</b>
          </p>
        </div>
        <div *ngIf="task.selected.task_definition?.additional_logos.length > 0" class="images">
          <ng-container *ngFor="let logo of task.selected.task_definition.additional_logos">
            >
            <img [src]="logo | asset: 'vendors'" />
          </ng-container>
        </div>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
<!--</div>-->
