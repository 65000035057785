<div class="action-form-wrapper">
  <div class="content">
    <span> Potwierdzam, że zapoznałem się i akceptuję Regulamin oraz warunki poniżej załączonych dokumentów. </span>
  </div>
  <loading></loading>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div formArrayName="requiredFiles">
      <div *ngFor="let file of form.get('requiredFiles')['controls']; let i = index">
        <div class="control control-checkbox">
          <div class="custom-checkbox">
            <input type="checkbox" [formControl]="file.controls.is_accepted" id="'file-{{ i }}" required />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label for="'file-{{ i }}'">
            Akceptuję <a [href]="file.controls.file_url.value" target="_blank"> {{ file.controls.name.value }} </a>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-button-wrapper">
      <button type="submit" class="auth-button primary" [disabled]="form.invalid">Przechodzę do Akcji</button>
    </div>
  </form>
</div>
