import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select } from "@ngrx/store";
import * as R from "ramda";
import { map } from "rxjs/operators";
import { GlobalEvent } from "src/app/core/interfaces/shared.interfaces";

import { CUSTOM_EVENTS } from "../../../../../../../../core/consts/custom/events";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { PlayerSelectors } from "../../../../../../../../store/player";
import { Player } from "../../../../../../../player/interfaces/player";
import { BoardService } from "../../../../../../services/board.service";
import { MenuButtonsConfigService } from "../../../../../../services/custom/menu-buttons-config.service";
import { GameService } from "../../../../../../services/game.service";
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../../../../../currency-exchange/consts/core/event-dialogs/event-names.const";
import { PrepaidCardDashboardComponent } from "../../../../../edenred/base/prepaid-card-dashboard/prepaid-card-dashboard.component";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../../../message/consts/core/event-dialogs/event-names.const";
import { MESSAGE_TYPE_ID } from "../../../../../message/consts/core/message-type.const";
import { EVENT_DIALOGS_NAMES_MGM_CUSTOM } from "../../../../../mgm/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../../rankings/consts/custom/event-dialogs/event-names.const";
import { AbstractHudMenuComponent } from "../../../../abstract/core/abstract-hud-menu.component";
import { MappedMenuButtonConfig, MenuButtonConfig } from "../../../../hud-menu-buttons/interfaces/core/menu-buttonts-config.interface";
import { HudMenuMoreComponent } from "../../dialogs/hud-menu-more/hud-menu-more.component";

@Component({
  selector: "app-custom-hud-menu",
  templateUrl: "./hud-menu.component.html",
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit, OnDestroy {
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(Router) router: Router;
  @Input() isDialog: boolean;
  @Input() sceneOtherConfigFromMenuMore?: any;

  menuButtons: MappedMenuButtonConfig[] = [];
  snapShotButtons: MappedMenuButtonConfig[] = [];
  otherButtonsIsActive = false;
  groups = [];
  player: Player;

  subs: { scene: any; player: any; globalEmitter: any } = {
    scene: null,
    player: null,
    globalEmitter: null,
  };

  sceneOtherConfig: any;

  ngOnInit() {
    if (!this.isDialog) {
      this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    } else {
      this.subscribePlayer();
    }
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
      this.setMenu();
    });
  }

  async handleGameEvents(event: GlobalEvent) {
    switch (event.name) {
      case "SCENE_CONFIG_CHANGE":
        this.sceneOtherConfig = event.value.other;

        if (this.sceneOtherConfig?.isBranchMap) {
          document.body.classList.add("second_map-scene");
          document.body.classList.remove("third_map-scene");
        } else if (this.sceneOtherConfig?.isCountryMap) {
          document.body.classList.add("third_map-scene");
          document.body.classList.remove("second_map-scene");
        } else {
          document.body.classList.remove("second_map-scene");
          document.body.classList.remove("third_map-scene");
        }

        if (this.subs.player === null) {
          this.subscribePlayer();
        } else {
          this.setMenu();
        }
        this.handlerLogicHud();
        break;
    }
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getButtonsMenuConfig({
      gui_unlocks: this.player.gui_unlocks,
      place: "menu-left",
      isDialog: this.isDialog,
      sceneConfig: this.isDialog ? this.sceneOtherConfigFromMenuMore : this.sceneOtherConfig,
    });

    buttons = buttons.filter(button => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    const bodyClassName = document.body.className;
    if (bodyClassName.includes("second_map-scene")) {
      buttons = buttons.filter(x => x.name !== "second_map");
    } else if (bodyClassName.includes("third_map-scene")) {
      buttons = buttons.filter(x => x.name !== "third_map");
    } else {
      buttons = buttons.filter(x => x.name !== "city");
    }

    if (R.equals(this.snapShotButtons, buttons)) {
      return;
    }
    this.snapShotButtons = buttons;
    this.menuButtons = R.clone(this.snapShotButtons);
    this.setNotification();
    this.otherButtonsIsActive = this.menuButtons.length > 5 && !this.isDialog;
  }

  tese() {
    console.log(this.menuButtons);
  }

  setNotification() {
    this.menuButtons.forEach(button => {
      switch (button.name) {
        case "missions":
          button["customNotification"] = {
            type: "async",
            observable: this.hasMissionsToCollect,
          };
          break;

        case "messages":
          button["customNotification"] = {
            type: "async",
            observable: this.hasNewMessagesToRead,
          };
          break;

        case "warehouse":
          button["customNotification"] = {
            type: "async",
            observable: this.newProductsInStorage.pipe(map(arr => arr.length)),
          };
          break;
      }
    });
  }

  handleMenuButtonEvent(button: MenuButtonConfig) {
    if (button.type === "group") {
      // this.toggleGroupButtons(button);
    } else if (button.type === "button") {
      switch (button.click_event) {
        case "messages":
          this.openMessages();
          break;

        case "missions":
          this.openMissions();
          break;

        case "warehouse":
          this.openWarehouse();
          break;

        case "qa":
          // EUROCASH only | no QA -> always pills #20257
          this.openMessagesQa();
          break;

        case "pills":
          this.openMessagesQa();
          break;

        case "ranking":
          this.openRankList();
          break;
        // case "question_list":
        //   this.openQuestionList();
        //   break;

        case "mgm":
          this.openMgmInviter();
          break;

        case "photobooth":
          this.openPhotoGallery();
          break;

        case "market":
          this.openMarket();
          break;

        case "exchange":
          this.openExchange();
          break;

        case "prepaid":
          this.openEdenred();
          break;

        case "second_map":
          this.changeMapCustomService.goToSecondMap();
          break;

        case "third_map":
          this.changeMapCustomService.goToThirdMap();
          break;

        case "city":
          this.changeMapCustomService.goToCityMap();
          break;
        case "typer":
          this.redirectTyper();
          break;
      }
    }
  }

  // private toggleGroupButtons(button: MappedMenuButtonConfig) {
  //   button.group_buttons.forEach(groupBtn => (groupBtn.isVisible = !groupBtn.isVisible));
  // }

  handlerLogicHud() {
    switch (this.sceneOtherConfig.configKeyName) {
      // case BRANCH_SCENE_CONFIG_KEY:
      //   this.globalService.globalEvents.emit({ name: CUSTOM_EVENTS.GUI.BUSINESS.HIDE });
      //   break;

      default:
        this.globalService.globalEvents.emit({ name: CUSTOM_EVENTS.GUI.BUSINESS.SHOW });
    }
  }

  openMessagesQa() {
    const data = {
      type: MESSAGE_TYPE_ID.QA,
      title: " ",
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
      config: {
        data,
      },
    });
  }

  openRankList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: 1,
        },
      },
    });
  }

  openMgmInviter() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MGM_CUSTOM.INVITER,
    });
  }

  // openQuestionList() {
  //   this.dialogService.open(QuestionListComponent);
  // }

  openPhotoGallery() {
    this.router.navigate(["/auth/gallery"]);
  }

  openMarket() {
    this.router.navigate(["/auth/shop"]);
  }

  openExchange() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    });
  }

  openHudMenuMore() {
    this.dialogService.open(HudMenuMoreComponent);
  }

  openEdenred() {
    this.dialogService.open(PrepaidCardDashboardComponent);
  }
  redirectTyper(){
    window.open('https://typer.euromisja.pl', '_blank');
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
