import { Component, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { APP_ROUTING_ABSOLUTE_PATH } from "../../../../../../../consts/routing-app-absolute.const";
import { Router } from "@angular/router";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";

@Component({
  selector: "app-challenges-difficulty-level",
  templateUrl: "./challenges-difficulty-level.component.html",
  styleUrls: ['./challenges-difficulty-level.component.scss']
})
export class ChallengesDifficultyLevelComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(Router) router: Router;
  a = {
    progress: 50,
    currPoints: 60000,
    maxPoints: 120000,
    dates: '01.09-30.09',
    actualDate: '23.09'
  }
  ngOnInit() {
    console.log("HELLO FROM CHALLENGES");
  }

  redirectToGame(){
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.GAME]);
  }
}
