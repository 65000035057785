import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { AwardPrize, CombinedAward } from "../../../interfaces/custom/common-business.interface";
import { Plan } from "../../../interfaces/custom/plans.interface";
import { ClientListComponent } from "../client-list/client-list.component";

@Component({
  selector: "app-plan-details",
  templateUrl: "./plan-details.component.html",
  styleUrls: ["./plan-details.component.scss"],
})
export class PlanDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ChangeDetectorRef) changeDetection: ChangeDetectorRef;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  imagePath = window.location.origin.includes("localhost") ? "https://eurocash4.o-w.dev/" : window.location.origin + "/";

  @OwInject(MAT_DIALOG_DATA) data?: {
    date: OwDate;
    plans: Plan[];
    index: number;
  };
  date: OwDate;
  plans: { [key: number]: Plan } = {};
  indexPlansList: number;
  currentPlan: Plan;
  loading = false;
  prizesWithBonus: CombinedAward[] = [];

  ngOnInit() {
    this.indexPlansList = this.data.index;
    this.date = this.data.date;
    this.fetchPlan(this.data.plans[this.indexPlansList].player_plan_id);
    this.prizesWithBonus = this.combinedPrizes(
      this.data.plans[this.indexPlansList].currency_prizes,
      this.data.plans[this.indexPlansList].bonus_currency_prizes
    );
  }

  backToPlansList() {
    this.dialogService.closeActive();
  }

  fetchPlan(plan_id: number) {
    this.loading = true;
    this.businessService.playerPlanById(plan_id).subscribe(data => {
      this.plans[this.indexPlansList] = data;
      this.currentPlan = this.plans[this.indexPlansList];
      this.registerGTAGEvent();
    });
  }

  registerGTAGEvent() {
    if (window["gtag"]) {
      window["gtag"]("event", "Szczegóły planu", {
        event_category: "Szczegóły planu",
        event_label: "Szczegóły planu",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
        custom_dimension_plan_id: this.currentPlan.plan_id,
      });
    }
  }

  nextPlan() {
    let index = this.indexPlansList + 1;
    if (index > this.data.plans.length - 1) {
      index = 0;
    }

    this.indexPlansList = index;
    if (this.plans[this.indexPlansList] === undefined) {
      this.fetchPlan(this.data.plans[this.indexPlansList].player_plan_id);
    } else {
      this.currentPlan = this.plans[this.indexPlansList];
    }
  }

  prevPlan() {
    let index = this.indexPlansList - 1;
    if (index < 0) {
      index = this.data.plans.length - 1;
    }

    this.indexPlansList = index;
    if (this.plans[this.indexPlansList] === undefined) {
      this.fetchPlan(this.data.plans[this.indexPlansList].player_plan_id);
    } else {
      this.currentPlan = this.plans[this.indexPlansList];
    }
  }

  openClientList() {
    this.dialogService.open(ClientListComponent, {
      data: {
        clientList: this.currentPlan,
        badge: this.data.plans[this.indexPlansList].badge,
        listType: "PLANS",
      },
    });
  }

  markAsInvalid(el: Plan) {
    el.logo = null;
  }

  combinedPrizes(prize: AwardPrize[], bonusPrize: AwardPrize[]): CombinedAward[] {
    const merged = [];

    prize.forEach(item => {
      const bonusItem = bonusPrize.find(bonus => bonus.currency_id === item.currency_id);

      if (bonusItem) {
        merged.push({
          currency_id: item.currency_id,
          currency_type: item.currency_type,
          amount: item.amount,
          type: "combined",
          bonus_amount: bonusItem.amount,
        });
      } else {
        merged.push({
          currency_id: item.currency_id,
          currency_type: item.currency_type,
          amount: item.amount,
          type: "normal",
        });
      }
    });

    bonusPrize.forEach(item => {
      const existing = merged.find(mergedItem => mergedItem.currency_id === item.currency_id);

      if (!existing) {
        merged.push({
          currency_id: item.currency_id,
          currency_type: item.currency_type,
          bonus_amount: item.amount,
          type: "bonus",
        });
      }
    });

    return merged;
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
