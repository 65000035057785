import { Injectable, NgZone } from "@angular/core";

import { PlayerService } from "../../modules/player/providers/player.service";
import { DialogService } from "../../modules/shared/providers/dialog.service";
import { orientationModes } from "../consts/core/orientation-modes.enum";
import { ApiOptions } from "../interfaces/api-options";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AndroidService {
  nativeInterface: {
    setOrientation: (number) => void;
    gameStarted: (playerId: number) => void;
    closeApp: () => void;
  } = window["NativeInterface"];

  constructor(
    private dialogService: DialogService,
    private zone: NgZone,
    public apiService: ApiService,
    public playerService: PlayerService
  ) {
    this.setAndroidServiceInWindow();
  }

  private setAndroidServiceInWindow() {
    window["AndroidBridge"] = this;
  }

  setNativeAppOrientation(orientation: orientationModes) {
    if (this.nativeInterface) {
      this.nativeInterface.setOrientation(orientation);
    }
  }

  gameStarted() {
    if (this.nativeInterface) {
      const playerId = this.playerService.getMePlayerId();

      this.nativeInterface.gameStarted(playerId);
    }
  }

  closeApp() {
    if (this.nativeInterface) {
      this.nativeInterface.closeApp();
    }
  }

  closeWindow() {
    if (DialogService.openDialogs.length) {
      this.zone.run(() => {
        this.dialogService.closeActive();
      });
    } else {
      this.zone.run(() => {
        this.dialogService.openConfirm(
          {
            title: "Uwaga!",
            description: "Czy na pewno chcesz zamknąć aplikację?",
          },
          confirm => {
            if (confirm) {
              this.closeApp();
            }
          }
        );
      });
    }
  }

  registerForPush(token, appVersion) {
    this.pushTokenRequest(token, appVersion).subscribe(() => {});
  }

  pushTokenRequest(token, appVersion) {
    const options: ApiOptions = {
      body: {
        token,
        app_version: appVersion,
        type: 4,
      },
    };

    return this.apiService.post("push-token", options);
  }
}
