<nav class="business-navigation">
  <div class="left">
    <div class="top" [ngClass]="{ reverse: reverseLeftTop }">
      <ng-content select="[left-top]"></ng-content>
    </div>
    <div class="bottom">
      <ng-content select="[left-bottom]"></ng-content>
    </div>
  </div>
  <div class="right">
    <div class="top">
      <button
        class="business-button-round fixed c-green"
        [matTooltip]="'Plany'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('PLANS')"
        [class.blocked]="blockButton === 'PLANS'">
        <i class="fa-regular fa-square-list"></i>
      </button>
      <button
        class="business-button-round fixed c-green"
        [matTooltip]="'Wyzwania'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('CHALLENGES')"
        [class.blocked]="blockButton === 'CHALLENGES'">
        <i class="fal fa-shield-check"></i>
      </button>
      <button
        *ngIf="[2, 3, 4, 5].includes(playerService.player.title_id.id)"
        class="business-button-round fixed c-green"
        [matTooltip]="'Zadania'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('TASKS')"
        [class.blocked]="blockButton === 'TASKS'">
        <i class="fa-regular fa-list-check"></i>
      </button>
    </div>
    <div class="bottom">
      <button
        class="business-button-round fixed c-green"
        [matTooltip]="'Akcje'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('ACTIONS')">
        <i class="fa-regular fa-bullseye-pointer"></i>
      </button>
      <button
        class="business-button-round fixed c-green"
        [matTooltip]="'Grywalizacja'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('GAME')">
        <i class="fa-regular fa-gamepad-modern"></i>
      </button>
      <button
        class="business-button-round fixed c-green"
        [matTooltip]="'Chat'"
        matTooltipPosition="left"
        matTooltipShowDelay="300"
        (click)="open('CHAT')">
        <i class="fa-regular fa-comment"></i>
        <span *ngIf="messageCount$ | async" class="message-count">{{ messageCount$ | async }}</span>
      </button>
    </div>
  </div>
</nav>
