import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";

import { environment } from "../../../../../../../environments/environment";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { LINKS } from "../../../../../../core/consts/core/links.const";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";

@Component({
  selector: "app-cookies",
  templateUrl: "./cookies.component.html",
})
export class CookiesComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<CookiesComponent>;
  @OwInject(CookieService) cookieService: CookieService;

  configureOn = false;
  typeAnalytics = false;
  FILE_LINKS = LINKS;

  configure() {
    this.configureOn = true;
    const analytics = this.cookieService.get("analytics");

    if (analytics) {
      this.typeAnalytics = JSON.parse(analytics);
    }
  }

  submitSelected() {
    this.createCookies();
    this.close();
  }

  createCookies() {
    this.cookieService.set(
      "analytics",
      String(this.typeAnalytics),
      null,
      environment.cookieConfig.path,
      null,
      environment.cookieConfig.secure
    );
  }

  submitAll() {
    this.typeAnalytics = true;
    this.createCookies();
    this.close();
  }

  close(isClosedByButton?) {
    this.matDialogRef.close(isClosedByButton);
  }
}
