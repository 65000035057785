import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../../../warehouse/consts/core/event-dialogs/event-names.const";
import { AbstractHudParametersComponent } from "../../../../abstract/core/abstract-hud-parameters.component";
import { ChangeMapService } from "../../../../services/custom/change-map.service";

@Component({
  selector: "app-hud-parameters",
  templateUrl: "./hud-parameters.component.html",
})
export class HudParameters2Component extends AbstractHudParametersComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ChangeMapService) changeMapService: ChangeMapService;
  loadTimeout: number;

  ngOnInit() {
    this.setHudParameters();
    this.subscribePlayer();
  }

  openStorage() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
      config: {
        data: {
          storageId: 3,
        },
      },
    });
  }

  afterPlayerSubscribe() {
    super.afterPlayerSubscribe();

    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }
}
