import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { LINKS } from "../../../../../../core/consts/core/links.const";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../consts/core/routing-auth-absolute.const";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
})
export class ReportComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(ApiService) apiService: ApiService;

  form: FormGroup;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  formSendSuccess: boolean;
  LINKS = LINKS;

  ngOnInit() {
    this.clearForm();
  }

  clearForm() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.required, Validators.email]),
      role: this.fb.control(null, [Validators.required]),
      branch: this.fb.control(null, null),
      hrid: this.fb.control(null, null),
      description: this.fb.control(null, [Validators.required]),
      confirm: this.fb.control(null, [Validators.required]),
    });
  }
  report() {
    const data = {
      email: this.form.value.email,
      branch: this.form.value.branch,
      role: this.form.value.role,
      hrid: this.form.value.hrid,
      description: this.form.value.description,
    };

    this.postWrongData(data)
      .pipe(
        map(() => {
          this.formSendSuccess = true;
        }),
        catchError(errResp => {
          return of(errResp);
        })
      )
      .subscribe();
  }

  postWrongData(data: WrongData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/wrong-data", options);
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }
}

export interface WrongData {
  email: string;
  branch: string;
  role: string;
  hrid?: string;
  description?: string;
}
