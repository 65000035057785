<div class="global-dialog ow-dialog window-a primary no-transparent">
  <ng-container *ngIf="switchOrientation; else listTpl">
    <app-generic-device-rotate [orientation]="'portrait'"></app-generic-device-rotate
  ></ng-container>
  <ng-template #listTpl>
    <loading></loading>
    <div class="wrapper">
      <app-action-header [title]="'Moje Akcje'"></app-action-header>
      <div class="action-list-wrapper">
        <div class="empty" [ngClass]="{ active: actionsList?.length === 0 }"><span>Brak dostępnych akcji</span></div>
        <div *ngFor="let action of actionsList" class="action-item" (click)="redirectToItem(action)">
          <div class="image"><img *ngIf="action.image_url" [src]="action.image_url" alt="{{ action.name }}" /></div>
          <div class="content">
            <div *ngIf="action.is_accounted" class="settled"><span>Rozliczone</span></div>
            <div class="name">
              <span>{{ action.name }}</span>
            </div>
            <div class="duration-wrapper">
              <div *ngIf="action.updated_at" class="updated-at">
                <span class="label">Dane do dnia:</span>
                <span class="value">{{ action.updated_at | date: "dd.MM.yyyy" }}</span>
              </div>
              <div class="duration">
                <span class="label">Czas trwania:</span>
                <span class="value">{{ action.date_start | date: "dd.MM.yyyy" }} - {{ action.date_end | date: "dd.MM.yyyy" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-action-navigation actualComponent="ACTIONS"></app-action-navigation>
    </div>
  </ng-template>
</div>
