import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_NEW_BUSINESS } from "../../../consts/custom/event-names.const";
import { CardTask } from "../../../interfaces/custom/card-task.interface";
import { fetchTasks } from "../../../interfaces/custom/fetch-Tasks.interface";
import { BusinessViewSelectorService } from "../../../services/business-view-selector.service";
import { ChallengesDetailsComponent } from "../challenges-details/challenges-details.component";
import { PlanListComponent } from "../plan-list/plan-list.component";
import {STOCK_VIEW} from "../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-tasks-daily",
  templateUrl: "./tasks-daily.component.html",
})
export class TasksDailyComponent extends AbstractInjectBaseComponent implements OnInit, fetchTasks {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BusinessViewSelectorService) viewSelector: BusinessViewSelectorService;
  @OwInject(PlayerService) playerService: PlayerService;
  taskList: CardTask[];
  taskIndex: number;
  task: CardTask;
  ngOnInit() {
    this.fetchTasksData();
    this.sendGAEvent();
  }

  fetchTasksData() {
    this.taskList = this.viewSelector.cardTasks;
    this.taskIndex = this.viewSelector.index;
    this.task = this.taskList[this.taskIndex];
    console.log(this.task);
  }
  click() {
    this.dialogService.closeActive();
  }
  backToListView() {
    this.viewSelector.changeView(EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_LIST);
  }
  nextTaskView() {
    this.viewSelector.nextTask();
  }
  prevTaskView() {
    this.viewSelector.prevTask();
  }
  openPlans() {
    this.dialogService.open(PlanListComponent, { data: { closeActive: true } });
  }
  openChallenges() {
    this.dialogService.open(PlanListComponent, { data: { closeActive: true } });
  }
  closeDialogWithBusiness() {
    this.dialogService.closeActive();
  }
  sendGAEvent() {
    if (window["gtag"]) {
      window["gtag"]("event", "Szczegóły Zadania Operacyjnego", {
        event_category: "Szczegóły Zadania Operacyjnego",
        event_label: "Szczegóły Zadania Operacyjnego",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
        custom_dimension_task_id: this.task.selected.task_id,
      });
    }
  }

    protected readonly STOCK_VIEW = STOCK_VIEW;
}
