<app-device-rotate></app-device-rotate>
<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading [showAlways]="state.loading"></loading>

  <div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" blockButton="CHALLENGES">
      <ng-container left-top>
        <button class="round-button fixed c-green" (click)="closeChallenges()">
          <i class="far fa-arrow-to-left"></i>
        </button>
      </ng-container>
      <ng-container left-bottom>
        <button
          class="round-button fixed c-green"
          *ngIf="state.data[state.currentIndex]?.client_realizations?.length"
          (click)="openClientList()">
          <i class="fa-light fa-clipboard-list"></i>
        </button>
      </ng-container>
    </app-business-navigation>
    <div class="content">
      <div class="swiper-navigation" *ngIf="data?.challenges?.length > 1">
        <i class="far fa-chevron-square-left left arrow-pagination" (click)="changeChallenge('PREVIOUS')"></i>
        <i class="far fa-chevron-square-right right arrow-pagination" (click)="changeChallenge('NEXT')"></i>
      </div>
      <div class="page" [ngClass]="{ 'vote-mode': state.voteMode }">
        <div class="title-container">
          <div class="logo">
            <img
              *ngIf="state.data[state.currentIndex]?.logo"
              [title]="state.data[state.currentIndex]?.name"
              [alt]="state.data[state.currentIndex]?.name"
              src="{{ imagePath }}/{{ state.data[state.currentIndex]?.logo }}"
              (error)="markAsInvalid(state.data[state.currentIndex])" />
            <div *ngIf="!state.data[state.currentIndex]?.logo" class="company-name" title="{{ state.data[state.currentIndex]?.name }}">
              <p>{{ state.data[state.currentIndex]?.name }}</p>
            </div>
          </div>

        </div>
        <div
                *ngIf="data.challenges[state.currentIndex]?.badge?.description"
                class="badge {{ data.challenges[state.currentIndex].badge.color }}">
          <p>{{ data.challenges[state.currentIndex].badge?.title +" "+ data.challenges[state.currentIndex].badge?.description}}</p>
        </div>
        <ow-perfect-scrollbar #scrollbar [disabled]="state.voteMode">
          <div class="activity-name" [innerHTML]="state.data[state.currentIndex]?.logo ? state.data[state.currentIndex].name : ''"></div>
          <div class="progress">
            <p
              class="percentage"
              [ngStyle]="{
                left:
                  (state.data[state.currentIndex]?.realization * 100 <= 95 ? state.data[state.currentIndex]?.realization * 100 : 95) + '%'
              }">
              {{ state.data[state.currentIndex]?.realization * 100 || 0 | toFixed: 0 : "down" }}%
            </p>
            <div class="bar">
              <div class="fill" [ngStyle]="{ width: state.data[state.currentIndex]?.realization * 100 + '%' }"></div>
            </div>
            <div class="info">
              <div class="left">
                <p [matTooltip]="'Czas trwania zadania'" matTooltipPosition="below" matTooltipShowDelay="300">
                  Daty: {{ state.data[state.currentIndex]?.task_start_date | date: "dd.MM" }}-{{
                    state.data[state.currentIndex]?.task_end_date | date: "dd.MM"
                  }}
                </p>
                <p *ngIf="state.data[state.currentIndex]?.updated_at">
                  Dane aktualne na: {{ state.data[state.currentIndex]?.updated_at | date: "dd.MM" }}
                </p>
              </div>
              <div class="right">
                <p>Realizacja: {{ state.data[state.currentIndex]?.performance || 0 }}</p>
                <p>Plan: {{ state.data[state.currentIndex]?.target || 0 }}</p>
              </div>
            </div>
          </div>
          <div class="difficulty">
            <p class="hint" *ngIf="this.state.canBeVoted">
              Wybierz poziom trudności wyzwania, aby otrzymać bonus! Na Halach poziom trudności wybierany jest większością głosów. Kliknij,
              aby dokonać swojego wyboru.
            </p>
            <div class="boxes-container">
              <ng-container
                *ngFor="let box of this.state.difficultyBoxes; let i = index"
                [ngTemplateOutlet]="difficultyBox"
                [ngTemplateOutletContext]="{ data: box, index: i }"></ng-container>
            </div>
            <p class="vote" *ngIf="!state.canBeVoted && state?.playerVote">
              Twój głos: <span class="{{ state?.playerVote?.color }}">{{ state?.playerVote?.difficulty }}</span>
            </p>
            <p class="confirm-title">Potwierdź, czy Twój wybór jest prawidłowy:</p>
            <div class="confirm-boxes">
              <button class="business-button-round fixed c-green" [disabled]="state.loading" (click)="cancelVote()">WRÓĆ</button>
              <button class="business-button-round fixed c-green" [disabled]="state.loading" (click)="submitVote()">OK</button>
            </div>
          </div>
          <div class="strategy">
            <div class="decorator"><p>Strategia nagradzania:</p></div>
            <div class="thresholds">
              <div class="step" *ngFor="let box of state.data[state.currentIndex]?.thresholds">
                <p class="name">{{ box.threshold }}%</p>
                <div class="list">
                  <div *ngFor="let curr of box.currency_prizes">
                    <m-ui-currency [item]="curr" [showTooltipExplanation]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="description">
            <div class="decorator"><p>Opis zadania:</p></div>
            <p class="description-text" [innerHTML]="state.data[state.currentIndex]?.description | safe: 'html'"></p>
          </div>
        </ow-perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
<ng-template #difficultyBox let-data="data" let-index="index">
  <div
    class="box {{ data?.color }}"
    (click)="voteMode(data)"
    [ngClass]="{
      votable: state.canBeVoted,
      'fade-out':
        (state.wonThreshold === null && !state.canBeVoted) ||
        (state.wonThreshold !== null && state.wonThreshold?.threshold_id !== data?.threshold_id) ||
        (state.difficultSelected !== null && state.difficultSelected?.threshold_id !== data?.threshold_id) ||
        (state.canBeVoted && state.playerVote !== null && state.playerVote?.threshold_id !== data?.threshold_id)
    }">
    <div class="info">
      <div class="header">
        <p class="name">{{ data.difficulty }} <span *ngIf="data?.has_won" class="green">✔</span></p>
        <div class="votes" *ngIf="data.vote_count">
          Głosów:
          <div class="round-button c-green round-reverse">
            <p>{{ data.vote_count }}</p>
          </div>
        </div>
      </div>
      <div class="award">
        <div class="objective">
          <p class="name">Cel:</p>
          <p class="value">{{ data.threshold }}%</p>
        </div>
        <div class="bonus">
          <p class="name">Bonus:</p>
          <div class="list">
            <div *ngFor="let curr of data.bonus_currency_prizes">
              <m-ui-currency [item]="curr" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
