<div class="wrapper">
  <loading></loading>

  <!-- LOGO -->
  <div class="logo"></div>

  <div class="content-wrapper">
    <span class="text-body">
      Kliknij zaloguj, a następnie wprowadź swoje dane do konta Microsoft Azure. Nie masz jeszcze konta? Wybierz opcję: Załóż konto
      Azure.<br />
      Jeśli masz pytania skontaktuj się z nami.
    </span>
    <span class="text-body error" *ngIf="error">{{ error }}</span>
    <button class="auth-button primary" (click)="login()">Zaloguj</button>
    <span class="text-body">Jesteś tutaj pierwszy raz?</span>
    <div class="buttons-group">
      <button class="auth-button primary">
        <a target="_blank" href="https://intranet.grupaeurocash.pl/Contents/ContentItems/4y5trmkvkcj6q5qxasjd67ffm2"> Załóż konto Azure </a>
      </button>
<!--      <button class="auth-button primary" (click)="landing()">Poznaj swoją misję w grze!</button>-->
      <button
        (click)="addApplicationToHomeScreen()"
        class="auth-button primary add-button"
        [class.add-button-show]="a2hsService.showAddToHomeScreenButton">
        Dodaj aplikację do ekranu
      </button>
      <button class="auth-button primary" (click)="report()">Napisz do nas</button>
    </div>
  </div>

  <div class="footer-wrapper">
    <span class="text-body"> {{ "global.helpdesk-email" | myTranslate }} </span>
  </div>
</div>
