import { TasksListComponent } from "../../base/dialogs/tasks-list/tasks-list.component";

export const EVENT_DIALOGS_NAMES_NEW_BUSINESS = {
  NEW_BUSINESS_PLAN_LIST: `new-business-plan-list`,
  NEW_BUSINESS_ACTION_LIST: `new-business-action-list`,
  NEW_BUSINESS_ACTION_ITEM: `new-business-action-item`,
  NEW_BUSINESS_CLIENT_LIST: `new-business-client-list`,
  NEW_BUSINESS_CHALLENGES_LIST: "new-business-challenges-list",
  NEW_BUSINESS_CHALLENGES_DETAILS: "new-business-challenges-details",
  NEW_BUSINESS_SHOP_LIST: `new-business-shop-list`,
  NEW_BUSINESS_PLAN_DETAILS: `new-business-plan-details`,
  NEW_BUSINESS_TASKS_LIST: `new-business-tasks-list`,
  NEW_BUSINESS_TASKS_DAILY: `new-business-tasks-daily`,
  NEW_BUSINESS_TASKS_WEEKLY: `new-business-tasks-weekly`,
  NEW_BUSINESS_CONTAINER: `new-business-container`,
};
