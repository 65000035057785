import { HudBusinessComponent } from "../../../../base/custom/components/hud-business/hud-business.component";
import { HudMenuComponent } from "../../../../base/custom/components/hud-menu/hud-menu.component";
import { HudParameters2Component } from "../../../../base/custom/components/hud-parameters/hud-parameters.component";
import { HudResourcesComponent } from "../../../../base/custom/components/hud-resources/hud-resources.component";

export const CUSTOM_DYNAMIC_COMPONENTS = {
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: "hudMenuTpl",
    data: context => {
      return {
        player: context["player"],
      };
    },
  },
  HUD_RESOURCES: {
    component: HudResourcesComponent,
    viewContainerRef: "hudResourcesTpl",
  },

  HUD_PARAMETERS: {
    component: HudParameters2Component,
    viewContainerRef: "hudParametersTpl",
  },
  HUD_BUSINESS: {
    component: HudBusinessComponent,
    viewContainerRef: "hudBusinessTpl",
  },
};
