<div class="top-left-container">
  <!-- HUD PLAYER LEVEL -->
  <app-hud-player-level></app-hud-player-level>

  <!-- HUD RESOURCES -->
  <div class="hud-resources">
    <template #hudResourcesTpl></template>
  </div>
  <!-- HUD PARAMETERS -->
  <div class="hud-business">
    <template #hudBusinessTpl></template>
  </div>
</div>

<div class="bottom-container">
    <div class="hud-parameters">
        <template #hudParametersTpl></template>
    </div>
</div>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>

<!-- HUD MENU -->
<div class="hud-menu">
  <template #hudMenuTpl></template>
</div>

<!-- OTHER DYNAMICS COMPONENT -->
<template #dynamicsComponentTpl></template>

<iframe *ngIf="window.location.host !== 'euromisja.pl'" src="https://czat.eurocash.o-w.dev"></iframe>
<iframe *ngIf="window.location.host === 'euromisja.pl'" src="https://czat.euromisja.pl"></iframe>
