import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../core/decorators/ow-inject.decorator";
import { updateMessageCount } from "../../store/chat/actions";
import { AppState } from "../../store/state";
import { ActionListComponent } from "../game/game-ui/business/base/dialogs/action-list/action-list.component";
import { PlayerService } from "../player/providers/player.service";
import { DialogService } from "../shared/providers/dialog.service";

@Component({
  standalone: true,
  selector: "app-actions",
  templateUrl: "./actions.component.html",
  styleUrls: ["./actions.component.scss"],
  imports: [NgIf],
})
export class ActionsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  subs = {
    player: null,
  };
  ngOnInit() {
    const playerId = this.playerService.getMePlayerId();
    if (playerId != null) {
      this.playerService.getPlayer(playerId).subscribe(res => {
        if (window["gtag"]) {
          window["gtag"]("event", "Akcje", {
            event_category: "Akcje",
            event_label: "Akcje",
            custom_dimension_hrid: res?.id,
            custom_dimension_warehouse_id: res?.branch_id,
          });
        }
      });
    }
    this.initChat();
    this.dialogService.open(ActionListComponent);
  }

  initChat() {
    window.addEventListener("message", e => {
      if (e.data.eventName === "startup") {
        const playerId = this.playerService.getMePlayerId();
        if (playerId != null) {
          this.playerService.getPlayer(playerId).subscribe(res => {
            const iframe = document.querySelector("iframe");
            if (iframe) {
              iframe.contentWindow.postMessage(
                {
                  externalCommand: "login-with-token",
                  token: res.chat_token,
                },
                "*"
              );
            }
          });
        }
      }
      if (e.data.eventName === "unread-changed") {
        return this.store.dispatch(updateMessageCount({ count: e.data.data }));
      }
    });
  }

  protected readonly window = window;
}
