import { Component, HostListener, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { mobileDetect } from "../../../../../../../core/providers/device.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { Action, ActionItemView } from "../../../interfaces/custom/actions.interface";

@Component({
  selector: "app-action-item",
  templateUrl: "./action-item.component.html",
  styleUrls: ["./action-item.component.scss"],
})
export class ActionItemComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data?: {
    action: Action;
  };
  @OwInject(DialogService) dialogService: DialogService;
  action: Action;
  actualView: ActionItemView = "form";
  switchOrientation: boolean = false;

  ngOnInit() {
    this.switchOrientationCheck();
    this.action = this.data.action;
    this.actualView = this.data.action.accepted_consents ? "blocks" : "form";
  }

  onBack() {
    if (this.actualView === "details" && this.action.accepted_consents) {
      this.actualView = "blocks";
    } else if (this.actualView === "details" && !this.action.accepted_consents) {
      this.actualView = "form";
    } else {
      this.dialogService.closeActive();
    }
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event) {
    this.switchOrientationCheck();
  }

  switchOrientationCheck() {
    const MobileDetect = require("mobile-detect");
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    const isMobile = mobileDetect.mobile() || this.checkSafariOnIpad();
    if (isMobile) {
      this.switchOrientation = window.orientation === -90 || window.orientation === 90;
    }
  }

  checkSafariOnIpad() {
    return mobileDetect["ua"].toLowerCase().includes("safari") && window.orientation !== undefined;
  }

  onDetails() {
    this.actualView = "details";
    this.action.blocks = this.action.blocks.map(block => ({
      ...block,
      active: false,
    }));
  }

  onConsentsAccepted() {
    this.action.accepted_consents = true;
  }

  activateFirstBlock() {
    this.action.blocks[0].active = true;
  }

  onOpenBlock(blockId: number) {
    this.action.blocks = this.action.blocks.map(block => ({
      ...block,
      active: block.id === blockId,
    }));
    this.action = R.clone(this.action);
    this.actualView = this.action.accepted_consents ? "blocks" : "form";
  }
}
