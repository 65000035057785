import { Injectable } from "@angular/core";
import AddToHomeScreen from "a2hs.js";
import { DeviceDetectorService } from "ngx-device-detector";

import { pwaImage } from "../../consts/custom/pwa-image.const";
@Injectable({ providedIn: "root" })
export class A2hsService {
  showAddToHomeScreenButton: boolean = false;
  deferredPrompt: any;

  constructor(private deviceService: DeviceDetectorService) {
  }

  addApplicationToHomeScreen() {
    if (this.deviceService.browser.toLowerCase() === "safari") {
      localStorage.removeItem("a2hs_message");
      if (document.querySelector(".a2hs__container") === null) {
        AddToHomeScreen({
          brandName: "Demo",
          fontFamily: "Tahoma, sans-serif",
          backgroundColor: "#00813D",
          color: "white",
          logoImage: pwaImage,
          htmlContent:
            "Zainstaluj <strong>Misja Eurocash </strong> na swoim iOS naciśnij <strong>Udostępnij</strong> następnie <strong>dodaj do ekranu początkowego</strong> ",
        });
      }
    } else {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        this.deferredPrompt = null;
      });
      this.showAddToHomeScreenButton = false;
    }
  }
  checkPWAIsAvailable(){
    window.addEventListener("beforeinstallprompt", deferredPrompt => {
      this.showAddToHomeScreenButton = true;
      deferredPrompt.preventDefault();
      this.deferredPrompt = deferredPrompt;
      if (!this.isMobile() && (this.isFireFox() || this.isSafari() || this.isOpera())) {
        this.showAddToHomeScreenButton = false;
      }
    });
    if (this.isSafariOnAppleMobileDevice() && !this.isEdge()) {
      this.showAddToHomeScreenButton = true;
    }
  }
  isMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /iphone|android|iPhone|Android|mobile/i.test(userAgent);
  }
  isSafari() {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      return true;
    }
  }
  isFireFox(): boolean {
    const userAgent = navigator.userAgent;
    return /firefox|fxios/i.test(userAgent);
  }
  isOpera(): boolean {
    const userAgent = navigator.userAgent;
    return userAgent.includes("OPR") || userAgent.includes("Opera");
  }
  isSafariOnAppleMobileDevice() {
    const userAgent = navigator.userAgent;
    const safariPattern = /Version\/[\d\.]+.*Mobile.*Safari.*/;
    const isAppleMobileDevice = /iPhone|iPad|iPod/.test(userAgent);
    return safariPattern.test(userAgent) && isAppleMobileDevice;
  }
  isEdge(): boolean {
    const userAgent = navigator.userAgent;
    return userAgent.includes("Edg");
  }
}
