import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalEvent } from "../../../../../../../../core/interfaces/shared.interfaces";
import { updateMessageCount } from "../../../../../../../../store/chat/actions";
import { UtilityActions } from "../../../../../../../../store/utility";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { MISSION_TYPE } from "../../../../../mission/consts/core";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../../mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_WAREHOUSE_CUSTOM } from "../../../../../warehouse/consts/custom/event-dialogs/event-names.const";
import { AbstractHudComponent } from "../../../../abstract/core/abstract-hud.component";

@Component({
  selector: "app-hud-custom",
  templateUrl: "./hud.component.html",
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  @ViewChild("hudBusinessTpl", { static: true, read: ViewContainerRef }) hudBusinessTpl: ViewContainerRef;
  @OwInject(PlayerService) playerService: PlayerService;

  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.setDynamicsComponents();
    this.sendGAEvent();
    this.initChat();
  }

  initChat() {
    window.addEventListener("message", e => {
      if (e.data.eventName === "startup") {
        const playerId = this.playerService.getMePlayerId();
        if (playerId != null) {
          this.playerService.getPlayer(playerId).subscribe(res => {
            const iframe = document.querySelector("iframe");
            if (iframe) {
              iframe.contentWindow.postMessage(
                {
                  externalCommand: "login-with-token",
                  token: res.chat_token,
                },
                "*"
              );
            }
          });
        }
      }
      if (e.data.eventName === "unread-changed") {
        return this.store.dispatch(updateMessageCount({ count: e.data.data }));
      }
    });
  }

  sendGAEvent() {
    if (window["gtag"]) {
      window["gtag"]("event", "Domyślna mapa gry", {
        event_category: "Domyślna mapa gry",
        event_label: "Domyślna mapa gry",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
      });
    }
  }

  protected readonly window = window;
}
