import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { AppState } from "../../../../../../../../store/state";
import { UtilityActions } from "../../../../../../../../store/utility";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { CenterMap } from "../../../../../../game-engine/interfaces/shared";
import { ApiMissionService } from "../../../../../mission/api/core/services/api-mission.service";
import { ApiMissionGroupService } from "../../../../../mission/api/core/services/api-mission-group.service";
import { MISSION_CONDITION_VALUE_TYPE } from "../../../../../mission/consts/core";
import {
  GroupMissionDetailsInterface,
  GroupMissionDetailsMissionInterface,
  GroupMissionInterface,
  MissionDetails,
} from "../../../../../mission/interfaces/core";

@Component({
  selector: "hud-star-missions",
  templateUrl: "./hud-star-missions.component.html",
})
export class HudStarMissionsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GlobalService) globalService: GlobalService;

  MISSION_CONDITION_VALUE_TYPE = MISSION_CONDITION_VALUE_TYPE;

  groupMissions: GroupMissionInterface[] = [];
  activeGroupMission: GroupMissionInterface;
  activeGroupMissionDetails: GroupMissionDetailsInterface;
  activeMission: GroupMissionDetailsMissionInterface;
  activeMissionDetails: MissionDetails;
  areConditionsComplete: boolean;
  isLoading: boolean;

  noMissionsForActiveGroup: boolean;
  allStarsCollected: boolean;

  ngOnInit() {
    this.getGroupMissions();
  }

  getGroupMissions() {
    this.isLoading = true;

    this.apiMissionGroupService.getMissionGroups().subscribe(
      groupMissions => {
        this.groupMissions = groupMissions;
        this.activeGroupMission = this.groupMissions.find(groupMission => !groupMission.is_reward_collected);
        this.allStarsCollected = this.groupMissions.every(m => m.is_reward_collected);

        if (this.activeGroupMission && !this.activeGroupMission.is_reward_collected && this.activeGroupMission?.total_missions === 0) {
          this.noMissionsForActiveGroup = true;
          this.isLoading = false;
          return;
        }

        if (
          this.activeGroupMission &&
          this.activeGroupMission?.completed_missions === this.activeGroupMission?.total_missions &&
          !this.activeGroupMission.is_reward_collected
        ) {
          this.apiMissionGroupService.getMissionGroupReward(this.activeGroupMission.mission_group_id).subscribe({
            next: () => {
              this.clearData();
              this.getGroupMissions();
              this.isLoading = false;
            },
            error: () => (this.isLoading = false),
          });
        } else if (this.activeGroupMission) {
          this.checkActiveGroupMission();
        } else {
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  clearData() {
    this.groupMissions = [];
    this.activeGroupMission = null;
    this.activeGroupMissionDetails = null;
    this.activeMission = null;
    this.activeMissionDetails = null;
    this.areConditionsComplete = false;
    this.noMissionsForActiveGroup = false;
    this.allStarsCollected = false;
  }

  checkActiveGroupMission() {
    this.apiMissionGroupService.getMissionGroupsDetails(this.activeGroupMission.mission_group_id).subscribe(groupMissionDetails => {
      this.activeGroupMissionDetails = groupMissionDetails;
      this.activeMission = this.activeGroupMissionDetails.missions.find(mission => !mission.is_collected);
      this.apiMissionService.getMissionDetails(this.activeMission.player_mission_id).subscribe(
        mission => {
          this.activeMissionDetails = mission;
          this.areConditionsComplete = this.activeMissionDetails.conditions.every(condition => condition.completed_at);
          this.dispatchRemoveMissionToOpen();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    });
  }

  dispatchRemoveMissionToOpen() {
    this.store.dispatch(
      new UtilityActions.RemoveMissionToOpen({
        player_mission_id: this.activeMissionDetails.player_mission_id,
      })
    );
  }

  changeMission(mission: GroupMissionDetailsMissionInterface) {
    this.activeMission = mission;
  }

  completeStage() {
    this.apiMissionService.missionCollect(this.activeMissionDetails.player_mission_id).subscribe(() => {
      this.getGroupMissions();
    });
  }

  showOnMap(centerMap: CenterMap) {
    this.globalService.globalEvents.emit({
      name: EVENTS.GAME.CENTER_TILE,
      value: centerMap,
    });

    this.dialogService.closeAll();
  }
}
