import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { getToken } from "../../../../../../core/utility/token";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { AbstractLoginComponent } from "../../../../abstract/abstract-login.component";
import { A2hsService } from "../../../../services/custom/a2hs.service";
import * as mobileDetect from "mobile-detect";

@Component({
  selector: "app-custom-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(A2hsService) a2hsService: A2hsService;
  device = null;
  error: string;

  ngOnInit() {
    this.device = new mobileDetect(window.navigator.userAgent);
    // window["deviceInfo"] = {
    //   full: this.device,
    //   os: this.device.os(),
    // };
    setTimeout(() => {
      this.a2hsService.checkPWAIsAvailable();
    });
    this.subscribeRoutingParams();
    if (getToken().token) {
      this.router.navigate(["/auth/player-choose"]);
    } else {
      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
  }

  login() {
    const rememberMe = this.form.value.remember_me;
    // #30406 - iOS
    if (this.device.os()?.toLowerCase().includes("android")) {
      this.globalService.askForNotificationPermission();
    }

    location.href = location.origin + `/auth/oauth2/login${rememberMe ? "?remember_me=1" : ""}`;
  }

  landing() {
    this.router.navigate(["/landing"]);
  }

  report() {
    this.router.navigate(["/report"]);
  }

  subscribeRoutingParams() {
    this.activatedRoute.queryParams.subscribe((data: { error: string }) => {
      if (data.hasOwnProperty("error")) {
        this.error = this.decodeBase64(data.error);
      }
    });
  }

  addApplicationToHomeScreen() {
    this.a2hsService.addApplicationToHomeScreen();
  }

  decodeBase64(string) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(string), c => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
