<div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" blockButton="TASKS">
        <ng-container left-top>
            <div class="business-button-round c-green">
                <p>Zadania</p>
            </div>
            <div class="month-selector">
                <button
                        class="arrow arrow-previous c-green"
                        (click)="prev()"
                        [class.button-disabled]="!paginationDate.allowPrev"
                        [disabled]="!paginationDate.allowPrev">
                    <i class="fas" [class.fa-chevron-left]="paginationDate.allowPrev"></i>
                </button>
                <div class="month c-green">
                    <p>{{ monthNames[momentDate.month()] + " | Tydzień " + momentDate.isoWeek() }}</p>
                </div>
                <button
                        class="arrow arrow-next c-green"
                        (click)="next()"
                        [class.button-disabled]="!paginationDate.allowNext"
                        [disabled]="!paginationDate.allowNext">
                    <i class="fas" [class.fa-chevron-right]="paginationDate.allowNext"></i>
                </button>
            </div>
        </ng-container>
    </app-business-navigation>

    <div class="content" *ngIf="!customLoading">
        <ow-perfect-scrollbar scrollGradient>
            <div class="tasks" *ngIf="cardTasks.length !== 0">
                <div
                        class="task"
                        *ngFor="let cardTask of cardTasks; let cardNumber = index"
                        (click)="openTasks(cardNumber)"
                        [class.top-border]="cardNumber === 0">
                    <ng-container *ngIf="cardTask.selected as selectedCard">
                        <ng-container
                                [ngTemplateOutlet]="titleTpl"
                                [ngTemplateOutletContext]="{ taskDefinition: selectedCard.task_definition }"></ng-container>

                        <ng-container
                                *ngIf="cardTask.card_type === 'daily'"
                                [ngTemplateOutlet]="cardContentDailyTpl"
                                [ngTemplateOutletContext]="{ cardTask: cardTask }"></ng-container>

                        <ng-container
                                *ngIf="cardTask.card_type !== 'daily'"
                                [ngTemplateOutlet]="cardContentDefaultTpl"
                                [ngTemplateOutletContext]="{ selectedCard: selectedCard }"></ng-container>

                        <ng-container [ngTemplateOutlet]="cardLabel" [ngTemplateOutletContext]="{ selectedCard: selectedCard }"></ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="tasks-empty" *ngIf="cardTasks.length === 0">
                <p>Brak zadań w wybranym okresie.</p>
            </div>
        </ow-perfect-scrollbar>
    </div>
</div>

<ng-template #titleTpl let-taskDefinition="taskDefinition">
    <!-- TITLE -->
    <ng-container *ngIf="taskDefinition.partner_logo | asset: 'vendors'; else titleTpl">
        <div class="logo">
            <img [src]="taskDefinition.partner_logo | asset: 'vendors'" />
        </div>
    </ng-container>
    <ng-template #titleTpl>
        <div class="title">
            <p>{{ taskDefinition.name }}</p>
        </div>
    </ng-template>
</ng-template>

<!-- CARD CONTENT DEFAULT TPL -->
<ng-template #cardContentDefaultTpl let-selectedCard="selectedCard">
    <div class="progress" *ngIf="selectedCard.progress">
        <div class="info">
            <p  [matTooltip]="'Wyniki z dnia:'" matTooltipPosition="left" matTooltipShowDelay="300">
                <ng-container *ngIf="selectedCard?.last_import_date">
                    {{ moment(selectedCard.last_import_date).format("DD") + "." + moment(selectedCard.last_import_date).format("MM") }}
                </ng-container>
            </p>
            <p *ngIf="selectedCard.progress?.valuePercentage"  [matTooltip]="'Realizacja w procentach'" matTooltipPosition="right" matTooltipShowDelay="300">
                {{ selectedCard.progress.valuePercentage }}
            </p>
        </div>
        <div class="bar">
            <div class="fill" [ngStyle]="{ width: selectedCard.progress.valuePercentage }"></div>
        </div>
    </div>
    <ng-container *ngIf="!selectedCard.is_cleared">
        <ng-container *ngIf="selectedCard.baseScope as scope; else emptyPrizeSpaceTpl">
            <div class="currencies">
                <div class="currency" *ngFor="let currency of scope.currency_prizes">
                    <div class="description">
                        <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                        <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                        <p class="value" *ngIf="selectedCard.is_cleared">{{ currency.finalAmount ?? currency.amount }}</p>
                    </div>
                    <div class="prizes">
                        <p class="max-amount">100%: {{ currency.amount }}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedCard.is_cleared">
        <ng-container *ngIf="selectedCard.realizationScope as scope; else emptyPrizeSpaceTpl">
            <div class="currencies">
                <div class="currency" *ngFor="let currency of scope.currency_prizes">
                    <div class="description">
                        <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                        <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                        <p class="value" *ngIf="selectedCard.is_cleared">{{ currency.finalAmount ?? currency.amount }}</p>
                    </div>
                    <div class="prizes">
                        <ng-container *ngFor="let baseCurrency of selectedCard.baseScope.currency_prizes">
                            <ng-container *ngIf="currency.currency_id === baseCurrency.currency_id">
                                <p class="max-amount">100%: {{ baseCurrency.amount }}</p>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #emptyPrizeSpaceTpl>
        <ng-container *ngIf="selectedCard.baseScope as scope">
            <div class="currencies">
                <div class="currency" *ngFor="let currency of scope.currency_prizes">
                    <div class="description">
                        <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                        <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                        <p class="value" *ngIf="selectedCard.is_cleared">0</p>
                    </div>
                    <div class="prizes">
                        <p class="max-amount">100%: {{ currency.amount }}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <!--    <ng-container [ngTemplateOutlet]="parametrizeTpl"></ng-container>-->
</ng-template>

<!-- CARD CONTENT DAILY TPL -->
<ng-template #cardContentDailyTpl let-cardTask="cardTask">
    <div class="day">
        <p>
            Rozliczony dzień:
            {{
                cardTask.dailyHelper.lastCalculation
                    ? moment(cardTask.dailyHelper.lastCalculation.date).format("DD") +
                    "." +
                    moment(cardTask.dailyHelper.lastCalculation.date).format("MM")
                    : ""
            }}
        </p>
    </div>
    <ng-container *ngIf="cardTask.dailyHelper.sumResourceScopes as sumResourceScopes">
        <div class="currencies">
            <div class="currency" *ngFor="let currency of sumResourceScopes.currencies">
                <div class="description">
                    <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                    <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                    <p class="value">{{ currency.finalAmount ?? currency.amount ?? 0 }}</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="cardTask.dailyHelper.baseScope as baseScope">
        <div class="currencies">
            <div class="currency" *ngFor="let currency of baseScope.currencies">
                <div class="description">
                    <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                    <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                    <p class="value">{{ currency.finalAmount ?? currency.amount ?? 0 }}</p>
                </div>
                <!--       <div class="prizes">-->
                <!--         <p  class="max-amount">100%: {{currency.amount}}</p>-->
                <!--       </div>-->
            </div>
        </div>
    </ng-container>
    <ng-container
            *ngIf="
      cardTask.selected.is_cleared && cardTask.selected && !(cardTask.dailyHelper.sumResourceScopes || cardTask.dailyHelper.baseScope)
    ">
        <div class="currencies">
            <ng-container *ngIf="cardTask.selected?.base_currency_prizes.length > 0; else noPrize">
                <div class="currency" *ngFor="let currency of cardTask.selected.base_currency_prizes">
                    <div class="description">
                        <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                        <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                        <p class="value" *ngIf="cardTask.selected.is_cleared">{{ currency.finalAmount ?? currency.amount }}</p>
                    </div>
                </div>
            </ng-container>
            <ng-template #noPrize>
                <div class="currency" *ngFor="let currency of cardTask.selected.scopes[0].currency_prizes">
                    <div class="description">
                        <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                        <p class="name">{{ currencyDefinitions[currency.currency_id].name }}:</p>
                        <p class="value" *ngIf="cardTask.selected.is_cleared">{{ currency.finalAmount ?? currency.amount }}</p>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
</ng-template>
<!--      CARD CONTENT LABEL-->
<ng-template #cardLabel let-selectedCard="selectedCard">
    <ng-container
            *ngIf="
      selectedCard.is_cleared &&
      (selectedCard.task_type === TASK_TYPE.WEEKLY ||
        selectedCard.task_type === TASK_TYPE.BI_WEEKLY ||
        selectedCard.task_type === TASK_TYPE.MONTHLY ||
        selectedCard.task_type === TASK_TYPE.OPEARATION_WEEKLY)
    ">
        <div class="badge {{ 'green' }}">
            {{ "Rozliczone" }}
        </div>
    </ng-container>

    <ng-container *ngIf="(selectedCard.is_cleared && selectedCard.task_type === TASK_TYPE.DAILY)">
        <div class="badge {{ 'green' }}">
            {{ "Otrzymane nagrody w ramach tygodnia" }}
        </div>
    </ng-container>
</ng-template>
