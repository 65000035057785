import "phaser";

import { select } from "@ngrx/store";
import { filter, mergeMap, take } from "rxjs/operators";

import { GameState } from "../../../../../store/game";
import { selectGame } from "../../../../../store/game/selectors";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { CustomMainScene } from "../custom/CustomMain.scene";
import { CustomPreloadScene } from "../custom/CustomPreload.scene";
import { MAIN_BOOT_SCENE, MAIN_MAIN_SCENE, MAIN_PRELOAD_SCENE } from "../main.constants";

export class BootScene extends MyScene {
  constructor() {
    super({
      key: MAIN_BOOT_SCENE,
    });
  }

  init(data?: any) {
    this.passedData = data;
  }

  preload(): void {
    super.preload();
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);

    // this.gameService.store.dispatch(new ResetBoard());
    // this.gameService.store.dispatch(new FetchBoard({playerId: this.gameService.playerService.player.id}));

    if (!this.scene.get(MAIN_PRELOAD_SCENE)) {
      this.scene.add(MAIN_PRELOAD_SCENE, CustomPreloadScene, false);
      this.scene.add(MAIN_MAIN_SCENE, CustomMainScene, false);
    }
  }

  create(): void {
    this.gameService.store
      .pipe(
        select(selectGame),
        filter(game => game.boardState.loaded),
        take(1)
      )
      .subscribe((game: GameState) => {
        const assetsToPreload = this.gameService.buildingsService.getBuildingsAssetsNamesFromBoard(game.boardState.board);
        this.scene.start(MAIN_PRELOAD_SCENE, {
          assetsToPreload,
          ...this.passedData,
        });
      });
  }
}
