<loading></loading>

<div class="action-details-wrapper">
  <div class="rules" *ngIf="details?.description">
    <div class="content">
      <div [outerHTML]="details?.description"></div>
    </div>
  </div>
  <div *ngIf="details?.files?.length !== 0" class="files">
    <div class="title">Pliki do pobrania</div>
    <div class="content">
      <div *ngFor="let file of details?.files" class="file">
        <i class="fa-regular fa-folder-arrow-down"></i><a [href]="file.file_url" target="_blank"> {{ file.name }} </a>
      </div>
    </div>
  </div>
</div>
