<div class="global-dialog ow-dialog window-a primary no-transparent">
  <div class="hud-tabs">
    <div
      (click)="changeTab(TABS.MISSION_STARS)"
      class="tab"
      [class.active-tab]="activeTab === TABS.MISSION_STARS"
    >
      Misje
    </div>

    <div
      (click)="changeTab(TABS.PLAYER_PROFILE)"
      class="tab"
      [class.active-tab]="activeTab === TABS.PLAYER_PROFILE"
    >
      Profil
    </div>
  </div>

  <ng-container [ngSwitch]="activeTab">
    <hud-player-profile-details *ngSwitchCase="TABS.PLAYER_PROFILE"></hud-player-profile-details>
    <hud-star-missions *ngSwitchCase="TABS.MISSION_STARS"></hud-star-missions>
  </ng-container>
</div>
