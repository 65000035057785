<div class="global-dialog ow-dialog window-a primary">
  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <loading></loading>

  <!-- DIALOG TITLE -->
  <div class="top-bar">
    <div class="title">Twoja karta przedpłacona Edenred</div>
  </div>

  <!-- CONTENT -->
  <ng-container *ngIf="isActive; then details; else form"></ng-container>

  <!-- BUTTON HAND OVER -->
  <div class="bottom-bar">
    <div class="bar-button base">
      <button class="base primary" (click)="openTransactionHistory()">Historia kart i zasileń</button>
    </div>
    <div class="bar-button block" *ngIf="isActive">
      <button class="base primary red" (click)="openBlockCard()">Zablokuj kartę</button>
    </div>
  </div>
</div>

<ng-template #form>
  <div class="form" *ngIf="isActive !== undefined">
    <p>
      Nie masz aktywnej karty Edenred.<br /><br />
      Wpisz poniżej nr seryjny karty, aby móc otrzymywać nagrody.<br />
      Nr seryjny to 10 cyfr, które znajdują się nad paskiem magnetycznym. Możesz mieć aktywną tylko jedną kartę.
    </p>
    <form [formGroup]="formGroup">
      <label>Nr seryjny:</label>
      <div>
        <input type="text" formControlName="key" disabled />
        <button class="base primary" [disabled]="formGroup.invalid || isLoading" (click)="submit()">Zapisz</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #details>
  <div class="details" *ngIf="isActive !== undefined">
    <p class="description">
      Poniżej znajduje się nr seryjny karty, na którą wypłacane będą Twoje nagrody.<br />
      Jeśli zgubiłeś kartę, skontaktuj się z Edenred w celu otrzymania duplikatu karty: email:
      <a href="mailto:kontakt-pl@edenred.com">kontakt-pl&#64;edenred.com</a>, tel. 22 481 39 08
    </p>
    <div *ngFor="let activeCard of activeCards" class="card-details">
      <p>
        Nr seryjny karty: <span class="card-number">{{ activeCard.number }}</span>
      </p>
      <p class="status {{ activeCard.status === CardStatus.Active ? 'active' : 'inactive' }}">
        karta {{ activeCard.status === CardStatus.Active ? "aktywna" : "nieaktywna" }}
      </p>
    </div>
  </div>
</ng-template>
