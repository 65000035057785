import { CardTask } from "../../interfaces/custom/card-task.interface";
import { setBaseScopeHelper } from "./set-base-scope.helper";
import { setDailyHelper } from "./set-daily.helper";
import { setProgressbarHelper } from "./set-progressbar.helper";
import { setRealizationScopeHelper } from "./set-realization-scope.helper";

import { Task } from "../../interfaces/custom/task.interface";

export function prepareCardTask(cardTasks: CardTask[]): CardTask[] {
  return cardTasks.map(cardTask => {
    if (cardTask.selected) {
      taskAssignment(cardTask.selected);
    }
    if (cardTask.other_tasks) {
      cardTask.other_tasks.forEach(task => {
        taskAssignment(task);
      });
    }
    cardTask = setDailyHelper(cardTask);
    return cardTask;
  });
}

function taskAssignment(task: Task) {
  task.baseScope = setBaseScopeHelper(task);
  task.progress = setProgressbarHelper(task);
  task.realizationScope = setRealizationScopeHelper(task);
}
