<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">Zaproś innego gracza</div>
  </div>

  <!-- DESCRIPTION -->
  <div class="description">
    <p>
      Zaproś nowego gracza i poproś, aby podał Twój kod rekrutacyjny. Kiedy nowy gracz wejdzie na 10 poziom, otrzymasz nagrodę! Możesz
      otrzymać nagrodę aż za 3 zrekrutowanych graczy. <br />
      <span>Uwaga: Nowy gracz również zostanie nagrodzony za podanie Twojego kodu!</span>
    </p>
  </div>

  <!-- CODE -->
  <div class="player-code loading-opacity">
    <span>Twój kod rekrutacyjny:</span>
    <span class="code">{{ userCode }}</span>
  </div>

  <div class="prizes-list loading-opacity">
    <p>Nagrody za osiągnięcie danego poziomu przez gracza:</p>

    <!-- PRIZES TABLE -->
    <div class="thead">
      <div class="tr">
        <div class="th t-1">Poziom</div>
        <div class="th t-2">Wartość nagrody</div>
        <div class="th t-3">Ilość graczy</div>
      </div>
    </div>
    <div class="tbody">
      <ow-perfect-scrollbar scrollGradient *ngIf="inviterPrizes">
        <div class="tr" *ngFor="let invitee of inviterPrizes">
          <div class="td t-1">{{ invitee.level }}</div>
          <div class="td t-2">
            {{ invitee.amount }}
            <img class="img-currency-small" [src]="'money.png' | asset: 'currency/small'" />
          </div>
          <div class="td t-3">{{ invitee.received_prizes }}/{{ invitee.max_prizes }}</div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>

  <div class="player-prize">
    <div class="prize-box">
      <div class="s1">Suma zdobytych nagród:</div>
      <div class="s2">
        <img class="img-currency-big" [src]="'money.png' | asset: 'currency/big'" />
        {{ maxPrize || 0 }}
      </div>
    </div>
  </div>

  <div *ngIf="!playerCodeActive" class="bottom-bar">
    <div class="bar-button base">
      <button class="base primary code" (click)="openMgmInvitee()">Wpisz kod rekrutacyjny</button>
    </div>
  </div>
</div>
