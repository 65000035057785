<div class="global-window">
  <loading></loading>

  <!-- DIALOG EXIT -->
<!--  <m-ui-close-button></m-ui-close-button>-->

  <div class="component">
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
  </div>



</div>
