import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { GAME_CONFIG } from "../../../../../../../../../core/config/custom/_parsed-game.config";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { LEVEL_FILTER_KEY } from "../../../../../consts/custom/level-filter-key.const";

@Component({
  selector: "ranking-game-table",
  templateUrl: "./ranking-game-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingGameTableComponent {
  @Input() rankResults;
  @Input() rank;
  @Input() columns;
  @Input() show;
  @Input() activeFilter;
  @Input() highLightTrIdx;

  STOCK_VIEW = STOCK_VIEW;
  LEVEL_FILTER_KEY = LEVEL_FILTER_KEY;
  GAME_CONFIG = GAME_CONFIG;
}
