import { Component, OnDestroy, OnInit } from "@angular/core";
import * as R from "ramda";
import { filter } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { selectCurrentScene } from "../../../../../../../../store/game/selectors";
import { AbstractHudResourcesComponent } from "../../../../abstract/core/abstract-hud-resources.component";
import { GuiItem } from "../../../../interfaces/core/gui-item.interface";
import { GlobalEvent } from "../../../../../../../../core/interfaces/shared.interfaces";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";

@Component({
  selector: "app-hud-resources-custom",
  templateUrl: "./hud-resources.component.html",
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeUser();
    this.setParameters();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
