import { Component, Input } from "@angular/core";

@Component({
  selector: "app-generic-device-rotate",
  templateUrl: "./generic-device-rotate.component.html",
  styleUrls: ["./generic-device-rotate.component.scss"],
})
export class GenericDeviceRotateComponent {
  @Input() orientation: "landscape" | "portrait";
}
