import { Component, HostListener, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { mobileDetect } from "../../../../../../../core/providers/device.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { Action } from "../../../interfaces/custom/actions.interface";
import { ActionItemComponent } from "../action-item/action-item.component";

@Component({
  selector: "app-action-list",
  templateUrl: "./action-list.component.html",
  styleUrls: ["./action-list.component.scss"],
})
export class ActionListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  actionsList: Action[];
  loading = false;
  switchOrientation: boolean = false;

  ngOnInit() {
    this.switchOrientationCheck();
    this.fetchActions();

    if (window["gtag"]) {
      window["gtag"]("Lista akcji", {
        event_category: "Lista akcji",
        event_label: "Lista akcji",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
      });
    }
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event) {
    this.switchOrientationCheck();
  }

  switchOrientationCheck() {
    const MobileDetect = require("mobile-detect");
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    const isMobile = mobileDetect.mobile() || this.checkSafariOnIpad();
    if (isMobile) {
      this.switchOrientation = window.orientation === -90 || window.orientation === 90;
    }
  }

  checkSafariOnIpad() {
    return mobileDetect["ua"].toLowerCase().includes("safari") && window.orientation !== undefined;
  }

  fetchActions() {
    this.loading = true;

    this.businessService.getActionsList().subscribe(data => {
      this.actionsList = data;
      this.loading = false;
    });
  }

  redirectToItem(action: Action) {
    this.dialogService.open(ActionItemComponent, { data: { action: action } });
  }
}
