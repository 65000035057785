import { Component, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OwPerfectScrollbarComponent } from "@oskarwegner/ow-perfect-scrollbar";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { Challenge, ChallengeThreshold } from "../../../interfaces/custom/challenges.interface";
import { BadgeColors } from "../../../interfaces/custom/common-business.interface";
import { ClientListComponent } from "../client-list/client-list.component";

type State = {
  currentIndex: number;
  data: {
    [key: number]: Challenge;
  };
  difficultyBoxes: ChallengeThreshold[];
  voteMode: boolean; // voting screen
  canBeVoted: boolean; // blocks voting (after date expired of already voted)
  difficultSelected: ChallengeThreshold;
  playerVote: ChallengeThreshold;
  loading: boolean;
  wonThreshold: ChallengeThreshold;
};

@Component({
  selector: "app-challenges-details",
  templateUrl: "./challenges-details.component.html",
  styleUrls: ["./challenges-details.component.scss"],
})
export class ChallengesDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data;
  @OwInject(PlayerService) playerService: PlayerService;
  @ViewChild("scrollbar", { static: false }) perfectScrollbar: OwPerfectScrollbarComponent;
  imagePath = window.location.origin.includes("localhost") ? "https://eurocash4.o-w.dev/" : window.location.origin;
  state: State = {
    currentIndex: null, // iterate through the array of challenges
    data: {},
    difficultyBoxes: [],
    voteMode: false,
    canBeVoted: true,
    difficultSelected: null,
    loading: false,
    playerVote: null,
    wonThreshold: null,
  };
  difficultyLevels: string[] = ["łatwy", "średni", "trudny"];
  difficultyColors = [BadgeColors.green, BadgeColors.yellow, BadgeColors.red];

  ngOnInit() {
    this.getChallengeDetails(this.data.index);
  }

  getChallengeDetails(index: number) {
    this.state.loading = true;
    this.businessService.getChallengeDetails(this.data.challenges[index].player_challenge_id).subscribe((res: Challenge) => {
      this.state.data[index] = res;
      this.state.currentIndex = index;
      this.refreshState();
      this.state.loading = false;
    });
  }

  refreshState() {
    this.state.difficultSelected = null;
    this.state.voteMode = false;
    this.generateChallengeBoxes();
    this.registerGTAGEvent();

    this.state.playerVote = this.state.difficultyBoxes.find(x => x.is_chosen) || null;

    // check - can be voted
    const daysDiff = moment(this.state.data[this.state.currentIndex].vote_end_date).diff(moment().startOf("day"), "days");
    this.state.wonThreshold = this.state.data[this.state.currentIndex].thresholds.find(x => x.has_won) || null;
    this.state.canBeVoted = this.state.wonThreshold == null && daysDiff > 0 && !this.state.playerVote;

    if (!this.state.canBeVoted) {
      // set proper box selection - based on vote
      if (this.state.difficultSelected != null) {
        this.state.difficultSelected = this.state.data[this.state.currentIndex].thresholds.find(x => x.is_chosen);
      }
    }
  }

  registerGTAGEvent() {
    if (window["gtag"]) {
      window["gtag"]("event", "Szczegóły wyzwania", {
        event_category: "Szczegóły wyzwania",
        event_label: "Szczegóły wyzwania",
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
        custom_dimension_challenge_id: this.state.data[this.state.currentIndex].player_challenge_id,
      });
    }
  }

  generateChallengeBoxes() {
    this.state.difficultyBoxes = [];
    this.state.difficultyBoxes = this.state.data[this.state.currentIndex].thresholds.filter(x => x.bonus_currency_prizes.length);
    this.state.difficultyBoxes.forEach((el, index) => {
      el.color = this.difficultyColors[index];
      el.difficulty = this.difficultyLevels[index];
    });
  }

  changeChallenge(action: "PREVIOUS" | "NEXT") {
    let index = null;
    switch (action) {
      case "PREVIOUS": {
        index = this.state.currentIndex === 0 ? this.data.challenges.length - 1 : this.state.currentIndex - 1;
        break;
      }
      case "NEXT": {
        index = this.state.currentIndex === this.data.challenges.length - 1 ? 0 : this.state.currentIndex + 1;
        break;
      }
    }

    if (this.state.data[index] === undefined) {
      this.getChallengeDetails(index);
    } else {
      this.state.currentIndex = index;
      this.refreshState();
    }
  }

  voteMode(threshold: ChallengeThreshold) {
    if (!this.state.canBeVoted) {
      return;
    }

    if (!this.state.voteMode) {
      // check current scrollbar position if this is the very beginning of the container
      if (this.perfectScrollbar.directiveRef.position().y === "start") {
        this.state.voteMode = true;
      } else {
        // if not, scroll to the top
        this.perfectScrollbar.directiveRef.scrollToTop(0, 50);

        setTimeout(() => {
          this.state.voteMode = true;
        }, 200);
      }
    }

    this.state.difficultSelected = threshold;
  }

  cancelVote() {
    if (!this.state.voteMode) {
      return;
    }

    this.state.voteMode = false;
    this.state.difficultSelected = null;
  }

  submitVote() {
    if (!this.state.voteMode) {
      return;
    }

    this.state.loading = true;
    this.businessService.voteForChallenge(this.state.difficultSelected.threshold_id).subscribe(() => {
      this.state.difficultSelected.vote_count++;
      this.state.difficultSelected.is_chosen = true;
      this.state.playerVote = this.state.difficultSelected;

      this.state.canBeVoted = false;
      this.state.voteMode = false;
      this.state.loading = false;
    });
  }

  openClientList() {
    if (!this.state.data[this.state.currentIndex]?.client_realizations?.length) {
      return false;
    }

    this.dialogService.open(ClientListComponent, {
      data: {
        clientList: this.state.data[this.state.currentIndex],
        badge: this.data.challenges[this.state.currentIndex].badge,
        listType: "CHALLENGES",
      },
    });
  }

  closeChallenges() {
    this.dialogService.closeActive();
  }

  markAsInvalid(el: Challenge) {
    el.logo = null;
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
  protected readonly open = open;
}
