import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { CardStatus, GetEdenredCardListResponse } from "../../api/interfaces/get-edenred-card-list-response.interface";
import { ApiEdenredService } from "../../api/services/api-edenred.service";
import { PrepaidCardsListComponent } from "../prepaid-cards-list/prepaid-cards-list.component";

@Component({
  selector: "app-prepaid-card-dashboard",
  standalone: true,
  imports: [CommonModule, SharedModule, SharedUiMobileModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./prepaid-card-dashboard.component.html",
  styleUrls: ["./prepaid-card-dashboard.component.scss"],
})
export class PrepaidCardDashboardComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiEdenredService) apiEdenredService: ApiEdenredService;
  isActive = undefined;
  activeCards: GetEdenredCardListResponse[] = [];
  cards: GetEdenredCardListResponse[] = [];
  formGroup: FormGroup;
  isLoading = false;

  ngOnInit() {
    this.formGroup = new FormGroup({
      key: new FormControl(null, [Validators.minLength(10), Validators.maxLength(10), Validators.required]),
    });

    this.fetchCardList();
  }

  fetchCardList() {
    this.apiEdenredService.getEdenredCardList().subscribe(response => {
      this.cards = response;
      this.activeCards = response.filter(card => card.status === CardStatus.Active);
      this.isActive = this.activeCards && this.activeCards.length > 0;
    });
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      return;
    }

    this.apiEdenredService.activateEdenredCard({ number: this?.formGroup?.controls?.key?.value }).subscribe(() => {
      this.fetchCardList();
    });
  }

  openBlockCard() {
    this.dialogService.openConfirm(
      {
        title: "Uwaga!",
        description: "Czy na pewno chcesz zablokować kartę?",
        buttonClose: {
          label: "Anuluj",
          colorSchema: "red",
        },
        buttonSuccess: {
          label: "Zablokuj",
          colorSchema: "primary",
        },
      },
      confirm => {
        if (confirm) {
          this.apiEdenredService.blockEdenredCard({ number: this.activeCards[0].number }).subscribe(() => {
            this.fetchCardList();
          });
        }
      }
    );
  }

  openTransactionHistory() {
    this.dialogService.open(PrepaidCardsListComponent, { data: { cards: this.cards } });
  }

  protected readonly CardStatus = CardStatus;
}
