import { ApiService } from "../../../../../../core/providers/api.service";
import { GetEdenredCardHistoryRequest } from "../interfaces/get-edenred-card-history-request.interface";
import { ActivateEdenredCardRequest } from "../interfaces/activate-edenred-card-request.interface";
import { BlockEdenredCardRequest } from "../interfaces/block-edenred-card-request.interface";
import { GetEdenredCardHistoryResponse } from "../interfaces/get-edenred-card-history-response.interface";
import { Observable } from "rxjs";
import { GetEdenredCardListResponse } from "../interfaces/get-edenred-card-list-response.interface";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApiEdenredService {
  constructor(public apiService: ApiService) {}

  getEdenredCardList(): Observable<GetEdenredCardListResponse[]> {
    return this.apiService.get("edenred/cards");
  }

  getEdenredCardHistory(card_id: GetEdenredCardListResponse["card_id"]): Observable<GetEdenredCardHistoryResponse[]> {
    return this.apiService.get(`edenred/cards/${card_id}/topups`);
  }

  activateEdenredCard({ number }: ActivateEdenredCardRequest) {
    return this.apiService.patch(`edenred/cards/${number}/activate`);
  }

  blockEdenredCard({ number }: BlockEdenredCardRequest) {
    return this.apiService.patch(`edenred/cards/${number}/block`);
  }
}
