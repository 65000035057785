import { Injectable } from "@angular/core";
import * as R from "ramda";

import { PlayerService } from "../../../player/providers/player.service";
import { CUSTOM_MENU_BUTTONS_CONFIG } from "../../game-ui/hud/hud-menu-buttons/const/custom/menu-buttons-custom.const";
import {
  ButtonMenuConfigFilter,
  MappedMenuButtonConfig,
  MenuButtonConfig,
} from "../../game-ui/hud/hud-menu-buttons/interfaces/core/menu-buttonts-config.interface";

@Injectable({
  providedIn: "root",
})
export class MenuButtonsConfigService {
  buttons: MenuButtonConfig[] = [];

  constructor(private playerService: PlayerService) {
    this.setButtons(CUSTOM_MENU_BUTTONS_CONFIG);
  }

  setButtons(x: MappedMenuButtonConfig[]) {
    this.buttons = x;
  }

  getButtonsMenuConfig(config: ButtonMenuConfigFilter): MappedMenuButtonConfig[] {
    let buttons = R.clone(this.buttons)
      .filter(button => button.place === config.place)
      .filter(button => button.gui_unlocks_button === null || config.gui_unlocks[button.gui_unlocks_button])
      .map(button => (button.group_name ? button : { ...button, isVisible: true }));
    buttons = R.sortBy(R.prop("order"))(buttons);
    buttons = buttons.reduce((result, button) => {
      if (button.type === "group") {
        if (config.isDialog) {
          return result;
        }

        let groupButtons = buttons.filter(btn => btn.group_name === button.name);
        groupButtons = groupButtons.filter(b => b.gui_unlocks_button !== config.sceneConfig.gui_unlocks_button);

        switch (button.group_variant) {
          case 2:
            if (groupButtons.length === 1) {
              result.push({ ...groupButtons[0], isVisible: true });
            } else if (groupButtons.length > 1) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
          default:
            if (groupButtons.length > 0) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
        }
      } else if (!button.group_name || config.isDialog) {
        result.push(button);
      }
      return result;
    }, []);
    return buttons;
  }
}
