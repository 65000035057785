<!-- SCREEN LOCK IF POSITION = LANDSCAPE -->
<div id="rotate" [ngClass]="orientation">
  <div class="flex-center">
    <i class="fas fa-mobile-alt mobile-icon"></i>
    <p *ngIf="orientation === 'landscape'" class="land-title">
      {{ "device-rotate.title" | myTranslate }}
    </p>
    <p *ngIf="orientation === 'landscape'" class="land-description">
      {{ "device-rotate.description" | myTranslate }}
    </p>
    <p *ngIf="orientation === 'portrait'" class="land-title">Zmień orientację urządzenia na pion</p>
    <p *ngIf="orientation === 'portrait'" class="land-description">Obróć urządzenie o 90 stopni, by poprawnie wyświetlić aplikację.</p>
  </div>
</div>
