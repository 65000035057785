<div class="hud-resources-container">
  <div class="top">
    <div class="hud-button" (click)="open('PLANS')">
      <button>
        <i class="fa-light fa-square-list"></i>
        <p>Plany</p>
      </button>
    </div>
    <div class="hud-button" (click)="open('CHALLENGES')">
      <button>
        <i class="fa-regular fa-shield-check"></i>
        <p>Wyzwania</p>
      </button>
    </div>
    <div *ngIf="[2, 3, 4, 5].includes(playerService.player.title_id.id)" class="hud-button" (click)="open('TASKS')">
      <button>
        <i class="fa-solid fa-list-check"></i>
        <p>Zadania</p>
      </button>
    </div>
  </div>
  <div class="bottom">
    <div class="hud-button" (click)="open('ACTIONS')">
      <button>
        <i class="fa-regular fa-bullseye-pointer"></i>
        <p>Akcje</p>
      </button>
    </div>
    <div class="hud-button active" (click)="open('GAME')">
      <button>
        <i class="fa-regular fa-gamepad-modern"></i>
        <p>Gra</p>
      </button>
    </div>
    <div class="hud-button" (click)="open('CHAT')">
      <button>
        <i class="fa-regular fa-comment"></i>
        <p>Chat</p>
        <span *ngIf="messageCount$ | async" class="message-count">{{ messageCount$ | async }}</span>
      </button>
    </div>
  </div>
</div>
