<app-device-rotate></app-device-rotate>
<div class="global-dialog ow-dialog window-a primary no-transparent">
  <div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" blockButton="PLANS">
      <ng-container left-top>
        <button class="business-button-round fixed c-green" (click)="backToPlansList()">
          <i class="far fa-arrow-to-top"></i>
        </button>
      </ng-container>
      <ng-container left-bottom>
        <button class="business-button-round fixed c-green" (click)="openClientList()" *ngIf="currentPlan?.client_realizations?.length">
          <i class="far fa-clipboard-list"></i>
        </button>
      </ng-container>
    </app-business-navigation>
    <div class="content">
      <div class="swiper-navigation">
        <i class="far fa-chevron-square-left left arrow-pagination" (click)="prevPlan()"></i>
        <i class="far fa-chevron-square-right right arrow-pagination" (click)="nextPlan()"></i>
      </div>
      <ng-container [ngTemplateOutlet]="PlanDetails"></ng-container>
    </div>
  </div>
</div>
<ng-template #PlanDetails>
  <div class="plan-details" *ngIf="currentPlan">
    <div class="title-container">
      <div class="logo">
        <img
          *ngIf="currentPlan.logo"
          [title]="currentPlan.name"
          [alt]="currentPlan.name"
          src="{{ imagePath }}/{{ currentPlan.logo }}"
          (error)="markAsInvalid(currentPlan)" />
        <div *ngIf="!currentPlan.logo" class="company-name" title="{{ currentPlan.name }}">
          <p>{{ currentPlan.name }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="data.plans[indexPlansList]?.badge?.description" class="badge {{ data.plans[indexPlansList]?.badge.color }}">
      <p>{{ data.plans[indexPlansList]?.badge?.title + " " + data.plans[indexPlansList]?.badge?.description}}</p>
    </div>
    <ow-perfect-scrollbar scrollGradient>
      <div class="activity-name" [innerHTML]="currentPlan?.logo ? currentPlan.name : ''"></div>

      <div class="progress">
        <p class="percentage" [ngStyle]="{ left: (currentPlan?.realization * 100 <= 95 ? currentPlan?.realization * 100 : 95) + '%' }">
          {{ currentPlan?.realization * 100 || 0 | toFixed: 0 : "down" }}%
        </p>
        <div class="bar">
          <div class="fill" [ngStyle]="{ width: currentPlan?.realization * 100 + '%' }"></div>
        </div>
        <div class="info">
          <div class="left">
            <p>Daty: {{ currentPlan.task_start_date | date: "dd.MM" }} - {{ currentPlan.task_end_date | date: "dd.MM" }}</p>
            <p *ngIf="currentPlan?.updated_at">Dane aktualne na: {{ currentPlan?.updated_at | date: "dd.MM" }}</p>
          </div>
          <div class="right">
            <p>Realizacja: {{ currentPlan?.performance || 0 }}</p>
            <p>Plan: {{ currentPlan?.target }}</p>
          </div>
        </div>
      </div>
      <div class="prizes-strategy">
        <div class="decorator"><p>Strategia nagradzania:</p></div>
        <div class="thresholds">
          <div class="step">
            <div class="list">
              <div *ngFor="let currencyPrize of prizesWithBonus; let i = index">
                <span *ngIf="currencyPrize.type !== 'bonus'">
                  <m-ui-currency [item]="currencyPrize" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency> za każde
                  {{ currentPlan.reward_progress_percent }}% realizacji</span
                >
                <span *ngIf="currencyPrize.type === 'combined'">
                  + bonus
                  <m-ui-currency
                    [item]="currencyPrize"
                    [stockView]="STOCK_VIEW.J"
                    [class]="'bonus-currency'"
                    [other]="{ amount: currencyPrize.bonus_amount }"
                    [lack]="false"></m-ui-currency>
                  za {{ currentPlan.bonus_threshold_percent }}%
                </span>
                <span *ngIf="currencyPrize.type === 'bonus'">
                  <m-ui-currency
                    [item]="currencyPrize"
                    [stockView]="STOCK_VIEW.J"
                    [class]="'bonus-currency'"
                    [other]="{ amount: currencyPrize.bonus_amount }"
                    [lack]="false"></m-ui-currency>
                  jako bonus za {{ currentPlan.bonus_threshold_percent }}% realizacji
                </span>
              </div>
              <p>Minimalny próg, od którego wypłacamy nagrody: {{ currentPlan.minimal_progress_percent }}%</p>
              <p>Maksymalny próg wypłacenia nagrody: {{ currentPlan.max_progress_percent }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="prizes-pay-out">
        <div class="decorator"><p>Wypłacone nagrody:</p></div>
        <div class="thresholds">
          <div class="step">
            <div class="list">
              <div class="player-rewarded-prizes-wrapper">
                <div *ngFor="let paidReward of currentPlan.player_rewarded_prizes">
                  <m-ui-currency [item]="paidReward" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div class="decorator"><p>Opis zadania:</p></div>
        <p class="text" [innerHTML]="currentPlan.description"></p>
      </div>
    </ow-perfect-scrollbar>
  </div>
</ng-template>
