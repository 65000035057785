import { Primary } from "../../../../core/interfaces/primary";

export const MOCK_PRIMARY: Primary = {
  available_surveys: [
    {
      survey_id: 12,
      location: 1,
    },
    {
      survey_id: 27,
      location: 1,
    },
  ],
  available_quizzes: [
    {
      quiz_id: 3,
      location: 1,
    },
    {
      quiz_id: 4,
      location: 1,
    },
    {
      quiz_id: 8,
      location: 1,
    },
    {
      quiz_id: 24,
      location: 1,
    },
    {
      quiz_id: 30,
      location: 1,
    },
  ],
  is_available_gallery: true,
  gallery_max_upload_size_in_mb: 16,
  move_building_price: {
    currency_id: 2,
    amount: 50,
  },
  move_building_currency_price: {
    currency_id: 2,
    amount: 50,
  },
  move_building_product_price: null,
  currencies: [
    {
      type: "player",
      currency_id: 1,
      key: "money",
      name: "Banknoty",
      description: "Banknoty",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Banknoty<br> U\u017cywane do budowy i rozbudowy sklep\u00f3w na mapie gry.",
      position: 1,
    },
    {
      type: "player",
      currency_id: 2,
      key: "victory_points",
      name: "Pkt. zwyci\u0119stwa",
      description: "Punkty zwyci\u0119stwa",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Punkty zwyci\u0119stwa<br> zdob\u0119dziesz je buduj\u0105c sklepy Groszek i ABC. <br>Za osi\u0105gni\u0119cie synergii na poszczeg\u00f3lnych poziomach sklep\u00f3w otrzymasz dodatkowe punkty zwyci\u0119stwa.",
      position: 2,
    },
    {
      type: "user",
      currency_id: 3,
      key: "cash",
      name: "Z\u0142ote talary",
      description: "Z\u0142ote talary",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Z\u0142ote talary<br> Nagrody w rankingach, kt\u00f3re zostan\u0105 wymienione na z\u0142ot\u00f3wki 1:1. Z\u0142ot\u00f3wki otrzymasz na swoj\u0105 kart\u0119 Edenred po zamkni\u0119ciu ranking\u00f3w.",
      position: 3,
    },
    {
      type: "player",
      currency_id: 4,
      key: "money_red",
      name: "Czerwone Banknoty",
      description: "Czerwone Banknoty",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Czerwone Banknoty<br> Potrzebujesz ich, by prowadzi\u0107 rekrutacj\u0119 pracownik\u00f3w.",
      position: 4,
    },
    {
      type: "player",
      currency_id: 5,
      key: "victory_points_red",
      name: "Pkt. zwyci\u0119stwa",
      description: "Punkty zwyci\u0119stwa",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Punkty zwyci\u0119stw:<br> zdob\u0119dziesz je zatrudniaj\u0105c pracownik\u00f3w. <br>Za osi\u0105gni\u0119cie synergii na poszczeg\u00f3lnych poziomach otrzymasz dodatkowe punkty zwyci\u0119stwa.",
      position: 5,
    },
    {
      type: "player",
      currency_id: 6,
      key: "money_blue",
      name: "Niebieskie Banknoty",
      description: "Niebieskie Banknoty",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Niebieskie Banknoty<br> Potrzebujesz ich, by budowa\u0107 i rozbudowywa\u0107 obiekty na mapie.",
      position: 6,
    },
    {
      type: "player",
      currency_id: 7,
      key: "victory_points_blue",
      name: "Pkt. zwyci\u0119stwa",
      description: "Punkty zwyci\u0119stwa",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Punkty zwyci\u0119stw:<br> zdob\u0119dziesz je za budow\u0119 i rozbudow\u0119. <br>Za osi\u0105gni\u0119cie synergii na poszczeg\u00f3lnych poziomach otrzymasz dodatkowe punkty zwyci\u0119stwa.",
      position: 7,
    },
    {
      type: "player",
      currency_id: 10,
      key: "money_ec5",
      name: "Banknoty",
      description: "Banknoty",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Banknoty",
      position: 1,
    },
    {
      type: "player",
      currency_id: 11,
      key: "coinsilver",
      name: "Srebrne talary",
      description: "Srebrne talary, kt\u00f3re otrzymasz z ratusza i sprzeda\u017cy produkt\u00f3w",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: true,
      tooltip: "Srebrne talary, kt\u00f3re otrzymasz z ratusza i sprzeda\u017cy produkt\u00f3w",
      position: 2,
    },
    {
      type: "user",
      currency_id: 12,
      key: "cash_ec5",
      name: "Z\u0142ote talary",
      description: "Z\u0142ote talary",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Z\u0142ote talary, kt\u00f3re otrzymasz za realizacj\u0119 zada\u0144 biznesowych",
      position: 3,
    },
    {
      type: "player",
      currency_id: 13,
      key: "diamonds",
      name: "Brylanty",
      description: "Brylanty",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Brylanty",
      position: 4,
    },
    {
      type: "player",
      currency_id: 14,
      key: "point_blue",
      name: "Punkty niebieskie",
      description: "Punkty niebieskie",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty niebieskie",
      position: -1,
    },
    {
      type: "player",
      currency_id: 15,
      key: "point_green",
      name: "Punkty zielone",
      description: "Punkty zielone",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty zielone",
      position: -1,
    },
    {
      type: "player",
      currency_id: 16,
      key: "point_orange",
      name: "Punkty pomara\u0144czowe",
      description: "Punkty pomara\u0144czowe",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty pomara\u0144czowe",
      position: -1,
    },
    {
      type: "player",
      currency_id: 17,
      key: "point_red",
      name: "Punkty czerwone",
      description: "Punkty czerwone",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty czerwone",
      position: -1,
    },
    {
      type: "player",
      currency_id: 18,
      key: "point_seladon",
      name: "Punkty seledynowe",
      description: "Punkty seledynowe",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty seledynowe",
      position: -1,
    },
    {
      type: "player",
      currency_id: 19,
      key: "point_violett",
      name: "Punkty fioletowe",
      description: "Punkty fioletowe",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty fioletowe",
      position: -1,
    },
    {
      type: "player",
      currency_id: 20,
      key: "point_yellow",
      name: "Punkty \u017c\u00f3\u0142te",
      description: "Punkty \u017c\u00f3\u0142te",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: "Punkty \u017c\u00f3\u0142te",
      position: -1,
    },
    {
      type: "user",
      currency_id: 49,
      key: "cash_2",
      name: "Z\u0142ote talary",
      description: "Z\u0142ote talary",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Z\u0142ote talary<br> Nagrody w rankingach, kt\u00f3re zostan\u0105 wymienione na z\u0142ot\u00f3wki 1:1. Z\u0142ot\u00f3wki otrzymasz na swoj\u0105 kart\u0119 Edenred po zamkni\u0119ciu ranking\u00f3w.",
      position: -1,
    },
    {
      type: "user",
      currency_id: 50,
      key: "cash_3",
      name: "Z\u0142ote talary",
      description: "Z\u0142ote talary",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "Z\u0142ote talary<br> Nagrody w rankingach, kt\u00f3re zostan\u0105 wymienione na z\u0142ot\u00f3wki 1:1. Z\u0142ot\u00f3wki otrzymasz na swoj\u0105 kart\u0119 Edenred po zamkni\u0119ciu ranking\u00f3w.",
      position: -1,
    },
  ],
  products: [
    {
      product_id: 1,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Mi\u0119dzychodzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 2,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Mi\u0119dzychodzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 3,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Mi\u0119dzychodzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Nowym Tomy\u015blu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 5,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Nowym Tomy\u015blu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 6,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Nowym Tomy\u015blu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 7,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Wolsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 8,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Wolsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 9,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Wolsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 10,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Grodzisku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 11,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Grodzisku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 12,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Grodzisku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 13,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Opalenicy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 14,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Opalenicy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 15,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Opalenicy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 16,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Pniewach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 17,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Pniewach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 18,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Pniewach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 19,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w we Wronkach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 20,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w we Wronkach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 21,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w we Wronkach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 22,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Szamotu\u0142ach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 23,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Szamotu\u0142ach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 24,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Szamotu\u0142ach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 25,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Obornikach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 26,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Obornikach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 27,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Obornikach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 28,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Swarz\u0119dzu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 29,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Swarz\u0119dzu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 30,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Swarz\u0119dzu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 31,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w K\u00f3rniku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 32,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w K\u00f3rniku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 33,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w K\u00f3rniku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 34,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w \u015aremie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 35,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w \u015aremie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 36,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w \u015aremie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 37,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w \u015arodzie Wielkopolskiej",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 38,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w \u015arodzie Wielkopolskiej",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 39,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w \u015arodzie Wielkopolskiej",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 40,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Gnie\u017anie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 41,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Gnie\u017anie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 42,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Gnie\u017anie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 43,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w we Wrze\u015bni",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 44,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w we Wrze\u015bni",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 45,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w we Wrze\u015bni",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 46,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Jarocinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 47,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Jarocinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 48,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Jarocinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 49,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 50,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 51,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 52,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Jakikolwiek sklep na 1 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 53,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Jakikolwiek sklep na 2 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 54,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Jakikolwiek sklep na 3 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 55,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "ABC na 1 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 56,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "ABC na 2 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 57,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "ABC na 3 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 58,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Groszek na 1 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 59,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Groszek na 2 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 60,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Groszek na 3 poziomie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 62,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Ostatni poziom du\u017cego budynku rozrywkowego",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 66,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Invisible do blokowania",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 67,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Invisible do blokowania",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 100,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Budowla\u0144cy",
      description:
        "Ekipy budowlane umo\u017cliwiaj\u0105 wznoszenie budynk&oacute;w. Ich roboczogodziny odnawiaj\u0105 si\u0119 ka\u017cdego dnia roboczego.",
      icon: "builders",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 101,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Energia",
      description: "Energia umo\u017cliwia zatrudnianie pracownik&oacute;w. Odnawia si\u0119 ka\u017cdego dnia roboczego.",
      icon: "energy",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 102,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Budowla\u0144cy",
      description:
        "Ekipy budowlane umo\u017cliwiaj\u0105 wznoszenie budynk&oacute;w. Ich roboczogodziny odnawiaj\u0105 si\u0119 ka\u017cdego dnia roboczego.",
      icon: "builders",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 200,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Bloker",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 201,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Odblokowanie miast po onboardingu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 202,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Odblokowanie groszka w jarocinie po 1 misji",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 203,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "zablokowanie rozbudowy abc w jarocinie po 1 misji",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 204,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Odblokowanie miast po onboardingu - edycja marcowa",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 205,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Odblokowanie rozbudowy pracownikow w Pile na 2 poziom",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 206,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "zablokowanie kierownika  w Pile po 1 misji",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 301,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 302,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 303,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 304,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 305,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 306,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 307,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 308,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 309,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 310,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 311,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Zielonej G\u00f3rze",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 312,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Zielonej G\u00f3rze",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 313,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Zielonej G\u00f3rze",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 314,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Zielonej G\u00f3rze",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 315,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Zielonej G\u00f3rze",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 316,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Kaliszu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 317,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Kaliszu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 318,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Kaliszu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 319,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Kaliszu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 320,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Kaliszu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 321,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 322,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 323,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 324,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 325,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Koninie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 326,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Koszalinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 327,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Koszalinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 328,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Koszalinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 329,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Koszalinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 330,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Koszalinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 331,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Lesznie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 332,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Lesznie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 333,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Lesznie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 334,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Lesznie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 335,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Lesznie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 336,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Pile",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 337,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Pile",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 338,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Pile",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 339,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Pile",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 340,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Pile",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 341,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom pracownik\u00f3w w Stargardzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 342,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom pracownik\u00f3w w Stargardzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 343,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom pracownik\u00f3w w Stargardzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 344,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "4 poziom pracownik\u00f3w w Stargardzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 345,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "5 poziom pracownik\u00f3w w Stargardzie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 401,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Krakowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 402,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Krakowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 403,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Krakowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 404,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Warszawie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 405,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Warszawie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 406,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Warszawie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 407,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Katowicach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 408,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Katowicach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 409,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Katowicach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 410,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 411,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 412,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Poznaniu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 413,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 414,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 415,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Gorzowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 416,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Opolu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 417,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Opolu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 418,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Opolu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 419,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Bia\u0142ymstoku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 420,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Bia\u0142ymstoku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 421,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Bia\u0142ymstoku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 422,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Kielcach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 423,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Kielcach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 424,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Kielcach",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 425,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Olsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 426,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Olsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 427,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Olsztynie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 428,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Szczecinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 429,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Szczecinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 430,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Szczecinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 431,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w we Wroc\u0142awiu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 432,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w we Wroc\u0142awiu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 433,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w we Wroc\u0142awiu",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 434,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Bydgoszczy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 435,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Bydgoszczy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 436,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Bydgoszczy",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 437,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Lublinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 438,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Lublinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 439,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Lublinie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 440,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w \u0141odzi",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 441,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w \u0141odzi",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 442,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w \u0141odzi",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 443,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Rzeszowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 444,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Rzeszowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 445,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Rzeszowie",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 446,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "1 poziom sklep\u00f3w w Gda\u0144sku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 447,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "2 poziom sklep\u00f3w w Gda\u0144sku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 448,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "3 poziom sklep\u00f3w w Gda\u0144sku",
      description: "",
      icon: "xxx",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 449,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Bloker sklep\u00f3w wojew\u00f3dzkich",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 450,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Bloker sklepu na 1 poziomie",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 451,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Bloker hali na 2 poziom",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 601,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Kostka do gry",
      description: "",
      icon: "dice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 602,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Kostka do gry",
      description: "Kostka do gry umo\u017cliwi Ci wej\u015bcie do minigry.",
      icon: "dice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 603,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Kostka do gry",
      description: "",
      icon: "dice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 604,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Kostka do gry",
      description: "",
      icon: "dice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 605,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Kostka do gry",
      description: "",
      icon: "dice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1001,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Jab\u0142ko",
      description:
        "Jab\u0142ko posiada w sobie du\u017co warto\u015bci od\u017cywczych, mo\u017cesz je spo\u017cy\u0107 jako przek\u0105sk\u0119 mi\u0119dzy posi\u0142kami. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "apple",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1002,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Sok jab\u0142kowy",
      description:
        "Sok jab\u0142kowy produkowany jest z jab\u0142ek najlepszej jako\u015bci. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "apple_juice",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1003,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Woda niegazowana",
      description:
        "Zdrowa niegazowana woda to idealny spos&oacute;b na to, by ugasi\u0107 pragnienie. Produkt z fabryki napoj&oacute;w sprzeda\u017c przez hurtowni\u0119 Eurocash.",
      icon: "aqua",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1004,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Woda gazowana",
      description:
        "Lubisz b\u0105belki? W takim razie produkowana w fabryce napoj&oacute;w, woda gazowana to strza\u0142 w 10! Produkt sprzeda\u017c przez hurtowni\u0119 Eurocash.",
      icon: "aqua_gaz",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1005,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Piwo",
      description:
        "Piwo, tradycyjny z\u0142ocisty trunek, kt&oacute;ry cieszy podniebienia smakoszy od wiek&oacute;w. Produkt sprzeda\u017c przez hurtowni\u0119 Eurocash.",
      icon: "ale",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1006,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Banany",
      description:
        "Banan to owoc tropikalny, kt&oacute;ry posiada w sobie ca\u0142\u0105 mas\u0119 witamin. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "banana",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1007,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Mas\u0142o",
      description:
        "Mas\u0142o to dodatek na \u015bniadaniowym pieczywie, kt&oacute;rego nie mo\u017ce zabrakn\u0105\u0107. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "butter",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1008,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Przetwory",
      description:
        "Przetwory bez konserwant&oacute;w to idealny plan na posi\u0142ek, gdy nie masz czasu. Produkt mo\u017cesz sprzeda\u0107 przez hurtowni\u0119 Eurocash.",
      icon: "campbell_soup",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1009,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "S\u0142odycze",
      description:
        "S\u0142odycze to s\u0142odka przek\u0105ska, kt&oacute;r\u0105 ka\u017cdy z nas uwielbia. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "candy",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1010,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Ser \u017c\u00f3\u0142ty",
      description: "Ser \u017c&oacute;\u0142ty produkowany jest w mleczarni. Produkt sprzeda\u017c przez hurtowni\u0119 Eurocash.",
      icon: "cheese",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1011,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Chipsy",
      description:
        "Chipsy to najpopularniejsza s\u0142ona przek\u0105ska, idealnie sprawdzi si\u0119 na wiecz&oacute;r przed telewizorem. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "chips",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1012,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Kawa",
      description:
        "Kawa to \u017ar&oacute;d\u0142o energii, dzi\u0119ki niej \u0142adujemy bateri\u0119 na ca\u0142y dzie\u0144. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "coffee-roasted",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1013,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Og\u00f3rek ",
      description:
        "Og&oacute;rek gruntowy, kiszony, konserwowy to tylko trzy propozycje z wielu rodzaj&oacute;w og&oacute;rka. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "cucumber",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1014,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Mleko w kartonie",
      description:
        "Mleko ma w sobie wiele przeznacze\u0144, mo\u017ce by\u0107 stosowane do kawy czy r&oacute;\u017cnego rodzaju wyrob&oacute;w cukierniczych. \u015awie\u017ce mleko sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "milk",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1015,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Makaron",
      description:
        "Makaron to podstawa najpopularniejszych da\u0144 w ka\u017cdej restauracji. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "pasta_packaging",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1016,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Petfood",
      description:
        "Petfood to jedzenie dla naszych w\u0142ochatych przyjaci&oacute;\u0142 na 4 \u0142apkach. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "petfood",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1017,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Ziemniaki",
      description:
        "Ziemniaki to \u017arod\u0142o warto\u015bci od\u017cywczych i niezast\u0105piona baza da\u0144 obiadowych. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "potatoes",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1018,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Pomidor",
      description: "Pomidor to prawdziwa bomba witaminowa. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "tomato",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1019,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "W\u00f3dka",
      description: "W&oacute;dka to alkohol wysokoprocentowy. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "vodka",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1020,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Woda",
      description:
        "Woda gasi pragnienie i nawadnia organizm. Nie mo\u017cna o niej zapomina\u0107 w trakcie dnia. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "aqua",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1021,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Wino",
      description:
        "Wino czerwone czy bia\u0142e? W sklepie monopolowym znajdziesz \u015bwietne wina sprowadzane z r&oacute;\u017cnych stron \u015bwiata.",
      icon: "wine",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1022,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Jogurt",
      description:
        "Jogurt produkowany jest w mleczarni. Jest niezwykle zdrowy, zawiera w sobie du\u017co bia\u0142ka, ale tak\u017ce jest bardzo smaczny. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "yoghurt",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 1023,
      category: {
        storage_id: 7,
        trading_currency_id: null,
        id: 6,
        name: "Produkty codzienne",
        description: "Produkty codzienne",
        icon: "Przedmioty",
      },
      name: "Nap\u00f3j",
      description: "S\u0142odkie napoje, cz\u0119sto wybierane do picia. Produkt sprzedasz przez hurtowni\u0119 Eurocash.",
      icon: "nappi",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 2000,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Skrzynia",
      description: "",
      icon: "chest",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 3000,
      category: {
        storage_id: 9,
        trading_currency_id: null,
        id: 7,
        name: "Specjalne",
        description: "Specjalne",
        icon: "Przedmioty",
      },
      name: "Certyfikat dziedzictwa",
      description:
        "Certyfikat przyznawany przez Ministerstwo Kultury i Dziedzictwa za realizacj\u0119 misji na warto\u015b\u0107 kolekcji muzealnej.",
      icon: "safety_certificate",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 3001,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Skrzynia",
      description: "Skrzynia odnaleziona w trakcie jednej z wypraw odkrywczych po \u0142owiskach, jaskiniach lub wykopaliskach.",
      icon: "chest",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4000,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Zezwolenie celne",
      description:
        "Obieg napoj&oacute;w wyskokowych powinien by\u0107 bardzo \u015bci\u015ble kontrolowany. \u017baden ruch bez zezwolenia celnego nie ma prawa zaistnie\u0107.",
      icon: "sheet",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4001,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Turysta",
      description:
        "Turysta w mie\u015bcie umo\u017cliwia rozbudow\u0119 bazy hotelowej. Przyci\u0105gniesz go, buduj\u0105c i rozbudowuj\u0105c wielkopowierzchniowe obiekty rozrywkowe, muzeum oraz pla\u017ce.",
      icon: "tourist",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4003,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan budowy atrakcji",
      description:
        "Nie sam\u0105 prac\u0105 cz\u0142owiek \u017cyje, miasto i mieszka\u0144cy potrzebuj\u0105 tak\u017ce atrakcji i rozrywki! A te zaplanujemy z g\u0142ow\u0105 dzi\u0119ki planom.",
      icon: "blueprint",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4004,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisible za budynek rozrywkowy blokowany planem",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4005,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan budynku rozrywkowego",
      description:
        "Plan umo\u017cliwia budow\u0119 ma\u0142ego budynku rozrywkowego. Zdob\u0119dziesz go za realizacj\u0119 misji gwiazdkowych.",
      icon: "blueprint_entertainment",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4006,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisile za fabryk\u0119 (ka\u017cdy level)",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4007,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan architektoniczny",
      description:
        "Plan umo\u017cliwia budow\u0119 i rozbudow\u0119 fabryk oraz du\u017cych obiekt&oacute;w logistycznych. Plan otrzymasz za realizacj\u0119 misji gwiazdkowych.",
      icon: "blueprint",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4008,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan fabryki 2 poziomu",
      description:
        "Plan umo\u017cliwia robudow\u0119 dowolnej fabryki do 2 poziomu. Plany zdob\u0119dziesz za realizacj\u0119 misji statusowych 1.0 oraz 1.5.",
      icon: "blueprint_factory_2",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4009,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisile za aptek\u0119 lub szpital",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4010,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisile za du\u017cy budunek rozrywkowy",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4011,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisible za drugi level du\u017cego budunku rozrwkowego",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4012,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisile za fabryk\u0119 lvl 5",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4013,
      category: {
        storage_id: 9,
        trading_currency_id: null,
        id: 7,
        name: "Specjalne",
        description: "Specjalne",
        icon: "Przedmioty",
      },
      name: "Grupa turyst\u00f3w",
      description: "Umo\u017cliwia budow\u0119 i rozbudow\u0119 hoteli",
      icon: "blueprint_factory_3",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4014,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan fabryki 4 poziomu",
      description:
        "Plan umo\u017cliwia robudow\u0119 dowolnej fabryki do 4 poziomu. Plany zdob\u0119dziesz za realizacj\u0119 misji statusowych 3.0 oraz 3.5.",
      icon: "blueprint_factory_4",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4015,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan fabryki 5 poziomu",
      description:
        "Plan umo\u017cliwia robudow\u0119 dowolnej fabryki do 5 poziomu. Plany zdob\u0119dziesz za realizacj\u0119 misji statusowych 4.0 oraz 4.5.",
      icon: "blueprint_factory_5",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4016,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan fabryki 6 poziomu",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4017,
      category: {
        storage_id: 10,
        trading_currency_id: null,
        id: 8,
        name: "Budowa",
        description: "Budowa",
        icon: "Przedmioty",
      },
      name: "Plan du\u017cego budynku rozrywkowego",
      description:
        "Plan umo\u017cliwia budow\u0119 du\u017cego budynku rozrywkowego. Zdob\u0119dziesz go za realizacj\u0119 misji gwiazdkowych.",
      icon: "blueprint_entertainment_big",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 4018,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "invisible za budynek zablokowany 513",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 5010,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Zrobienie misji z batyskafem",
      description: "",
      icon: "",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 5011,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Nagroda",
      description: "",
      icon: "trophy",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 6000,
      category: {
        storage_id: 6,
        trading_currency_id: null,
        id: 10,
        name: "Ekipy",
        description: "Ekipy",
        icon: "Przedmioty",
      },
      name: "Budowla\u0144cy",
      description:
        "Ekipy budowlane umo\u017cliwiaj\u0105 wznoszenie budynk&oacute;w. Ich roboczogodziny odnawiaj\u0105 si\u0119 ka\u017cdego dnia roboczego.",
      icon: "builders",
      visible_from_level: 999,
      maximum_amount: null,
    },
    {
      product_id: 9999,
      category: {
        storage_id: 8,
        trading_currency_id: null,
        id: 9,
        name: "Invisible",
        description: "Invisible",
        icon: "Przedmioty",
      },
      name: "Puchar",
      description: "",
      icon: "trophy",
      visible_from_level: 999,
      maximum_amount: null,
    },
  ],
  parameters: [
    {
      type: "happiness_bonus",
      parameter_id: 1,
      key: "happiness",
      name: "Zadowolenie",
      tooltip:
        "Dbaj o potrzeby mieszka\u0144c\u00f3w<br>i utrzymuj paramery miasta na poziomie 100%,<br>aby osi\u0105gn\u0105\u0107 pe\u0142ne zadowolenie.",
      position: 1,
    },
    {
      type: "population_delta",
      parameter_id: 2,
      key: "population",
      name: "Populacja",
      tooltip:
        "Rozwijaj budynki mieszkalne<br>i utrzymuj zadowolenie na poziomie 100%,<br>aby w pe\u0142ni zasiedli\u0107 mieszkania w mie\u015bcie.<br>Pojemno\u015b\u0107 budynk\u00f3w mieszkalnych: %population_max.",
      position: 2,
    },
    {
      type: "penalized_parameter",
      parameter_id: 3,
      key: "supply",
      name: "Zaopatrzenie",
      tooltip:
        "Rozwijaj sklepy, hurtownie i punkty gastronomiczne,<br>aby zapewni\u0107 miastu zaopatrzenie.<br>Poziom zaopatrzenia: %current_income_value.<br>Zapotrzebowanie: %population_current.",
      position: 3,
    },
    {
      type: "penalized_parameter",
      parameter_id: 4,
      key: "safety",
      name: "Bezpiecze\u0144stwo",
      tooltip:
        "Rozwijaj apteki, przychodnie i szpitale,<br>aby zwi\u0119kszy\u0107 poczucie bezpiecze\u0144stwa w\u015br\u00f3d mieszka\u0144c\u00f3w.<br>Poziom bezpiecze\u0144stwa: %current_income_value.<br>Zapotrzebowanie: %population_current.",
      position: 4,
    },
    {
      type: "penalized_parameter",
      parameter_id: 5,
      key: "entertainment",
      name: "Rozrywka",
      tooltip:
        "Rozwijaj lokale gastronomiczne, kina, teatry<br>i obiekty rekreacyjne, aby<br>zapewni\u0107 mieszka\u0144com dost\u0119p do rozrywki.<br>Poziom rozrywki: %current_income_value.<br>Zapotrzebowanie: %population_current.",
      position: 5,
    },
    {
      type: "penalized_parameter",
      parameter_id: 6,
      key: "eco",
      name: "Ekologia",
      tooltip:
        "Rozwijaj tereny zielone i inwestuj w odnawialne \u017arod\u0142a energii,<br>aby rozwin\u0105\u0107 ekologi\u0119 w mie\u015bcie.<br>Poziom ekologii: %current_income_value.<br>Zapotrzebowanie: %population_current.",
      position: 6,
    },
  ],
  spreads: [],
  stars: [
    {
      star_id: 1,
      star_name: "GWIAZDKA 0.1",
    },
    {
      star_id: 2,
      star_name: "GWIAZDKA 0.2",
    },
    {
      star_id: 3,
      star_name: "GWIAZDKA 0.3",
    },
    {
      star_id: 4,
      star_name: "GWIAZDKA 0.4",
    },
    {
      star_id: 5,
      star_name: "GWIAZDKA 0.5",
    },
    {
      star_id: 6,
      star_name: "GWIAZDKA 0.6",
    },
    {
      star_id: 7,
      star_name: "GWIAZDKA 0.7",
    },
    {
      star_id: 8,
      star_name: "GWIAZDKA 0.8",
    },
    {
      star_id: 9,
      star_name: "GWIAZDKA 0.9",
    },
    {
      star_id: 10,
      star_name: "GWIAZDKA 1.0",
    },
    {
      star_id: 11,
      star_name: "GWIAZDKA 1.1",
    },
    {
      star_id: 12,
      star_name: "GWIAZDKA 1.2",
    },
    {
      star_id: 13,
      star_name: "GWIAZDKA 1.3",
    },
    {
      star_id: 14,
      star_name: "GWIAZDKA 1.4",
    },
    {
      star_id: 15,
      star_name: "GWIAZDKA 1.5",
    },
    {
      star_id: 16,
      star_name: "GWIAZDKA 1.6",
    },
    {
      star_id: 17,
      star_name: "GWIAZDKA 1.7",
    },
    {
      star_id: 18,
      star_name: "GWIAZDKA 1.8",
    },
    {
      star_id: 19,
      star_name: "GWIAZDKA 1.9",
    },
    {
      star_id: 20,
      star_name: "GWIAZDKA 2.0",
    },
    {
      star_id: 21,
      star_name: "GWIAZDKA 2.1",
    },
    {
      star_id: 22,
      star_name: "GWIAZDKA 2.2",
    },
    {
      star_id: 23,
      star_name: "GWIAZDKA 2.3",
    },
    {
      star_id: 24,
      star_name: "GWIAZDKA 2.4",
    },
    {
      star_id: 25,
      star_name: "GWIAZDKA 2.5",
    },
    {
      star_id: 26,
      star_name: "GWIAZDKA 2.6",
    },
    {
      star_id: 27,
      star_name: "GWIAZDKA 2.7",
    },
    {
      star_id: 28,
      star_name: "GWIAZDKA 2.8",
    },
    {
      star_id: 29,
      star_name: "GWIAZDKA 2.9",
    },
    {
      star_id: 30,
      star_name: "GWIAZDKA 3.0",
    },
    {
      star_id: 31,
      star_name: "GWIAZDKA 3.1",
    },
    {
      star_id: 32,
      star_name: "GWIAZDKA 3.2",
    },
    {
      star_id: 33,
      star_name: "GWIAZDKA 3.3",
    },
    {
      star_id: 34,
      star_name: "GWIAZDKA 3.4",
    },
    {
      star_id: 35,
      star_name: "GWIAZDKA 3.5",
    },
    {
      star_id: 36,
      star_name: "GWIAZDKA 3.6",
    },
    {
      star_id: 37,
      star_name: "GWIAZDKA 3.7",
    },
    {
      star_id: 38,
      star_name: "GWIAZDKA 3.8",
    },
    {
      star_id: 39,
      star_name: "GWIAZDKA 3.9",
    },
    {
      star_id: 40,
      star_name: "GWIAZDKA 4.0",
    },
    {
      star_id: 41,
      star_name: "GWIAZDKA 4.1",
    },
    {
      star_id: 42,
      star_name: "GWIAZDKA 4.2",
    },
    {
      star_id: 43,
      star_name: "GWIAZDKA 4.3",
    },
    {
      star_id: 44,
      star_name: "GWIAZDKA 4.4",
    },
    {
      star_id: 45,
      star_name: "GWIAZDKA 4.5",
    },
    {
      star_id: 46,
      star_name: "GWIAZDKA 4.6",
    },
    {
      star_id: 47,
      star_name: "GWIAZDKA 4.7",
    },
    {
      star_id: 48,
      star_name: "GWIAZDKA 4.8",
    },
    {
      star_id: 49,
      star_name: "GWIAZDKA 4.9",
    },
    {
      star_id: 50,
      star_name: "GWIAZDKA 5.0",
    },
    {
      star_id: 51,
      star_name: "GWIAZDKA 5.1",
    },
    {
      star_id: 52,
      star_name: "GWIAZDKA 5.2",
    },
    {
      star_id: 53,
      star_name: "GWIAZDKA 5.3",
    },
    {
      star_id: 54,
      star_name: "GWIAZDKA 5.4",
    },
    {
      star_id: 55,
      star_name: "GWIAZDKA 5.5",
    },
    {
      star_id: 56,
      star_name: "GWIAZDKA 5.6",
    },
    {
      star_id: 57,
      star_name: "GWIAZDKA 5.7",
    },
    {
      star_id: 58,
      star_name: "GWIAZDKA 5.8",
    },
    {
      star_id: 59,
      star_name: "GWIAZDKA 5.9",
    },
    {
      star_id: 60,
      star_name: "GWIAZDKA 6.0",
    },
    {
      star_id: 61,
      star_name: "GWIAZDKA 6.1",
    },
    {
      star_id: 62,
      star_name: "GWIAZDKA 6.2",
    },
    {
      star_id: 63,
      star_name: "GWIAZDKA 6.3",
    },
    {
      star_id: 64,
      star_name: "GWIAZDKA 6.4",
    },
    {
      star_id: 65,
      star_name: "GWIAZDKA 6.5",
    },
    {
      star_id: 66,
      star_name: "GWIAZDKA 6.6",
    },
    {
      star_id: 67,
      star_name: "GWIAZDKA 6.7",
    },
    {
      star_id: 68,
      star_name: "GWIAZDKA 6.8",
    },
    {
      star_id: 69,
      star_name: "GWIAZDKA 6.9",
    },
  ],
  action_block_dates: [],
};
