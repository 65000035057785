<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="rank">
    <a *ngIf="rank.parameters?.gift_link_pdf as giftPdfLink" [href]="giftPdfLink" target="_blank">
      <div class="back-bar gift-button">
        <button class="square normal primary">
          <i class="fas fa-gift"></i>
        </button>
      </div>
    </a>

    <div *ngIf="rankStarted && !rankFinished" class="navigation-bar next hall-results">
      <button class="square normal primary" (click)="openRankDetailsInfo(rank.rank_edition_id)">
        <i class="fas fa-warehouse"></i>
      </button>
    </div>

    <!-- TOP BAR BASE -->
    <div class="top-bar base">
      <!-- RANK NAME -->
      <div class="title">
        {{ rank.name }}
      </div>
    </div>

    <!-- RANK DETAILS -->
    <div class="rank-details-container loading-opacity" *ngIf="rankResults">
      <ng-container *ngIf="rankResults.results.length; else noItemsTpl">
        <div class="header">
          <ow-perfect-scrollbar class="height-auto">
            <ranking-game-table
              [rankResults]="rankResults"
              [rank]="rank"
              [columns]="RANK_CONFIG.columns"
              [show]="'header'"
              [activeFilter]="activeFilter"></ranking-game-table>
          </ow-perfect-scrollbar>
        </div>

        <!-- RANK RESULTS -->
        <ow-perfect-scrollbar [scrollGradient]="rankResults">
          <ranking-game-table
            [rankResults]="rankResults"
            [rank]="rank"
            [columns]="RANK_CONFIG.columns"
            [show]="'results'"
            [activeFilter]="activeFilter"></ranking-game-table>
        </ow-perfect-scrollbar>

        <!-- MY POSITION -->
        <div class="my-position-container" *ngIf="rankResults.my_position">
          <!-- LABEL -->
          <label>{{ "ranks.rank-details-game.my-position" | myTranslate }}</label>

          <ow-perfect-scrollbar class="height-auto my-position">
            <ranking-game-table
              [rankResults]="rankResults"
              [rank]="rank"
              [columns]="RANK_CONFIG.columns"
              [show]="'myPosition'"
              [activeFilter]="activeFilter"></ranking-game-table>
          </ow-perfect-scrollbar>
        </div>
        <div class="filter-container" *ngIf="displayFilter">
          <div class="each-filter">
            <!-- CHECKBOX -->
            <button class="checkbox" (click)="toggleFilter()" [ngClass]="{ 'active': isActiveFilter }"></button>
            <!-- LABEL -->
            <span>Pokaż mój zespół</span>
          </div>
        </div>
      </ng-container>
      <ng-template #noItemsTpl>
        <div class="flex-center no-items">Aktualnie brak graczy spełniających założenia tego rankingu.</div>
      </ng-template>
    </div>
  </ng-container>
</div>
