<app-device-rotate></app-device-rotate>
<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>
  <div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" [blockButton]="config[data.mode].blockBusinessButton">
      <ng-container left-top>
        <div class="business-button-round c-green">
          <p>{{ config[this.data.mode].title }}</p>
        </div>
        <div class="month-selector">
          <button
            class="arrow arrow-previous c-green"
            (click)="prevMonth()"
            [class.button-disabled]="businessDataList[data.mode]?.[currentTabDate]?.blockPrev"
            [disabled]="businessDataList[data.mode]?.[currentTabDate]?.blockPrev">
            <i class="fas" [class.fa-chevron-left]="!businessDataList[data.mode]?.[currentTabDate]?.blockPrev"></i>
          </button>
          <div class="month c-green">
            <p>{{ owDate.month_text_normal }}</p>
          </div>
          <button
            class="arrow arrow-next c-green"
            (click)="nextMonth()"
            [class.button-disabled]="businessDataList[data.mode]?.[currentTabDate]?.blockNext"
            [disabled]="businessDataList[data.mode]?.[currentTabDate]?.blockNext">
            <i class="fas" [class.fa-chevron-right]="!businessDataList[data.mode]?.[currentTabDate]?.blockNext"></i>
          </button>
        </div>
      </ng-container>
    </app-business-navigation>
    <div class="content">
      <ow-perfect-scrollbar scrollGradient>
        <div
          class="vendor"
          *ngFor="let el of businessDataList[data.mode]?.[currentTabDate]?.data; index as i"
          (click)="redirectToDetails(i)">
          <div class="badge-container">
            <div class="badge {{ el?.badge?.color }}" *ngIf="el?.badge?.description">
              <p>{{ el?.badge?.title + " "+ el?.badge?.description }}</p>
            </div>
          </div>
          <div class="info">
            <div class="logo">
              <img *ngIf="el.logo" [title]="el.name" [alt]="el.name" src="{{ imagePath }}/{{ el.logo }}" (error)="markAsInvalid(el)" />
              <div class="company-name" title="{{ el.name }}">
                <p>{{ el.name }}</p>
              </div>
            </div>
            <div class="progress">
              <div class="info">
                <p
                  [matTooltip]="'Data ostatniej aktualizacji ' + (data.mode === 'PLANS' ? 'planu' : 'wyzwania')"
                  matTooltipPosition="left"
                  matTooltipShowDelay="300">
                  {{ el.updated_at ? (el.updated_at | date: "dd.MM") : "" }}
                </p>
                <p [matTooltip]="'Realizacja w procentach'" matTooltipPosition="right" matTooltipShowDelay="300">
                  {{ (el.realization * 100) || 0 | toFixed:0:'down' }}%
                </p>
              </div>
              <div class="bar">
                <div class="fill" [ngStyle]="{ width: el.realization * 100 + '%' }"></div>
              </div>
              <div class="points">
                <p [matTooltip]="'Realizacja'" matTooltipPosition="left" matTooltipShowDelay="300">R: {{ el.performance || 0 }}</p>
                <p [matTooltip]="'Plan'" matTooltipPosition="right" matTooltipShowDelay="300">
                  P: {{ el.target || 0 }}
                </p>
              </div>
            </div>
            <div class="currencies">
              <ng-container *ngIf="el?.player_plan_id">
                <div class="currency" *ngFor="let currency of el?.currency_prizes; let i = index">

<!--                  <p class="name">{{ currencyDefinitions[currency.currency_id]?.name }}:</p>-->
                  <p class="value">{{ getPrize(el?.player_rewarded_prizes, currency.currency_id)?.amount || '' }}</p>
                  <m-ui-currency [item]="currency" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                  <p class="percentage">{{ el.bonus_threshold_percent }}%:</p>
                  <p class="max-amount">{{ getPrize(el?.reference_prizes, currency.currency_id)?.amount || '' }}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="el?.player_challenge_id">
                <div class="currency" *ngFor="let currency of el?.currency_prizes; let i = index">
<!--                  <p class="name">{{currencyDefinitions[currency.currency_id]?.name}}:</p>-->
                  <p class="value">{{currency.rewarded_amount || ''}}</p>
                  <m-ui-currency
                          [item]="currency"
                          [hideValue]="true"
                          [stockView]="STOCK_VIEW.J"
                          [lack]="false"></m-ui-currency>
                  <p class="percentage">{{el.reference_threshold}}%:</p>
                  <p class="max-amount">{{currency.amount}}</p>
                </div>
              </ng-container>
            </div>
            <button
              matTooltip="Lista klientów"
              matTooltipPosition="below"
              matTooltipShowDelay="300"
              *ngIf="true"
              (click)="redirectToList(i, $event)">
              <i class="far fa-clipboard-list fa-lg"></i>
            </button>
          </div>
        </div>
        <div class="tasks-empty" *ngIf="businessDataList[data.mode]?.[currentTabDate]?.data.length === 0">
          <p>Brak {{ data.mode === "PLANS" ? "planów" : "wyzwań" }} w wybranym okresie.</p>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>
</div>
