<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="mission">
    <!-- MISSION ICON -->
    <div class="mission-icon">
      <img [src]="mission.icon + '.png' | asset : 'missions/board-main'" />
    </div>

    <!-- TOP BAR BASE -->
    <div class="top-bar base">
      <div class="title-left">
        {{ mission.title }}
      </div>
    </div>

    <!-- TIMER -->
    <div class="timer" *ngIf="mission.expires_at"><i class="fas fa-clock"></i> {{ timer | toHour }}</div>

    <ow-perfect-scrollbar scrollGradient class="loading-opacity">
      <!-- MISSION DESCRIPTION -->
      <div class="inner-html-description" [innerHTML]="mission.description | safe : 'html'"></div>

      <!-- MISSION PRIZES -->
      <div class="mission-prizes">
        <!-- POINTS EXPERIENCE -->
        <ng-container *ngIf="mission.points_prize">
          <m-ui-stock-other
            [item]="{ icon: 'exp', amount: mission.points_prize }"
            [stockView]="STOCK_VIEW.F"
            [lack]="false"
          ></m-ui-stock-other>
        </ng-container>

        <!-- CURRENCY PRIZE -->
        <ng-container *ngFor="let currency of mission.currency_prizes">
          <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.F" [lack]="false"></m-ui-currency>
        </ng-container>

        <!-- PRODUCTS PRIZE -->
        <ng-container *ngFor="let product of mission.product_prizes">
          <m-ui-product [item]="product" [stockView]="STOCK_VIEW.F" [lack]="false" [lockOpenStorage]="true"></m-ui-product>
        </ng-container>
      </div>

      <!-- MISSION CONDITIONS -->
      <table class="conditions">
        <tbody>
        <tr *ngFor="let condition of mission.conditions" [class.completed]="condition.completed_at">
          <!-- CONDITION CHECKBOX -->
          <td class="td-check">
            <i class="fas" [ngClass]="condition.completed_at ? 'fa-check-circle' : 'fa-circle'"></i>
          </td>

          <!-- CONDITION NAME -->
          <td class="td-name">
            {{ condition.name }}
          </td>

          <!-- CONDITION TARGET -->
          <td class="td-condition">
            <ng-container [ngSwitch]="condition.target_type">
              <!-- INTEGER -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.INTEGER">
                {{ condition.value }} / {{ condition.target }}
              </ng-container>

              <!-- PERCENTAGE -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.PERCENTAGE">
                {{ condition.value | percent : "1.2-2" }} / {{ condition.target | percent : "1.2-2" }}
              </ng-container>
            </ng-container>
          </td>

          <!-- CONDITION BUTTON HAND OVER -->
          <td class="td-transfer">
            <button
              class="square small primary"
              *ngIf="condition.is_hand_over"
              [disabled]="condition.completed_at"
              (click)="openMissionTransfer(condition)">
              <i class="fas fa-arrow-alt-from-bottom"></i>
            </button>

            <!-- SHOW ON MAP -->
            <button
              class="square small primary"
              *ngIf="condition.center_map"
              [disabled]="condition.completed_at"
              (click)="showOnMap(condition.center_map)">
              <i class="far fa-map-marker-alt"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </ow-perfect-scrollbar>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar">
      <ng-container *ngIf="noConditionsComplete">
        <!-- REJECT -->
        <div class="bar-button base" *ngIf="mission.rejectable">
          <button class="base tertiary" (click)="reject()">
            {{ "missions.button-reject" | myTranslate }}
          </button>
        </div>

        <!-- SHOW ON MAP -->
        <div class="bar-button base" *ngIf="mission.center_map">
          <button class="base primary" (click)="showOnMap(mission.center_map)">
            {{ "missions.show-on-map" | myTranslate }}
          </button>
        </div>
      </ng-container>

      <!-- COLLECT -->
      <div class="bar-button base">
        <button class="base secondary" [disabled]="missionExpired || noConditionsComplete" (click)="collect()">
          {{ "missions.button-collect" | myTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
