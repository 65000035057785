<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">Wynik hali</div>
  </div>

  <div class="desc" *ngIf="rankDetails">
    Wynik Twojej hali w rankingu liczony jest ze wzoru: średni wynik 80% najlepszych graczy * 0.8 + wynik kierownika hali * 0.2. <br />
    Wynik Twojej hali = {{ rankDetails.avg_result?.toFixed(2) }} * 0.8 + {{ rankDetails.manager_points }} * 0.2 =
    {{ rankDetails.final_result?.toFixed(2) }}.
  </div>

  <ng-container *ngIf="rankDetails">
    <!-- RANK RESULTS -->
    <div class="results-table">
      <ow-perfect-scrollbar [scrollGradient]="rankDetails.results">
        <ranking-game-table
          [rankResults]="rankDetails"
          [rank]="data.rank"
          [columns]="RANK_CONFIG.columns"
          [show]="'results'"
          [highLightTrIdx]="rankDetails.best_count"></ranking-game-table>
      </ow-perfect-scrollbar>
    </div>

    <div class="details-info">
      <div class="rows">
        <div class="tr">
          <div class="td name">Liczba uprawnionych do gry:</div>
          <div class="td value">{{ rankDetails.headcount }}</div>
        </div>
        <div class="tr">
          <div class="td name">Liczba zarejestrowanych:</div>
          <div class="td value">{{ rankDetails.registered_count }}</div>
        </div>
        <div class="tr">
          <div class="td name">Liczba 80% najlepszych graczy:</div>
          <div class="td value">{{ rankDetails.best_count }}</div>
        </div>
      </div>

      <div class="rows">
        <div class="tr h-50">
          <div class="td name line-height">Średni wynik 80% uprawnionych do gry:</div>
          <div class="td value">{{ rankDetails.avg_result?.toFixed(2) }}</div>
        </div>
        <div class="tr">
          <div class="td name">Wynik kierownika:</div>
          <div class="td value">{{ rankDetails.manager_points }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
