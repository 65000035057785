import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { ActionFile } from "../../../interfaces/custom/actions.interface";

@Component({
  selector: "app-action-consents-form",
  templateUrl: "./action-consents-form.component.html",
  styleUrls: ["./action-consents-form.component.scss"],
})
export class ActionConsentsFormComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @Input() actionId: number;
  @Output() consentsAccepted = new EventEmitter();
  @OwInject(FormBuilder) fb: FormBuilder;
  form: FormGroup;
  files: ActionFile[];
  loading: boolean = false;

  ngOnInit(): void {
    this.getFormDataFromApi();
  }

  public getFormDataFromApi() {
    this.loading = true;
    this.businessService.getActionConsents(this.actionId).subscribe(data => {
      this.files = data;
      this.form = this.fb.group({
        requiredFiles: this.fb.array([]),
      });
      this.addCheckboxes();
      this.loading = false;
    });
  }

  private addCheckboxes() {
    const control = this.form.get("requiredFiles") as FormArray;
    this.files.forEach(file => {
      control.push(
        this.fb.group({
          id: file.id,
          name: file.name,
          file_url: file.file_url,
          is_accepted: file.is_accepted,
        })
      );
    });
  }

  onSubmit() {
    const transformedData = this.form.value.requiredFiles.map(file => ({
      file_id: file.id,
      is_accepted: file.is_accepted,
    }));
    this.businessService.submitActionConsents(this.actionId, transformedData).subscribe(() => {
      this.consentsAccepted.emit(true);
    });
  }
}
