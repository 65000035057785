import { createReducer, on } from "@ngrx/store";

import { updateMessageCount } from "./actions";

export interface MessageState {
  messageCount: number;
}

export const initialState: MessageState = {
  messageCount: 0,
};

export const messageReducer = createReducer(
  initialState,
  on(updateMessageCount, (state, { count }) => {
    //@ts-ignore
    if (count == "•") {
      count = 0;
    }
    return { ...state, messageCount: count };
  })
);
