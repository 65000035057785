import { LandingComponent } from "../../base/custom/components/landing/landing.component";
import { ReportComponent } from "../../base/custom/components/report/report.component";

export const CUSTOM_ROUTING_AUTH_PATH = [
  {
    path: "report",
    component: ReportComponent,
  },
  {
    path: "landing",
    component: LandingComponent,
  },
];
