import { AsyncPipe, NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { AbstractInjectBaseComponent } from "../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../core/decorators/ow-inject.decorator";
import { updateMessageCount } from "../../store/chat/actions";
import { MessageState } from "../../store/chat/reducer";
import { AppState } from "../../store/state";
import { PlayerService } from "../player/providers/player.service";

type State = AppState & {
  message: MessageState;
};

@Component({
  standalone: true,
  selector: "app-navigation-boxes",
  templateUrl: "./navigation-boxes.component.html",
  styleUrls: ["./navigation-boxes.component.scss"],
  imports: [RouterLink, NgIf, AsyncPipe],
})
export class NavigationBoxesComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<State>;
  subs = {
    player: null,
  };
  messageCount$: Observable<number>;

  ngOnInit() {
    const playerId = this.playerService.getMePlayerId();
    if (playerId != null) {
      this.playerService.getPlayer(playerId).subscribe(res => {
        if (window["gtag"]) {
          window["gtag"]("event", "Ekran startowy", {
            event_category: "Ekran startowy",
            event_label: "Ekran startowy",
            custom_dimension_hrid: res?.id,
            custom_dimension_warehouse_id: res?.branch_id,
          });
        }
      });
    }
    this.initChat();
    this.messageCount$ = this.store.pipe(select(state => state.message.messageCount));
  }

  initChat() {
    window.addEventListener("message", e => {
      if (e.data.eventName === "startup") {
        const playerId = this.playerService.getMePlayerId();
        if (playerId != null) {
          this.playerService.getPlayer(playerId).subscribe(res => {
            const iframe = document.querySelector("iframe");
            if (iframe) {
              iframe.contentWindow.postMessage(
                {
                  externalCommand: "login-with-token",
                  token: res.chat_token,
                },
                "*"
              );
            }
          });
        }
      }
      if (e.data.eventName === "unread-changed") {
        return this.store.dispatch(updateMessageCount({ count: e.data.data }));
      }
    });
  }

  protected readonly window = window;
}
