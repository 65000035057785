import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { CardStatus, GetEdenredCardListResponse } from "../../api/interfaces/get-edenred-card-list-response.interface";
import { PrepaidCardTransactionsListComponent } from "../prepaid-card-transactions-list/prepaid-card-transactions-list.component";

@Component({
  selector: "app-prepaid-cards-list",
  standalone: true,
  imports: [CommonModule, SharedUiMobileModule, OwPerfectScrollbarModule, SharedModule],
  templateUrl: "./prepaid-cards-list.component.html",
  styleUrls: ["./prepaid-cards-list.component.scss"],
})
export class PrepaidCardsListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { cards: GetEdenredCardListResponse[] };
  cardList: GetEdenredCardListResponse[] = [];

  ngOnInit() {
    this.cardList = this.data.cards;
  }

  openTransaction(cardId: number) {
    this.dialogService.open(PrepaidCardTransactionsListComponent, {
      data: { card: this.cardList.find(item => item.card_id === cardId) },
    });
  }

  protected readonly CardStatus = CardStatus;
}
