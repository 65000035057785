import { Injectable } from "@angular/core";

import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiMgmService {
  constructor(protected apiService: ApiService) {}

  getInvitationPrizes() {
    return this.apiService.get(`invitation/prizes`);
  }

  postInvitationCode(code: string) {
    const options: ApiOptions = {
      params: {
        code,
      },
    };

    return this.apiService.post(`invitation`, options);
  }
}
