import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { LINKS } from "../../../../../../../../core/consts/core/links.const";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { getToken } from "../../../../../../../../core/utility/token";
import { LoginActions } from "../../../../../../../../store/auth/login";
import { PlayerSelectors } from "../../../../../../../../store/player";
import { AppState } from "../../../../../../../../store/state";
import { AuthService } from "../../../../../../../auth/providers/auth.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { GuiService } from "../../../../../../services/gui.service";
import { HudBankAccountNumberComponent } from "../../dialogs/hud-bank-account-number/hud-bank-account-number.component";
@Component({
  selector: "hud-player-profile-details",
  templateUrl: "./hud-player-profile-details.component.html",
})
export class HudPlayerProfileDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(Store) store: Store<AppState>;

  isImpersonated = getToken().is_impersonate;
  LINKS = LINKS;
  player;
  subs: { player: any } = {
    player: null,
  };

  ngOnInit(): void {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  close() {
    this.dialogService.closeAll();
  }
  openBankAccountNumber() {
    this.dialogService.open(HudBankAccountNumberComponent);
  }

  logout() {
    this.authService.logoutApi().subscribe(() => {
      this.dialogService.closeAll();
      this.guiService.isSplashShow.next(true);
      this.store.dispatch(new LoginActions.AuthLogout());
    });
  }

  protected readonly open = open;
}
