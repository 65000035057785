import { formatCurrency } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { Action, ActionBlockData, ActionBlockDetails } from "../../../interfaces/custom/actions.interface";

@Component({
  selector: "app-action-blocks-content",
  templateUrl: "./action-blocks-content.component.html",
  styleUrls: ["./action-blocks-content.component.scss"],
})
export class ActionBlocksContentComponent extends AbstractInjectBaseComponent implements OnInit, OnChanges {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @Input() action: Action;
  @Output() activateDefaultBlock = new EventEmitter();
  @Output() blockChanged = new EventEmitter();
  block: ActionBlockDetails = {
    data: [],
    pagination: {
      page: 0,
      limit: 0,
      item_count: 0,
    },
  };
  loading: boolean = false;
  isMoreBlocks: boolean;
  filters: ActionBlockDetails["data"];
  itemSorted?: { direction?: string; type: ActionBlockData["label_type"] }[];
  itemFiltered: string[] = [];
  filtersCount = 0;
  isFiltersOpen: boolean = false;

  ngOnInit() {
    this.fetchBlock();
  }

  ngOnChanges() {
    this.fetchBlock();
  }

  toggleFilters() {
    this.isFiltersOpen = !this.isFiltersOpen;
  }

  calculateFiltersCount() {
    this.filtersCount = 0;
    this.itemFiltered.forEach(filter => {
      if (filter) {
        this.filtersCount++;
      }
    });
    this.itemSorted.forEach(sort => {
      if (sort?.direction) {
        this.filtersCount++;
      }
    });
  }

  formatBlockValue(value: ActionBlockData["value"], label_type: ActionBlockData["label_type"]) {
    const hasPercentage = value?.includes("%");

    if (label_type === "percent" && !hasPercentage) {
      return `${value}%`;
    } else if (label_type === "currency") {
      return !isNaN(parseFloat(value)) ? `${formatCurrency(parseFloat(value), "pl", "zł")}` : value;
    } else if (label_type === "number") {
      return !isNaN(parseFloat(value)) ? `${formatCurrency(parseFloat(value), "pl", "", undefined, "1.0-0")}` : value;
    } else {
      return value;
    }
  }

  getActiveBlockId(): number {
    const activeBlock = this.action.blocks.find(block => block.active);

    if (activeBlock) {
      return activeBlock.id;
    } else if (this.action.blocks.length > 0) {
      this.activateDefaultBlock.emit(true);
      return this.action.blocks[0].id;
    } else {
      return undefined;
    }
  }

  fetchBlock(filtering?: boolean) {
    if (this.getActiveBlockId()) {
      this.loading = true;

      if (!filtering) {
        this.isFiltersOpen = false;
        this.itemSorted = [];
        this.itemFiltered = [];
      }

      this.businessService
        .getActionBlock(this.action.id, this.getActiveBlockId(), undefined, undefined, this.itemFiltered, this.itemSorted)
        .subscribe(data => {
          this.block = data;
          if (!filtering) {
            this.filters = data?.data;
          }
          this.isMoreBlocks = data.pagination.item_count / data.pagination.limit > data.pagination.page;
          this.loading = false;
          this.calculateFiltersCount();
        });
    }
  }

  changeSort(id, type) {
    const selectedSort = { id: id, direction: this.itemSorted[id]?.direction };
    this.itemSorted = [];
    switch (selectedSort.direction) {
      case undefined:
        this.itemSorted[selectedSort.id] = { direction: "asc", type: type };
        break;
      case "asc":
        this.itemSorted[selectedSort.id] = { direction: "desc", type: type };
        break;
      case "desc":
        this.itemSorted[selectedSort.id] = { direction: undefined, type: type };
        break;
    }
  }

  onFilterChange(newValue: string, id: number) {
    this.itemFiltered[id] = newValue;
  }

  setFilters() {
    this.fetchBlock(true);
    this.isFiltersOpen = false;
  }

  loadMoreBlockData() {
    this.loading = true;
    this.businessService
      .getActionBlock(
        this.action.id,
        this.getActiveBlockId(),
        this.block.pagination.page + 1,
        undefined,
        this.itemFiltered,
        this.itemSorted
      )
      .subscribe(data => {
        this.block.pagination = data.pagination;
        this.block.data = [...this.block.data, ...data.data];
        this.isMoreBlocks = data.pagination.item_count / data.pagination.limit > data.pagination.page;
        this.loading = false;
      });
  }
}
