import { state } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../../core/providers/currency.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { ListChallenge } from "../../../interfaces/custom/challenges.interface";
import { AwardPrize, BadgeColors, BadgeStatus } from "../../../interfaces/custom/common-business.interface";
import { Plan } from "../../../interfaces/custom/plans.interface";
import { ChallengesDetailsComponent } from "../challenges-details/challenges-details.component";
import { ClientListComponent } from "../client-list/client-list.component";
import { PlanDetailsComponent } from "../plan-details/plan-details.component";

type MODE = "PLANS" | "CHALLENGES";

type BusinessData = {
  PLANS: {
    [key: string]: { blockPrev: boolean; blockNext: boolean; data: Plan[] };
  };
  CHALLENGES: {
    [key: string]: { blockPrev: boolean; blockNext: boolean; data: ListChallenge[] };
  };
};

type Config = {
  PLANS: {
    blockBusinessButton: MODE;
    title: string;
  };
  CHALLENGES: {
    blockBusinessButton: MODE;
    title: string;
  };
};

enum SortValues {
  CLEARED = 0,
  OPEN = 1000,
  VOTING = 2000,
}

@Component({
  selector: "app-plan-list",
  templateUrl: "./plan-list.component.html",
})
export class PlanListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data?: {
    mode: MODE;
  };
  imagePath = window.location.origin.includes("localhost") ? "https://eurocash4.o-w.dev/" : window.location.origin + "/";
  owDate: OwDate = null; // current date from picker
  maxPaginationDate = null; // max date that can't be exceeded in paginator
  currentTabDate = ""; // key for object elements, 'month_year', for instance '10_23'
  businessDataList: BusinessData = {
    PLANS: {},
    CHALLENGES: {},
  };
  loading = false;
  currencyDefinitions = null;
  config: Config = {
    PLANS: {
      blockBusinessButton: "PLANS",
      title: "Plan",
    },
    CHALLENGES: {
      blockBusinessButton: "CHALLENGES",
      title: "Wyzwania",
    },
  };

  ngOnInit() {
    // initiate paginator
    this.owDate = new OwDate();
    this.maxPaginationDate = this.getNextTabDate(new OwDate());
    this.updateCurrentTabDate();

    this.currencyDefinitions = this.getCurrencyDefinition();

    this.data.mode === "PLANS" ? this.fetchPlans() : this.fetchChallenges();

    if (window["gtag"]) {
      const label = "Lista " + (this.data.mode === "PLANS" ? "planów" : "wyzwań");
      window["gtag"]("event", label, {
        event_category: label,
        event_label: label,
        custom_dimension_hrid: this.playerService?.player?.id,
        custom_dimension_warehouse_id: this.playerService?.player?.branch_id,
      });
    }
  }

  getData() {
    switch (this.data.mode) {
      case "PLANS": {
        if (!this.businessDataList.PLANS[this.currentTabDate]) {
          this.fetchPlans();
        }
        break;
      }
      case "CHALLENGES": {
        if (!this.businessDataList.CHALLENGES[this.currentTabDate]) {
          this.fetchChallenges();
        }
        break;
      }
    }
  }

  fetchPlans() {
    this.loading = true;
    this.businessService.playerPlans(this.owDate.year, this.owDate.month).subscribe(data => {
      data.forEach(el => {
        el.badge = this.determineBadge(el);
      });
      this.businessDataList.PLANS[this.currentTabDate] = {
        data: data.sort((a, b) => b.sortValue - a.sortValue),
        blockNext: this.currentTabDate === this.maxPaginationDate,
        blockPrev: this.owDate.month === 12 && this.owDate.year === 2023,
      };
      this.loading = false;
    });
  }

  fetchChallenges() {
    this.loading = true;
    this.businessService.getChallengesList(this.owDate.year, this.owDate.month).subscribe(data => {
      for (const el of data) {
        el.badge = this.determineBadge(el);
        el.currency_prizes.forEach(prize => {
          const rewarded = el.rewarded_currency_prizes.find(x => x.currency_id === prize.currency_id);
          if (rewarded) {
            prize.rewarded_amount = rewarded.amount;
          }
        });
      }
      this.businessDataList.CHALLENGES[this.currentTabDate] = {
        data: data.sort((a, b) => b.sortValue - a.sortValue),
        blockNext: this.currentTabDate === this.maxPaginationDate,
        blockPrev: this.owDate.month === 12 && this.owDate.year === 2023,
      };
      this.loading = false;
    });
  }

  determineBadge(el: ListChallenge | Plan) {
    let daysDiff = null;
    if (el.priority == null) {
      el.priority = 0;
    }

    if ("player_challenge_id" in el) {
      daysDiff = moment(el.vote_end_date).diff(moment().startOf("day"), "days");
      el.sortValue = SortValues.VOTING + el.priority;

      if (daysDiff != null && daysDiff > 0) {
        return {
          color: BadgeColors.red,
          title: "Wybór trudności - ",
          description: " Pozostało dni: " + daysDiff,
          status: BadgeStatus.VOTING,
        };
      }
    }

    if (!el.target) {
      el.sortValue = SortValues.OPEN + el.priority;
      return { color: BadgeColors.green, title: "", description: "W trakcie parametryzacji", status: BadgeStatus.TO_PARAMETRIZE };
    }

    if (el.is_cleared) {
      el.sortValue = SortValues.CLEARED + el.priority;
      return { color: BadgeColors.green, title: "", description: el.is_cleared ? "Rozliczone" : "", status: BadgeStatus.CLEARED };
    }

    if ("player_challenge_id" in el) {
      el.sortValue = SortValues.OPEN + el.priority;
      daysDiff = moment(el.vote_end_date).diff(moment().startOf("day"), "days");
      if (daysDiff != null && daysDiff <= 0) {
        return {
          color: BadgeColors.yellow,
          title: "",
          description: "Ważne do: " + moment(el.task_end_date).format("DD.MM"),
          status: BadgeStatus.ACTIVE,
        };
      }
    }

    el.sortValue = SortValues.OPEN + el.priority;
    return { color: BadgeColors.green, title: "", description: "", status: BadgeStatus.TO_PARAMETRIZE };
  }

  getPrize(prizeArrr: AwardPrize[], currencyId: number) {
    return prizeArrr.find(prize => prize.currency_id === currencyId);
  }

  getCurrencyDefinition() {
    const currencyDefinitions = {};
    for (const currency of this.currencyService.currencyDefinitions) {
      currencyDefinitions[currency.currency_id] = currency;
    }
    return currencyDefinitions;
  }

  // button prev action; change pagination date
  prevMonth() {
    if (this.loading) {
      return;
    }

    if (this.owDate.month > 1) {
      this.owDate.set({ month: this.owDate.month - 1, year: this.owDate.year });
    } else {
      this.owDate.set({ month: 12, year: this.owDate.year - 1 });
    }

    this.updateCurrentTabDate();
    this.getData();
  }

  // button next action; change pagination date
  nextMonth() {
    if (this.loading) {
      return;
    }

    if (this.owDate.month < 12) {
      this.owDate.set({ month: this.owDate.month + 1, year: this.owDate.year });
    } else {
      this.owDate.set({ month: 1, year: this.owDate.year + 1 });
    }

    this.updateCurrentTabDate();
    this.getData();
  }

  // get reference name for previous month data object
  getNextTabDate(owDate: OwDate): string {
    if (this.owDate.month < 12) {
      return `${this.owDate.month + 1}_${owDate.year}`;
    } else {
      return `1_${owDate.year + 1}`;
    }
  }

  // updates current date text; reference switching purposes
  updateCurrentTabDate() {
    this.currentTabDate = `${this.owDate.month}_${this.owDate.year}`;
  }

  redirectToDetails(index: number) {
    const dialogData = {
      date: this.owDate,
      index: index,
    };

    switch (this.data.mode) {
      case "PLANS": {
        dialogData["plans"] = this.businessDataList["PLANS"]?.[this.currentTabDate]?.data;
        this.dialogService.open(PlanDetailsComponent, { data: dialogData });
        break;
      }
      case "CHALLENGES": {
        dialogData["challenges"] = this.businessDataList["CHALLENGES"]?.[this.currentTabDate]?.data;
        this.dialogService.open(ChallengesDetailsComponent, { data: dialogData });
        break;
      }
    }
  }

  redirectToList(index: number, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    // if (!this.businessDataList[this.data.mode]?.[this.currentTabDate]?.data[index]?.client_realizations) {
    //   return false;
    // }

    this.dialogService.open(ClientListComponent, {
      data: {
        clientList: this.businessDataList[this.data.mode]?.[this.currentTabDate]?.data[index],
        listType: this.data.mode,
      },
    });
  }

  markAsInvalid(el) {
    el.logo = "";
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
  protected readonly state = state;
}
