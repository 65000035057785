import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NEVER, Observable, of, throwError, timer } from "rxjs";
import { catchError, delayWhen, finalize, mergeMap, retryWhen } from "rxjs/operators";

import { translate } from "../../../core/helpers/translate.helper";
import { OwHttpErrorResponse } from "../../../core/interfaces/ow-http-error-response.interface";
import { GlobalService } from "../../../core/providers/global.service";
import { LoadingService } from "../../../core/providers/loading.service";
import { PasswordActions } from "../../../store/auth/change-password";
import { AppState } from "../../../store/state";
import { DialogService } from "../../shared/providers/dialog.service";
import { checkIsDoubleRequest } from "../helpers/check-is-double-request.helper";
import { checkPairRequest } from "../helpers/check-pair-request.helper";
import { isOnWhitelist } from "../helpers/is-on-whitelist.helpers";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  requests: HttpRequest<any>[] = [];

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    public loadingService: LoadingService,
    public globalService: GlobalService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let retryAttempt = 1;

    const cloned = <any>req.clone();

    if (checkIsDoubleRequest(cloned, this.requests) || checkPairRequest(cloned, this.requests)) {
      return NEVER;
    }

    this.requests.push(cloned);
    if (!cloned.params.get("isNotShowLoading")) {
      this.loadingService.show();
    }
    return next.handle(cloned).pipe(
      retryWhen(errors => {
        return errors.pipe(
          mergeMap(error => {
            if (error.status === 529) {
              cloned.headers = cloned.headers.set("X-Retry-Attempt", `${retryAttempt}`);
              retryAttempt++;
              return of(error);
            }

            return throwError(error);
          }),
          delayWhen(() => timer(200 * retryAttempt))
        );
      }),
      finalize(() => {
        this.removeRequestFromArray(cloned);

        if (this.requests.length === 0) {
          this.loadingService.hide();
        }
      }),
      catchError((errResp: OwHttpErrorResponse) => {
        const debugLink = errResp.headers.get("x-debug-token-link");
        if (errResp.url?.includes("/main/build.txt") && errResp.status === 0) {
          return of(null);
        }
        errResp.defaultHandler = timer(0).subscribe(() => {
          if (!isOnWhitelist(cloned.url)) {
            switch ((<HttpErrorResponse>errResp).status) {
              case 409:
                if (errResp.error.error === "must-change-password") {
                  const token = errResp.error.token;
                  setTimeout(() => {
                    this.store.dispatch(new PasswordActions.MustChange({ token }));
                  });
                } else {
                  this.dialogService.openAlertErrorApi({ errResp });
                }
                break;

              case 0:
              case 500:
              case 502:
                this.dialogService.openAlert({
                  description: `
                  ${translate("global.alert-500")}
                  ${
                    this.globalService.isDevDomain
                      ? translate("global.alert-500-message", {
                          error: errResp,
                          debugLink,
                        })
                      : ""
                  }
                `,
                });
                break;

              case 503:
                location.reload();
                break;

              default:
                this.dialogService.openAlertErrorApi({ errResp });
            }
          }
        });

        return throwError(errResp);
      })
    );
  }

  removeRequestFromArray(request: HttpRequest<any>) {
    this.requests = this.requests.filter(r => r !== request);
  }
}
