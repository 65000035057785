import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../../../../../../core/providers/api.service";
import {
  Action,
  ActionBlock,
  ActionBlockData,
  ActionBlockDetails,
  ActionDetails,
  ActionFile,
} from "../../../interfaces/custom/actions.interface";
import { Challenge, ListChallenge } from "../../../interfaces/custom/challenges.interface";
import { Plan } from "../../../interfaces/custom/plans.interface";

@Injectable({
  providedIn: "root",
})
export class ApiCustomBusinessService {
  constructor(private apiService: ApiService) {}

  playerPlans(year: number, month: number): Observable<Plan[]> {
    return this.apiService.get(`plans/${year}/${month}`);
  }

  playerPlanById(id: number): Observable<Plan> {
    return this.apiService.get(`plan/${id}`);
  }

  getChallengesList(year: number, month: number): Observable<ListChallenge[]> {
    return this.apiService.get(`challenges/${year}/${month}`);
  }

  getChallengeDetails(playerChallengeId: number) {
    return this.apiService.get(`challenge/${playerChallengeId}`);
  }

  voteForChallenge(playerChallengeId: number) {
    return this.apiService.post(`challenge/vote/${playerChallengeId}`);
  }

  getActionsList(): Observable<Action[]> {
    return this.apiService.get(`actions`);
  }

  getActionConsents(actionId: number): Observable<ActionFile[]> {
    return this.apiService.get(`actions/${actionId}/consents`);
  }

  getActionDetails(actionId: number): Observable<ActionDetails> {
    return this.apiService.get(`actions/${actionId}/details`);
  }

  getActionBlock(
    actionId: number,
    blockId: number,
    page?: number,
    limit?: number,
    filters?: string[],
    sorting?: { direction?: string; type: ActionBlockData["label_type"] }[]
  ): Observable<ActionBlockDetails> {
    const queryParams: any = {
      page,
      limit,
      ...(filters && filters.length > 0 ? this.convertFiltersToQueryParams(filters) : {}),
      ...(sorting && sorting.length > 0 ? this.convertSortingToQueryParams(sorting) : {}),
    };

    return this.apiService.get(`actions/${actionId}/blocks/${blockId}/details`, {
      params: queryParams,
    });
  }

  convertFiltersToQueryParams(filters: any[]): any {
    const queryParams: any = {};
    filters.forEach((filter, index) => {
      if (filter !== null && filter !== undefined) {
        queryParams[`filters[${index}]`] = filter;
      }
    });
    return queryParams;
  }

  convertSortingToQueryParams(sort: { direction?: string; type: ActionBlockData["label_type"] }[]): string[] {
    const queryParams: any = {};
    sort.forEach((sort, index) => {
      if (sort !== null && sort !== undefined) {
        queryParams[`sorts[${index}][${sort.type}]`] = sort.direction;
      }
    });
    return queryParams;
  }

  submitActionConsents(actionId: number, data: ActionFile[]) {
    return this.apiService.patch(`actions/${actionId}/consents`, {
      body: data,
    });
  }
}
