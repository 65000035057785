import { Component, Input, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { ActionDetails } from "../../../interfaces/custom/actions.interface";

@Component({
  selector: "app-action-details",
  templateUrl: "./action-details.component.html",
  styleUrls: ["./action-details.component.scss"],
})
export class ActionDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @Input() actionId: number;
  details: ActionDetails;
  loading: boolean = false;

  ngOnInit(): void {
    this.getFormDataFromApi();
  }

  public getFormDataFromApi() {
    this.loading = true;
    this.businessService.getActionDetails(this.actionId).subscribe(data => {
      this.details = data;
      this.loading = false;
    });
  }
}
