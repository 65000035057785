import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { EVENT_DIALOGS_NAMES_QA } from "../../core/event-dialogs/event-names.const";
import { MQaListComponent } from "../../../base/custom/dialogs/qa-list/qa-list.component";
import { MQaDetailsComponent } from "../../../base/custom/dialogs/qa-details/qa-details.component";
import { MQaFinishComponent } from "../../../base/custom/dialogs/qa-finish/qa-finish.component";

export const EVENT_DIALOGS_QA_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_QA.QA_LIST]: MQaListComponent,
  [EVENT_DIALOGS_NAMES_QA.QA_DETAILS]: MQaDetailsComponent,
  [EVENT_DIALOGS_NAMES_QA.QA_FINISH]: MQaFinishComponent,
};
