<div class="wrapper">
  <loading></loading>
  <button class="auth-button primary back-button" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN">
    <i class="fas fa-arrow-left-to-line"></i>
  </button>
  <!-- LOGO -->
  <div class="logo"></div>

  <span *ngIf="!formSendSuccess" class="text-body">Wypełnij formularz:</span>

  <div class="content-wrapper form">
    <!-- SEND SUCCESS INFO -->
    <ng-container *ngIf="formSendSuccess; else formTpl">
      <span class="text-body">
        Twoje zgłoszenie zostało wysłane. <br />
        Pracujemy nad nim i wkrótce się z Tobą skontaktujemy, maksymalnie będą to dwa dni robocze. <br />
        Kopia zgłoszenia została wysłana również na Twój adres e-mail.
      </span>
    </ng-container>

    <ng-template #formTpl>
      <form [formGroup]="form" *ngIf="form">
        <!-- EMAIL -->
        <ng-container *ngIf="form.get('email') as email">
          <div class="control" [class.error]="getErrors(email)">
            <div class="label-flex">
              <label>Email:</label>
            </div>
            <input class="control-input" formControlName="email" type="text" />
            <div class="alert alert-danger" *ngIf="getErrors(email)">
              <span *ngIf="email.errors.email">Błędny adres e-mail</span>
              <span *ngIf="email.errors.required">Pole wymagane</span>
            </div>
          </div>
        </ng-container>

        <!-- BRANCH -->
        <ng-container *ngIf="form.get('second_map') as branch">
          <div class="control" [class.error]="getErrors(branch)">
            <div class="label-flex">
              <label>ID Hali (nieobowiązkowe):</label>
            </div>
            <input class="control-input" formControlName="branch" type="text" />
            <div class="alert alert-danger" *ngIf="getErrors(branch)">
              <span *ngIf="branch.errors.required">Pole wymagane</span>
            </div>
          </div>
        </ng-container>

        <!-- ROLE -->
        <ng-container *ngIf="form.get('role') as role">
          <div class="control" [class.error]="getErrors(role)">
            <div class="label-flex">
              <label>Stanowisko:</label>
            </div>
            <input class="control-input" formControlName="role" type="text" />
            <div class="alert alert-danger" *ngIf="getErrors(role)">
              <span *ngIf="role.errors.required">Pole wymagane</span>
            </div>
          </div>
        </ng-container>

        <!-- HDID -->
        <ng-container *ngIf="form.get('hrid')">
          <div class="control">
            <div class="label-flex">
              <label>HRID:</label>
            </div>
            <input class="control-input" formControlName="hrid" type="text" />
          </div>
        </ng-container>

        <!-- MESSAGE -->
        <ng-container *ngIf="form.get('description') as description">
          <div class="control" [class.error]="getErrors(description)">
            <div class="label-flex">
              <label>Opis:</label>
            </div>
            <textarea class="control-input textarea" formControlName="description" rows="5"></textarea>
            <div class="alert alert-danger" *ngIf="getErrors(description)">
              <span *ngIf="description.errors.required">Pole wymagane</span>
            </div>
          </div>
        </ng-container>

        <div class="control control-checkbox">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirm" id="confirm" required />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>

          <label for="confirm">
            Wyrażam zgodę na przetwarzanie moich danych osobowych przez Eurocash S.A. w celu udzielenia odpowiedzi na powyższe zgłoszenie.
            Pełna informacja o zasadach przetwarzania danych znajduje się
            <a
              [href]="
                'https://euromisja.pl/api/files/download/b2018129-9438-40c0-b664-de6f91cc7e10/Informacje%20o%20przetwarzaniu%20danych%20osobowych.docx.pdf'
              "
              target="_blank">
              tutaj </a
            >.
          </label>
        </div>
      </form>

      <button class="auth-button primary" (click)="report()" [disabled]="form.invalid" *ngIf="!formSendSuccess">Wyślij</button>
    </ng-template>
  </div>
</div>
