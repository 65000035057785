import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../../constants";
import {
  CUSTOM_CITY_SCENE_CONFIG_KEY,
  CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
  CUSTOM_THIRD_MAP_SCENE_CONFIG_KEY,
} from "../../../../../game-engine/consts/custom/game-map-custom.const";
import { BASIC_BOOT_SCENE } from "../../../../../game-engine/scenes-basic/basic.constants";
import { ChangeMapService } from "../core/change-map.service";

@Injectable({
  providedIn: "root",
})
export class ChangeMapCustomService extends ChangeMapService {
  countryPlayerIslandId: number;
  branchPlayerIslandId: number;
  cityPlayerIslandId: number;

  constructor(
    public globalService: GlobalService,
    private playerService: PlayerService
  ) {
    super(globalService);
    this.subscribePlayerRegions();
  }

  subscribePlayerRegions() {
    this.subs.playerRegions = this.playerService.playerRegions
      .pipe(filter(playerRegions => playerRegions != null))
      .subscribe(playerRegions => {
        console.log("player regions ", playerRegions);
        const islands = playerRegions.regions.reduce((_islands, region) => {
          _islands.push(...region.islands);
          return _islands;
        }, []);

        // Country
        const country = islands.find(_island => _island.config_name === CUSTOM_THIRD_MAP_SCENE_CONFIG_KEY);
        if (country) {
          this.countryPlayerIslandId = country.player_island_id;
        }

        // BRANCH
        const branch = islands.find(_island => _island.config_name === CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY);
        if (branch) {
          this.branchPlayerIslandId = branch.player_island_id;
        }

        const city = islands.find(_island => _island.config_name === CUSTOM_CITY_SCENE_CONFIG_KEY);
        if (city) {
          this.cityPlayerIslandId = city.player_island_id;
        }
      });
  }

  goToThirdMap() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: "default",
        playerIslandId: this.countryPlayerIslandId,
      },
    });
  }

  goToSecondMap() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: CUSTOM_SECOND_MAP_SCENE_CONFIG_KEY,
        playerIslandId: this.branchPlayerIslandId,
      },
    });
  }

  goToCityMap() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: CUSTOM_CITY_SCENE_CONFIG_KEY,
        playerIslandId: this.cityPlayerIslandId,
      },
    });
  }
}
