<div class="wrapper">
  <button *ngIf="showBackButton" class="business-button-round fixed c-green radius-inverted" (click)="whileBack()">
    <i class="far fa-arrow-to-left"></i>
  </button>
  <div class="business-button-round c-green title">
    <p>{{ title }}</p>
  </div>
  <button *ngIf="showDetailsButton" class="business-button-round fixed c-green radius-inverted" (click)="whileDetails()">
    <i class="far fa-info"></i>
  </button>
</div>
