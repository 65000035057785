import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";

import { LINKS } from "../../../../../../core/consts/core/links.const";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { AbstractRegistrationConfirmAComponent } from "../../../../abstract/registration-confirm/variant-a/abstract-registration-confirm-a.component";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../consts/core/routing-auth-absolute.const";
import { TokenObject } from "../../../../interfaces/token-object";

@Component({
  selector: "app-oauth-confirm",
  templateUrl: "./oauth-confirm.component.html",
})
export class OauthConfirmComponent extends AbstractRegistrationConfirmAComponent implements OnInit {
  LINKS = LINKS;

  ngOnInit() {
    this.subscribeRoutingParams();
  }

  clearForm() {
    this.form = this.fb.group({
      token: this.fb.control(this.token, [Validators.required]),
      username: this.fb.control(null, [Validators.required, Validators.maxLength(23)]),
      confirm: this.fb.control(null, [Validators.required]),
      confirmRodo: this.fb.control(null, [Validators.required]),
    });
  }

  registrationConfirm() {
    const data: RegistrationConfirmDataRequest = {
      token: this.form.value.token,
      username: this.form.value.username,
    };

    this.confirmRegistrationRequest(data).subscribe(
      (resp: TokenObject) => {
        this.dispatchToken(resp);
        this.routeToPlayerChoose();
      },
      errResp => {
        if (errResp.status === 404) {
          this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
          this.dialogService.openAlert({ title: "Token wygasł, spróbuj ponownie." });
        } else {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      }
    );
  }

  confirmRegistrationRequest(data: RegistrationConfirmDataRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post(this.devLogin ? "auth/oauth2/test/confirm" : "auth/oauth2/confirm", options);
  }

  onPaste(event) {
    event.preventDefault();
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }
}

interface RegistrationConfirmDataRequest {
  token: string;
  username: string;
}
