<div class="global-dialog ow-dialog window-a primary">
  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- DIALOG TITLE -->
  <div class="top-bar">
    <div class="title">Twoja karta przedpłacona Edenred</div>
  </div>

  <div class="content">
    <p>
      Poniżej znajdziesz historię przypisanych do swojego konta kart Edenred, aby poznać historię zasileń kliknij <br />
      w ikonę po prawej stronie przy wybranej karcie.<br />
      Pamiętaj, że nagroda może zostać wypłacona tylko na aktywną kartę.
    </p>
    <div class="list-wrapper">
      <div class="header">
        <p>Nr seryjny karty:</p>
        <p>Status:</p>
        <p>Data aktywacji:</p>
        <p>Data zablokowania:</p>
        <p>Zasilenia karty:</p>
      </div>
      <ow-perfect-scrollbar class="height-auto">
        <div class="body">
          <div class="card" *ngFor="let card of cardList">
            <p>{{ card.number }}</p>
            <p class="{{ card.status === CardStatus.Active ? 'active' : 'inactive' }}">
              {{ card.status === CardStatus.Active ? "Aktywna" : "Zablokowana" }}
            </p>
            <p>{{ card.activated_at | date: "yyyy-MM-dd" }}</p>
            <p>{{ card.blocked_at | date: "yyyy-MM-dd" }}</p>
            <p (click)="openTransaction(card.card_id)">
              <i class="fa-regular fa-chevron-right"></i>
            </p>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>
</div>
