import { PortalModule } from "@angular/cdk/portal";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { SharedModule } from "../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../shared-ui/mobile/shared-ui-mobile.module";
import { ActionBlocksContentComponent } from "../base/components/action-blocks/action-blocks-content.component";
import { ActionBlocksHeaderComponent } from "../base/components/action-blocks/action-blocks-header.component";
import { ActionConsentsFormComponent } from "../base/components/action-consents-form/action-consents-form.component";
import { ActionDetailsComponent } from "../base/components/action-details/action-details.component";
import { ActionHeaderComponent } from "../base/components/action-header/action-header.component";
import { ActionNavigationComponent } from "../base/components/action-navigation/action-navigation.component";
import { BusinessNavigationComponent } from "../base/components/business-navigation/business-navigation.component";
import { ActionItemComponent } from "../base/dialogs/action-item/action-item.component";
import { ActionListComponent } from "../base/dialogs/action-list/action-list.component";
import { ChallengesDetailsComponent } from "../base/dialogs/challenges-details/challenges-details.component";
import { ChallengesDifficultyLevelComponent } from "../base/dialogs/challenges-difficulty-level/challenges-difficulty-level.component";
import { ClientListComponent } from "../base/dialogs/client-list/client-list.component";
import { NewBusinessContainerComponent } from "../base/dialogs/new-business-container/new-business-container.component";
import { PlanDetailsComponent } from "../base/dialogs/plan-details/plan-details.component";
import { PlanListComponent } from "../base/dialogs/plan-list/plan-list.component";
import { PlanShopListComponent } from "../base/dialogs/plan-shop-list/plan-shop-list.component";
import { TasksDailyComponent } from "../base/dialogs/tasks-daily/tasks-daily.component";
import { TasksListComponent } from "../base/dialogs/tasks-list/tasks-list.component";
import { TasksWeeklyComponent } from "../base/dialogs/tasks-weekly/tasks-weekly.component";
import { ToFixedPipe } from "../pipes/toFixed.pipe";

@NgModule({
  declarations: [
    PlanListComponent,
    ActionListComponent,
    ActionItemComponent,
    ClientListComponent,
    ChallengesDifficultyLevelComponent,
    ChallengesDetailsComponent,
    PlanDetailsComponent,
    PlanShopListComponent,
    TasksListComponent,
    TasksDailyComponent,
    TasksWeeklyComponent,
    NewBusinessContainerComponent,
    BusinessNavigationComponent,
    ActionNavigationComponent,
    ActionHeaderComponent,
    ActionConsentsFormComponent,
    ActionDetailsComponent,
    ActionBlocksHeaderComponent,
    ActionBlocksContentComponent,
    ToFixedPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedUiMobileModule,
    MatTableModule,
    FormsModule,
    MatSortModule,
    OwPerfectScrollbarModule,
    PortalModule,
    NgOptimizedImage,
    ReactiveFormsModule,
  ],
  exports: [ActionNavigationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BusinessModule {}
