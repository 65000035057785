<app-device-rotate></app-device-rotate>
<div class="global-dialog ow-dialog window-a primary no-transparent">
  <div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" blockButton="{{ listType }}">
      <ng-container left-top>
        <button class="business-button-round fixed c-green" (click)="backToPreviousDialog()">
          <i class="far fa-arrow-to-top"></i>
        </button>
      </ng-container>
      <ng-container left-bottom>
        <button class="business-button-round fixed c-green" (click)="backToPreviousDialog()">
          <i class="far fa-info-circle"></i>
        </button>
      </ng-container>
    </app-business-navigation>

    <div class="content">
      <div class="client-list">
        <div class="title-container">
          <div class="logo">
            <img
              *ngIf="clientList.logo"
              [title]="clientList.name"
              [alt]="clientList.name"
              src="{{ imagePath }}/{{ clientList.logo }}"
              (error)="markAsInvalid(clientList)" />
            <div *ngIf="!clientList.logo" class="company-name" title="{{ clientList.name }}">
              <p>{{ clientList.name }}</p>
            </div>
          </div>
          <div *ngIf="data.badge?.description" class="badge {{ data.badge.color }}">
            <p>{{ data.badge?.title }}</p>
            <p>{{ data.badge?.description }}</p>
          </div>
        </div>
        <div class="activity-name" [innerHTML]="clientList?.logo ? clientList.name : ''"></div>
        <div class="search">
          <div class="search-bar">
            <input [(ngModel)]="searchString" (ngModelChange)="onSearchStringChange()" type="text" name="shop" placeholder="Szukaj" />
            <i class="far fa-search"></i>
          </div>
        </div>
        <div class="table">
          <div class="table-headers">
            <button class="header-id" (click)="sortBy('external_id')">
              ID Klienta
              <i
                class="fas"
                [ngClass]="{
                  'fa-sort': currentSortColumn !== 'external_id',
                  'fa-sort-asc': currentSortColumn === 'external_id' && sortDirection === 'asc',
                  'fa-sort-desc': currentSortColumn === 'external_id' && sortDirection === 'desc'
                }"></i>
            </button>

            <button class="header-name" (click)="sortBy('full_name')">
              Nazwa i adres
              <i
                class="fas"
                [ngClass]="{
                  'fa-sort': currentSortColumn !== 'full_name',
                  'fa-sort-asc': currentSortColumn === 'full_name' && sortDirection === 'asc',
                  'fa-sort-desc': currentSortColumn === 'full_name' && sortDirection === 'desc'
                }"></i>
            </button>

            <button class="header-performance" (click)="sortBy('performance')">
              Realizacja

              <i
                class="fas"
                [ngClass]="{
                  'fa-sort': currentSortColumn !== 'performance',
                  'fa-sort-asc': currentSortColumn === 'performance' && sortDirection === 'asc',
                  'fa-sort-desc': currentSortColumn === 'performance' && sortDirection === 'desc'
                }"></i>
            </button>
          </div>
          <ow-perfect-scrollbar scrollGradient>
            <table mat-table [dataSource]="dataAdjusted" class="mat-elevation-z8">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element" [ngClass]="{'no-data': !element.full_name}">{{ element.full_name || 'Brak danych'}} </td>
              </ng-container>

              <ng-container matColumnDef="external_id">
                <td mat-cell *matCellDef="let element">{{ element.external_id }}</td>
              </ng-container>

              <ng-container matColumnDef="performance">
                <td mat-cell *matCellDef="let element">{{ element.performance }}</td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-style"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data-match" colspan="4">Brak wyników</td>
              </tr>
            </table>
          </ow-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>
