<div class="wrapper">
  <div class="content">
    <div class="inner">
      <!-- HEADER -->
      <div class="head">
        <m-ui-close-button [preventDefault]="true" (click)="close(true)"></m-ui-close-button>
        <h2 class="title">Komunikat cookies</h2>
        <div class="clear"></div>
      </div>

      <div class="row paragraphs">
        Strona korzysta z plików cookie. Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce -
        <a [href]="FILE_LINKS.GPDR" target="_blank"><strong>więcej informacji</strong></a
        >.
      </div>

      <div class="row">
        <div class="checkbox">
          <div class="control control-checkbox">
            <div class="custom-checkbox disabled" [ngClass]="{ hidden: !configureOn }">
              <input type="checkbox" id="typeNecessary" name="necessary" checked disabled />
              <span class="custom-checkbox-fill">
                <i class="fas fa-check"></i>
              </span>
            </div>

            <label for="typeNecessary">
              <strong>Techniczne i bezwzględnie niezbędne pliki cookie</strong><br />
              Pliki niezbędne do prawidłowego funkcjonowania strony internetowej lub świadczenia usługi żądanej przez użytkownika. W związku
              z tym pliki cookie do personalizacji, które umożliwiają użytkownikom zapamiętanie informacji o pewnych cechach, które mogą
              odróżniać ich doświadczenia od innych użytkowników, takich jak język lub liczba wyników wyświetlanych w wyszukiwaniu, są
              uważane za konieczne, a zatem nie wymagają udzielenia zgody.
            </label>
          </div>
        </div>
        <div class="checkbox">
          <div class="control control-checkbox">
            <div class="custom-checkbox" [ngClass]="{ hidden: !configureOn }">
              <input type="checkbox" id="typeAnalytics" name="analytics" />
              <span class="custom-checkbox-fill">
                <i class="fas fa-check"></i>
              </span>
            </div>

            <label for="typeAnalytics">
              <strong>Analityczne pliki cookies</strong><br />
              Ten rodzaj plików cookie pozwala nam rozpoznać i uwzględnić liczbę odwiedzających naszą witrynę internetową oraz analizować
              sposób jej przeglądania i korzystania. Umożliwiają także wykonywanie profili przeglądania. Nie zbierają danych osobowych.
              Należą do nich Google Analytics, usługa analizy sieci świadczona przez Google, Inc. Więcej informacji na temat sposobu
              gromadzenia i przetwarzania przez Google można znaleźć na stronie
              <a [attr.href]="'https://policies.google.com/privacy?hl=pl'" target="_blank"> https://policies.google.com/privacy?hl=pl </a>
            </label>
          </div>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="row row-btn">
        <div *ngIf="!configureOn">
          <button id="configuration-btn" (click)="configure()" class="base primary">Konfiguruj</button>
        </div>
        <div class="submit-wrapper" *ngIf="configureOn">
          <div class="submit-container second">
            <button class="base secondary" (click)="submitSelected()">Zaakceptuj zaznaczone</button>
          </div>
        </div>
        <div class="submit-container third">
          <button class="base red" (click)="submitAll()">Zaakceptuj wszystkie</button>
        </div>
      </div>
    </div>
  </div>
</div>
