import { Injectable, OnDestroy } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { PrimarySelectors } from "../../../store/primary";
import { MOCK_PRIMARY } from "../mock/consts/mock-primary.const";

@Injectable({
  providedIn: "root",
})
export class ConfigPrimaryService implements OnDestroy {
  $config = null;
  config = MOCK_PRIMARY;

  constructor(private store: Store) {
    this.subscribeData();
  }

  private subscribeData() {
    this.$config = this.store.pipe(take(1), select(PrimarySelectors.selectPrimary)).subscribe(config => {
      this.config = config;
    });
  }

  ngOnDestroy() {
    this.$config.unsubscribe();
  }
}
