import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import * as moment from "moment/moment";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { LoadingService } from "../../../../../../../../core/providers/loading.service";
import { AbstractQaSummaryComponent } from "../../../../abstract/core/abstract-qa-summary.component";
import { QaCustom } from "../../../../interfaces/custom/qa.interface";

@Component({
  selector: "app-custom-qa-summary",
  templateUrl: "./qa-summary.component.html",
})
export class MQaSummaryComponent extends AbstractQaSummaryComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @OwInject(LoadingService) loadingService: LoadingService;

  @Input() qa: QaCustom;
  @Output() refreshQaList = new EventEmitter();
  prizeReductionTimer;
  intervalTimer;
  isLoading = true;

  ngOnInit(): void {
    this.afterQa();

    if (this.qa.prize_reduction?.valid_till) {
      this.setTimer();
    }
  }

  ngAfterViewInit() {
    this.checkAnyElScoreAndPrizes();
  }

  ngOnChanges() {
    this.afterQa();
    this.checkAnyElScoreAndPrizes();
  }

  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = new Date().getTime();
      const expiredAt = new Date(this.qa.prize_reduction.valid_till).getTime();
      this.prizeReductionTimer = moment(expiredAt).diff(moment(actualDate), "seconds");
      this.isLoading = false;
      if (this.prizeReductionTimer <= 0) {
        clearInterval(this.intervalTimer);
        this.refreshQaListEmit();
      }
    }, 1000);
  }

  refreshQaListEmit() {
    this.refreshQaList.emit();
    this.loadingService.show();
    setTimeout(() => {
      this.ngOnInit();
    }, 2050);
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
  }
}
