import { Component, EventEmitter, Input, Output } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiCustomBusinessService } from "../../../api/custom/services/api-custom-business.service";
import { ActionBlock } from "../../../interfaces/custom/actions.interface";

@Component({
  selector: "app-action-blocks-header",
  templateUrl: "./action-blocks-header.component.html",
  styleUrls: ["./action-blocks-header.component.scss"],
})
export class ActionBlocksHeaderComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiCustomBusinessService) businessService: ApiCustomBusinessService;
  @Input() blocks: ActionBlock[];
  @Input() hidden: boolean;
  @Output() openBlock = new EventEmitter();

  onBlockOpen(blockId: number) {
    this.openBlock.emit(blockId);
  }
}
