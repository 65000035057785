import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ApiCoreGameRanksService } from "../../../../api/core/services/api-game-ranks.service";
import { ApiBusinessRanksService } from "../../../../api/custom/services/api-business-ranks.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { RANK_TYPE } from "../../../../consts/custom/rank-type.const";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";
import { RankType } from "../../../../interfaces/custom/rank-type.interface";

@Component({
  selector: "app-ranking-list",
  templateUrl: "./ranking-list.component.html",
})
export class RankingListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { location: number };

  RANK_TYPE = RANK_TYPE;
  rankList: any[] = [];
  activeRankType: RankType;
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate = new OwDate();
  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  RANKS_TYPES = {
    GAMES: {
      label: "RANKINGI",
      order: 1,
      type: RANK_TYPE.GAME,
      show: true,
    },
  };

  ngOnInit() {
    this.clearDate();
    this.changeActiveRankType(this.RANKS_TYPES.GAMES);
  }

  clearDate() {
    this.owDate = new OwDate();
  }

  clearRanks() {
    this.rankList = [];
  }

  changeActiveRankType(activeRankType: RankType) {
    this.activeRankType = activeRankType;
    this.getRanks();
  }

  getRanks() {
    this.clearRanks();

    switch (this.activeRankType) {
      case this.RANKS_TYPES.GAMES:
        this.gameRanks();
        break;
    }
  }

  gameRanks() {
    this.apiCoreGameRanksService.getGameRanks({ location: this.data.location }).subscribe(
      (resp: RankGame[]) => {
        switch (this.activeRankType) {
          case this.RANKS_TYPES.GAMES:
            this.rankList = resp.filter(rank => {
              return !rank.parameters || (rank.parameters && rank.parameters.is_special !== 1);
            });
            break;
        }

        this.setVisibleRanks();
      },
      errResp => {
        this.rankList = [];
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  openRankDetails(rank: RankGame) {
    switch (this.activeRankType.type) {
      case RANK_TYPE.GAME:
        this.openRankDetailsGame(rank);
        break;
    }
  }

  openRankDetailsGame(rank: RankGame) {
    if (!rank.last_calculated_at) {
      this.dialogService.openAlert({
        description: "Ranking nie jest jeszcze gotowy. Spróbuj ponownie jutro!",
      });
      return;
    }
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: rank.rank_edition_id,
        },
      },
    });
  }

  keyAscOrder = (a: KeyValue<number, RankType>, b: KeyValue<number, RankType>): number => {
    return b.value.order > a.value.order ? -1 : a.value.order > b.value.order ? 1 : 0;
  };

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.setPagination();
    this.getRanks();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.setPagination();
    this.getRanks();
  }

  openRankDescription(rank) {
    this.dialogService.openAlert({
      title: rank.name,
      description: rank.description,
    });
  }

  setVisibleRanks() {
    this.rankList = this.rankList.filter(rank => {
      const visibleOnTitleIds = rank.parameters && rank.parameters.visible_on_title_ids;

      if (!visibleOnTitleIds) {
        return true;
      } else {
        for (const titleId of visibleOnTitleIds) {
          if (titleId === this.playerService.firstTimeMePlayerSnapshot.title_id.id) {
            return true;
          }
        }
      }
    });
  }
}
