<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TITLE -->
  <div class="title">
    <p>Wpisz kod rekrutacyjny</p>
  </div>

  <div class="loading-opacity">
    <!-- DESCRIPTION -->
    <div class="description margin-v1">
      <p>
        Inny gracz zaprosił Cię do gry? Podaj jego kod rekrutacyjny i odbierz nagrodę. <br>
        Uwaga: gracz, który Cię zaprosił otrzyma swoją nagrodę dopiero jak osiągniesz
        {{ inviteePrizes?.max_level_to_fill_code }} poziom.
      </p>
    </div>

    <!-- PRIZE -->
    <div class="player-prize">
      <div class="prize-box">
        <div class="s1">Wartość nagrody:</div>
        <div class="s2">
          <img class="img-currency-big" [src]="'money.png' | asset:'currency/big'"/>
          {{ inviteePrizes?.amount || 0 }}
        </div>
      </div>
    </div>

    <!-- CODE -->
    <form [formGroup]="form" *ngIf="form" class="loading-opacity">
      <div class="player-code">
        <span>Twój kod rekrutacyjny:</span>
        <div>
          <input
            class="control-input"
            formControlName="playerCode"
            type="text"
            [class.readonly]="playerLevel >= inviteePrizes?.max_level_to_fill_code"
            [readonly]="playerLevel >= inviteePrizes?.max_level_to_fill_code"
            placeholder="Wpisz kod"
          />
          <button [disabled]="form.invalid || playerLevel >= inviteePrizes?.max_level_to_fill_code"
                  class="base primary submit"
                  (click)="submitCode()">OK
          </button>
        </div>
      </div>
      <p class="info">*Kod można wpisać do {{ inviteePrizes?.max_level_to_fill_code }} poziomu.</p>
    </form>
  </div>
</div>
