import { MenuButtonConfig } from "../../interfaces/core/menu-buttonts-config.interface";

export const CUSTOM_MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  {
    type: "button",
    place: "menu-left",
    order: 2,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "missions",
    tooltip: "Misje",
    icon: "mission.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "message.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 4,
    name: "ranking",
    gui_notification_type: null,
    gui_unlocks_button: "ranks",
    click_event: "ranking",
    tooltip: "Rankingi",
    icon: "ranking.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 5,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: "warehouse",
    click_event: "warehouse",
    tooltip: "Magazyn",
    icon: "warehouse.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 6,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "missions",
    tooltip: "Misje",
    icon: "mission.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 7,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "message.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 8,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: "warehouse",
    click_event: "warehouse",
    tooltip: "Magazyn",
    icon: "warehouse.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 9,
    name: "ranking",
    gui_notification_type: null,
    gui_unlocks_button: "ranks",
    click_event: "ranking",
    tooltip: "Rankingi",
    icon: "ranking.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 10,
    name: "prepaid",
    gui_notification_type: null,
    gui_unlocks_button: "scratch_cards_code",
    click_event: "prepaid",
    tooltip: "Moja karta przedpłacona",
    icon: "prepaid.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },

  {
    type: "button",
    place: "menu-left",
    order: 11,
    name: "qa",
    gui_notification_type: null,
    gui_unlocks_button: "qa",
    click_event: "qa",
    tooltip: "Quizy",
    icon: "quiz.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 12,
    name: "photobooth",
    gui_notification_type: null,
    gui_unlocks_button: "photobooth",
    click_event: "photobooth",
    tooltip: "Fotowrzutka",
    icon: "photobooth.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 13,
    name: "exchange",
    gui_notification_type: null,
    gui_unlocks_button: "exchange",
    click_event: "exchange",
    tooltip: "Kantor",
    icon: "exchange.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 14,
    name: "typer",
    gui_notification_type: null,
    gui_unlocks_button: "typer",
    click_event: "typer",
    tooltip: "EUROTYPER'24",
    icon: "type.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
];
