<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- DIALOG TITLE -->
  <div class="top-bar">
    <div class="title">Twoja karta przedpłacona Edenred</div>
  </div>

  <div class="content">
    <div class="description">
      <p>
        Nr seryjny karty: <span class="card-number">{{ card.number }}</span>
      </p>
      <p class="status {{ card.status === CardStatus.Active ? 'active' : 'inactive' }}">
        karta {{ card.status === CardStatus.Active ? "aktywna" : "zablokowana" }}
      </p>
    </div>
    <div class="list-wrapper">
      <div class="header">
        <p>Wartość:</p>
        <p>Tytułem:</p>
        <p>Data:</p>
        <p>Status nagrody:</p>
      </div>
      <ow-perfect-scrollbar class="height-auto" scrollGradient>
        <div class="body">
          <div *ngFor="let item of cardHistory" class="transaction {{ item.is_main ? 'bold' : '' }}">
            <p>{{ item.amount | number }} zł</p>
            <p>{{ item.description }}</p>
            <p>
              {{
                item.is_main
                  ? item.status !== 2
                    ? (item.payout_request_at | date: "yyyy-MM-dd")
                    : (item.top_up_at | date: "yyyy-MM-dd")
                  : ""
              }}
            </p>
            <p class="status payout">
              {{ item.is_main ? (item.status === 2 ? "wypłacona" : "zlecona") : "" }}
            </p>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>
</div>
