import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerActions } from "../../../../../../../../store/player";
import { AppState } from "../../../../../../../../store/state";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ApiMgmService } from "../../../../api/custom/api-mgm.service";

@Component({
  selector: "app-mgm-invitee",
  templateUrl: "./mgm-invitee.component.html",
})
export class MgmInviteeComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiMgmService) apiMgmService: ApiMgmService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;

  form: FormGroup;
  inviteePrizes: Invitee;

  get playerLevel() {
    return this.playerService.player.level;
  }

  ngOnInit() {
    this.clearForm();
    this.getInvitationPrizes();
  }

  clearForm() {
    this.form = this.fb.group({
      playerCode: this.fb.control(null, [Validators.required]),
    });
  }

  submitCode() {
    this.apiMgmService.postInvitationCode(this.form.value.playerCode).subscribe(() => {
      this.store.dispatch(new PlayerActions.FetchPlayer());
      this.dialogService.closeAll();
      setTimeout(() => {
        this.dialogService.openAlert({
          title: "Sukces!",
          description: `Kod rekrutacyjny został aktywowany, <br>` + "a nagroda za wpisanie kodu przyznana.",
        });
      }, 800);
    });
  }

  getInvitationPrizes() {
    this.apiMgmService.getInvitationPrizes().subscribe(resp => {
      this.inviteePrizes = resp["invitee"];
    });
  }
}

export interface Invitee {
  amount: number;
  currency_id: number;
  max_level_to_fill_code: number;
}
