import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { ApiMgmService } from "../../../../api/custom/api-mgm.service";
import { EVENT_DIALOGS_NAMES_MGM_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";

@Component({
  selector: "app-mgm-inviter",
  templateUrl: "./mgm-inviter.component.html",
})
export class MgmInviterComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(UserService) userService: UserService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiMgmService) apiMgmService: ApiMgmService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  inviterPrizes: Inviter[];
  maxPrize: any;

  get userCode() {
    return this.userService.me["my_code"];
  }

  get playerCodeActive() {
    return this.playerService.player["used_code"];
  }

  ngOnInit() {
    this.getInvitationPrizes();
  }

  getInvitationPrizes() {
    this.apiMgmService.getInvitationPrizes().subscribe(resp => {
      this.inviterPrizes = resp["inviter"];
      this.getMaxPrize();
    });
  }

  getMaxPrize() {
    this.maxPrize = 0;
    this.inviterPrizes.forEach(invitee => {
      this.maxPrize += invitee.amount * invitee.received_prizes;
    });
  }

  openMgmInvitee() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MGM_CUSTOM.INVITEE,
    });
  }
}

export interface Inviter {
  amount: number;
  currency_id: number;
  level: number;
  max_prizes: number;
  received_prizes: number;
}
