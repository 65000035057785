import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiCoreGameRanksService } from "../../../../api/core/services/api-game-ranks.service";
import { RankDetails } from "../../../../interfaces/custom/rank-details.interface";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";
import { RANK_CONFIG } from "../ranking-game-details/config/rank.config";

@Component({
  selector: "app-ranking-game-details-info",
  templateUrl: "./ranking-game-details-info.component.html",
})
export class RankingGameDetailsInfo extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(MAT_DIALOG_DATA) data: {
    rank: RankGame;
    rankEditionId: number;
  };

  rankDetails: RankDetails;
  RANK_CONFIG = RANK_CONFIG.WAREHOUSE;

  ngOnInit(): void {
    this.getRankGameDetails();
  }

  getRankGameDetails() {
    this.apiCoreGameRanksService.getGameRanksDetails(this.data?.rankEditionId).subscribe(resp => {
      this.rankDetails = resp;
      this.rankDetails.results = this.rankDetails.players;

      this.rankDetails.results.forEach((p, index) => {
        p.position = index + 1;
      });
    });
  }
}
