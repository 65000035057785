import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { GlobalService } from "../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../constants";
import { BRANCH_SCENE_CONFIG_KEY } from "../../../../ec4-constans/game.constants";
import { BASIC_BOOT_SCENE } from "../../../../game-engine/scenes-basic/basic.constants";

@Injectable({
  providedIn: "root",
})
export class ChangeMapService {
  branchPlayerIslandId: number;

  subs = {
    playerRegions: null,
  };

  constructor(
    private playerService: PlayerService,
    private globalService: GlobalService
  ) {
    this.subscribePlayerRegions();
  }

  subscribePlayerRegions() {
    this.subs.playerRegions = this.playerService.playerRegions
      .pipe(filter(playerRegions => playerRegions != null))
      .subscribe(playerRegions => {
        const islands = playerRegions.regions.reduce((_islands, region) => {
          _islands.push(...region.islands);
          return _islands;
        }, []);

        // BRANCH
        const branch = islands.find(_island => _island.config_name === BRANCH_SCENE_CONFIG_KEY);
        if (branch) {
          this.branchPlayerIslandId = branch.player_island_id;
        }
      });
  }

  goToCity() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
    });
  }

  goToBranch() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: BRANCH_SCENE_CONFIG_KEY,
        playerIslandId: this.branchPlayerIslandId,
      },
    });
  }
}
