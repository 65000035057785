import { EventDialog } from "../../../../../../core/interfaces/event-dialog.interface";
import { ChallengesDetailsComponent } from "../../base/dialogs/challenges-details/challenges-details.component";
import { ChallengesDifficultyLevelComponent } from "../../base/dialogs/challenges-difficulty-level/challenges-difficulty-level.component";
import { ClientListComponent } from "../../base/dialogs/client-list/client-list.component";
import { NewBusinessContainerComponent } from "../../base/dialogs/new-business-container/new-business-container.component";
import { PlanDetailsComponent } from "../../base/dialogs/plan-details/plan-details.component";
import { PlanListComponent } from "../../base/dialogs/plan-list/plan-list.component";
import { PlanShopListComponent } from "../../base/dialogs/plan-shop-list/plan-shop-list.component";
import { TasksDailyComponent } from "../../base/dialogs/tasks-daily/tasks-daily.component";
import { TasksListComponent } from "../../base/dialogs/tasks-list/tasks-list.component";
import { TasksWeeklyComponent } from "../../base/dialogs/tasks-weekly/tasks-weekly.component";
import { EVENT_DIALOGS_NAMES_NEW_BUSINESS } from "./event-names.const";

export const EVENT_DIALOGS_NEW_BUSINESS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_PLAN_LIST]: PlanListComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_CLIENT_LIST]: ClientListComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_CHALLENGES_LIST]: ChallengesDifficultyLevelComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_CHALLENGES_DETAILS]: ChallengesDetailsComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_SHOP_LIST]: PlanShopListComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_PLAN_DETAILS]: PlanDetailsComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_LIST]: TasksListComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_DAILY]: TasksDailyComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_TASKS_WEEKLY]: TasksWeeklyComponent,
  [EVENT_DIALOGS_NAMES_NEW_BUSINESS.NEW_BUSINESS_CONTAINER]: NewBusinessContainerComponent,
};
