export const RANK_CONFIG = {
  DEFAULT: {
    columns: [
      { header: null, value: "position" },
      // { header: null, custom: true, template: "avatar" },
      { header: "Nick", value: "username" },
      { header: "Hala", value: "branch_name" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: null, custom: true, template: "prizes", prizes: true, textAlign: "right" },
    ],
  },
  BRANCH: {
    columns: [
      { header: null, value: "position" },
      { header: "Hala", value: "branch_name" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: null, custom: true, template: "prizes", prizes: true, textAlign: "right" },
    ],
  },
  AM: {
    columns: [
      { header: null, value: "position" },
      { header: "Imię", value: "first_name" },
      { header: "Nazwisko", value: "last_name" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: null, custom: true, template: "prizes", prizes: true, textAlign: "right" },
    ],
  },
  WAREHOUSE: {
    columns: [
      { header: null, value: "position" },
      { header: null, custom: true, template: "avatar" },
      { header: "Nick", value: "name" },
      { header: "Punkty", value: "points" },
    ],
  },
};
