<!--<div class="global-dialog ow-dialog window-a primary no-transparent">-->
  <loading></loading>

  <!-- DIALOG EXIT -->
  <!--  <m-ui-close-button></m-ui-close-button>-->

  <div class="wrapper">
    <app-business-navigation [reverseLeftTop]="true" blockButton="TASKS">
      <ng-container left-top>
        <button class="round-button fixed c-green" (click)="backToListView()">
          <i class="far fa-arrow-to-left"></i>
        </button>
      </ng-container>
    </app-business-navigation>

    <div class="content">
      <i class="far fa-chevron-square-left left arrow-pagination" (click)="prevTaskView()"></i>
      <i class="far fa-chevron-square-right right arrow-pagination" (click)="nextTaskView()"></i>
      <div class="title">
        <p>{{ task?.selected?.task_definition?.name }}</p>
      </div>
      <div class="task">
        <p>{{ task?.selected?.task_definition?.description }}</p>
      </div>
      <ow-perfect-scrollbar scrollGradient>
        <div class="table" [class.narrow-columns]="task?.calculated_days?.length < 6">
          <div class="plan" *ngFor="let day of task?.calculated_days">
            <p class="date">{{ day?.calculated_date }}</p>
            <ng-container *ngIf="day?.scopes" [ngTemplateOutlet]="scopes" [ngTemplateOutletContext]="{ day: day }"></ng-container>
            <ng-container
              *ngIf="day?.base_currency_prizes"
              [ngTemplateOutlet]="currency_prizes"
              [ngTemplateOutletContext]="{ day: day }"></ng-container>
            <ng-container *ngIf="day.scopes" [ngTemplateOutlet]="fullfilment" [ngTemplateOutletContext]="{ day: day }"></ng-container>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="prizesTotal"></ng-container>
      </ow-perfect-scrollbar>
    </div>
  </div>
<!--</div>-->

<ng-template #currency_prizes let-day="day">
  <div class="sale">
    <div class="prize" *ngFor="let currency of day?.base_currency_prizes; let i = index">
      <p class="value">{{ currency?.amount }}</p>
      <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.H" [hideValue]="true" [lack]="false"></m-ui-currency>
    </div>
  </div>
</ng-template>
<ng-template #scopes let-day="day">
  <div class="sale" *ngFor="let scope of day?.scopes; let i = index" [class.green-background]="day?.realized_scope_id === scope?.scope_id">
    <p class="percentage" [class.rounded]="i === 0">{{ (scope?.target * 100).toFixed(0) }}%</p>
    <div class="prize" *ngFor="let currency of scope?.currency_prizes">
      <p class="value">{{ currency.amount }}</p>
      <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.H" [hideValue]="true" [lack]="false"></m-ui-currency>
    </div>
  </div>
</ng-template>
<ng-template #fullfilment let-day="day">
  <div class="fullfilment">
    <p class="text">Wykonanie</p>
    <p class="number">{{ day?.is_cleared === null || day?.performance === null ? "b/d" : ((day?.performance) | percent: "1.0-2") }}</p>
  </div>
</ng-template>
<ng-template #prizesTotal>
  <div class="total-prizes">
    <p class="text">Suma nagród w tym tygodniu:</p>
    <ng-container *ngIf="task?.dailyHelper?.sumResourceScopes; else noPrizes">
      <div class="prizes" *ngFor="let currency of task?.dailyHelper?.sumResourceScopes?.currencies">
        <p class="value">{{ currency?.amount }}</p>
        <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.H" [hideValue]="true" [lack]="false"></m-ui-currency>
      </div>
    </ng-container>
    <ng-template #noPrizes>
      <ng-container *ngIf="task?.selected?.scopes[0]?.currency_prizes?.length; else zero">
        <div class="prizes" *ngFor="let currency of task.selected.scopes[0].currency_prizes">
          <p class="value">0</p>
          <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.H" [hideValue]="true" [lack]="false"></m-ui-currency>
        </div>
      </ng-container>
      <ng-template #zero>
        <div class="prizes">
          <p class="value">0</p>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>
