import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import * as R from "ramda";
import { Observable, of } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";
import { CurrencyBalanceDetails } from "../../core/interfaces/currency";
import { CurrencyService } from "../../core/providers/currency.service";
import { User } from "../../modules/user/interfaces/user";
import { UserService } from "../../modules/user/providers/user.service";
import { AppState } from "../state";
import { UtilityActions } from "../utility";
import {
  ActionTypes,
  FetchUser,
  FetchUserFailure,
  FetchUserSuccess,
  NullAction,
  UpdateUser,
  UpdateUserCurrenciesBalances,
  UpdateUserDiscount,
  UpdateUserSuccess,
} from "./actions";
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private currencyService: CurrencyService
  ) {}

  $fetchBoard: Observable<any | Action> = createEffect((): any =>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_USER, ActionTypes.UPDATE_USER),
      mergeMap((action: FetchUser | UpdateUser) => {
        if (action.payload) {
          this.handlerUserBeforeSave(<User>action.payload);
          return of();
        } else {
          return this.fetchUser();
        }
      })
    )
  );

  $updateUserCurrenciesBalances = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ActionTypes.UPDATE_USER_CURRENCY_BALANCES),
        mergeMap((action: UpdateUserCurrenciesBalances) => {
          return this.updateUserCurrenciesBalances(action.payload);
        })
      )
  );

  $updateUserDiscount = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ActionTypes.UPDATE_USER_DISCOUNT),
        mergeMap((action: UpdateUserDiscount) => {
          return this.updateUserDiscount(action.payload);
        })
      )
  );

  fetchUser() {
    return this.userService.getMe().pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave(user);
      }),
      mergeMap((user: User) => {
        return [
          new UtilityActions.UpdateMePlayerId({
            playerId: user.selected_player_id,
          }),
          new FetchUserSuccess(user),
        ];
      }),
      catchError((error: any) => {
        return of(new FetchUserFailure(omitErrorResponseHelper(error)));
      })
    );
  }

  updateUserCurrenciesBalances(balances) {
    return of(this.userService.me).pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave({
          ...user,
          currency_balances: balances,
        });
      }),
      mergeMap(() => {
        return of(new NullAction());
      })
    );
  }

  handlerUserBeforeSave(user: User) {
    window["storeUser"] = user;
    Sentry.setUser({
        external_id: window["storeUser"].external_id ?? "unknown",
        id: window["storeUser"].id ?? "unknown",
        previous_login_at: window["storeUser"].previous_login_at ?? "unknown",
    });
    const userClone = R.clone(user);
    userClone.currency_balances = <CurrencyBalanceDetails[]>this.currencyService.getCurrencyDefinitions(userClone.currency_balances);
    this.userService.me = userClone;
    this.store.dispatch(new UpdateUserSuccess(userClone));
  }

  updateUserDiscount(currentDiscountValue: number) {
    return of(this.userService.me).pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave({
          ...user,
          current_discount_value: currentDiscountValue,
        });
      }),
      mergeMap(() => {
        return of(new NullAction());
      })
    );
  }
}
