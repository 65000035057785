export const swiperInjectionStyles = `
          .swiper-pagination-bullet {
            background-color: #ebe5d5;
            border-radius: calc(50px * var(--multiply));
            transition: .3s;
            transform: scale(0);
            transform-origin: 50% 50%;
            height: 14px;
            margin: 0 2px;
            width: 14px;
            border: 2px solid #000000;
            box-sizing: border-box;
            opacity: 1;
      
            &.swiper-pagination-bullet-active-prev-prev {
              transform: scale(0.4);
            }
      
            &.swiper-pagination-bullet-active-prev {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active {
              &.swiper-pagination-bullet-active-main {
                transform: scale(1);
                width: 18px;
                height: 18px;
                background-color: #77cc4f;
                margin: 0 2px;
                border: 2px solid #000000;
              }
            }
      
            &.swiper-pagination-bullet-active-next {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active-next-next {
              transform: scale(0.4);
            }
          }
      `;
export const swiperInjectionStylesPaginationGap = `
            .swiper{
                padding-bottom: calc(10px * var(--multiply));
            }
            .swiper-pagination {
                position: absolute;
                text-align: center;
                transition: .3s opacity;
                transform: translate3d(0, 0, 0);
                z-index: 10;
                
            }
                  
          .swiper-pagination-bullet {
            
            background-color: #ebe5d5;
            border-radius: calc(50px * var(--multiply));
            transition: .3s;
            transform: scale(0);
            transform-origin: 50% 50%;
            height: 14px;
            margin: 0 2px;
            width: 14px;
            border: 2px solid #000000;
            box-sizing: border-box;
            opacity: 1;
            bottom:0;
            &.swiper-pagination-bullet-active-prev-prev {
              transform: scale(0.4);
            }
      
            &.swiper-pagination-bullet-active-prev {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active {
              &.swiper-pagination-bullet-active-main {
                transform: scale(1);
                width: 18px;
                height: 18px;
                background-color: #77cc4f;
                margin: 0 2px;
                border: 2px solid #000000;
              }
            }
      
            &.swiper-pagination-bullet-active-next {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active-next-next {
              transform: scale(0.4);
            }
          }
          .swiper-pagination-bullets-dynamic{
            bottom:0 !important;
          }
      `;
