<!-- DIALOG EXIT -->
<m-ui-close-button></m-ui-close-button>
<!-- PLAYER BAR -->
<app-hud-player [allowHover]="false" [changeProfileImage]="true" [player]="player"></app-hud-player>

<!-- EXPERIENCE -->
<span class="experience"> {{ player.points_balance | NumberWithSpaces }} {{ "player-details.exp" | myTranslate }} </span>

<div class="name">
  {{ playerService.player.first_name }}
  {{ playerService.player.last_name }}
</div>

<div class="email">
  {{ playerService.player.email }}
</div>

<div class="buttons">
  <!-- GUIDE -->
  <a [href]="LINKS.MANUAL" target="_blank">
    <button class="base primary">Pobierz przewodnik</button>
  </a>

  <!-- TERMS -->
  <a [href]="LINKS.TERMS" target="_blank">
    <button class="base primary">
      {{ "player-details.terms" | myTranslate }}
    </button>
  </a>

  <button
    class="base primary"
    (click)="openBankAccountNumber()"
    *ngIf="(playerService.player.title_id.id === 4 || playerService.player.title_id.id === 1) && !isImpersonated">
    Nr konta bankowego
  </button>
</div>

<!-- LOGOUT -->
<button class="base logout" id="logout" (click)="logout()">
  {{ "player-details.logout" | myTranslate }}
</button>
